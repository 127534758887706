import angular from 'angular';

class SfaComponent {
  constructor($sce, SfaService, SessionService) {
    this.$sce = $sce;
    this.sfaService = SfaService;
    this.sessionService = SessionService;
  }

  $onInit() {
    let user = this.sessionService.getUser();
    this.showSfa = user.hasEnabledFeature('sfa');
    if (this.showSfa) {
      this.getSfaContent();
    }
  }

  getSfaContent() {
    this.sfaService.loadSfa().then((data) => {
      if (data && data.html) {
        let html = data.html.replace('height: 0px; display:none', '');
        this.sfaHtml = this.$sce.trustAsHtml(html);
      } else {
        this.sfaHtml = null;
      }
    });
  }
}

angular.module('ll').component('sfa', {
  template: require('./sfa.component.html'),
  controller: SfaComponent,
  controllerAs: 'vm',
  bindings: {
  }
});

import angular from 'angular';

angular.module('ll.core')
  .config(materialColorConfiguration);

materialColorConfiguration.$inject = ['$mdThemingProvider'];

function materialColorConfiguration($mdThemingProvider) {

  $mdThemingProvider.definePalette('locallifePalette', {
    '50': '#d1ff95',
    '100': '#c7ff6e',
    '200': '#abff4f',
    '300': '#7bd85b',
    '400': '#5acc3a',
    '500': '#2ebb32',
    '600': '#51934f',
    '700': '#3a8244',
    '800': '#207534',
    '900': '#07601d',
    'A100': '#d3efc4',
    'A200': '#b7f2b0',
    'A400': '#88ca86',
    'A700': '#69b56d',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': ['50', '100', '200', '300', '400', 'A100']
  });

  // Modified copy of the 'blue-grey' theme from angular-material.js
  $mdThemingProvider.definePalette('locallifeAccentedPalette', {
    '50': '#eceff1',
    '100': '#cfd8dc',
    '200': '#2ebb32', //b0bec5 replaced by 2ebb32 to make switches look selected
    '300': '#90a4ae',
    '400': '#78909c',
    '500': '#607d8b',
    '600': '#546e7a',
    '700': '#455a64',
    '800': '#37474f',
    '900': '#263238',
    'A100': '#cfd8dc',
    'A200': '#2ebb32', //b0bec5 replaced by 2ebb32 to make switches look selected
    'A400': '#78909c',
    'A700': '#455a64',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': '50 100 200 300 A100 A200',
    'contrastStrongLightColors': '400 500 700'
  });

  $mdThemingProvider.theme('default')
    .primaryPalette('locallifePalette')
    .accentPalette('locallifeAccentedPalette');
}

import angular from 'angular';
import {createRoute} from '../../core/config';

class VicinityController {

  constructor(ToolbarService, SessionService, FeedService, $translate) {
    this.currentUser = SessionService.getUser();
    this.feedService = FeedService;
    this.$translate = $translate;
    this.toolbarService = ToolbarService;
  }

  $onInit() {
    this.toolbarService.setPageTitle(this.$translate.instant('VICINITY.TITLE', {neighborhood: this.currentUser.neighborhood.name}));
  }

  loadPosts(page, pageSize) {
    return this.feedService.getAreaFeed(this.categoryId, page, pageSize);
  }
}

angular.module('ll')
  .component('vicinity', {
    template: '<div><feed level="\'area\'" on-feed-bottom="vm.loadPosts(page, pageSize)"></feed></div>',
    controller: VicinityController,
    controllerAs: 'vm',
    bindings: {
      categoryId: '<'
    }
  })
  .config(createRoute({
    name: 'app.vicinity',
    component: 'vicinity',
    url: '/vicinity?categoryId',
    params: {categoryId: null},
    resolve: {
      categoryId: ($transition$) => $transition$.params().categoryId
    }
  }));

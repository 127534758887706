import angular from 'angular';

class EnergyConsumptionController{
  constructor(EnergyService, EnvironmentSettings, $translate, $state, moment){
    this.energyService = EnergyService;
    this.energyConsumptionData = [];
    this.$translate = $translate;
    this.$state = $state;
    this.moment = moment;
    this.avatars = [
      '/images/energy/dragon-natural-left.png',
      '/images/energy/dragon-natural-left.png',
      '/images/energy/dragon-happy-left.png'
    ];
    this.levels = ['household','building','neighborhood'];
    this.isNeighbourhoodLevelEnabled = false;
    this.selectedTab = 0;
    this.tipTranslated = null;
    this.isDemo = null;
    this.country = {country: EnvironmentSettings.country};
  }

  $onInit(){
    // eslint-disable-next-line no-undef
    this.isDemo = __IS_DEVELOPMENT__ ? 1 : 0;
    this.pauseHourIndex = this.pauseHourIndex  || 0;
    if (!this.isPauseHour){
      this.energyService.getConsumptionVsTypical('household', 'day', null, this.isDemo)
        .then(data => {
          this.setEnergyBars(data);
          this.setTip();
        });
    } else {
      this.setEnergyBars(this.pauseHourModel);
      this.pauseHourModel.datetimeUTC = this.moment.utc(this.pauseHourModel.datetimeUTC).local().format('YYYY-MM-DD HH:mm');
      this.setPauseHourMessage(this.pauseHourModel.isPassed);
      this.setTip();
    }
  }

  setTip() {
    if (this.isPauseHour) {
      this.tipTranslated = this.energyService.getPauseHourTip();
    } else {
      this.tipTranslated = this.energyService.getEnergyTip();
    }
  }

  setPauseHourMessage(isPassed) {
    let numberOfPauseHourMessageTitles = 1;
    let numberOfPauseHourMessages = 1;
    let randomIndexTitle = Math.floor(Math.random() * numberOfPauseHourMessageTitles);
    let randomIndexMessage = Math.floor(Math.random() * numberOfPauseHourMessages);
    this.pauseHourMessageTitleTranslated = this.$translate.instant('ENERGY_PAUSE_HOUR.PREVIOUS_CARD_MESSAGE.TITLE.' + (isPassed ? 'PASSED' : 'FAILED') + '.' + randomIndexTitle.toString());
    this.pauseHourMessageMessageTranslated = this.$translate.instant('ENERGY_PAUSE_HOUR.PREVIOUS_CARD_MESSAGE.MESSAGE.' + (isPassed ? 'PASSED' : 'FAILED') + '.' + randomIndexMessage.toString());
  }

  setEnergyBars(data){
    this.energyConsumptionData = data;

    this.setBarStyle(this.energyConsumptionData.best, 'best');
    this.setBarStyle(this.energyConsumptionData.self, 'self');
    this.setBarStyle(this.energyConsumptionData.average, 'average');

    switch (data.avatarState){
      case -1:
        this.avatar = this.avatars[0];
        break;
      case 0:
        this.avatar = this.avatars[1];
        break;
      case 1:
        this.avatar = this.avatars[2];
        break;
    }
  }

  setBarStyle(barData, barType){
    let backgroundColor = '';
    let dif = 1 - barData;

    if (barType !== 'self' && this.isPauseHour && this.energyConsumptionData.participants < 3) {
      return; // Don't show averages if amount of participants is too small
    }

    if (dif < 0){
      if (this.isPauseHour) {
        this.selfBarText = this.$translate.instant('ENERGY_PAUSE_HOUR.ME').toUpperCase() + ': ' + this.pauseHourModel.savedKwh + ' kWh';
        backgroundColor = '#428ae8';
      } else {
        if (barType === 'self') {
          this.selfBarText = Math.round(dif * -100) + '% ' + this.$translate.instant('ENERGY.OVER').toUpperCase();
        }
        backgroundColor = '#FF5046';
      }

    } else {
      if (dif >= 0.2) {
        backgroundColor = this.isPauseHour ? '#FF5046' : '#37B93C';
      } else {
        backgroundColor = this.isPauseHour ? '#FF5046' : '#F7C531';
      }
    }

    if (barType === 'self'){
      this.selfBarText = (this.isPauseHour ? this.$translate.instant('ENERGY_PAUSE_HOUR.ME') + ': ' + this.pauseHourModel.savedKwh : this.energyConsumptionData.consumption) + ' kWh';
    }

    let percentage = Math.round(barData * 100);

    if (percentage < 50 && this.isPauseHour) {
      document.getElementsByName('selfBarText')[this.pauseHourIndex].classList.add('right');
    }

    if (percentage <= 120 && percentage > 100){
      percentage = 105 + '%';
    } else {
      if (percentage > 120){
        percentage = 120 + '%';
      } else {
        percentage += '%';
      }
    }
    document.getElementsByName(barType)[this.pauseHourIndex].setAttribute('style','background-color: ' + backgroundColor + '; width: ' + percentage);
  }

  updateConsumptionData(selectedTab){
    if (!angular.isUndefined(selectedTab)){
      this.selectedTab = selectedTab;
      for (let i = 0; i < this.levels.length; i++){
        document.getElementsByName(this.levels[i])[0].classList.remove('active');
      }
      document.getElementsByName(this.levels[this.selectedTab])[0].classList.add('active');
    }
    this.energyService.getConsumptionVsTypical(this.levels[this.selectedTab], 'day', null, this.isDemo)
      .then(data => {
        this.setEnergyBars(data);
      });
  }

  goToEnergyConsumptionDetails(){
    if (this.$state.current.name === 'app.energy.summary'){
      this.$state.go('app.energyConsumptionDetails');
    }
  }
}

angular.module('ll')
  .component('energyConsumption', {
    template: require('./energyconsumption.component.html'),
    controller: EnergyConsumptionController,
    controllerAs: 'vm',
    bindings: {
      isPauseHour: '<',
      pauseHourModel: '<',
      pauseHourIndex: '<'
    }
  });

import angular from 'angular';
import {createRoute} from '../../core/config';

angular.module('ll')
  .component('setFacilityId', {
    template: require('./setFacilityId.html'),
    controller: SetFacilityIdController,
    controllerAs: 'vm',
    bindings: {accessCode: '<'}
  })
  .config(createRoute({
    name: 'setFacilityidTemplate.setFacilityId',
    component: 'setFacilityId',
    url: '/setfacilityid/:accessCode',
    resolve: {
      accessCode: ($transition$) => $transition$.params().accessCode
    },
    data: {
      authenticate: false
    }
  }));

function SetFacilityIdController($translate, EnergyService, LoggingService, uiAlertService) {
  /* globals */
  const vm = this;

  // Variables
  vm.consent = null;
  vm.facilityId = null;
  // vm.accessCode = $transition$.params().accessCode;
  vm.hasFacilityId = false;
  vm.loading = false;
  vm.isSaving = false;
  vm.hasSaved = false;

  // Accessable Functions
  vm.onSubmitForm = onSubmitForm;
  vm.$onInit = init;

  // Internal variables and settings

  // Functions

  function init() {
    loadConsent(vm.accessCode);
  }

  function loadConsent(accessCode) {
    vm.loading = true;
    EnergyService.getConsentByAccessCode(accessCode).then((consent) => {
      if (consent) {
        vm.consent = consent;
        if (consent.facilityId) {
          vm.facilityId = consent.facilityId;
          vm.hasFacilityId = true;
        }
      } else {
        vm.error = {
          title: $translate.instant('ELECTRICITYDATACONSENT.MESSAGE.COULD_NOT_FETCH_CONSENT'),
          message: $translate.instant('ELECTRICITYDATACONSENT.ERROR.SIGNED_CONSENT_FOR_ACCESS_CODE_NOT_FOUND')
        };
      }
      vm.loading = false;
    }, (err) => {
      LoggingService.error('Error fetching electricitydata consent by access code ' + accessCode, err);
      uiAlertService.showError($translate.instant('ELECTRICITYDATACONSENT.MESSAGE.COULD_NOT_FETCH_CONSENT'), '');
      vm.loading = false;
    });
  }

  function onSubmitForm() {
    vm.form.$setSubmitted(); // triggers any error messages on fields with errors
    if (!vm.form.$valid || vm.form.$pristine) {
      vm.isValidated = true;
    } else {
      saveFacilityId();
    }
  }

  function saveFacilityId() {
    if (vm.isSaving) {
      return;
    }

    vm.isSaving = true;

    EnergyService.saveDsoDataByAccessCode(vm.facilityId, vm.consent.electricityDataInterval, vm.consent.needsAttention, vm.consent.dsoComment, vm.accessCode).then((result) => {
      vm.isSaving = false;
      vm.hasSaved = true;
      uiAlertService.showSuccess($translate.instant('ELECTRICITYDATACONSENT.MESSAGE.FACILITYID_SAVED'), '');
    }, (err) => {
      vm.isSaving = false;
      let errorTitle = $translate.instant('ELECTRICITYDATACONSENT.ERROR.SAVE_FACILITYID_TITLE');

      if (err.data && err.data.message === 'FACILITYID_ALREADY_SET') {
        uiAlertService.showError(errorTitle, $translate.instant('ELECTRICITYDATACONSENT.ERROR.FACILITYID_ALREADY_SET'));
      } else {
        uiAlertService.showError(errorTitle, uiAlertService.errorToString(err.data));
      }
    });
  }
}

import angular from 'angular';

angular.module('ll')
  .factory('RegionRepository', RegionRepository);

RegionRepository.$inject = ['$http', 'EnvironmentSettings', 'LoggingService'];

function RegionRepository($http, EnvironmentSettings, LoggingService) {
  const apiUrl = EnvironmentSettings.baseUrl;

  return {
    getRegionForToken,
    saveRegionForToken,
    getRegions,
    getRegionPolygons,
    getRegionForPointWKT,
    createRegion,
    saveRegionPolygon,
    deleteRegionPolygon,
    getRegionForAddress,
  };

  function getRegionForToken(token) {
    return $http.get(`${apiUrl}/api/region/polygon/${token}`)
      .then(resp => resp.data, resp => LoggingService.error('Error getting one time usage region token', resp));
  }

  function saveRegionForToken(token, polygon) {
    let data = {polygon: polygon};
    return $http.put(`${apiUrl}/api/region/polygon/${token}`, data)
      .then(resp => resp.data, resp => LoggingService.error('Error updating region polygon for one time usage token', resp));
  }

  function getRegions() {
    return $http.get(`${apiUrl}/api/region/regions`)
      .then(resp => resp.data, resp => LoggingService.error('Error getting regions', resp));
  }

  function getRegionPolygons() {
    return $http.get(`${apiUrl}/api/region/polygons`)
      .then(resp => resp.data, resp => LoggingService.error('Error getting region polygons', resp));
  }

  function getRegionForPointWKT(pointWKT) {
    return $http.get(`${apiUrl}/api/region/forpointwkt`, {params: {pointWKT: pointWKT}})
      .then(resp => resp.data, resp => LoggingService.error('Error getting region for point wkt', resp));
  }

  function createRegion(region) {
    let data = {
      name: region.name,
      city: region.city,
      municipality: region.municipality,
      coordinates: region.coordinates
    };
    return $http.post(`${apiUrl}/api/region`, data)
      .then(resp => resp.data, resp => LoggingService.error('Error creating region', resp));
  }

  function saveRegionPolygon(regionId, polygonWKT, surroundingRegionIds) {
    let data = {
      polygonWKT: polygonWKT,
      surroundingRegionIds: JSON.stringify(surroundingRegionIds)
    };
    return $http.put(`${apiUrl}/api/region/${regionId}/polygon`, data)
      .then(resp => resp.data, resp => LoggingService.error('Error updating region polygon', resp));
  }

  function deleteRegionPolygon(regionId) {
    return $http.delete(`${apiUrl}/api/region/${regionId}/polygon`)
      .then(resp => resp.data, resp => LoggingService.error('Error deleting region polygon', resp));
  }

  function getRegionForAddress(address) {
    let params = {address};
    return $http.get(`${apiUrl}/api/region`, {params}).then(resp => resp.data);
  }
}

import angular from 'angular';

class SystemVersionComponentController {
  constructor(Version) {
    this.version = Version;
  }
}

angular.module('ll').component('systemVersion', {
  template: require('./system-version.component.html'),
  controller: SystemVersionComponentController,
  controllerAs: 'vm'
});

/* eslint-disable no-console */
import angular from 'angular';

angular.module('ll.core').run(transitionHook);

transitionHook.$inject = ['$transitions', '$rootScope', 'SessionService', '$timeout', '$mdSidenav', '$mdComponentRegistry'];

function transitionHook($transitions, $rootScope, SessionService, $timeout, $mdSidenav, $mdComponentRegistry) {
  let stateRequiringAuthentication = {
    to: (state) => !!state.data && !!state.data.authenticate
  };

  let checkCredentials = (transition) => {
    if (SessionService.isAuthenticated()) {
      return transition;
    }
    let targetState = {
      identifier: transition.targetState().identifier().name,
      params: transition.targetState().params()
    };
    SessionService.setSession('redirectTo', targetState);
    return transition.router.stateService.target('welcome.login');
  };

  $transitions.onBefore(stateRequiringAuthentication, checkCredentials);

  let loginState = {
    exiting: 'welcome.login'
  };

  let checkRedirectTo = (transition) => {
    let redirectState = SessionService.getSession('redirectTo');
    if (redirectState) {
      SessionService.setSession('redirectTo', null);
      return transition.router.stateService.target(redirectState.identifier, redirectState.params);
    }
    return transition;
  };

  $transitions.onExit(loginState, checkRedirectTo);

  let goingToWelcomePages = {
    to: 'welcome.**'
  };

  let redirectToFeedIfAuthenticated = (transition) => {
    // Go to the change password page if being logged in to LocalLife but requesting the password reset for an Envac Reflow user
    if (getCurrentTarget(transition) === 'welcome.changePassword' && transition.targetState().params().platformKey === 'envac_reflow') {
      return transition;
    }
    return SessionService.isAuthenticated() ? transition.router.stateService.target('app.neighborhood') : transition;
  };
  $transitions.onBefore(goingToWelcomePages, redirectToFeedIfAuthenticated);

  let scrolltop = (transition) => {
    $timeout(() => {
      let scrollableContent = document.getElementsByClassName('scrollableContent');
      if (scrollableContent.length > 0) {
        scrollableContent[0].scrollTo(0, 0);
      }
    });
  };
  $transitions.onFinish({}, scrolltop);


  let closeSlideMenu = () => {
    $mdComponentRegistry.when('sideMenu').then(function() {
      $mdSidenav('sideMenu').close();
    });
    // $mdSidenav('sideMenu').close();
  };

  $transitions.onStart({}, closeSlideMenu);

  let goingToAnyAppScreen = {
    to: 'app.**'
  };

  let checkMandatoryRedirects = (transition) => {
    let user = SessionService.getUser();
    let currentTarget = getCurrentTarget(transition);
    if (currentTarget === 'app.principles' || currentTarget === 'app.useTerms' || currentTarget === 'app.userSettings') {
      return transition;
    }
    let surveyPage = 'app.surveyPage';
    if (user.registrationFlow === 'liveinlab') {
      // Make sure user has agreed to updated consent text 2020-01
      if (!user.household || user.household.dateSignedElectricitydataConsent == null || user.household.dateSignedElectricitydataConsent < '2020-01-23') {
        let consentPage = 'app.liveInLabConsentPage';
        if (currentTarget !== consentPage) {
          return transition.router.stateService.target(consentPage, {hasSignedBefore: true});
        }
      } else if (!user.hasAnsweredSignupSurvey) {
        if (currentTarget !== surveyPage) {
          return transition.router.stateService.target(surveyPage, {surveyKey: 'LIVEINLAB_AUTUMN_2020', surveyRoundKey: 'SIGNUP'});
        }
      }
    } else if (user.registrationFlow === 'hornslandet') {
      if (!user.hasAnsweredSignupSurvey) {
        if (currentTarget !== surveyPage) {
          return transition.router.stateService.target(surveyPage, {surveyKey: 'HORNSLANDET', surveyRoundKey: 'SIGNUP'});
        }
      }
    }
    else if (user.registrationFlow === 'plusenergihuset') {
      if (!user.hasAnsweredSignupSurvey) {
        if (currentTarget !== surveyPage) {
          return transition.router.stateService.target(surveyPage, {surveyKey: 'PLUSENERGIHUSET_AUTUMN_2020', surveyRoundKey: 'SIGNUP'});
        }
      }
    }
    else if (user.registrationFlow === 'portugal') {
      if (!user.hasAnsweredSignupSurvey) {
        if (currentTarget !== surveyPage) {
          return transition.router.stateService.target(surveyPage, {surveyKey: 'PORTUGAL', surveyRoundKey: 'SIGNUP'});
        }
      }
    }
    return transition;
  };
  $transitions.onBefore(goingToAnyAppScreen, checkMandatoryRedirects);
}

function getCurrentTarget(transition) {
  let currentTarget = transition.targetState().identifier();
  // Some transitions have an object as identifier (e.g. app.principles), get the name from that object.
  if (typeof currentTarget === 'object' && currentTarget !== null) {
    currentTarget = currentTarget.name;
  }
  return currentTarget;
}

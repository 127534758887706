import angular from 'angular';
import {createRoute} from '../../core/config';

class LiveInLabConsentController {

  constructor(LoggingService, EnergyService, ToastMessageService, ToolbarService, UserService, $state, $translate) {
    this.energyService = EnergyService;
    this.toastMessageService = ToastMessageService;
    this.toolbarService = ToolbarService;
    this.userService = UserService;
    this.$state = $state;
    this.$translate = $translate;
  }

  $onInit() {
    this.hasAcceptedLiveInLabConsent = false;
    this.setPageTitle();
  }

  setPageTitle() {
    this.toolbarService.setPageTitle(this.$translate.instant('LIVEINLAB.CONSENT_TITLE'));
  }

  agreeToConsent() {
    if (this.hasAcceptedLiveInLabConsent) {
      this.energyService.signExistingLiveInLabConsent().then(
        (success) => {
          if (success) {
            this.userService.fetchLoggedInUser().then(() => {
              this.$state.go('app.neighborhood');
            });
          } else {
            this.toastMessageService.showMessage(this.$translate.instant('LIVEINLAB.CONSENT_SIGN_FAILURE'));
          }
        }, (err) => {
          this.toastMessageService.showMessage(this.$translate.instant('LIVEINLAB.CONSENT_SIGN_FAILURE'));
        }
      );
    }
  }

  redirectToFeed() {

  }
}

angular.module('ll')
  .component('liveInLabConsentPage', {
    template: require('./liveInLabConsent.html'),
    controller: LiveInLabConsentController,
    controllerAs: 'vm',
    bindings: {
      hasSignedBefore: '<'
    }
  })
  .config(createRoute({
    name: 'app.liveInLabConsentPage',
    component: 'liveInLabConsentPage',
    url: '/live-in-lab-consent',
    params: {
      hasSignedBefore: false
    },
    resolve: {
      hasSignedBefore: ($transition$) => $transition$.params().hasSignedBefore
    }
  }));

import angular from 'angular';

class LinkyService {

  getTarget(url) {
    if (url) {
      let selfUrls = [
        'http://localhost:9000',
        'https://localhost:9000',
        'https://dev.llnw.io',
        'https://app.locallife.se'
      ];

      for (let i = 0; i < selfUrls.length; i++) {
        if (url.startsWith(selfUrls[i])) {
          return {target: ''};
        }
      }
    }
    return {target: '_blank'};
  }
}

angular.module('ll').service('LinkyService', LinkyService);

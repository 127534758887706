import angular from 'angular';

angular.module('ll')
  .filter('ImageFilter', function () {
    return function (image, type) {
      if (image !== undefined) {
        switch (type) {
          case 'topnav':
            return image === null ? require('../images/Feedpost_35x35.png') : image;
          case 'feedpost':
            return image === null ? require('../images/Feedpost_35x35.png') : image;
          case 'profile':
            return image === null ? require('../images/Profile_150x150.png') : image;
          case 'background':
            return image === null ? require('../images/Background_blank_960x195.png') : image;
          default:
            return image;
        }
      }
    };
  });

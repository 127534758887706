import angular from 'angular';

class FeedPostController {
  constructor($mdMedia) {
    this.$mdMedia = $mdMedia;
  }

  deletePublication(e) {
    this.onDelete({postId: this.post.postId});
  }

  onSave(post) {
    this.post = post;
  }
}

angular.module('ll').component('feedPost', {
  template: require('./feedpost.component.html'),
  controller: FeedPostController,
  controllerAs: 'vm',
  bindings: {
    post: '<',
    isExpanded: '<',
    onDelete: '&'
  }
});

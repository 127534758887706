/* eslint-disable no-undef */
import angular from 'angular';
import {createRoute} from '../../core/config';

class ErrorReportComponentController {

  constructor(ToolbarService, TrackingService, $mdMedia, FileReaderService, WasteService, $state, LoggingService, ToastMessageService, $translate) {
    this.$mdMedia = $mdMedia;
    this.toolbarService = ToolbarService;
    this.trackingService = TrackingService;
    this.fileReaderService = FileReaderService;
    this.wasteService = WasteService;
    this.$state = $state;
    this.loggingService = LoggingService;
    this.toastMessageService = ToastMessageService;
    this.$translate = $translate;
  }

  $onInit() {
    this.trackingService.track('wasteInletErrorReportForm');
    this.toolbarService.configure({title: this.$translate.instant('WASTE_INLET_ERROR_REPORTING.FORM.TITLE'), showBackButton: true});
    this.attachedFile = null;
    this.errorDescription = '';
    this.inletDescription = this.inlet.location && this.inlet.location.properties.description ? this.inlet.location.properties.description : this.inlet.externalId;
    this.fractionDescription = this.inlet.wasteFraction ? this.$translate.instant('GENERAL.FOR') + ' ' + this.$translate.instant('WASTE_INLET_ERROR_REPORTING.MAP.FRACTION_' + this.inlet.wasteFraction).toLowerCase() : null;
  }

  selectFile(file, invalidFiles) {
    this.fileReaderService.loadFile(file, f => {
      this.attachedFile = f.file;
    });
  }

  send() {
    let blob = this.attachedFile ? this.attachedFile.blob : null;
    let maxAllowedFileSizeMB = 10;
    let photoFileSizeMb = blob ? Math.round( 100 * blob.size / 1024 / 1024) / 100 : null; // round to 2 decimals
    if (photoFileSizeMb && photoFileSizeMb > maxAllowedFileSizeMB) {
      let errorMessage = this.$translate.instant('WASTE_INLET_ERROR_REPORTING.FORM.ERROR_PHOTO_TOO_LARGE', {'sizeMB': photoFileSizeMb, 'maxAllowedSizeMB': maxAllowedFileSizeMB});
      this.toastMessageService.showMessage(errorMessage);
      return;
    }
    this.wasteService.reportError(this.inlet.id, this.errorDescription, blob)
      .then(
        () => this.$state.go('app.wasteInlet.errorReportSent', {operator: this.inlet.operator}),
        (err) => {
          this.loggingService.error('Error saving error report', err);
          let errorMessage = this.$translate.instant('WASTE_INLET_ERROR_REPORTING.FORM.ERROR_POST');
          this.toastMessageService.showMessage(errorMessage);
        });
  }
}

angular.module('ll').component('wasteInletErrorReportForm', {
  template: require('./error-report.component.html'),
  controller: ErrorReportComponentController,
  controllerAs: 'vm',
  bindings: {
    inlet: '<'
  }
}).config(createRoute({
  name: 'app.wasteInlet.errorReport',
  component: 'wasteInletErrorReportForm',
  url: '/error',
  params: {
    inletId: null
  },
  resolve: {
    inlet: (WasteService, $transition$) => WasteService.getInlet($transition$.params().inletId)
  }
})).component('wasteInletErrorReportSent', {
  template: require('./error-report-sent.component.html'),
  controllerAs: 'vm',
  bindings: {
    operator: '<'
  }
}).config(createRoute({
  name: 'app.wasteInlet.errorReportSent',
  component: 'wasteInletErrorReportSent',
  url: '/sent',
  params: {
    operator: 'Envac'
  },
  resolve: {
    operator: ($transition$) => $transition$.params().operator
  }
}));

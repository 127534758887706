import angular from 'angular';
import {createRoute} from '../../core/config';

class ForumController {
  constructor(EnvironmentSettings, ToolbarService, $translate, SessionService, ForumService, uiAlertService, $sce) {
    this.forumUrl = EnvironmentSettings.forumUrl;
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.sessionService = SessionService;
    this.forumService = ForumService;
    this.uiAlertService = uiAlertService;
  }

  $onInit() {
    this.hasLoadedForumUsername = false;
    this.forumAuthToken = null;
    this.user = this.sessionService.getUser();
    this.newForumUsername = null;
    this.forumUsername = null;
    this.neighborhoodName = this.user ? this.user.neighborhood.name : '';
    this.toolbarService.setPageTitle(this.$translate.instant('MENU.FORUM') + ' - ' + this.neighborhoodName);
    this.getForumUsernameAndLoginIfExists();
  }

  getForumUsernameAndLoginIfExists() {
    this.forumService.getForumUserDetails().then(userDetails => {
      this.forumUsername = userDetails.forumUsername;
      this.hasLoadedForumUsername = true;
      if (this.forumUsername) {
        this.login();
      }
    });
  }

  register() {
    this.forumService.register(this.newForumUsername).then(() => {
      this.getForumUsernameAndLoginIfExists();
    }).catch(data => {
      let message = this.getErrorMessage(data);
      this.uiAlertService.showError(this.$translate.instant('FORUM.REGISTER_ERROR'), message);
    });
  }

  login() {
    this.forumService.loginUser().then(data => {
      this.forumAuthToken = data.authToken;
    }).catch(data => {
      let message = this.getErrorMessage(data);
      this.uiAlertService.showError(this.$translate.instant('FORUM.LOGIN_ERROR'), message);
    });
  }

  getErrorMessage(forumLoginDto) {
    let message = null;
    if (forumLoginDto && forumLoginDto.messageKey) {
      message = this.$translate.instant('FORUM.MESSAGES.' + forumLoginDto.messageKey);
    }
    return message;
  }
}

angular.module('ll')
  .component('forum', {
    template: require('./forum.html'),
    controller: ForumController,
    controllerAs: 'vm'
  })
  .config(createRoute({
    name: 'app.forum',
    component: 'forum',
    url: '/forum',
  }));

import angular from 'angular';
import {createRoute} from '../../core/config';

angular.module('ll')
  .component('energyJoin', {
    template: require('./energyJoin.html'),
    controller: EnergyJoinController,
    controllerAs: 'vm',
    bindings: {
      showActivation : '<'
    },
    transclude: true
  })
  .config(createRoute({
    name: 'app.energyJoin',
    component: 'energyJoin',
    url: '/energyjoin?{showActivation:bool}',
    resolve: {
      showActivation: ($transition$) => $transition$.params().showActivation,
    },
    params: {
      showActivation: false
    }
  }));


function EnergyJoinController(SessionService, ToolbarService, EnergyService, LoggingService, TrackingService, uiAlertService, UserService, $translate, $mdDialog, moment, $window, $state, $sce) {
  const vm = this;

  vm.isPropertyMember = true;
  vm.isPropertyVerified = true;
  vm.propertygroupName = null;
  vm.dsoName = null;
  vm.userBirth = '';
  vm.openInfoDialog = openInfoDialog;
  vm.openExplanationDialog = EnergyService.openExplanationDialog;
  vm.onOnboardingInit = onOnboardingInit;
  vm.showActivationn = showActivationn;
  vm.nextConsentSlide = nextConsentSlide;
  vm.activate = activate;
  vm.onActivationCarouselInit = onActivationCarouselInit;
  vm.$onInit = init;
  vm.showOnboarding = true;
  vm.submitConsentForm = submitConsentForm;
  vm.createConsent = createConsent;
  vm.cancelConsent = cancelConsent;
  vm.hideDialog = hideDialog;
  vm.validHousehouldData = validHousehouldData;
  vm.validPersonalData = validPersonalData;
  vm.nextOnboardingSlide = nextOnboardingSlide;
  vm.previousOnboardingSlide = previousOnboardingSlide;
  vm.handleSlider = handleSlider;
  vm.showAttentionConsentDialog = showAttentionConsentDialog;
  vm.consentBack = consentBack;
  vm.showFinishPage = showFinishPage;
  vm.signConsent = signConsent;
  vm.preventTabClick = preventTabClick;
  vm.showPersonalDetails = {value: true};
  vm.showHouseholdDetails = {value: false};
  vm.showFeedbackDetails = {value: false};
  vm.showAddress = true;
  vm.showApartmentNumber = true;
  vm.showPersonalNumber = true;
  vm.showElectricityFacilityId = false;
  vm.enableElectricityFacilityId = false;
  vm.showElectricityRetailer = false; // 2019-07-03: Always off for now
  vm.isMoveInYearMonthRequired = false;
  vm.validConsentForm = false;
  vm.hasReadImportantInformation = false;
  vm.showFinish = false;
  vm.consentForm = {};
  vm.newConsent = {
    householdType: null,
    apartmentNumber: null,
    electricityFacilityId: null,
    personalNumber: null,
    moveInYearMonth: null
  };
  vm.regexPersonalNumber = /^(19|20)[0-9]{2}[01][0-9][0-3][0-9](-)[0-9]{4}$/;  ///^(19|20)?(\d{6}([-+]|\s)\d{4}|(?!19|20)\d{10})$/; //;
  vm.regexMoveInYearMonth = /^(19|20)[0-9]{2}-(0[1-9]|1[0-2])$/;
  vm.regexApartmentNumber = /[0-9]{4}/;
  vm.onboardingSlides = [
    {
      title: $translate.instant('ENERGY_JOIN.ONBOARDINGSLIDES.TITLE.ARE_YOU_READY'),
      content: $sce.trustAsHtml('<div>' + $translate.instant('ENERGY_JOIN.ONBOARDINGSLIDES.CONTENT.ARE_YOU_READY') + '</div>'),
      image: 'images/energy/sustainable.png',
      alt: 'house with leaf'
    },
    {
      title: $translate.instant('ENERGY_JOIN.ONBOARDINGSLIDES.TITLE.USAGE_TIME_MATTERS'),
      content: $sce.trustAsHtml('<div>' + $translate.instant('ENERGY_JOIN.ONBOARDINGSLIDES.CONTENT.USAGE_TIME_MATTERS') + '</div>'),
      image: 'images/energy/eolical.png',
      alt: 'house with wind turbine'
    }
    // ,{
    //   title: $translate.instant('ENERGY_JOIN.ONBOARDINGSLIDES.TITLE.YOU_AND_YOUR_NEIGHBOURS'),
    //   content: $sce.trustAsHtml('<div>' + $translate.instant('ENERGY_JOIN.ONBOARDINGSLIDES.CONTENT.YOU_AND_YOUR_NEIGHBOURS') + '</div>'),
    //   image: 'images/energy/rating.png',
    //   alt: 'three people with a star above their head'
    // }
  ];
  vm.activateNowContent = $sce.trustAsHtml('<div>' + $translate.instant('ENERGY_JOIN.ACTIVATE_NOW.CONTENT') + '</div>');
  vm.activationSlides = [
    {
      title: $translate.instant('ELECTRICITYDATACONSENT.SLIDES.TITLE.FIRST'),
      content: $translate.instant('ELECTRICITYDATACONSENT.SLIDES.CONTENT.FIRST'),
      image: 'images/energy/list.png',
    },
    {
      title: $translate.instant('ELECTRICITYDATACONSENT.SLIDES.TITLE.SECOND'),
      content: $translate.instant('ELECTRICITYDATACONSENT.SLIDES.CONTENT.SECOND'),
      image: 'images/energy/green-house.png',
    }
  ];

  function init() {
    setupMessagesFromLoggedInUser();
    vm.showActivation = vm.showActivation || false;
    if (vm.showActivation){
      vm.showOnboarding = false;
    }
    TrackingService.track('energyjoin.init');
  }

  function setupMessagesFromLoggedInUser() {
    vm.user = SessionService.getUser();
    vm.userFullName = vm.user.getFullName();
    vm.hasNetworkArea = !!vm.user.networkArea;
    vm.isPropertyMember = vm.user.isPropertyMember();
    vm.propertygroupName = vm.isPropertyMember ? vm.user.property.name : null;
    vm.isPropertyVerified = vm.user.isPropertyVerified();
    vm.dsoName = vm.user.networkArea ? vm.user.networkArea.dsoCode : null;
    vm.service = vm.user.networkArea ? vm.user.networkArea.service : null;
    vm.electricityRetailerEmailSubject =  $translate.instant('ELECTRICITYDATACONSENT.ELECTRICITY_RETAILER_EMAIL_SUBJECT');
    vm.electricityRetailerEmailBody = $translate.instant('ELECTRICITYDATACONSENT.ELECTRICITY_RETAILER_EMAIL_BODY', {fullName: vm.userFullName, address: vm.user.address}).replace(/\n/g, '%0D%0A');
    if (vm.user.household) {
      vm.newConsent.householdType = vm.user.household.type;
      vm.newConsent.apartmentNumber = vm.user.household.apartmentNumber;
      vm.newConsent.moveInYearMonth = vm.user.household.moveInYearMonth;
      vm.newConsent.electricityFacilityId = vm.user.household.electricityFacilityId;
    }
    if (vm.service === 'gmhub') {
      vm.showPersonalNumber = false;
      vm.showElectricityRetailer = false;
      vm.showElectricityFacilityId = true;
      vm.enableElectricityFacilityId = !vm.newConsent.electricityFacilityId;
      vm.showApartmentNumber = false;
      vm.showAddress = false;
      vm.isMoveInYearMonthRequired = true;
    }
  }

  function openInfoDialog($event){
    $mdDialog.show({
      targetEvent: $event,
      template: require('./infoDialog.tmpl.html'),
      parent: angular.element(document.body),
      clickOutsideToClose:true,
      fullscreen: false,
      controllerAs: 'vm',
      preserveScope : true,
      bindToController: true,
      controller: EnergyJoinController
    });
    TrackingService.track('energyjoin.infodialog.open');
  }

  function onOnboardingInit(carouselScope){
    let ctrl = carouselScope.$$childHead.ctrl;
    ctrl.showActivation = vm.showActivationn;
    ctrl.nextOnboardingSlide = vm.nextOnboardingSlide;
    ctrl.previousOnboardingSlide = vm.previousOnboardingSlide;
    ctrl.openExplanationDialog = vm.openExplanationDialog;
    unbindClickFromCarousel();
  }
  function onActivationCarouselInit(carouselScope){
    TrackingService.track('energyjoin.form.init');
    let ctrl = carouselScope.$$childHead.ctrl;

    ctrl.consentForm = vm.consentForm;
    ctrl.newConsent = vm.newConsent;
    ctrl.showPersonalDetails = vm.showPersonalDetails;
    ctrl.showHouseholdDetails = vm.showHouseholdDetails;
    ctrl.showFeedbackDetails = vm.showFeedbackDetails;
    ctrl.showPersonalNumber = vm.showPersonalNumber;
    ctrl.showElectricityRetailer = vm.showElectricityRetailer;
    ctrl.showElectricityFacilityId = vm.showElectricityFacilityId;
    ctrl.enableElectricityFacilityId = vm.enableElectricityFacilityId;
    ctrl.showApartmentNumber = vm.showApartmentNumber;
    ctrl.showAddress = vm.showAddress;
    ctrl.isMoveInYearMonthRequired = vm.isMoveInYearMonthRequired;
    ctrl.openInfoDialog = vm.openInfoDialog;

    ctrl.userFullName = vm.userFullName;
    ctrl.regexPersonalNumber = vm.regexPersonalNumber;
    ctrl.user = vm.user;
    ctrl.regexApartmentNumber = vm.regexApartmentNumber;
    ctrl.regexMoveInYearMonth = vm.regexMoveInYearMonth;
    ctrl.regexApartmentNumber = vm.regexApartmentNumber;
    ctrl.nextConsentSlide = vm.nextConsentSlide;
    ctrl.cancelConsent = vm.cancelConsent;
    ctrl.consentBack = vm.consentBack;
    ctrl.signConsent = vm.signConsent;
    ctrl.preventTabClick = vm.preventTabClick;
    ctrl.electricityRetailerEmailSubject = vm.electricityRetailerEmailSubject;
    ctrl.electricityRetailerEmailBody = vm.electricityRetailerEmailBody;

    unbindClickFromCarousel();
    unbindDotsClickFromCarousel();
  }

  function showActivationn(){
    TrackingService.track('energyjoin.activate_now.show');
    vm.showOnboarding = false;
    vm.showActivation = true;
  }

  function nextConsentSlide(carouselCtrl) {
    doSpecialChecksAndGoToNextSlideAtSucess(carouselCtrl);
  }

  function doSpecialChecksAndGoToNextSlideAtSucess(carouselCtrl) {
    if (carouselCtrl.currentSlide === 0 && vm.enableElectricityFacilityId && vm.service === 'gmhub'){
      validateGmhubActivationCodeAndGoToNextSlideAtSuccess(carouselCtrl);
    } else {
      nextConsentSlideAction(carouselCtrl);
    }
  }

  function validateGmhubActivationCodeAndGoToNextSlideAtSuccess(carouselCtrl) {
    EnergyService.checkGmhubActivationCode(vm.newConsent.electricityFacilityId).then(isCodeValid => {
      if (isCodeValid) {
        nextConsentSlideAction(carouselCtrl);
      } else {
        uiAlertService.showError($translate.instant('ELECTRICITYDATACONSENT.INVALID_GMHUB_ACTIVATION_CODE_ERROR_TITLE'), $translate.instant('ELECTRICITYDATACONSENT.INVALID_GMHUB_ACTIVATION_CODE_ERROR_MESSAGE'));
      }
    });
  }

  function nextConsentSlideAction(carouselCtrl){
    vm.submitConsentForm();
    vm.handleSlider(carouselCtrl.currentSlide);
    if (vm.validConsentForm){
      vm.validConsentForm = false;
      vm.consentForm.$setPristine();
      carouselCtrl.next();
    }
  }

  function activate(){
    vm.showActivation = false;
    vm.showActivate = true;
  }

  function unbindClickFromCarousel(){
    let prev = document.getElementsByClassName('carousel-prev');
    let next = document.getElementsByClassName('carousel-next');
    angular.element(prev).unbind('click');
    angular.element(next).unbind('click');
  }

  function changeContentHeight(){
    if (screen.width < 600){
      let dots = document.getElementsByClassName('carousel-dots');
      angular.element(dots).css('bottom', '-30px');

      let next = document.getElementById('nextConsentSlide');
      let prev = document.getElementById('cancelConsent');
      angular.element(next).css('bottom', '-30px');
      angular.element(prev).css('bottom', '-30px');

      let container = document.getElementById('energyConsentContent');
      angular.element(container).css('height', container.offsetHeight - 20);
    }
  }

  // issues with form submitting
  function unbindDotsClickFromCarousel(){
    let dotsList = document.getElementsByTagName('li');
    for (let i = 0; i < dotsList.length; i++){
      angular.element(dotsList[i]).unbind('click');
      dotsList[i].firstElementChild.setAttribute('disabled','true');
    }
  }

  function nextOnboardingSlide(ctrl){
    if (ctrl.currentSlide === vm.onboardingSlides.length - 1){
      vm.showActivationn();
    } else {
      TrackingService.track('energyjoin.onboarding.slide_' + ctrl.currentSlide.toString() + '.next.click');
      ctrl.next();
    }
  }

  function previousOnboardingSlide(ctrl){
    if (ctrl.currentSlide > 0){
      ctrl.prev();
    }
  }

  function cancelConsent(){
    $mdDialog.show({
      template: require('./cancelConsentDialog.tmpl.html'),
      parent: angular.element(document.body),
      clickOutsideToClose:true,
      fullscreen: false,
      controllerAs: 'vm',
      preserveScope : true,
      bindToController: true,
      controller: EnergyJoinController
    });
  }

  function hideDialog(isCancelDialog){
    if (isCancelDialog){
      TrackingService.track('energyjoin.cancelconsentdialog.cancel');
      $state.go($state.current, {showActivation: true}, {reload: true});
    }
    $mdDialog.hide();
  }

  function submitConsentForm(){
    vm.consentForm.$setSubmitted();
  }

  function showAttentionConsentDialog(signingUrl){
    vm.newConsent.service = vm.service;
    $mdDialog.show({
      template: require('./attentionConsentDialog.tmpl.html'),
      parent: angular.element(document.body),
      clickOutsideToClose:true,
      fullscreen: false,
      controllerAs: 'vm',
      preserveScope : true,
      bindToController: true,
      locals: {signingUrl, isUsingApp: TrackingService.isUsingApp()},
      controller: EnergyJoinController
    });
  }

  function handleSlider(currentSlide, nextSlide){
    if (currentSlide !== nextSlide){
      switch (currentSlide){
        case 0:
          TrackingService.track('energyjoin.form.personal_details.submit.click');
          if (vm.validPersonalData()){
            vm.showPersonalDetails.value = false;
            vm.showHouseholdDetails.value = true;
            vm.showFeedbackDetails.value = false;
            vm.validConsentForm = true;
            TrackingService.track('energyjoin.form.personal_details.submit.success');
          }
          break;
        case 1:
          if (vm.validHousehouldData()){
            vm.showPersonalDetails.value = false;
            vm.showHouseholdDetails.value = false;
            vm.showFeedbackDetails.value = true;
            changeContentHeight();
            vm.validConsentForm = true;
          }
          break;
        default:
          if (vm.validConsentForm){
            vm.consentForm.$setPristine();
            vm.validConsentForm = false;
          }
      }
    }
  }

  function showFinishPage(){
    hideDialog();
    vm.showPersonalDetails.value = false;
    vm.showHouseholdDetails.value = false;
    vm.showFeedbackDetails.value = false;
    vm.showActivate = false;
    vm.showFinish = true;
    vm.validConsentForm = true;
  }

  function validPersonalData(){
    let f = vm.consentForm;
    return f.$submitted && (vm.showPersonalNumber ? f.personalNumber.$valid : true) && (vm.enableElectricityFacilityId ? f.electricityFacilityId.$valid : true);
  }

  function validHousehouldData(){
    return vm.consentForm.$submitted && vm.consentForm.householdType.$valid && vm.consentForm.apartmentNumber.$valid && vm.consentForm.moveInYearMonth.$valid;
  }

  function consentBack(carouselCtrl){
    carouselCtrl.prev();
    vm.showPersonalDetails.value = true;
    vm.showHouseholdDetails.value = false;
  }

  function signConsent(){
    TrackingService.track('energyjoin.form.address_details.submit.click');
    vm.submitConsentForm();
    if (vm.validHousehouldData()){
      TrackingService.track('energyjoin.form.address_details.submit.success');
      if (vm.service === 'egreement') {
        if (!vm.newConsent.moveInYearMonth) {
          vm.newConsent.moveInYearMonth = moment().format('YYYY-MM');
        }
        createConsent(vm.newConsent);
      } else if (vm.service === 'gmhub') {
        createConsent(vm.newConsent);
      }
    }
  }

  function createConsent(consentData) {
    if (vm.isCreating) {
      return;
    }

    TrackingService.track('energyjoin.go_to_signing.click');

    vm.isCreating = true;

    if (!consentData) {
      consentData = vm.locals;
    }

    EnergyService.createConsent(consentData).then((consent) => {
      vm.isCreating = false;
      // reload user so that we get the new consent data
      UserService.fetchLoggedInUser().then(() => {
        if (consent.service === 'egreement') {
          if (consent.signingUrl) {
            TrackingService.track('energyjoin.consent_created_show_forward_to_egreement_dialog');
            vm.showAttentionConsentDialog(consent.signingUrl);
          } else {
            LoggingService.error('Error - missing signing url for newly created electricitydata consent (' + consent.service + ')', consent);
            uiAlertService.showError($translate.instant('ELECTRICITYDATACONSENT.MESSAGE.CONSENT_CANNOT_BE_SIGNED'));
          }
        } else if (consent.service === 'gmhub') {
          if (consent.status === 'POSTED') {
            if (consent.id) {
              $state.go('app.gmhubSign', {consentId: consent.id});
            } else {
              LoggingService.error('Error - missing id for newly created electricitydata consent (' + consent.service + ')', consent);
              uiAlertService.showError($translate.instant('ELECTRICITYDATACONSENT.MESSAGE.CONSENT_CANNOT_BE_SIGNED'));
            }
          } else if (consent.status === 'SIGNED_BY_ALL') {
            TrackingService.track('energyjoin.consent_already_exists_forward_to_energy');
            $state.go('app.energy');
          } else {
            LoggingService.error('Error - unknown status when creating electricitydata consent: ' + consent.status + ' (' + consent.service + ')', consent);
            uiAlertService.showError($translate.instant('ELECTRICITYDATACONSENT.MESSAGE.CONSENT_CANNOT_BE_SIGNED'));
          }
        } else {
          LoggingService.error('Error - unknown service for newly created electricitydata consent (' + consent.service + ')', consent);
          uiAlertService.showError($translate.instant('ELECTRICITYDATACONSENT.MESSAGE.CONSENT_CANNOT_BE_SIGNED'));
        }
      });
    }, (err) => {
      vm.isCreating = false;
      LoggingService.error('Error creating electricitydata consent', err);
      uiAlertService.showError($translate.instant('ELECTRICITYDATACONSENT.MESSAGE.CREATE_ERROR_TITLE'));
    });
  }

  function preventTabClick(event){
    if (event.keyCode === 9){
      event.preventDefault();
    }
  }

}



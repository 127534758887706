/* eslint-disable no-undef */
import angular from 'angular';

class MapController {

  constructor($timeout) {
    this.$timeout = $timeout;
  }

  $onInit() {
    this.defaultParameters();
    this.$timeout(() => {
      let {map, drawingManager} = this.initMap({center: this.center, zoom: this.zoomLevel, isStatic: this.isStatic, elementId: this.elementId, placeId: this.placeId});
      this.initCallbacks(drawingManager);
      this.map = map;
      this.drawingManager = drawingManager;
    }, 2);
  }

  $onDestroy() {
    this.map = null;
    this.drawingManager = null;
  }

  defaultParameters() {
    let noop = () => {};
    this.isStatic = this.isStatic || false;
    this.height = this.height || '30vh';
    this.center = this.center || {lat: 59.3293, lng: 18.0686};
    this.placeId = this.placeId || null;
    this.onPolygonComplete = this.onPolygonComplete || noop;
    this.onReset = this.onReset || noop;
    this.zoomLevel = 14;
  }

  initCallbacks(drawingManager) {
    if (!drawingManager) {
      return;
    }
    google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
      this.polygon = polygon;
      this.hideDrawingTools();
      this.exitPolygonDrawingMode();
      let polygonVertices = this.extractCoordinates(polygon);
      this.onPolygonComplete({polygonVertices});
    });
  }

  initMap(mapOptions) {
    let map = this.createMap(mapOptions);
    let drawingManager = null;
    if (!mapOptions.isStatic) {
      drawingManager = this.createDrawingManager(map);
    }
    if (mapOptions.placeId) {
      this.createMarkerForPlaceId(map, mapOptions.placeId);
    } else {
      this.createMarker(map, mapOptions.center);
    }
    return {map, drawingManager};
  }

  createMap(mapOptions) {
    return new google.maps.Map(document.getElementsByClassName('llmap')[0], {
      ...mapOptions,
      zoomControl: !mapOptions.isStatic,
      mapTypeControl: false,
      fullscreenControl: !mapOptions.isStatic,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      gestureHandling: mapOptions.isStatic ? 'none' : 'cooperative'
    });
  }

  createMarker(map, center) {
    return new google.maps.Marker({
      position: center,
      map: map
    });
  }

  createMarkerForPlaceId(map, placeId) {
    let request = {placeId, fields: ['name', 'formatted_phone_number', 'geometry', 'formatted_address', 'url']};
    let service = new google.maps.places.PlacesService(map);
    let onPlaceDetailsCallback = this.onPlaceDetails;
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        onPlaceDetailsCallback({place});
        let marker = this.createMarker(map, place.geometry.location);
        map.setCenter(place.geometry.location);
        let infoWindow = new google.maps.InfoWindow();
        infoWindow.setContent(`<div><a href="${place.url}" target="_blank"><strong>${place.name}</strong></a><br>${place.formatted_address}</div>`);
        infoWindow.open(map, marker);
      }
    });
  }

  createDrawingManager(map) {
    let drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['polygon']
      }
    });
    drawingManager.setMap(map);
    return drawingManager;
  }

  hideDrawingTools() {
    this.toggleDrawingTools(false);
  }

  showDrawingTools() {
    this.toggleDrawingTools(true);
  }

  toggleDrawingTools(toggle) {
    this.drawingManager.setOptions({
      drawingControl: toggle
    });
  }

  exitPolygonDrawingMode() {
    this.drawingManager.setOptions({
      drawingMode: null
    });
  }

  extractCoordinates(polygon) {
    let parsedVertices = [];
    let outerPolygon = polygon.getPaths();

    if (outerPolygon.getLength() !== 1) return;

    let vertices = outerPolygon.getAt(0);

    for (let i = 0; i < vertices.getLength(); i++) {
      let point = vertices.getAt(i);
      parsedVertices.push(point.toJSON());
    }
    return parsedVertices;
  }

  resetMap() {
    if (this.polygon) {
      this.polygon.setMap(null);
      this.polygon = null;
    }
    this.showDrawingTools();
    this.map.setCenter(this.center);
    this.map.setZoom(this.zoomLevel);
    if (this.onReset) {
      this.onReset();
    }
  }
}

angular.module('ll').component('llMap', {
  template: require('./map.component.html'),
  controller: MapController,
  controllerAs: 'vm',
  bindings: {
    center: '<',
    placeId: '<',
    onPlaceDetails: '&',
    height: '<',
    onPolygonComplete: '&',
    onReset: '&',
    isStatic: '<'
  }
});

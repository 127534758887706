import angular from 'angular';
import {createRoute} from '../../core/config';

class RegistrationFormController {

  constructor(EnvironmentSettings, SessionService, RegistrationApi, LoginService, $state, $translate, LanguageService, $location, $mdDialog, $scope, ToolbarService, $q, TrackingService, moment, ToastMessageService, $window, LoggingService) {
    this.environmentSettings = EnvironmentSettings;
    this.sessionService = SessionService;
    this.registrationApi = RegistrationApi;
    this.languageService = LanguageService;
    this.loginService = LoginService;
    this.$state = $state;
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.$location = $location;
    this.$mdDialog = $mdDialog;
    this.$scope = $scope;
    this.$q = $q;
    this.trackingService = TrackingService;
    this.moment = moment;
    this.toastMessageService = ToastMessageService;
    this.$window = $window;
    this.loggingService = LoggingService;
  }

  $onInit() {
    this.showStreetAddress = this.environmentSettings.country !== 'pt';
    this.isDoingCall = false;
    if (this.checkRegistrationData() === false) {
      return;
    }
    this.emailChecked = false;
    this.isEmailTaken = false;
    this.setPageTitle();
    this.setRegistrationLanguage();
    this.setRegistrationFlow();
    this.setupFields();
  }

  checkRegistrationData() {
    if (this.registrationData === undefined) {
      this.loggingService.error('Entered registration form with empty registrationData');
      this.$state.go('welcome');
      return false;
    }
    if (Object.keys(this.registrationData).length > 1)
      return;

    if (this.registrationData.placeId) {
      this.$state.go('registration.registerInterest', {placeId: this.registrationData.placeId});
    } else if (this.registrationData.activationCode) {
      this.loggingService.error('Trying to register invalid activation code', {activationCode:this.registrationData.activationCode});
      this.$state.go('welcome');
    }
  }

  setPageTitle() {
    this.toolbarService.setPageTitle(this.$translate.instant('REGISTRATION.TITLE'));
  }

  setRegistrationLanguage() {
    this.registrationData.language = this.languageService.getLanguage();
  }

  setRegistrationFlow() {
    let flow = this.registrationData.registrationFlowName;
    if (flow === 'liveinlab' || flow === 'vallatorg' || flow === 'hornslandet' || flow === 'portugal' || flow === 'plusenergihuset') {
      this.requireActivtionCode = true;
      this.registrationFlow = flow;
    }
    if (flow === 'liveinlab' || flow === 'hornslandet' || flow === 'plusenergihuset') {
      this.showMoveInDate = true;
      this.requireMoveInDate = true;
    }
    if (flow === 'liveinlab') {
      this.showMoveOutDate = true;
      this.requireMoveOutDate = true;
    }
    if (this.registrationData.activationCode) {
      this.hasUserProvidedActivationCode = true;
    }
  }

  setupFields() {
    this.setMoveInMinMaxDates();
    if (this.registrationData && (this.registrationData.firstName || this.registrationData.lastName)) {
      this.showReuseActivationCodeInfo = true;
    }
  }

  setMoveInMinMaxDates() {
    let now = new Date();
    this.minMoveInDate = new Date(1950, 0, 1);
    this.maxMoveInDate = new Date(now.getFullYear(), now.getMonth() + 2, now.getDate());
  }

  setMoveOutMinMaxDates() {
    let now = new Date();
    this.minMoveOutDate = this.moveInDate ? this.moveInDate : now;
    this.maxMoveOutDate = new Date(now.getFullYear() + 1, 12, 31);
  }

  setEmailTaken(isEmailTaken) {
    this.isEmailTaken = isEmailTaken;
    this.emailChecked = true;
    this.$scope.registrationForm.email.$setValidity('isEmailTaken', !this.isEmailTaken);
  }

  onMoveInDateChanged() {
    this.setMoveOutMinMaxDates();
  }

  changeToText(event) {
    event.target.type = 'text';
  }

  changeToPassword(event) {
    event.target.type = 'password';
  }

  openDialog(event) {
    this.$mdDialog.show({
      controller: function ($scope, $mdDialog) {
        $scope.close = function () {
          $mdDialog.cancel();
        };
        $scope.read = function () {
          $mdDialog.hide();
        };
      },
      template: require('./termsandconditions.html'),
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      fullscreen: true
    });
  }

  register() {
    this.$scope.registrationForm.$setSubmitted();
    if (!this.checkAndSetMoveInDate()) {
      return;
    }
    if (!this.checkAndSetMoveOutDate()) {
      return;
    }
    if (this.$scope.registrationForm.$invalid) {
      return;
    }
    this.registrationData.platformKey = 'locallife_1';
    this.isDoingCall = true;
    this.registrationApi.createAccount(this.registrationData)
      .then(data => this.handleRegistrationSuccess({...this.registrationData, ...data.data}))
      .catch(resp => this.handleRegistrationError({...resp}));
  }

  checkAndSetMoveInDate() {
    if (this.showMoveInDate) {
      if (this.moveInDate) {
        this.registrationData.moveInDate = this.getUtcCorrectedDate(this.moveInDate);
        this.registrationData.hasGivenMoveInDate = true;
        return true;
      } else if (this.requireMoveInDate) {
        return false;
      }
    } else {
      return true;
    }
  }

  checkAndSetMoveOutDate() {
    if (this.showMoveOutDate) {
      if (this.moveOutDate) {
        this.registrationData.moveOutDate = this.getUtcCorrectedDate(this.moveOutDate);
        return true;
      } else if (this.requireMoveOutDate) {
        return false;
      }
    } else {
      return true;
    }
  }

  getUtcCorrectedDate(date) {
    const offset = date.getTimezoneOffset();
    const correctedDate = new Date(date.getTime() - offset * 60 * 1000); // Avoid converting to the previous day
    return correctedDate.toISOString().split('T')[0];
  }

  handleRegistrationSuccess(data) {
    this.trackRegistration(data);
    this.loginService.signIn(data.email, data.password)
      .then(data => {
        this.isDoingCall = false;
        this.$state.go('app.profile.about');
      });
  }

  handleRegistrationError(data) {
    this.isDoingCall = false;
    if (data.message === 'EMAIL_ALREADY_REGISTERED') {
      this.setEmailTaken(true);
    } else {
      this.toastMessageService.showMessage(this.$translate.instant('REGISTRATION.REGISTRATION_ERROR'));
    }
  }

  trackRegistration(data) {
    this.trackingService.register({
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      'Neighborhood Name': this.neighborhoodName
    });
    this.trackingService.track('Sign Up', {
      address: data.address,
      registrationDate: this.moment().toISOString(),
      hasActivationCode: !!data.activationCode
    });
    return data;
  }

  checkEmail() {
    if (!this.registrationData.email || this.registrationData.email.indexOf('@') < 0) {
      return;
    }
    this.registrationApi.checkEmail(this.registrationData.email).then(resp => this.setEmailTaken(resp.data));
  }
}

angular.module('ll')
  .component('registrationForm', {
    template: require('./registration-form.html'),
    controller: RegistrationFormController,
    controllerAs: 'vm',
    bindings: {
      registrationData: '<'
    }
  })
  .config(createRoute({
    name: 'registration.form',
    component: 'registrationForm',
    url: '/registration',
    params: {
      placeId: null,
      activationCode: null
    },
    resolve: {
      registrationData: ($transition$, RegistrationApi) => {
        let placeId = $transition$.params().placeId;
        let activationCode = $transition$.params().activationCode;
        if (placeId) {
          return RegistrationApi.getRegistrationDetailsByPlaceId(placeId);
        } else if (activationCode) {
          return RegistrationApi.getRegistrationDetailsByActivationCode(activationCode)
            .then(r => ({...r, activationCode}));
        }
      }
    }
  }));

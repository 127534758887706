import angular from 'angular';

class ContextActionMenuController {

  $onInit() {
    this.hideEdit = this.hideEdit || false;
    this.hideDelete = this.hideDelete || false;
    this.hideFlag = this.hideFlag || false;
  }

  delete(e) {
    this.onDelete({e});
  }

  edit(e) {
    this.onEdit({e});
  }

  flag(e) {
    this.onFlag({e});
  }
}

angular.module('ll').component('contextActionMenu', {
  template: require('./context-action-menu.component.html'),
  controller: ContextActionMenuController,
  controllerAs: 'vm',
  bindings: {
    hideEdit: '<',
    hideDelete: '<',
    hideFlag: '<',
    onEdit: '&',
    onDelete: '&',
    onFlag: '&'
  }
});

import angular from 'angular';
import {createRoute} from '../../core/config';

class SurveyDemoPageController {
  constructor() {
  }
}

angular.module('ll')
  .component('surveyDemoPage', {
    template: require('./surveyDemoPage.html'),
    controller: SurveyDemoPageController,
    controllerAs: 'vm'
  })
  .config(createRoute({
    name: 'app.surveyDemoPage',
    component: 'surveyDemoPage',
    url: '/survey/demo'
  }));

import angular from 'angular';

class HtmlPostViewComponentController {
  constructor($sce, TrackingService) {
    this.$sce = $sce;
    this.trackingService = TrackingService;
  }

  $onInit() {
    this.setEditorContent();
    this.showComments = false;
  }

  $onChanges(changes) {
    if (changes.post) {
      this.post = changes.post.currentValue;
      this.setEditorContent();
    }
  }

  setEditorContent() {
    this.trustedContent = this.post ? this.$sce.trustAsHtml(this.post.content) : null;
  }

  toggleComments() {
    this.showComments = !this.showComments;
  }

  updateLikes(likes) {
    this.post.likes = likes;
  }

  onExpand() {
    this.trackingService.track('feed.post.see_more', {postId: this.post.postId, subject: this.post.subject});
  }
}

angular.module('ll').component('htmlPostView', {
  template: require('./html-post-view.component.html'),
  controller: HtmlPostViewComponentController,
  controllerAs: 'vm',
  bindings: {
    post: '<',
    isExpanded: '<'
  }
});

import angular from 'angular';

class ForumService {
  constructor($http, EnvironmentSettings) {
    this.$http = $http;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
  }

  getForumUserDetails() {
    return this.$http.get(`${this.labradorUrl}/api/forum/user`)
      .then(resp => {
        return resp.data;
      });
  }

  register(forumUsername) {
    return this.$http.post(`${this.labradorUrl}/api/forum/register/${forumUsername}`)
      .then(resp => resp.data);
  }

  loginUser() {
    return this.$http.get(`${this.labradorUrl}/api/forum/login`)
      .then(resp => resp.data);
  }
}

angular.module('ll').service('ForumService', ForumService);

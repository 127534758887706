import angular from 'angular';
import {createRoute} from '../../core/config';

class ShowInletIssueController {
  constructor(WasteService, $translate, uiAlertService) {
    this.wasteService = WasteService;
    this.$translate = $translate;
    this.uiAlertService = uiAlertService;
  }

  $onInit() {
    this.loadInletIssue(this.accessCode);
    this.isError = false;
  }

  loadInletIssue(accessCode) {
    this.isError = false;
    this.wasteService.getInletIssue(accessCode).then((inletIssue) => {
      this.inletIssue = inletIssue;
    }, (err) => {
      this.uiAlertService.showError(this.$translate.instant('WASTE_INLET_ERROR_REPORTING.SHOW_INLET_ISSUE.MESSAGE.INLET_ISSUE_NOT_FOUND'), '');
      this.isError = true;
    });
  }
}

angular.module('ll')
  .component('showInletIssue', {
    template: require('./show-inlet-issue.html'),
    controller: ShowInletIssueController,
    controllerAs: 'vm',
    bindings: {
      accessCode: '<'
    }
  })
  .config(createRoute({
    name: 'showInletIssue',
    component: 'showInletIssue',
    url: '/show-inlet-error-report/:accessCode',
    resolve: {
      accessCode: ($transition$) => $transition$.params().accessCode
    },
    params: {
      accessCode: null
    },
    data: {
      authenticate: false
    }
  }));

import angular from 'angular';

class CustomSliderController {
  constructor($timeout, $element){
    this.$timeout = $timeout;
    this.sliderElement = $element[0].querySelector('.custom-slider__inner');
  }
  $onInit() {
    this.minMaxDifference = this.max - this.min;
    this.prevValue = this.currentValue;
    this.prevValuePercentage = this.prevValue / this.minMaxDifference * 100;

    this.lastValueChangeDeltaX = 0;

    this.countCurrentValuePercentage();
  }

  moveHandle(event) {
    if (event.direction !== 4 && event.direction !== 2) {
      return;
    }
    this.dragging = true;

    const handleShift = Math.sqrt(Math.pow((event.deltaX - this.lastValueChangeDeltaX) / event.element[0].parentNode.offsetWidth, 2));
    const stepDistance = this.step / this.minMaxDifference;
    const handleShiftDirection = event.deltaX - this.lastValueChangeDeltaX < 0 ? -1 : 1;

    const newCurrentValue = this.currentValue + this.step * handleShiftDirection;
    if (handleShift >= stepDistance && newCurrentValue >= this.min && newCurrentValue <= this.max) {
      this.currentValue = newCurrentValue;
      this.lastValueChangeDeltaX = event.deltaX + (stepDistance - handleShift) * event.element[0].parentNode.offsetWidth * handleShiftDirection;
      this.countCurrentValuePercentage();
    }
  }

  handleRelease() {
    this.dragging = false;
    this.lastValueChangeDeltaX = 0;
  }

  countCurrentValuePercentage() {
    this.currentValuePercentage = this.currentValue / this.minMaxDifference * 100;
  }

  sliderClick(event) {
    if (event.target.className === 'custom-slider__handle' || event.target.className === 'custom-slider__handle-line') {
      return;
    }

    const sliderX = this.sliderElement.getBoundingClientRect().left;
    const clickOffsetPercentage = (event.clientX - sliderX) / this.sliderElement.offsetWidth;
    const stepDistance = this.step / this.minMaxDifference;

    this.currentValue = Math.round(this.max * (clickOffsetPercentage % stepDistance > stepDistance / 2 ? clickOffsetPercentage - clickOffsetPercentage % stepDistance + stepDistance : clickOffsetPercentage - clickOffsetPercentage % stepDistance));

    this.countCurrentValuePercentage();
  }
}

angular.module('ll').component('llCustomSlider', {
  template: require('./custom-slider.component.html'),
  controller: CustomSliderController,
  controllerAs: 'vm',
  transclude: {
    top: '?top',
    bottom: '?bottom'
  },
  bindings: {
    titleText: '=',
    text: '=',
    min: '<',
    max: '<',
    step: '<',
    currentValue: '=',
    mainColor: '<',
    darkerColor: '<'
  }
});

import angular from 'angular';

class FeedPostPostComponentController {
  constructor(uiAlertService, PostService, $translate) {
    this.uiAlertService = uiAlertService;
    this.postService = PostService;
    this.$translate = $translate;
  }

  $onInit() {
    this.isEditing = false;
  }

  $onChanges(changes) {
    if (changes.isEditing) {
      this.isEditing = changes.isEditing.currentValue;
    }
  }

  onSave(post) {
    this.post = post;
    this.toggleEditPost();
  }

  toggleEditPost() {
    this.isEditing = !this.isEditing;
  }

  deletePost(event) {
    this.uiAlertService.showConfirm(this.$translate.instant('POST.DELETE.POST'), this.$translate.instant('POST.DELETE.ARE_YOU_SURE'))
      .then(() => this.postService.deletePost(this.post.postId))
      .then(() => this.onDelete());
  }
}

angular.module('ll').component('feedpostPost', {
  template: require('./feedpost-post.component.html'),
  controller: FeedPostPostComponentController,
  controllerAs: 'vm',
  bindings: {
    post: '<',
    isEditing: '<',
    isExpanded: '<',
    onDelete: '&'
  }
});

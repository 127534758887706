import angular from 'angular';

class FeedPostPollComponentController {

  constructor(FeedService, uiAlertService, PostService, TrackingService, $translate) {
    this.feedService = FeedService;
    this.uiAlertService = uiAlertService;
    this.postService = PostService;
    this.trackingService = TrackingService;
    this.$translate = $translate;
  }

  $onInit() {
    this.showComments = false;
    this.selectedPollChoiceId = 0;
    this.showPollResults = this.poll.voted;
    this.content = this.poll.getContent();
    this.question = this.poll.question;
  }

  vote() {
    this.feedService
      .vote(this.poll.postId, this.selectedPollChoiceId)
      .then(p => this.updatePollResults(p));
  }

  updatePollResults(poll) {
    this.showPollResults = poll.voted;
    this.poll = poll;
  }

  deletePoll(event) {
    this.uiAlertService.showConfirm(this.$translate.instant('POST.DELETE.POST'), this.$translate.instant('POST.DELETE.ARE_YOU_SURE'))
      .then(() => this.postService.deletePost(this.poll.postId))
      .then(() => this.onDelete());
  }

  toggleComments() {
    this.showComments = !this.showComments;
  }

  updateLikes(likes) {
    this.poll.likes = likes;
  }

  onExpand() {
    this.trackingService.track('feed.post.see_more', {postId: this.poll.postId, subject: this.poll.getTitle()});
  }
}

angular.module('ll').component('feedpostPoll', {
  template: require('./feedpost-poll.component.html'),
  controller: FeedPostPollComponentController,
  controllerAs: 'vm',
  bindings: {
    poll: '<',
    isExpanded: '<',
    onDelete: '&'
  }
});

import angular from 'angular';

class ToolbarService {

  constructor() {
    this.callbacks = [];
    this.defaultSettings = {
      title: null,
      showBackButton: false,
      backgroundColor: null,
      categoryId: null,
    };
  }

  onConfigurationChange(callback) {
    this.callbacks.push(callback);
  }

  setPageTitle(title) {
    this.configure({title});
  }


  /**
   * @param settings object like the this.defaultSettings
   */
  configure(settings) {
    settings = {
      ...this.defaultSettings,
      ...settings
    };
    this.callbacks.forEach(cb => cb(settings));
  }
}

angular.module('ll').service('ToolbarService', ToolbarService);

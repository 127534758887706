import angular from 'angular';

class BadgeNavController {
  constructor($element, $scope, $interval){
    this.$element = $element;
    this.$scope = $scope;
    this.$interval = $interval;
  }

  $onInit() {
    let i = 0;
    const interval = this.$interval(() => {
      this.setNavWidth();
      if (++i >= 20) {
        this.$interval.cancel(interval);
      }
    }, 50);

    window.addEventListener('resize', () => {
      this.setNavWidth();
      this.$scope.$apply();
    });
  }

  setNavWidth() {
    this.navWidth = this.$element[0].parentNode.clientWidth;
  }
}

angular.module('ll').component('llBadgeNav', {
  template: require('./badge-nav.component.html'),
  controller: BadgeNavController,
  controllerAs: 'vm',
  bindings: {
    links: '<',
  }
});

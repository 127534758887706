import angular from 'angular';
import {createRoute} from '../../core/config';

class NotFoundController {
  constructor(ToolbarService, $translate) {
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
  }

  $onInit(){
    this.toolbarService.setPageTitle(this.$translate.instant('NOT_FOUND.NOT_FOUND'));
    this.message = this.$translate.instant('NOT_FOUND.MESSAGE');
  }
}

angular.module('ll')
  .component('notFound', {
    template: require('./not-found.html'),
    controller: NotFoundController,
    controllerAs: 'vm'
  })
  .config(createRoute({
    name: 'app.notFound',
    component: 'notFound',
    url: '/notfound'
  }));

import angular from 'angular';

class ColumnsVisualizationController {
  constructor($timeout) {
    this.$timeout = $timeout;
  }

  $onInit() {
    this.columns.forEach((column) => {
      column.percentageValue = 1;
      column.toppedValuePercentage = 1;
    });

    this.$timeout(() => {
      this.initColumnsPercentage();
    }, 100);
  }

  $onChanges() {
    this.initColumnsPercentage();
  }

  initColumnsPercentage() {
    if (!this.columns) {
      this.columns = [...this.config.columns.map(column => { return {...column}; })];
    } else {
      this.config.columns.forEach((column, i) => {
        for (let key in column) {
          this.columns[i][key] = column[key];
        }
      });
    }

    const minMaxDifference = this.config.max - this.config.min;

    this.columns.forEach((column) => {
      column.percentageValue = column.currentValue / minMaxDifference * 100;
      column.toppedValuePercentage = column.toppedValue / column.currentValue * 100;
    });
  }
}

angular.module('ll').component('llColumnsVisualization', {
  template: require('./columns-visualization.component.html'),
  controller: ColumnsVisualizationController,
  controllerAs: 'vm',
  transclude: {
    top: '?top',
    bottom: '?bottom'
  },
  bindings: {
    config: '<',
    mainColor: '<',
    darkerColor: '<',
    availableHeight: '<'
  }
});

/* eslint-disable no-undef */
import angular from 'angular';

angular.module('ll.core')
  .constant('Version', {
    commit: __COMMIT_HASH__,
    environment: __ENVIRONMENT__,
    isDevelopment: __IS_DEVELOPMENT__,
    buildDate: __BUILD_DATE__
  })
  .constant('EnvironmentSettings', __ENVIRONMENT_SETTINGS__);

import angular from 'angular';

class HtmlPostEditComponentController {

  constructor(HtmlPostService, SessionService, FeedService, uiAlertService, $translate) {
    this.uiAlertService = uiAlertService;
    this.htmlPostService = HtmlPostService;
    this.sessionService = SessionService;
    this.feedService = FeedService;
    this.$translate = $translate;

    this.editorConfig = {
      toolbarGroups: [
        {name: 'document', groups: ['mode', 'document', 'doctools']},
        {name: 'clipboard', groups: ['clipboard', 'undo']},
        {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
        {name: 'forms', groups: ['forms']},
        '/',
        {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
        {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
        {name: 'links', groups: ['links']},
        {name: 'insert', groups: ['insert']},
        '/',
        {name: 'styles', groups: ['styles']},
        {name: 'colors', groups: ['colors']},
        {name: 'tools', groups: ['tools']},
        {name: 'others', groups: ['others']},
        {name: 'about', groups: ['about']}
      ],
      extraPlugins: 'embed,autoembed,image2,emoji,uploadimage',
      // eslint-disable-next-line camelcase
      embed_provider: '//iframe.ly/api/oembed?url={url}&callback={callback}&key=eb2e1cd516692b04e6d2494849318bf1'
    };
  }

  $onInit() {
    this.subject = this.post ? this.post.subject : null;
    this.editorContent = this.post ? this.post.content : null;
    // Only load dropdown menu content when creating a new html post. Else it would load
    // for each html post in the feed, leading to bad performance and unnecessary calls.
    if (!this.post) {
      let user = this.sessionService.getUser();
      this.toOptions = this.htmlPostService.getToOptions(user);
      let selectedCategoryId = this.post ? this.post.categoryId : null;
      this.feedService.getGroupedPostGategories().then(categories => {
        this.categoryOptions = this.htmlPostService.getCategoryOptions(categories, selectedCategoryId);
      });
    }
  }

  cancel() {
    this.onCancel();
  }

  save() {
    let user = this.sessionService.getUser();
    if (!user.hasEnabledFeature('advanced_post')) {
      this.uiAlertService.showInfo(this.$translate.instant('ADVANCED_POST.FEATURE_NOT_ENABLED.TITLE'), this.$translate.instant('ADVANCED_POST.FEATURE_NOT_ENABLED.MESSAGE'));
      return;
    }

    this.htmlPostService.createOrUpdateHtmlPost(this.post ? this.post.postId : null, this.postedTo ? this.postedTo.name : null, this.category ? this.category.id : null, this.subject, this.editorContent)
      .then(p => {
        this.post = p;
        this.$onInit();
        this.onSave({post: p});
      }, err => {
        this.uiAlertService.showError(this.$translate.instant('ADVANCED_POST.SAVE_ERROR'));
      });
  }
}

angular.module('ll').component('htmlPostEdit', {
  template: require('./html-post-edit.component.html'),
  controller: HtmlPostEditComponentController,
  controllerAs: 'vm',
  bindings: {
    post: '<',
    onSave: '&',
    onCancel: '&'
  }
});

import angular from 'angular';

class SingleMessageController {

  $onInit() {
    this.isCurrentUser = this.message.author.userId === this.senderUserId;
  }
}

angular.module('ll').component('singleMessage', {
  template: require('./single-message.component.html'),
  controller: SingleMessageController,
  controllerAs: 'vm',
  bindings: {
    message: '<',
    senderUserId: '<'
  }
});

import angular from 'angular';

angular.module('ll').directive('llUseremail', UserEmailDirective);

function UserEmailDirective($q, RegistrationApi) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {
      ctrl.$asyncValidators.userEmail = function (modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return $q.resolve();
        }

        let deferred = $q.defer();
        RegistrationApi.checkEmail(modelValue)
          .then(() => deferred.reject())
          .catch(() => deferred.resolve());
        return deferred.promise;
      };
    }
  };
}

import angular from 'angular';
import {createRoute} from '../../core/config';

class RegisterInterestController {

  constructor(EnvironmentSettings, $translate, ToolbarService, TrackingService) {
    this.aboutUrl = EnvironmentSettings.aboutUrl;
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.trackingService = TrackingService;
    this.registrationData = {};
  }

  $onInit() {
    this.toolbarService.setPageTitle(this.$translate.instant('REGISTRATION.INTEREST.TITLE'));
    this.loadRegistrationData();
  }

  loadRegistrationData() {
    if (!this.placeId) {
      this.$state.go('welcome');
    }
    this.registrationData.placeId = this.placeId;
    // TODO: fetch address from API and track that.
  }

  trackUserInterest(registrationData) {
    this.trackingService.track('Register Interest', {
      address: registrationData.address
    });
  }
}

angular.module('ll')
  .component('registerInterest', {
    template: require('./interest.html'),
    controller: RegisterInterestController,
    controllerAs: 'vm',
    bindings: {
      placeId: '<'
    }
  })
  .config(createRoute({
    name: 'registration.registerInterest',
    component: 'registerInterest',
    url: '/interest',
    params: {
      placeId: null
    },
    resolve: {
      placeId: ($transition$) => $transition$.params().placeId
    }
  }));

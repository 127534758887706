import angular from 'angular';

class ProfileIconController {

  constructor(SessionService) {
    SessionService.onUserDataRefresh(u => {
      this.user = u;
      this.$onInit();
    });
  }

  $onInit() {
    this.profilePicture = this.getIcon(this.size, this.user.profilePicture);
    this.style = {'width' : `${this.size}px`, 'height': `${this.size}px`, 'border-radius': '2px', 'object-fit': 'cover'};
  }

  getIcon(size, picture) {
    if (typeof picture === 'string')
      return picture;

    if (!picture)
      return '';

    if (size <= 16) {
      return picture.s16;
    } else if (size <= 50) {
      return picture.s50;
    } else if (size <= 75) {
      return picture.s75;
    } else if (size <= 150) {
      return picture.s150;
    } else {
      return picture.original;
    }
  }
}

angular.module('ll').component('profileIcon', {
  template: require('./profile-icon.component.html'),
  controller: ProfileIconController,
  controllerAs: 'vm',
  bindings: {
    user: '<',
    size: '<'
  }
});

import angular from 'angular';

class SurveyService {
  constructor($http, EnvironmentSettings) {
    this.$http = $http;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
  }

  getSurvey(surveyKey, surveyRoundKey) {
    if (surveyKey === 'DEMO_FRONTEND') {
      return new Promise(function (resolve, reject) {
        let surveyData = SurveyService.getDemoSurvey();
        resolve(surveyData);
      });
    } else {
      return this.$http.get(`${this.labradorUrl}/api/survey/${surveyKey}/${surveyRoundKey}`)
        .then(resp => resp.data);
    }
  }

  saveSurveyRound(surveyRoundId, answers) {
    return this.$http.post(`${this.labradorUrl}/api/survey/${surveyRoundId}`, answers)
      .then(resp => {
        // eslint-disable-next-line no-console
        console.log(surveyRoundId, answers);
      });
  }

  static getDemoSurvey() {
    return {
      id: 1,
      nameInternal: 'The demo survey',
      key: 'DEMO', // Root: SURVEY.SURVEYS
      surveyRound: {
        id: 1,
        key: 'DEMO_ROUND', // Root: SURVEY.SURVEYS.<SURVEY_KEY>.SURVEY_ROUNDS
        hasDescription: true // SURVEY.SURVEYS.<SURVEY_KEY>.SURVEY_ROUNDS.<SURVEY_ROUND_KEY>.DESCRIPTION
      },
      questionGroups: [
        {
          id: 1,
          key: 'DEMO_START', // Root: SURVEY.QUESTION_GROUPS
          hasDescription: true, // SURVEY.QUESTION_GROUPS.<QUESTION_GROUP_KEY>.DESCRIPTION
          questions: [
            {
              id: 1,
              key: 'DEMO.ISDEMO', // Root: SURVEY.QUESTIONS
              hasDescription: false, // SURVEY.QUESTIONS.<QUESTION_KEY>.DESCRIPTION
              isRequired: true,
              type: 'yesNo',
              hasDoNotKnowOption: false,
              questionChoices: [
                {
                  id: 1,
                  key: null
                }
              ]
            },
            {
              id: 2,
              key: 'DEMO.HOW_GOOD_IS_DEMO',
              hasDescription: true,
              isRequired: true,
              type: 'scale',
              hasDoNotKnowOption: true,
              minValue: 1,
              maxValue: 7,
              questionChoices: [
                {
                  id: 2,
                  key: null
                }
              ]
            },
            {
              id: 3,
              key: 'DEMO.DO_YOU_HAVE_ENOUGH',
              hasDescription: true,
              isRequired: true,
              hasDoNotKnowOption: false,
              type: 'scale',
              scaleKey: 'SUFFICIENCY',
              minValue: -1,
              maxValue: 1,
              questionChoices: [
                {
                  id: 3,
                  key: 'FOOD' // Root: SURVEY.QUESTIONS.<QUESTION_KEY>.CHOICES
                },
                {
                  id: 4,
                  key: 'DRINK' // Root: SURVEY.QUESTIONS.<QUESTION_KEY>.CHOICES
                },
                {
                  id: 5,
                  key: 'CANDY' // Root: SURVEY.QUESTIONS.<QUESTION_KEY>.CHOICES
                }
              ],
            },
          ]
        }, // question group 1
        {
          id: 2,
          key: 'DEMO_FINISH', // Root: SURVEY.QUESTION_GROUPS
          hasDescription: false, // SURVEY.QUESTION_GROUPS.<QUESTION_GROUP_KEY>.DESCRIPTION
          questions: [
            {
              id: 4,
              key: 'DEMO.YOUR_OPINIONS', // Root: SURVEY.QUESTIONS
              hasDescription: true, // SURVEY.QUESTIONS.<QUESTION_KEY>.DESCRIPTION
              isRequired: false,
              type: 'text',
              questionChoices: [
                {
                  id: 6,
                  key: null,
                  isText: true
                }
              ]
            },
            {
              id: 5,
              key: 'DEMO.BIRTH_YEAR',
              hasDescription: false,
              isRequired: true,
              type: 'intValue',
              hasDoNotKnowOption: true,
              minValue: 1910,
              maxValue: 2019,
              questionChoices: [
                {
                  id: 7,
                  key: null
                }
              ]
            },
          ]
        }, // question group 2
      ]
    }; // survey object
  }
}

angular.module('ll').service('SurveyService', SurveyService);

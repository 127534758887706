import angular from 'angular';

class InterestsController {
  constructor($translate, InterestService) {
    this.$translate = $translate;
    this.interestService = InterestService;
  }

  $onInit() {
    this.title = this.title || null;
    this.enableEdit = this.enableEdit || false;
  }

  $onChanges(changes) {
    this.userId = changes.userId ? changes.userId.currentValue : this.userId;
    this.interestService.getInterests(this.userId).then(i => this.userInterests = i.length === 'undefined' ? [] : i );
  }

  addInterest(interest) {
    return {id: null, name: interest};
  }

  saveInterest(interest) {
    this.interestService.saveInterest(this.userId, interest.name)
      .then(response => {
        this.userInterests[this.userInterests.length - 1].id = response.interestId;
      });
  }

  deleteInterest(interest) {
    this.interestService.deleteInterest(this.userId, interest.id);
  }
}

angular.module('ll').component('interests', {
  template: require('./interests.component.html'),
  controller: InterestsController,
  controllerAs: 'vm',
  bindings: {
    enableEdit: '<',
    title: '<',
    userId: '<'
  },
  transclude: true
});

import angular from 'angular';

class ActivationCodeController {
  constructor(RegistrationApi, $state, ToastMessageService, $translate) {
    this.registrationApi = RegistrationApi;
    this.$state = $state;
    this.toastMessageService = ToastMessageService;
    this.$translate = $translate;
  }

  $onInit() {
    this.activationCode = '';
  }

  cancel() {
    this.$state.go('welcome');
  }

  join() {
    let activationCode = this.activationCode;
    this.registrationApi.getRegistrationDetailsByActivationCode(activationCode)
      .then(resp => {
        if (!resp.neighborhoodName)
          this.toastMessageService.showMessage(this.$translate.instant('ACTIVATION_CODE.CODE_NOT_VALID'));
        else
          this.$state.go('registration.form', {activationCode});
      });
  }
}

angular.module('ll').component('activationCode', {
  template: require('./activationcode.component.html'),
  controller: ActivationCodeController,
  controllerAs: 'vm'
});

import angular from 'angular';

angular.module('ll').factory('EventService', EventService);

function EventService($http, LoggingService, EnvironmentSettings, FeedPostFactory, EventFactory) {
  let labradorUrl = EnvironmentSettings.labradorUrl;
  return {
    getEvents: function () {
      return $http.get(`${labradorUrl}/api/publication/event`)
        .then(resp => resp.data.map(EventFactory.createEvent), resp => LoggingService.error('Error getting events', resp));
    },
    getEvent: function (publicationId) {
      return $http.get(`${labradorUrl}/api/publication/${publicationId}`)
        .then(resp => EventFactory.createEvent(FeedPostFactory.createFeedPost(resp.data)), resp => LoggingService.error('Error getting event', resp));
    },
    rsvpEvent: function (publicationId, rsvpStatus) {
      return $http.post(`${labradorUrl}/api/publication/event/${publicationId}/rsvp`, {status: rsvpStatus})
        .then(resp => resp.data, resp => LoggingService.error('Error rsvping event', resp));
    }
  };
}

/* eslint-disable no-console */
import angular from 'angular';

class ClassifiedsMenuController {

  constructor(ClassifiedsService, $state) {
    this.classifiedsService = ClassifiedsService;
    this.$state = $state;
  }

  $onInit() {
    this.adCategories = [];
    this.classifiedsService.getAdvertisementCategoriesFiltersGrouped().then(data => this.adCategories = data);
    this.freeItemsToggleName = 'CLASSIFIEDS.FREE_FILTER_BUTTON';
  }

  toggleFreeItems() {
    this.$state.go('app.classifieds.list', {onlyFreeItems: this.onlyFreeItems});
  }
}

angular.module('ll').component('classifiedsMenu', {
  template: require('./classifieds-menu.component.html'),
  controller: ClassifiedsMenuController,
  controllerAs: 'vm',
  bindings: {
    adType: '<',
    onlyFreeItems: '<'
  }
});

import angular from 'angular';
import {createRoute} from '../../../core/config';

class FacilityDetailController {
  constructor($state, $translate, $timeout, $scope, $mdDialog, moment, ToolbarService, ToastMessageService, FacilityService) {
    this.$state = $state;
    this.$translate = $translate;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$mdDialog = $mdDialog;
    this.moment = moment;
    this.toastMessageService = ToastMessageService;
    this.toolbarService = ToolbarService;
    this.facilityService = FacilityService;
  }

  $onInit() {
    this.toolbarService.configure({
      title: this.facility.name,
      showBackButton: true
    });

    const now = this.moment().toDate();
    const self = this;

    this._rebuildSlots(now);
    this.settings = {
      ...this._getInvalidDates(now),
      display: 'inline',
      layout: 'liquid',
      min: now,
      showSelector: false,
      yearChange: true,
      maxRange: 24 * 60 * 60 * 1000,
      theme: 'locallife',
      showOuterDays: false,
      onPageChange: (event, inst) => {
        const monthYear = self.moment(event.firstDay).format('YYYY-MM');
        self.reloadAllBookings(monthYear);
        self.facilityService.getBookingSlotsForFacility(self.facility.id, monthYear).then(data => {
          self.monthlySlotsData = data;
          self._rebuildSlots(event.firstDay);

          const invalidAndColorsObj = self._getInvalidDates(event.firstDay);
          inst.settings.invalid = invalidAndColorsObj.invalid;
          inst.settings.marked = invalidAndColorsObj.marked;
          inst.redraw();
        });
      },
      onDayChange: () => {
        self.$timeout(() => {
          self.selectedDaySlots = self.slots[self.moment(self.selectedDate[0]).format('YYYY-MM-DD')];
        }, 0);
      }
    };
  }

  _rebuildSlots(dayOfCurrentMonth) {
    this.selectedSlot = null;
    this.slots = {};

    const isFullDay = this.monthlySlotsData.slotLength === '1d';
    const hourSpan = +this.monthlySlotsData.slotLength[0];

    const lastDayOfMonth = this.moment(dayOfCurrentMonth).endOf('month').get('date');
    for (let d = 1; d <= lastDayOfMonth; d++) {
      const date = this.moment(dayOfCurrentMonth).set('date', d).format('YYYY-MM-DD');
      const currentDayData = this.monthlySlotsData.dates.find(day => day.date === date);
      const daySlots = [];
      if (currentDayData) {
        if (isFullDay) {
          daySlots.push({
            title: currentDayData.bookableRanges[0],
            available: !currentDayData.booked.length,
            myBooking: !currentDayData.bookedByMe.length
          });
        } else {
          currentDayData.bookableRanges.forEach(range => {
            const startHour = +range.split('-')[0];
            const endHour = +range.split('-')[1];
            for (let slotStartHour = startHour; slotStartHour < endHour; slotStartHour += hourSpan) {
              const occupied = currentDayData.booked.indexOf(slotStartHour) !== -1;
              daySlots.push({
                title: slotStartHour + '-' + (slotStartHour + hourSpan),
                available: !occupied,
                myBooking: occupied && currentDayData.bookedByMe.indexOf(slotStartHour) !== -1
              });
            }
          });
        }
      }
      this.slots[date] = daySlots;
    }
  }

  _getInvalidDates(dayOfCurrentMonth) {
    const disabledDates = [];
    for (let d = 1; d <= +this.moment(dayOfCurrentMonth).endOf('month').get('date'); d++) {
      const dMoment = this.moment(dayOfCurrentMonth).set('date', d);
      if (!this.monthlySlotsData.dates.find(dd => dd.date === dMoment.format('YYYY-MM-DD'))) {
        disabledDates.push(dMoment.startOf('day').toDate());
      }
    }

    const busyDates = [];
    const freeDates = [];
    const today = this.moment().startOf('day').toDate();

    for (let dKey in this.slots) {
      const date = this.moment(dKey, 'YYYY-MM-DD').toDate();
      if (this.slots[dKey].length && !this.slots[dKey].some(d => d.available)) {
        busyDates.push(date);
      } else if (!disabledDates.find(d => d.getTime() === date.getTime()) && date >= today) {
        freeDates.push(date);
      }
    }

    return {
      invalid: disabledDates,
      marked: [
        ...freeDates.map(dd => {
          return {d: dd, color: 'lightgreen'};
        }),
        ...busyDates.map(dd => {
          return {d: dd, color: 'red'};
        })
      ]
    };
  }

  selectSlot(slot) {
    this.selectedSlot = slot;
  }

  bookFacility() {
    this.facilityService.createBooking({
      facilityId: this.facility.id,
      date: this.moment(this.selectedDate[0]).format('YYYY-MM-DD'),
      hour: +this.selectedSlot.title.split('-')[0],
      lengthInMinutes: (+this.selectedSlot.title.split('-')[1] - +this.selectedSlot.title.split('-')[0]) * 60
    }).then(() => {
      this.toastMessageService.showMessage(this.$translate.instant('FACILITY_LIST.FACILITY_BOOKED'));
      this.$state.reload();
    }).catch((resp) => {
      if (resp.status === 409) {
        this.toastMessageService.showError(this.$translate.instant('FACILITY_LIST.FACILITY_ALREADY_BOOKED'));
      } else if (resp.status === 429) {
        this.toastMessageService.showError(this.$translate.instant('FACILITY_LIST.BOOKING_LIMIT_EXCEEDED'));
      } else {
        this.toastMessageService.showError(this.$translate.instant('FACILITY_LIST.FACILITY_BOOK_ERROR'));
      }
    });
  }

  editFacility() {
    this.$state.go('app.facilityEdit', {level: this.level, facilityId: this.facility.id});
  }

  reloadMyBookings() {
    this.facilityService.getMyBookingsForFacility(this.facility.id).then(data => this.myBookings = data);
  }

  reloadAllBookings(monthYear) {
    if (monthYear) {
      this.monthYear = monthYear;
    }
    this.facilityService.getBookingsForFacility(this.facility.id, this.monthYear).then(data => this.allBookings = data);
  }

  cancelBooking(e, booking) {
    let confirm = this.$mdDialog.confirm()
      .title(this.$translate.instant('FACILITY_LIST.CANCEL_BOOKING_CONFIRM'))
      .targetEvent(e)
      .ok(this.$translate.instant('BUTTON.YES'))
      .cancel(this.$translate.instant('BUTTON.NO'));

    this.$mdDialog.show(confirm)
      .then(() => this.facilityService.deleteBooking(booking.id))
      .then(() => {
        this.toastMessageService.showMessage(this.$translate.instant('FACILITY_LIST.CANCEL_BOOKING_SUCCESS'));
        this.$state.reload();
      });
  }
}

angular.module('ll')
  .component('facilityDetail', {
    template: require('./facility-detail.component.html'),
    controller: FacilityDetailController,
    controllerAs: 'vm',
    bindings: {
      facility: '<',
      canEdit: '<',
      level: '<',
      monthlySlotsData: '<',
      myBookings: '<',
      allBookings: '<',
      monthYear: '<'
    }
  })
  .config(createRoute({
    name: 'app.facility',
    component: 'facilityDetail',
    url: '/:level/facility/:facilityId',
    resolve: {
      canEdit: (SessionService) => SessionService.getUser().isPropertyAdmin(),
      level: ($transition$) => $transition$.params().level,
      facility: ($transition$, $state, FacilityService) => FacilityService.getFacility($transition$.params().facilityId).then(facility => {
        facility.images = facility.images.map(imgUrl => {
          return {
            feed: imgUrl,
            original: imgUrl
          };
        });

        return facility;
      }),
      myBookings: ($transition$, FacilityService, moment) => FacilityService.getMyBookingsForFacility($transition$.params().facilityId),
      allBookings: ($transition$, FacilityService, moment) => FacilityService.getBookingsForFacility($transition$.params().facilityId, moment().format('YYYY-MM')),
      monthlySlotsData: ($transition$, FacilityService, moment) => FacilityService.getBookingSlotsForFacility($transition$.params().facilityId, moment().format('YYYY-MM'))
    },
  }));

import angular from 'angular';
import {unlock} from 'tua-body-scroll-lock';

class CustomPopupController {
  constructor() {
  }

  hidePopup() {
    this.shown = false;
    unlock();
  }
}

angular.module('ll').component('llCustomPopup', {
  template: require('./custom-popup.component.html'),
  controller: CustomPopupController,
  controllerAs: 'vm',
  transclude: true,
  bindings: {
    showCancelButton: '<',
    shown: '='
  }
});

import angular from 'angular';

angular.module('ll').filter('dateFilter', DateFilter);

function DateFilter(moment) {
  return function (input, month) {
    let date = moment(input);

    if (month) {
      return date.format('MMM');
    }
    return new Date(date.toISOString());
  };
}

import angular from 'angular';
import {captureMessage, configureScope, addBreadcrumb, Severity} from '@sentry/browser';


class LoggingService {
  constructor(EnvironmentSettings, SessionService, $q) {
    this.sessionService = SessionService;
    this.$q = $q;
    this.loggingEnabled = EnvironmentSettings.loggingEnabled;
  }

  identifyUser(user) {
    if (this.loggingEnabled) {
      configureScope(scope => {
        scope.setUser({id: user.userId});
      });
    }
  }

  addBreadcrumb(message, data, severity = null, category = null) {
    if (this.loggingEnabled) {
      severity = severity || Severity.Log;
      category = category || 'app';
      addBreadcrumb({message, data, category, level: severity});
    }
  }

  _log(msg, severity = null, obj = null) {
    obj = obj || {};
    severity = severity || Severity.Log;
    if (this.loggingEnabled) {
      captureMessage(msg, severity);
    }
    return this.$q.reject(obj);
  }

  error(msg, obj = null) {
    return this._log(msg, Severity.Error, obj);
  }

  log(msg, obj = null) {
    return this._log(msg, Severity.Log, obj);
  }

  info(msg, obj = null) {
    return this._log(msg, Severity.Info, obj);
  }
}

angular.module('ll').service('LoggingService', LoggingService);

import angular from 'angular';

class RentOut {

  constructor(ToolbarService, $translate, FeedService, TrackingService) {
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.feedService = FeedService;
    this.trackingService = TrackingService;
  }

  $onInit() {
    this.items = this.items || [];
    this.title = this.$translate.instant('CLASSIFIEDS.RENT_OUT_BOOKINGS');
    this.configureToolbar();

    let mapUpcomingRentApplications = ad => {
      return ad.rentApplications.filter(rent => rent.isAccepted)
        .map(rent => ({adId: ad.id, title: ad.title, type: ad.type, price: ad.price, currency: ad.currency, images: ad.images, ...rent}));
    };

    this.upcoming = this.items.flatMap(mapUpcomingRentApplications);

    let mapPendingRentApplications = ad => {
      let pendingRequests = ad.rentApplications.filter(rent => !rent.isAccepted).length;
      return {...ad, pendingRequests};
    };

    this.pending = this.items
      .filter(i => i.rentApplications.find(rent => !rent.isAccepted))
      .map(mapPendingRentApplications);

    this.trackingService.track('classifieds.rentOut', {upcoming: this.upcoming.length, pending: this.pending.length});
  }

  configureToolbar(){
    this.toolbarService.configure({
      title: this.title
    });
  }
}

angular.module('ll').component('rentOut', {
  template: require('./rent-out-bookings.component.html'),
  controller: RentOut,
  controllerAs: 'vm',
  bindings: {
    items: '<',
    postCategory: '<'
  }
});

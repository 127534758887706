import angular from 'angular';

class LanguageService {
  constructor($translate, LoggingService) {
    this.$translate = $translate;
    this.loggingService = LoggingService;
  }

  getLanguage() {
    let defaultLanguage = 'en-US';
    let lang = this.$translate.proposedLanguage() || this.$translate.use();
    if (!lang) {
      this.loggingService.error('LanguageService.getLanguage(): Could not determine language, setting to default ' + defaultLanguage);
      return defaultLanguage;
    }
    if (lang.startsWith('en') || lang.startsWith('sv') || lang.startsWith('pt')) {
      return lang.replace('_', '-');
    }
    return defaultLanguage;
  }

  setLanguage(lang) {
    lang = lang.replace('-', '_'); // hack so $translate understands the locale we are using
    this.$translate.use(lang);
  }
}

angular.module('ll').service('LanguageService', LanguageService);

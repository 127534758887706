require('./comments');
require('./docs');
require('./energywidget/energywidget');
require('./event-rsvp/event-rsvp.component');
require('./feed/feed.component');
require('./feedpost/index');
require('./filelist/filelist.component');
require('./facilities/facility-list/facility-list.component');
require('./facilities/facility-detail/facility-detail.component');
require('./facilities/facility-create-edit/facility-create-edit.component');
require('./fileselector/fileselector.component');
require('./menu');
require('./simplecard/simplecard.component');
require('./interests/interests.component');
require('./toolbar/toolbar.component');
require('./userlist/userlist.component');
require('./login/login.component');
require('./activationcode/activationcode.component');
require('./findmyarea/findmyarea.component');
require('./map/map.component');
require('./app/app.component');
require('./direct-message');
require('./profile-icon/profile-icon.component');
require('./forgotpassword/forgotpassword.component');
require('./base2/base2.component');
require('./profileheader/profileheader.component');
require('./changepassword/changePassword.component');
require('./changepassword-success/changePasswordSuccess.component');
require('./feedpostcomposer');
require('./imagethumb/imagethumb.component');
require('./item-advertisement');
require('./image-slider/image-slider.component');
require('./user-simple-badge/user-simple-badge.component');
require('./rent-item/rent-item.component');
require('./rent-out-item/rent-out-item.component');
require('./scoring-scale/scoring-scale.component');
require('./notification-item/notification-item.component');
require('./badge-nav/badge-nav.component');
require('./date-period-picker/date-period-picker.component');
require('./gauge/gauge.component');
require('./expandable-visualization/expandable-visualization.component');
require('./expandable-visualization/progress-bar/progress-bar.component');
require('./expandable-visualization/columns-visualization/columns-visualization.component');
require('./expandable-visualization/lanes-visualization/lanes-visualization.component');
require('./expandable-visualization/chart-visualization/chart-visualization.component');
require('./expandable-visualization/plain-visualization/plain-visualization.component');
require('./custom-popup');
require('./custom-slider/custom-slider.component');
require('./bell-badge/bell-badge.component');
require('./collapsable-text/collapsable-text.component');
require('./like-button/like-button.component');
require('./event-detail');
require('./time-selector/time-selector.component');
require('./context-action-menu/context-action-menu.component');
require('./user-settings/user-settings.component');
require('./system-version/system-version.component');
require('./delete-account/delete-account.service');
require('./marketplace/marketplace.component');
require('./quick-access-bar/quick-access-bar.component');
require('./survey/index');
require('./waste-inlet-map');
require('./coupon-list/coupon-list.component');
require('./sfa');


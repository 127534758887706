import angular from 'angular';

class DeviceInfoService {

  constructor($http, $q, EnvironmentSettings, LoggingService, SessionService) {
    this.$http = $http;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
    this.loggingService = LoggingService;
    this.sessionService = SessionService;
  }

  collectDeviceInformation() {
    let deviceInfo = window.oneSignal ? window.oneSignal.deviceInfo : null;

    if (deviceInfo == null)
      return;

    this.loggingService.addBreadcrumb('Collected device info', {deviceInfo: deviceInfo});
    if (deviceInfo.oneSignalUserId) {
      this.sessionService.setDeviceId(deviceInfo.oneSignalUserId);
    }

    this.$http.post(`${this.labradorUrl}/api/notifications/connect_user_to_device`, deviceInfo)
      .catch(resp => this.loggingService.error('Error capturing device information', resp));
  }
}

angular.module('ll').service('DeviceInfoService', DeviceInfoService);

/* eslint-disable camelcase */
import angular from 'angular';

// This is a hack so we can save the placeId from onAddressSelected() callback
//    problem is that from within the callback the 'this' is incorrect, since
//    ngMaps is doing something weird with it
let __hack_place = null;
// Another hack that allows saving of a location text not part of a place, after a place has first been selected
let __hack_hasLocationTextChangedAfterChoosingAddress = false;

class EventComposerController {

  constructor(FeedService, $scope, moment, SessionService, EnvironmentSettings, $translate) {
    this.feedService = FeedService;
    this.$scope = $scope;
    this.moment = moment;
    this.sessionService = SessionService;
    this.isPropertyBoardMessage = false;
    this.country = {country: EnvironmentSettings.country};
    this.$translate = $translate;
  }

  $onInit() {
    this.attachments = [];
    this.startDate = this.moment().toDate();

    let preSelectedTo = this.toOptions.find(opt => opt.isPreSelected === true);
    if (preSelectedTo) {
      this.to = preSelectedTo.value;
    }

    this.isPropertyAdmin = this.sessionService.getUser().isPropertyAdmin();
    this.startTimeLabel = this.$translate.instant('NEWPOST.EVENT.START_TIME');
  }

  $onChanges(objectChange) {
    if (!objectChange.command) return;

    let command = objectChange.command.currentValue;
    if (command.postType !== 'event') return;
    switch (command.name) {
      case 'save':
        this.save();
        break;
      case 'attachFile':
        this.setAttachment(command.value);
        break;
    }
  }

  setAttachment(file) {
    if (this.attachments.length <= 4) {
      this.attachments.push(file);
    }
  }

  removeAttachment(src) {
    this.attachments = this.attachments.filter(a => a.url !== src);
  }

  save() {
    this.$scope.eventForm.$setSubmitted();
    this.validateStartDate();
    if (!this.$scope.eventForm.$valid) {
      this.onSaveError({error:'invalid form'});
      return;
    }

    let startDate = this.moment(this.startDate);
    this.feedService.postEventToFeed(
      this.to.name,
      this.to.id,
      this.title,
      __hack_place && !__hack_hasLocationTextChangedAfterChoosingAddress ? __hack_place.name : this.place,
      __hack_place && !__hack_hasLocationTextChangedAfterChoosingAddress ? __hack_place.place_id : null,
      startDate.format('YYYY-MM-DD'),
      startDate.format('HH:mm'),
      this.description,
      this.attachments.map(a => a.blob),
      this.isPropertyBoardMessage
    )
      .then(p => {
        this.clearForm();
        this.onSave({feedPost: p});
      })
      .catch(() => this.onSaveError({error: 'server error'}));
  }

  setTime(hour, minute) {
    this.startDate.setHours(hour, minute);
    this.validateStartDate();
  }

  validateStartDate() {
    let isStartTimeValid = this.moment(this.startDate).isAfter(this.moment());
    this.$scope.eventForm.startDate.$setValidity('dateInPast', isStartTimeValid);
    if (this.$scope.eventForm.selectedHour || this.$scope.eventForm.selectedMinute) {
      this.$scope.eventForm.selectedHour.$setValidity('dateInPast', isStartTimeValid);
      this.$scope.eventForm.selectedMinute.$setValidity('dateInPast', isStartTimeValid);
    }
  }

  clearForm() {
    this.to = null;
    this.title = '';
    this.place = null;
    this.description = '';
    this.attachments = null;
    this.startDate = this.moment().toDate();
    __hack_place = null;
    let form = this.$scope.eventForm;
    form.$setPristine();
    form.$setUntouched();
  }

  onPlaceTextChanged() {
    __hack_hasLocationTextChangedAfterChoosingAddress = true;
  }

  onAddressSelected() {
    let place = this.getPlace();
    if (place) {
      __hack_place = place;
      __hack_hasLocationTextChangedAfterChoosingAddress = false;
    }
  }
}

angular.module('ll').component('eventComposer', {
  template: require('./eventcomposer.component.html'),
  controller: EventComposerController,
  controllerAs: 'vm',
  bindings: {
    toOptions: '<',
    command: '<',
    onSave: '&',
    onSaveError: '&'
  }
});

import angular from 'angular';

angular.module('ll').service('LoaderInterceptorService', LoaderInterceptorService);

function LoaderInterceptorService($q) {
  const self = this;
  self.isLoading = false;
  self.counter = 0;
  self.callbacks = [];

  return {
    request,
    requestError,
    response,
    responseError,
    onLoading
  };

  function request(config) {
    if (!config.hideLoader) {
      setLoading();
    }
    return config;
  }

  function requestError(rejection) {
    unsetLoading();
    return $q.reject(rejection);
  }

  function response(response) {
    if (!response.config.hideLoader) {
      unsetLoading();
    }
    return response;
  }

  function responseError(rejection) {
    unsetLoading();
    return $q.reject(rejection);
  }

  function onLoading(cb) {
    self.callbacks.push(cb);
  }

  function setLoading() {
    self.counter++;
    self.isLoading = true;
    self.callbacks.forEach(cb => cb(self.isLoading));
  }

  function unsetLoading() {
    self.counter--;
    if (self.counter === 0) {
      self.isLoading = false;
      self.callbacks.forEach(cb => cb(self.isLoading));
    }
  }
}


import angular from 'angular';

class SustainabilitySnapshotComponentController {
  constructor(DateService, SessionService, $mdDialog, moment, $state, $translate, TrackingService) {
    this.dateService = DateService;
    this.sessionService = SessionService;
    this.$mdDialog = $mdDialog;
    this.moment = moment;
    this.$state = $state;
    this.$translate = $translate;
    this.trackingService = TrackingService;
  }

  $onInit() {
    const user = this.sessionService.getUser();
    this.user = user;
    this.neigborhood = user.neighborhood.name;
    this.outcome = this.data.outcome;
    this.type = this.data.type;
    this.periodTranslated = this.getPeriodAsText(this.data.dateFrom, this.data.period, user.language);
    this.periodWithoutYearTranslated = this.getPeriodAsText(this.data.dateFrom, this.data.period, user.language, false);
    this.lastPeriodKey = 'ENERGY.PERIOD.LAST_' + this.data.period.toUpperCase();
    // Aram 2021-02-02: disable auto-showing the popup for now as most users should have seen it already
    // if (!this.isInFeed && this.type === 'recycling') { // Onboarding is only shown for recycling for now
    //   this.openOnboardingPopup(this.type);
    // }
  }

  getPeriodAsText(dateStr, period, locale, withYear = true) {
    if (period === 'month') {
      const date = this.moment(dateStr);
      return withYear ? date.locale(locale).format('MMMM YYYY') : date.locale(locale).format('MMMM');
    } else {
      return dateStr;
    }
  }

  goToDetails() {
    this.trackingService.track('sustainability_snapshot_feedpost.details.click', {dateFrom: this.data.dateFrom, outcome: this.outcome, isBetterThanPrevious: this.data.isBetterThanPrevious});
    let typeUppercase = this.type.toUpperCase();
    let outcomeUppercase = this.outcome.toUpperCase();
    let bulletPrefix = 'SUSTAINABILITY.SNAPSHOT.HIGHLIGHTS.LONG.' + typeUppercase + '.';
    let tipPrefix = 'SUSTAINABILITY.SNAPSHOT.TIP.';
    let title = this.$translate.instant('SUSTAINABILITY.SNAPSHOT.POPUP_TITLE', {period: this.periodWithoutYearTranslated});
    let lastPeriodTranslated = '';
    if (this.data.period === 'month') {
      let lastPeriodName = this.getPeriodAsText(this.moment(this.data.dateFrom).subtract(1, 'months').format('YYYY-MM-DD'), this.data.period, this.user.language, false);
      lastPeriodTranslated = this.$translate.instant('DATE.COMPARISON.MONTH', {lastPeriodName});
    }
    let vsNeighbors = this.$translate.instant(bulletPrefix + 'VS_NEIGHBORS.' + outcomeUppercase, {
      neighborhood: this.neigborhood,
      percentImprovement: Math.abs(this.data.percentImprovementVsNeighbors)
    });
    let vsSelf = this.data.isBetterThanPrevious != null ? this.$translate.instant(bulletPrefix + 'VS_SELF.' + (this.data.isBetterThanPrevious ? 'BETTER' : 'WORSE'), {
      percentImprovement: Math.abs(this.data.percentImprovement),
      valueImprovement: this.data.valueImprovement ? Math.abs(this.data.valueImprovement) : null,
      numberOfBags: this.data.valueImprovement ? Math.round(Math.abs(this.data.valueImprovement / 0.8)) : null,
      lastPeriod: lastPeriodTranslated
    }) : null;
    let tipTitle = this.$translate.instant(tipPrefix + 'TITLE.' + outcomeUppercase);
    let tipContent;
    if (this.data.tip) {
      let language = this.user.language ? this.user.language.substr(0, 2) : '';
      tipContent = this.data.tip[language] ? this.data.tip[language] : this.data.tip['en']; // Fallback to english if no translation exists for user language
    }
    if (!tipContent) {
      tipContent = this.$translate.instant(tipPrefix + 'CONTENT.' + typeUppercase + '.' + outcomeUppercase);
    }
    let bullets = [vsNeighbors];
    if (vsSelf != null) {
      bullets.push(vsSelf);
    }
    this.$state.go('app.sustainability.household', {
      initialPeriod: 'month',
      initialDateFrom: this.data.dateFrom,
      reportPopup: {
        title,
        bullets,
        tip: {
          title: tipTitle,
          content: tipContent
        }
      }
    });
  }

  openOnboardingPopup(type){
    return this.$mdDialog.show({
      template: require('./sustainability-snapshot-help-popup.tmpl.html'),
      locals: {
        type
      },
      clickOutsideToClose: true,
      controllerAs: 'vm',
      controller: this.onboardingPopupCtrl
    });
  }

  onboardingPopupCtrl(type, $mdDialog) {
    this.type = type;
    this.hideDialog = function () {
      $mdDialog.hide();
    };
  }

}

angular.module('ll').component('sustainabilitySnapshot', {
  template: require('./sustainability-snapshot.component.html'),
  controller: SustainabilitySnapshotComponentController,
  controllerAs: 'vm',
  bindings: {
    data: '<',
    isInFeed: '<'
  }
});

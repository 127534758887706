import angular from 'angular';

angular.module('ll').factory('LoginService', LoginService);

function LoginService($window, $http, $q, SessionService, DeviceInfoService, UserService, TrackingService, EnvironmentSettings, $httpParamSerializerJQLike, LoggingService, LanguageService) {

  return {
    signIn,
    signOut,
    initializeSessionService,
    initializeExternalServices
  };

  function signIn(username, password) {
    return mainSignInFlow(login(username, password));
  }

  function signOut() {
    let deviceId = SessionService.getDeviceId();
    return $http.post(`${EnvironmentSettings.baseUrl}/api/logout`, {deviceId})
      .then(() => {
        LoggingService.info('Logout successful');
      }).catch(r => {
        LoggingService.error('Logout error', r);
      })
      .then(() => resetSession());
  }

  function resetSession() {
    TrackingService.track('Sign out');
    TrackingService.reset();
    SessionService.reset();
  }

  function mainSignInFlow(login) {
    return login
      .then(storeTokens)
      .then(initializeSessionService)
      .then(initializeExternalServices);
  }

  function storeTokens(resp) {
    SessionService.doAuthenticate(resp.data);
  }

  function initializeSessionService() {
    return UserService.fetchLoggedInUser()
      .then(u => {
        LanguageService.setLanguage(u.language);
        return u;
      });
  }

  function initializeExternalServices(userData) {
    let deferred = $q.defer();
    LoggingService.identifyUser(userData);
    TrackingService.identifyUser(userData);
    TrackingService.track('Login');
    DeviceInfoService.collectDeviceInformation();
    deferred.resolve(userData);
    return deferred.promise;
  }

  function login(username, password) {
    let fd = new FormData();
    fd.append('username', username);
    fd.append('password', password);

    let opts = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };
    return $http.post(`${EnvironmentSettings.labradorUrl}/api/login`, fd, opts);
  }

}

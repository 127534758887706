import angular from 'angular';
import {Feature} from 'ol';
import {Point} from 'ol/geom';
import {GeoJSON, WKT} from 'ol/format';
import {fromLonLat} from 'ol/proj';
import {intersect} from '@turf/turf';

angular.module('ll')
  .factory('RegionPolygonService', RegionPolygonService);

function RegionPolygonService(RegionRepository) {
  // Variables that need to be accessable within functions
  const geoJSONformatter = new GeoJSON();
  const WKTformatter = new WKT();
  const projectionIdentifierESPG3857 = 'EPSG:3857';

  return {
    getRegions: getRegions,
    getRegionFeatures: getRegionFeatures,
    getRegionForLocation: getRegionForLocation,
    createRegion: createRegion,
    saveRegionPolygon: saveRegionPolygon,
    deleteRegionPolygon: deleteRegionPolygon
  };

  function getRegions() {
    return RegionRepository.getRegions().then((responseData) => {
      return responseData;
    });
  }

  function getRegionFeatures() {
    return RegionRepository.getRegionPolygons().then((responseData) => {
      let returnData = [];
      if (responseData) {
        let regionPolygons = responseData;
        for (let i = 0; i < regionPolygons.length; i++) {
          let region = regionPolygons[i];
          let feature = readFeatureFromWKT(region.polygonWKT);
          feature.set('name', region.name);
          feature.setId(region.id);
          returnData.push(feature);
        }
      }
      return returnData;
    });
  }

  function getRegionForLocation(lon, lat) {
    let pointFeature = new Feature({
      geometry: new Point(fromLonLat([lon, lat]))
    });

    let pointWKT = WKTformatter.writeFeature(
      pointFeature,
      {
        dataProjection: projectionIdentifierESPG3857,
        featureProjection: projectionIdentifierESPG3857
      }
    );

    return RegionRepository.getRegionForPointWKT(pointWKT).then((responseData) => {
      return responseData;
    });
  }

  function createRegion(region) {
    return RegionRepository.createRegion(region).then((responseData) => {
      return responseData;
    });
  }

  function saveRegionPolygon(chosenRegion, feature, polygonSource) {
    let regionId = parseInt(chosenRegion.id);

    try {
      let overlappingPolygons = getOverlappingPolygons(feature, polygonSource);

      if (overlappingPolygons.length > 0) {
        let errorMsg = 'The polygon for ' + chosenRegion.name + ' overlaps with the following ' + overlappingPolygons.length + ' regions: ';
        for (let i = 0; i < overlappingPolygons.length; i++) {
          let ip = overlappingPolygons[i];
          errorMsg += ip.get('name');
          if (i < overlappingPolygons.length - 1) {
            errorMsg += ', ';
          }
        }
        return Promise.reject(new Error(errorMsg));
      }
    } catch (e) {
      return Promise.reject(e);
    }

    let polygonWKT = WKTformatter.writeFeature(
      feature,
      {
        dataProjection: projectionIdentifierESPG3857,
        featureProjection: projectionIdentifierESPG3857
      }
    );

    let surroundingRegionIds = [];
    for (let i = 0; i < chosenRegion.surroundingRegions.length; i++) {
      surroundingRegionIds.push(chosenRegion.surroundingRegions[i].id);
    }

    return RegionRepository.saveRegionPolygon(regionId, polygonWKT, surroundingRegionIds).then((responseData) => {
      return responseData;
    });
  }

  function deleteRegionPolygon(regionId) {
    return RegionRepository.deleteRegionPolygon(regionId).then((responseData) => {
      return responseData;
    });
  }

  // Private functions
  function readFeatureFromWKT(WKTString) {
    let feature = WKTformatter.readFeature(
      WKTString,
      {
        dataProjection: projectionIdentifierESPG3857,
        featureProjection: projectionIdentifierESPG3857
      });
    return feature;
  }

  function getOverlappingPolygons(feature, source) {
    let intersectingPolygons = [];
    let featureGeoJson = geoJSONformatter.writeFeatureObject(feature);

    source.forEachFeature(function (f) {
      let feature2GeoJson = geoJSONformatter.writeFeatureObject(f);
      let intersection = intersect(featureGeoJson, feature2GeoJson);
      if (intersection && intersection.geometry !== null) {
        // Check for overlapping area = the intersecting element is a Polygon or MultiPolygon
        // (intersection Points and LineStrings are OK as the features merely touch but do not share any area)
        let type = intersection.geometry.type;
        if (type === 'Polygon' || type === 'MultiPolygon') {
          intersectingPolygons.push(f);
        } else if (type === 'GeometryCollection') {
          for (let i = 0; i < intersection.geometry.geometries.length; i++) {
            let type2 = intersection.geometry.geometries[i].type;
            if (type2 === 'Polygon' || type2 === 'MultiPolygon') {
              intersectingPolygons.push(f);
            }
          }
        }
      }
    });

    return intersectingPolygons;
  }
}

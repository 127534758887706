import angular from 'angular';

class FeedPostAlertComponentController {
  constructor(uiAlertService, PostService, $translate) {
    this.uiAlertService = uiAlertService;
    this.postService = PostService;
    this.$translate = $translate;
  }

  $onInit() {
    this.content = this.alert.getContent();
    this.showComments = false;
  }

  deleteAlert(event) {
    this.uiAlertService.showConfirm(this.$translate.instant('POST.DELETE.POST'), this.$translate.instant('POST.DELETE.ARE_YOU_SURE'))
      .then(() => this.postService.deletePost(this.alert.postId))
      .then(() => this.onDelete());
  }

  toggleComments() {
    this.showComments = !this.showComments;
  }

  updateLikes(likes) {
    this.alert.likes = likes;
  }
}

angular.module('ll').component('feedpostAlert', {
  template: require('./feedpost-alert.component.html'),
  controller: FeedPostAlertComponentController,
  controllerAs: 'vm',
  bindings: {
    alert: '<',
    onDelete: '&'
  }
});

import angular from 'angular';

function CompileHtmlDirective($sce, $compile) {

  function link(scope, element, attr) {
    let expression = $sce.parseAsHtml(attr.llCompileHtml);

    let getResult = function () {
      return expression(scope);
    };

    scope.$watch(getResult, function (newValue) {
      let linker = $compile(newValue);
      element.append(linker(scope));
    });
  }

  return {
    restrict: 'A',
    link
  };
}

angular.module('ll').directive('llCompileHtml', CompileHtmlDirective);

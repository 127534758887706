import angular from 'angular';

class PlainVisualizationController {
  constructor() {
  }

  $onInit() {
    if (this.config.iconProgress) {
      this.progressIcons = [];
      for (let i = 0; i < this.config.iconProgress.max; i++) {
        this.progressIcons.push({
          filled: i < this.config.iconProgress.value
        });
      }
    }
  }
}

angular.module('ll').component('llPlainVisualization', {
  template: require('./plain-visualization.component.html'),
  controller: PlainVisualizationController,
  controllerAs: 'vm',
  bindings: {
    config: '<',
    availableHeight: '<',
    mainColor: '<'
  },
});

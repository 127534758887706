import angular from 'angular';

class ClassifiedsService {
  constructor($http, LoggingService, EnvironmentSettings, SessionService, AdvertisementFactory, $q, $translate) {
    this.$http = $http;
    this.loggingService = LoggingService;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
    this.sessionService = SessionService;
    this.advertisementFactory = AdvertisementFactory;
    this.$q = $q;
    this.$translate = $translate;

    this.requestWithFormDataConfig = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };
  }

  postBuySellAdvertisement(collectionType, collectionId, postCategoryId, adCategoryId, title, description, type, price, files) {
    let fd = new FormData();
    fd.append('title', title);
    fd.append('description', description);
    fd.append('categoryId', postCategoryId);
    fd.append('itemCategoryId', adCategoryId);
    fd.append('price', price);
    fd.append('publicationTarget', collectionType);
    fd.append('type', type);
    files.forEach((f) => fd.append('images', f));

    return this.$http.post(`${this.labradorUrl}/api/classifieds/ad`, fd, this.requestWithFormDataConfig)
      .then(resp => this.advertisementFactory.create(resp.data), resp => this.loggingService.error('Error posting post', resp));
  }

  fetchAdvertisements(adType = null, itemCategoryId = 1, page = 0, search = null, sortBy = null, pageSize = 20, onlyFreeItems = false, userId = null) {

    let url = `${this.labradorUrl}/api/classifieds`;

    let params = {search, page, size: pageSize};

    if (sortBy) {
      params['sort'] = `${sortBy.field}_${sortBy.direction}`; // New Java way
    }

    if (adType) {
      // aType means adType to avoid the request being blocked by ad blockers
      // params.aType = adType; // Currently removed so we can see all ads on one page.
    }

    if (itemCategoryId && itemCategoryId !== 1) {
      params.itemCategory = itemCategoryId;
    }

    if (onlyFreeItems) {
      params.price = 0;
    }

    if (userId) {
      params.author = userId;
    }

    return this.$http.get(url, {params})
      .then(resp => this.convertToClassifiedsObjects(resp.data), resp => this.loggingService.error('Error getting classifieds items', resp));
  }

  getAdvertisement(id) {
    return this.$http.get(`${this.labradorUrl}/api/classifieds/ad/${id}`)
      .then(resp => this.advertisementFactory.create(resp.data), resp => this.loggingService.error('Error getting classifieds item', resp));
  }

  cancelBooking(id) {
    return this.$http.post(`${this.labradorUrl}/api/classifieds/rent/${id}/cancel`)
      .then(resp => resp.data, resp => this.loggingService.error('Error canceling rental booking', resp));
  }

  acceptBooking(id) {
    return this.$http.post(`${this.labradorUrl}/api/classifieds/rent/${id}/accept`)
      .then(resp => resp.data, resp => this.loggingService.error('Error canceling rental booking', resp));
  }

  // TODO: tested but ad belonging to user can be booked by self
  bookItem(id, startDate, endDate) {
    let params = {advertisementId: id, startDate: startDate, endDate: endDate};
    return this.$http.post(`${this.labradorUrl}/api/classifieds/rent`, params)
      .then(resp => resp.data, resp => this.loggingService.error('Error booking item', resp));
  }

  editAdvertisement(advertisement) {
    let oldImageIds = [];
    let newImages = [];
    for (let i = 0; i < advertisement.images.length; i++) {
      let imageIdOrBlob = advertisement.images[i];
      if (imageIdOrBlob instanceof Blob) {
        newImages.push(imageIdOrBlob);
      } else {
        oldImageIds.push(imageIdOrBlob);
      }
    }

    let fd = new FormData();
    fd.append('title', advertisement.title);
    fd.append('price', advertisement.price);
    fd.append('description', advertisement.description);
    fd.append('itemCategoryId', advertisement.itemCategory);
    oldImageIds.forEach(id => fd.append('oldImageIds', id));
    newImages.forEach((f) => fd.append('newImages', f));

    return this.$http.put(`${this.labradorUrl}/api/classifieds/ad/${advertisement.id}`, fd, this.requestWithFormDataConfig)
      .then(resp => this.advertisementFactory.create(resp.data), resp => this.loggingService.error('Error updating classifieds item', resp));
  }

  getAdvertisementTypeById(id) {
    return this.getAdvertisementTypes().then(data => data.find(ad => ad.id.toLowerCase() === id.toLowerCase()));
  }

  getAdvertisementCategoryById(id) {
    return this.getAdvertisementCategories().then(data => data.find(c => c.id === id));
  }

  getAdvertisementCategoryFilterById(id) {
    return this.getAdvertisementCategoriesFilters().then(data => data.find(c => c.id === id));
  }

  getAdvertisementTypes() {
    let deferred = this.$q.defer();
    deferred.resolve(ADVERTISEMENT_TYPES);
    return deferred.promise;
  }

  getAdvertisementCategoriesFilters() {
    return this.getAdvertisementCategories().then(data => {
      return [{id: 1, name: 'ITEM_CATEGORY.ALL'}].concat(data);
    });
  }

  getAdvertisementCategoriesFiltersGrouped() {
    return this.getAdvertisementCategories().then(data => {
      let groups = [];
      for (let i = 0; i < data.length; i++) {
        let category = data[i];
        let group = groups.find(g => {
          return g.advertisementType === category.advertisementType;
        });
        if (!group) {
          group = {advertisementType: category.advertisementType, categories: []};
          groups.push(group);
        }
        group.categories.push(category);
      }
      return groups;
    });
  }

  getAdvertisementCategoriesFiltersByAdType(adType) {
    return this.getAdvertisementCategories().then(data => {
      return [{id: 1, name: 'ITEM_CATEGORY.ALL'}].concat(data.filter(cat => cat.advertisementType === adType));
    });
  }

  getAdvertisementCategoriesByAdType(adType) {
    return this.getAdvertisementCategories()
      .then(data => data.filter(cat => cat.advertisementType === adType));
  }

  getAdvertisementCategories() {
    let deferred = this.$q.defer();
    let adCategories = this.sessionService.getSession('adCategories');

    if (adCategories) deferred.resolve(adCategories);
    else this.fetchAdvertisementCategories().then(deferred.resolve);

    return deferred.promise;
  }

  fetchAdvertisementCategories() {
    return this.$http.get(`${this.labradorUrl}/api/classifieds/categories`)
      .then(resp => this.sessionService.setSession('adCategories', resp.data))
      .catch(resp => this.loggingService.error('Error getting advertisement categories', resp));
  }

  // TODO: sold not shown in frontend
  markAsSold(advertisement) {
    return this.$http.put(`${this.labradorUrl}/api/classifieds/ad/mark_as_sold/${advertisement.id}`)
      .then(resp => resp.data, resp => this.loggingService.error('Error marking item as sold', resp));
  }

  convertToClassifiedsObjects(ads) {
    return ads.map(ad => this.advertisementFactory.create(ad));
  }

  getRentApplication() {
    return this.$http.get(`${this.labradorUrl}/api/classifieds/rent`)
      .then(resp => resp.data.advertisements)
      .catch(resp => this.loggingService.error('Error getting rent application', resp));
  }

  getRentOutBookings() {
    return this.getRentApplication().then(data => data.filter(t => t.author.userId === this.sessionService.getUser().userId));
  }

  getRentBookings() {
    return this.getRentApplication().then(data => data.filter(t => t.author.userId !== this.sessionService.getUser().userId));
  }

  getAdvertisementReviews(advertisementId) {
    return this.$http.get(`${this.labradorUrl}/api/classifieds/ad/${advertisementId}/review`)
      .then(resp => resp.data, resp => this.loggingService.error('Error getting reviews', resp));
  }

  writeAdvertisementReview(advertisementId, reviewScore, reviewComment) {
    return this.$http.post(`${this.labradorUrl}/api/classifieds/ad/${advertisementId}/review`, {score: reviewScore, comment: reviewComment})
      .then(resp => resp.data, resp => this.loggingService.error('Error writing review', resp));
  }


  deleteAdvertisement(advertisementId) {
    return this.$http.delete(`${this.labradorUrl}/api/classifieds/ad/${advertisementId}`)
      .then(resp => resp.data, resp => this.loggingService.error('Error deleting advertisement', resp));
  }

  getPlaceholderMessageToSeller(sellerFirstName, itemDescription) {
    return this.$translate.instant('CLASSIFIEDS.PLACEHOLDER_MESSAGE_TO_SELLER', {sellerFirstName, itemDescription});
  }
}

angular.module('ll').service('ClassifiedsService', ClassifiedsService);

const ADVERTISEMENT_TYPES = [
  {
    id: 'sell',
    name: 'ADVERTISEMENT_TYPE.SELL',
    postCategory: 'CATEGORY.SALE_AND_FREE'
  },
  {
    id: 'rent',
    name: 'ADVERTISEMENT_TYPE.RENT',
    postCategory: 'CATEGORY.RENT_OUT'
  }
];

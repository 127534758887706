import angular from 'angular';

class FeedPostAdvertisementComponentController {

  constructor(ClassifiedsService, uiAlertService, $translate, $state) {
    this.uiAlertService = uiAlertService;
    this.classifiedsService = ClassifiedsService;
    this.$translate = $translate;
    this.$state = $state;
  }

  $onInit() {
    this.classifiedsService
      .getAdvertisementTypeById(this.advertisement.aType)
      .then(t => this.advertisement.aType = t);
  }

  deleteAdvertisement(event) {
    this.uiAlertService.showConfirm(this.$translate.instant('POST.DELETE.POST'), this.$translate.instant('POST.DELETE.ARE_YOU_SURE'))
      .then(() => this.classifiedsService.deleteAdvertisement(this.advertisement.aId))
      .then(() => this.onDelete());
  }

  editAdvertisement() {
    this.$state.go('app.advertisement.edit', {id: this.advertisement.aId});
  }
}

angular.module('ll').component('feedpostAdvertisement', {
  template: require('./feedpost-advertisement.component.html'),
  controller: FeedPostAdvertisementComponentController,
  controllerAs: 'vm',
  bindings: {
    advertisement: '<',
    onDelete: '&'
  }
});

import angular from 'angular';

class SurveyQuestionChoiceController {

  constructor($translate) {
    this.$translate = $translate;
  }

  $onInit() {
    this.questionType = this.question.type;
    this.setupForQuestionType();
    this.setInitialValue();
  }

  setupForQuestionType() {
    let type = this.questionType;
    if (type !== 'multipleScale' && type !== 'multipleChoice') {
      this.isUnknownQuestionType = true;
    }
  }

  setInitialValue() {
    this.value = null;
    this.text = null;
    if (this.questionChoice.value !== undefined && this.questionChoice.value !== null) {
      this.value = this.questionChoice.value;
      this.boolValue = !!this.value;
    }
    if (this.questionChoice.text !== undefined && this.questionChoice.text !== null) {
      this.text = this.questionChoice.text;
    }
  }

  onValueChange(isDecimal) {
    this.hasUserSetValue = true;
    if (this.value !== undefined) {
      if (isDecimal) {
        this.value = parseFloat(this.value);
      } else {
        this.value = parseInt(this.value);
      }
      if (isNaN(this.value)) {
        this.value = null;
      }
    } else {
      this.value = null;
    }
    this.callCallback();
  }

  onBoolValueChange() {
    this.hasUserSetValue = true;
    if (this.boolValue) {
      this.value = 1;
    } else {
      this.value = null;
    }
    this.callCallback();
  }

  onTextChange() {
    this.hasUserSetValue = true;
    this.callCallback();
  }

  callCallback() {
    let id = this.questionChoice.id;
    this.outputValues({questionChoiceId: id, value: this.value, text: this.text});
  }
}

angular.module('ll').component('surveyQuestionChoice', {
  template: require('./survey-question-choice.component.html'),
  controller: SurveyQuestionChoiceController,
  controllerAs: 'vm',
  bindings: {
    index: '<',
    question: '<',
    questionChoice: '<',
    outputValues: '&'
  }
});

import angular from 'angular';

class RentItemController {
  $onInit() {
    this.item = this.item || {};
    this.rentApplication = this.rentApplication || {};
  }
}

angular.module('ll').component('rentItem', {
  template: require('./rent-item.component.html'),
  controller: RentItemController,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    rentApplication: '<'
  }
});

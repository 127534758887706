import angular from 'angular';

angular.module('ll').component('fileList', {
  template: require('./filelist.component.html'),
  controller: FileListController,
  bindings: {
    files: '<',
    onFileSelect: '&',
    onFileDelete: '&',
    enableEdit: '<'
  }
});

FileListController.$inject = ['$mdDialog', '$translate', 'ToastMessageService'];

function FileListController($mdDialog, $translate, ToastMessageService) {
  const vm = this;
  vm.selectFile = selectFile;
  vm.deleteFile = deleteFile;
  vm.$onInit = init;

  function init() {
    vm.enableEdit = vm.enableEdit || false;
  }

  function selectFile(file, invalid) {
    file.loading = true;
    vm.files.push(file);
    vm.onFileSelect({file: file}).then(f => {
      file.id = f.id;
      file.url = f.url;
      file.author = f.author;
      file.loading = false;
    });
  }

  function deleteFile(e, file) {
    let confirm = $mdDialog.confirm()
      .title(file.name)
      .textContent($translate.instant('FILE_LIST.DELETE_FILE_CONFIRM'))
      .targetEvent(e)
      .ok($translate.instant('BUTTON.YES'))
      .cancel($translate.instant('BUTTON.NO'));

    $mdDialog.show(confirm)
      .then(() => vm.onFileDelete({file: file}))
      .then(() => vm.files = vm.files.filter(f => f.id !== file.id))
      .then(() => ToastMessageService.showMessage($translate.instant('FILE_LIST.DELETE_SUCCESS', {fileName: file.name})));
  }
}


import angular from 'angular';
import {createRoute} from '../../core/config';

angular.module('ll')
  .config(createRoute({
    name: 'app.principles',
    url: '/principles',
    views: {
      '': 'principles',
      'desktopMenu@app': ''
    },
    data: {authenticate: false}
  }))
  .config(createRoute({
    name: 'app.useTerms',
    url: '/useterms',
    views: {
      '': 'useTerms',
      'desktopMenu@app': ''
    },
    data: {authenticate: false}
  }));

import angular from 'angular';

class ToastMessageService {
  constructor($mdToast) {
    this.$mdToast = $mdToast;
  }

  showMessage(message, parentElement = null) {
    let toast = this.$mdToast
      .simple()
      .textContent(message)
      .hideDelay(5000)
      .position('top');

    if (parentElement) {
      toast.parent(parentElement);
    }

    return this.$mdToast.show(toast);
  }

  showError(message, parentElement = null) {
    let toast = this.$mdToast
      .simple()
      .textContent(message)
      .hideDelay(0)
      .action('OK')
      .position('top');

    if (parentElement) {
      toast.parent(parentElement);
    }

    return this.$mdToast.show(toast);
  }

}

angular.module('ll').service('ToastMessageService', ToastMessageService);

/* eslint-disable no-unused-vars */
import angular from 'angular';

// has to come before everyone else
require('./logging');

import ngMaterial from 'angular-material';
import ngAnimate from 'angular-animate';
import ngSanitize from 'angular-sanitize';
import ngMessages from 'angular-messages';
import uiRouter from '@uirouter/angularjs';
import ngStorage from 'ngstorage';
import ngMap from 'ngmap';
import ngFileUpload from 'ng-file-upload';
import translate from 'angular-translate';
import validationMatch from 'angular-validation-match';
import infiniteScroll from 'ng-infinite-scroll';
import ngCk from 'ng-ck';

require('../3rdparty/mobiscroll.angularjs.min.js');
require('angular-ui-carousel');
require('shepherd.js/dist/js/shepherd');
require('angular-hammer');

export default angular.module('ll.core', [
  uiRouter,
  ngAnimate,
  ngSanitize,
  ngStorage.name,
  ngMap,
  ngMessages,
  ngFileUpload,
  ngMaterial,
  translate,
  validationMatch,
  infiniteScroll,
  'ngCk',
  'hmTouchEvents',
  'ui.carousel',
  'mobiscroll-range',
  'ngSentry'
]).name;

require('./config');
require('./translation');
require('./angularmaterial');
require('./constants');
require('./external');
require('./cookieWarning');
require('./initialization.hook');
require('./transition.hook');
require('./onesignal.hook');
require('smartbanner.js/dist/smartbanner.min');
require('angular-i18n/pt-pt');
require('angular-i18n/sv');

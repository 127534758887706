import angular from 'angular';

class MessagesController {

  constructor(ConversationService, $scope, $timeout, $interval) {
    this.conversationService = ConversationService;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.messages = [];
    this.conversationId = null;
    this.hasPreviousMessages = true;
    this.isLoading = true;
  }

  $onInit() {
    this.conversationService.findOrCreateConversation([this.receiverUserId])
      .then(cID => {
        this.conversationId = cID;
        return this.getMessagesByConversationId(cID);
      })
      .then(m => this.uiEnablePreviousMessages(m))
      .then(m => {
        this.isLoading = false;
        return m;
      })
      .then(m => this.appendMessages(m))
      .then(() => this.scrollViewToBottom())
      .then(() => this.checkNewMessages());
  }

  $onDestroy() {
    this.$interval.cancel(this.refreshTimer);
  }

  getMessagesByConversationId(conversationId, direction = null, lastMessageId = null) {
    return this.conversationService.getMessagesForConversationById(conversationId, direction, lastMessageId);
  }

  uiEnablePreviousMessages(messages) {
    this.hasPreviousMessages = messages.length >= 10;
    return messages;
  }

  scrollViewToBottom(timeout = 500) {
    this.$timeout(() => document.getElementsByTagName('direct-message-composer')[0].scrollIntoView(), timeout);
  }

  checkNewMessages() {
    this.refreshTimer = this.$interval(() => this.loadNewMessages(), 5000);
  }

  loadNewMessages() {
    this.getMessagesByConversationId(this.conversationId, 'forward', this.getNewestMessageId())
      .then(m => {
        let hasNewMessages = m && m.length > 0;
        if (hasNewMessages) {
          this.scrollViewToBottom(0);
        }
        return m;
      })
      .then(m => this.appendMessages(m));
  }

  loadPreviousMessages() {
    this.getMessagesByConversationId(this.conversationId, 'backward', this.getOldestMessageId())
      .then(m => this.uiEnablePreviousMessages(m))
      .then(m => this.prependMessages(m));
  }

  appendMessages(messages) {
    messages = messages.filter(msg => !this.messages.find(m => m.messageId === msg.messageId));
    this.messages = this.messages.concat(messages.reverse());
  }

  prependMessages(messages) {
    if (messages) {
      messages.forEach(m => this.messages.unshift(m));
    }
  }

  getNewestMessageId() {
    if (this.messages && this.messages.length > 0) {
      return this.messages[this.messages.length - 1].messageId;
    }
    return null;
  }

  getOldestMessageId() {
    if (this.messages && this.messages.length > 0) {
      return this.messages[0].messageId;
    }
    return null;
  }

  sendMessage(message) {
    this.appendMessages([message]);
    this.scrollViewToBottom(10);
  }

  resetForm() {
    this.isSendingMessage = false;
    this.newMessage = null;
    this.$scope.directMessageForm.$setPristine();
    this.$scope.directMessageForm.$setUntouched();
    this.$timeout(() => document.getElementById('newMessage').focus());
  }
}

angular.module('ll').component('directMessage', {
  template: require('./direct-message.component.html'),
  controller: MessagesController,
  controllerAs: 'vm',
  bindings: {
    senderUserId: '<',
    receiverUserId: '<',
    initialMessage: '<'
  }
});

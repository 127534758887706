import angular from 'angular';
import {createRoute} from '../../core/config';

angular.module('ll')
  .component('editHouseholdDetails', {
    template: require('./editHouseholdDetails.html'),
    controller: EditHouseholdDetailsController,
    controllerAs: 'vm',
    bindings: {
      forwardTo: '<'
    }
  })
  .config(createRoute({
    name: 'app.editHouseholdDetails',
    component: 'editHouseholdDetails',
    url: '/household/edit?{forwardTo:string}',
    params: {
      forwardTo: null
    },
    resolve: {
      forwardTo: ($transition$) => $transition$.params().forwardTo
    }
  }));

function EditHouseholdDetailsController($translate, EnergyService, LoggingService, TrackingService, UserService, uiAlertService, $state, $sce) {
  /* globals */
  const vm = this;

  // Variables
  vm.hasLoadedHousehold = false;
  vm.hasHouseholdTypeAlreadyBeenSet = false;
  vm.householdDetails = {
    householdType: null,
    householdArea: null,
    numberOfAdults: null,
    numberOfChildren: null,
    numberOfPeopleAtHomeDuringWorkHours: null,
    hasWashingMachine: false
  };
  vm.householdDetailsForm = {};

  vm.item = null;

  // Accessable Functions
  vm.submitHouseholdDetailsForm = submitHouseholdDetailsForm;
  vm.openExplanationDialog = EnergyService.openExplanationDialog;
  vm.$onInit = init;

  // Functions

  function init() {
    setTopContent();
    loadHousehold();
    if (vm.forwardTo === 'serviceEnabled' || vm.forwardTo === 'energySummary') {
      TrackingService.track('energyjoin.household_details.init');
    }
  }

  function setTopContent() {
    vm.item = vm.forwardTo ? {
      title: $translate.instant('ENERGY_JOIN.ALMOST_DONE.TITLE'),
      content: $sce.trustAsHtml('<div>' + $translate.instant('ENERGY_JOIN.ALMOST_DONE.CONTENT') + '</div>'),
      image: 'images/energy/dragon-happy-left.png',
    } : {
      title:  $translate.instant('ENERGY.EDIT_HOUSEHOLD_TITLE'),
      content: $translate.instant('ENERGY.EDIT_HOUSEHOLD_CONTENT'),
      image: 'images/energy/washingmachine.png',
    };
  }

  function loadHousehold() {
    EnergyService.getHouseholdDetails().then((householdDetails) => {
      vm.householdDetails = householdDetails;
      vm.hasHouseholdTypeAlreadyBeenSet = !!householdDetails.householdType;
      vm.hasLoadedHousehold = true;
    }, (err) => {
      LoggingService.error('Error fetching household details', err);
      uiAlertService.showError($translate.instant('TODO'), '');
    });
  }

  function updateHouseholdDetails() {
    TrackingService.track('household_details.update');
    EnergyService.updateHouseholdDetails(vm.householdDetails).then(() => {
      UserService.fetchLoggedInUser().then(() => {
        if (vm.forwardTo) {
          forward();
        } else {
          let title = $translate.instant('HOUSEHOLD_EDIT.SUCCESS_TITLE');
          let message = $translate.instant('HOUSEHOLD_EDIT.SUCCESS_MESSAGE');
          uiAlertService.showSuccess(title, message);
        }
      });
    }, (err) => {
      LoggingService.error('Error updating household details', err);
      uiAlertService.showError($translate.instant('HOUSEHOLD_EDIT.ERROR_TITLE'), '');
    });
  }

  function forward() {
    if (vm.forwardTo === 'serviceEnabled' || vm.forwardTo === 'energySummary') {
      $state.go('app.energy'); // Let energy service decide where to
    }
  }

  function submitHouseholdDetailsForm(){
    vm.householdDetailsForm.$setSubmitted();
    if (vm.householdDetailsForm.$valid){
      updateHouseholdDetails();
    }
  }
}

import angular from 'angular';
import {createRoute} from '../../core/config';

class ProfileController {

  constructor(ToolbarService, $translate, ImageUploadService, ProfileService, ToastMessageService, UserService, $mdMedia) {
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.imageUploadService = ImageUploadService;
    this.profileService = ProfileService;
    this.toastMessageService = ToastMessageService;
    this.userService = UserService;
    this.$mdMedia = $mdMedia;
  }

  $onInit() {
    let pageTitle = this.user.isSelf ? this.$translate.instant('PROFILE.TITLE') : this.user.firstName + ' ' + this.user.lastName;
    this.toolbarService.setPageTitle(pageTitle);
    this.setBackgroundPicture(this.user.backgroundImage);
    this.setProfilePicture(this.user.profilePicture);
  }

  static getImageBackgroundStyle(imageUrl) {
    return {'background-image': `url('${imageUrl}')`, 'background-size': 'cover', 'background-position': 'center', 'border-radius': '4px'};
  }

  setProfilePicture(profilePicture) {
    profilePicture = profilePicture ? profilePicture.s150 : profilePicture;
    let style = ProfileController.getImageBackgroundStyle(profilePicture);
    this.profilePictureStyle = {...style, 'height': '90px', 'width': '90px'};
  }

  setBackgroundPicture(backgroundPicture) {
    if (!backgroundPicture || Object.keys(backgroundPicture).length === 0) {
      backgroundPicture = {original: require('../../images/leaves.png')};
    }
    this.backgroundPictureStyle = ProfileController.getImageBackgroundStyle(backgroundPicture.original);
  }

  openChangeProfilePictureDialog(e) {
    this.imageUploadService.openDialog(e, this.user.profilePicture ? this.user.profilePicture.original : '', {})
      .then(result => this.updateProfilePicture(result));
  }

  openChangeBackgroundPictureDialog(e) {
    this.imageUploadService.openDialog(e, this.user.backgroundImage ? this.user.backgroundImage.original : '', {})
      .then(result => this.updateBackgroundPicture(result));
  }

  updateProfilePicture(result) {
    let file = result.action === 'save' ? result.image.blob : null;
    this.profileService.changeProfileImage(file)
      .then(resp => {
        this.user.profilePicture = resp;
        this.setProfilePicture(resp);
        return resp.s150;
      })
      .then(() => this.userService.fetchLoggedInUser())
      .then(() => this.toastMessageService.showMessage(this.$translate.instant(file ? 'PROFILE.IMAGE_UPDATED' : 'PROFILE.IMAGE_DELETED')))
      .catch(() => this.toastMessageService.showMessage(this.$translate.instant('PROFILE.IMAGE_UPLOAD_ERROR')));
  }

  updateBackgroundPicture(result) {
    let file = result.action === 'save' ? result.image.blob : null;
    this.profileService.changeBackgroundImage(this.user.userId, file)
      .then(resp => {
        this.user.backgroundImage = resp;
        this.setBackgroundPicture(resp);
        return resp.original;
      })
      .then(() => this.userService.fetchLoggedInUser())
      .then(() => this.toastMessageService.showMessage(this.$translate.instant(file ? 'PROFILE.IMAGE_UPDATED' : 'PROFILE.IMAGE_DELETED')))
      .catch(() => this.toastMessageService.showMessage(this.$translate.instant('PROFILE.IMAGE_UPLOAD_ERROR')));
  }
}

angular.module('ll')
  .component('profile', {
    template: require('./profile.html'),
    controller: ProfileController,
    controllerAs: 'vm',
    bindings: {
      user: '<'
    }
  })
  .config(createRoute({
    name: 'app.profile',
    component: 'profile',
    url: '/profile/:userId',
    params: {
      userId: null
    },
    resolve: {
      user: ($transition$, SessionService, UserService) => {
        let userId = $transition$.params().userId || SessionService.getUser().userId;
        return UserService.getUserProfile(userId);
      }
    },
    redirectTo: 'app.profile.feed'
  }))
  .config(createRoute({
    name: 'app.profile.directMessage',
    url: '/directmessage',
    params: {
      initialMessage: null
    },
    resolve: {
      senderUserId: (SessionService) => SessionService.getUser().userId,
      receiverUserId: ($transition$) => Number($transition$.params().userId),
      initialMessage: ($transition$) => $transition$.params().initialMessage
    },
    views: {
      profile: 'directMessage'
    }
  }));

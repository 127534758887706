import angular from 'angular';
import {createRoute} from '../../core/config';

class NotificationsController {
  constructor(NotificationService, ToolbarService, $translate, $timeout) {
    this.notificationService = NotificationService;
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.page = 1;
    this.pageSize = 20;
    this.disableScroll = false;
    this.isLoading = false;
    this.unnoticedCount = this.unnoticedNotifications.length;
    this.toolbarService.setPageTitle(this.$translate.instant('NOTIFICATIONS.YOUR'));
    this.$timeout(() => this.notificationService.markAllAsNoticed(), 5000);
  }

  loadNotifications() {
    if (this.disableScroll) return;

    this.disableScroll = true;
    this.isLoading = true;

    this.fetchData()
      .then(data => this.appendNotifications(data.notifications))
      .then(notifications => this.disableScroll = notifications.length < this.pageSize)
      .then(() => this.page++)
      .then(() => this.isLoading = false);
  }

  fetchData() {
    return this.notificationService.getNotifications(this.page, this.pageSize, 'DESC', null, 1);
  }

  appendNotifications(notifications) {
    notifications.forEach(ad => this.notifications.push(ad));
    return notifications;
  }
}

angular.module('ll')
  .component('notifications', {
    template: require('./notifications.html'),
    controller: NotificationsController,
    controllerAs: 'vm',
    bindings: {
      unnoticedNotifications: '<',
      notifications: '<'
    }
  })
  .config(createRoute({
    name: 'app.notifications',
    component: 'notifications',
    url: '/notifications',
    resolve: {
      unnoticedNotifications: (NotificationService) => NotificationService.getNotifications(1, 10000, 'DESC', null, 0).then(data => data.notifications),
      notifications: (NotificationService) => NotificationService.getNotifications(1, 20, 'DESC', null, 1).then(data => data.notifications)
    }
  }));

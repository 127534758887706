import angular from 'angular';
import {createRoute} from '../../core/config';

class CouponsController {
  constructor(UserService, SessionService, $translate, ToolbarService, CouponService) {
    this.sessionService = SessionService;
    this.userService = UserService;
    this.$translate = $translate;
    this.toolbarService = ToolbarService;
    this.couponService = CouponService;
  }

  $onInit() {
    this.toolbarService.setPageTitle(this.$translate.instant('COUPONS.TITLE'));
    this.tabs = [];
    this.loadCoupons();
  }

  loadCoupons() {
    this.couponService.getCoupons().then(coupons => this.showCoupons(coupons));
  }

  showCoupons(coupons) {
    this.tabs.push({titleKey: 'COUPONS.TITLE', coupons});
  }
}

angular.module('ll')
  .component('coupons', {
    template: require('./coupons.html'),
    controller: CouponsController,
    controllerAs: 'vm',
    bindings: {
      user: '<'
    }
  })
  .config(createRoute({
    name: 'app.coupons',
    component: 'coupons',
    url: '/coupons',
    resolve: {
      user: (SessionService) => SessionService.getUser()
    }
  }));

/* eslint-disable no-unused-labels */
import angular from 'angular';
import cropperJS from 'cropperjs';
import moment from 'moment';
import 'moment/locale/sv';
import mixpanel from 'mixpanel-browser';
import _ from 'lodash';
import {extendMoment} from 'moment-range';

angular.module('ll.core')
  .constant('mixpanel', mixpanel)
  .constant('oneSignal', window.oneSignal) // wrapping global variable declared on app.js
  .constant('moment', extendMoment(moment))
  .constant('Cropper', cropperJS)
  .constant('_', _)
  .factory('FB', [() => ({FB: window.FB})]); // wrapping global variable declared on index.html

import angular from 'angular';

class FeedPostStatusController {

  constructor($translate) {
    this.$translate = $translate;
  }

  $onInit() {
    this.likes = this.likes || null;
    this.comments = this.comments || null;
    this.hideStats = this.hideStats || false;
  }

  $onChanges(changes) {
    if (changes.comments) {
      this.comments = changes.comments.currentValue;
    } else if (changes.likes) {
      this.likes = changes.likes.currentValue;
    }
  }
}

angular.module('ll').component('feedpostStatus', {
  template: require('./feedpost-status.component.html'),
  controller: FeedPostStatusController,
  controllerAs: 'vm',
  bindings: {
    likes: '<',
    comments: '<',
    hideStats: '<',
    date: '<',
    onCommentsClick: '&'
  }
});

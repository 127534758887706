import angular from 'angular';
import {createRoute} from '../../core/config';

class EnergyConsumptionDetailsController{
  constructor(DateService, EnergyService, EnvironmentSettings, SessionService, ToolbarService, TrackingService, $translate, moment){
    this.dateService = DateService;
    this.energyService = EnergyService;
    this.sessionService = SessionService;
    this.openExplanationDialog = EnergyService.openExplanationDialog;
    this.energyConsumptionData = [];
    this.energyConsumptionStats = null;
    this.levels = ['household','building','neighborhood'];
    this.isNeighbourhoodLevelEnabled = false;
    this.period;
    this.neighborhoodName = null;
    this.propertyName = null;
    this.propertyTypeTranslated = null;
    this.propertyTypePluralTranslated = null;
    this.toolbarService = ToolbarService;
    this.trackingService = TrackingService;
    this.$translate = $translate;
    this.moment = moment;
    this.avatars = [
      '/images/energy/dragon-sad-left.png',
      '/images/energy/dragon-natural-left.png',
      '/images/energy/dragon-happy-left.png'
    ];
    this.selectedTab = 0;
    this.chosenLevel = null;
    this.enableNextPeriodButton = false;
    this.enablePreviousPeriodButton = false;
    this.dateToShow = null;
    this.dateToShowFormatted = null;
    this.consumptionFeedback = {
      translatedTitle: null,
      translatedMessage: null,
      translatedEndText: null
    };
    this.styles = [];
    this.isDemo = null;
    this.country = {country: EnvironmentSettings.country};
    this.typicalConsumptionWidthPercent = 60;
  }

  $onInit(){
    // eslint-disable-next-line no-undef
    this.isDemo = __IS_DEVELOPMENT__ ? 1 : 0;

    if (this.showInFeed) {
      this.showTopBar = false;
    } else {
      this.showTopBar = true;
      this.toolbarService.configure({
        title: this.$translate.instant('ENERGY.ENERGY_CONSUMPTION'),
        showBackButton: true,
      });
    }

    if (this.energyConsumptionData) { // If the component is called with pre-set data (for example in a feedpost)
      this.period = this.energyConsumptionData.period;
      this.selectedTab = this.levels.indexOf(this.energyConsumptionData.level);
      this.updateSelectedTab(this.selectedTab);
    } else { // If component is called on its own
      if (this.level) {
        this.selectedTab = this.levels.indexOf(this.level);
        this.updateSelectedTab(this.selectedTab);
      }

    }
    this.initStrings();
    this.energyService.getConsumptionStats()
      .then(stats => {
        this.energyConsumptionStats = stats;
        if (this.energyConsumptionData) {
          // TODO: set up chosen tab, level, period from returned data. Hide chooser, feedback.
          this.updateFromData(this.energyConsumptionData, this.period, null);
        } else {
          this.getConsumptionVsTypical('household', 'day', null, this.isDemo);
        }
      });
  }

  initStrings() {
    this.chosenLevel = this.levels[this.selectedTab];
    let user = this.sessionService.getUser();
    this.neighborhoodName = user.neighborhood.name;
    this.propertyName = user.property ? user.property.name : null;
    this.propertyTypeTranslated = user.property ? this.$translate.instant('PROPERTY.TYPE.' + user.property.translationKey) : null;
    this.propertyTypePluralTranslated = user.property ? this.$translate.instant('PROPERTY.TYPE_PLURAL.' + user.property.translationKey) : null;
    this.typicalConsumptionTranslated = this.$translate.instant('SUSTAINABILITY.TYPICAL_CONSUMPTION').toLowerCase();
  }

  updateFromData(data, period, date) {
    if (data.dateFrom) {
      this.dateToShow = data.dateFrom;
    } else if (date) {
      this.dateToShow = date;
    }
    this.dateToShowFormatted = this.dateService.getFormattedDateForPeriod(this.dateToShow, period);
    if (data.consumption && data.consumption >= 100) {
      data.consumption = this.round(data.consumption, 0);
    }
    this.setEnergyBars(data);
  }

  setEnergyBars(data) {
    this.chosenLevel = this.levels[this.selectedTab];
    this.energyConsumptionData = data;
    this.enableNextPeriodButton = this.getEnableNextPeriodButton();
    this.enablePreviousPeriodButton = this.getEnablePreviousPeriodButton();

    this.typicalConsumptionWidthPercent = this.determineTypicalConsumptionWidthPercent(this.energyConsumptionData);
    let maxAllowedBarPercentage = Math.round(100.0 / this.typicalConsumptionWidthPercent  * 95.0); // * 95 gives some space to the right

    this.setBarStyle(this.energyConsumptionData.best, 'best', maxAllowedBarPercentage);
    this.setBarStyle(this.energyConsumptionData.self, 'self', maxAllowedBarPercentage);
    this.setBarStyle(this.energyConsumptionData.average, 'average', maxAllowedBarPercentage);

    switch (data.avatarState){
      case -1:
        this.avatar = this.avatars[0];
        break;
      case 0:
        this.avatar = this.avatars[1];
        break;
      case 1:
        this.avatar = this.avatars[2];
        break;
    }

    if (data.consumptionFeedback) {
      // If a pre-created message is given from the back-end, use that instead of randomly creating a new one
      this.consumptionFeedback = data.consumptionFeedback;
    } else {
      this.setEnergyMessages();
    }
  }

  determineTypicalConsumptionWidthPercent(energyConsumptionData) {
    let percent = 60; // standard value
    let minAllowedWidthPercent = 35;
    let maxAllowedWidthPercent = 80;
    let values = [energyConsumptionData.self, energyConsumptionData.best, energyConsumptionData.average];
    let maxValue = Math.max(...values);
    if (maxValue != null) {
      percent = Math.round(1 / maxValue * 100.0);
      percent = Math.max(percent, minAllowedWidthPercent);
      percent = Math.min(percent, maxAllowedWidthPercent);
    }
    return percent;
  }



  setEnergyMessages() {
    let today = this.moment();
    let lastPeriodKey = null;
    let dateCurrentPeriod = null;
    let dateData = this.moment(this.energyConsumptionData.dateFrom);
    if (this.period === 'day') {
      dateCurrentPeriod = today.startOf('day');
      if (dateCurrentPeriod.isSame(dateData)) {
        lastPeriodKey = 'YESTERDAY';
      } else if (dateCurrentPeriod.add(-1, 'days').isSame(dateData)) {
        lastPeriodKey = 'DAY_BEFORE_YESTERDAY';
      } else {
        lastPeriodKey = 'THE_DAY_BEFORE';
      }
    } else if (this.period === 'week') {
      dateCurrentPeriod = today.startOf('week');
      if (dateCurrentPeriod.isSame(dateData)) {
        lastPeriodKey = 'LAST_WEEK';
      } else {
        lastPeriodKey = 'THE_WEEK_BEFORE';
      }
    } else if (this.period === 'month') {
      dateCurrentPeriod = today.startOf('month');
      if (dateCurrentPeriod.isSame(dateData)) {
        lastPeriodKey = 'LAST_MONTH';
      } else {
        lastPeriodKey = 'THE_MONTH_BEFORE';
      }
    }
    let lastPeriodTranslated = this.$translate.instant('ENERGY.PERIOD.' + lastPeriodKey);

    let levelUppercase = this.levels[this.selectedTab].toUpperCase();
    let youSingularOrPlural = this.$translate.instant('ENERGY.FEEDBACK.YOU.' + (levelUppercase === 'HOUSEHOLD' ? 'SINGULAR' : 'PLURAL'));
    let outcome = this.energyConsumptionData.outcome;
    let randomIndexTitle = Math.floor(Math.random() * 3);
    let randomIndexEndText = Math.floor(Math.random() * 3);

    this.consumptionFeedback = {
      translatedTitle: this.$translate.instant('ENERGY.FEEDBACK.TITLE.' + outcome.toUpperCase() + '.' + randomIndexTitle, {youSingularOrPlural: youSingularOrPlural}),
      translatedMessage: this.$translate.instant('ENERGY.FEEDBACK.' + levelUppercase + '.' + this.energyConsumptionData.messageKey,
        {
          lastPeriod: lastPeriodTranslated,
          neighbourhood: this.neighborhoodName,
          property: this.propertyName,
          propertyType: this.propertyTypeTranslated,
          propertyTypePlural: this.propertyTypePluralTranslated
        }),
      translatedEndText: this.$translate.instant('ENERGY.FEEDBACK.END_TEXT.' + outcome.toUpperCase() + '.' + randomIndexEndText, {youSingularOrPlural: youSingularOrPlural})
    };
  }

  setBarStyle(barData, barType, maxAllowedPercentage){
    let backgroundColor = '';
    let dif = 1 - barData;

    if (dif < 0){
      backgroundColor = '#FF5046';
      if (barType === 'self'){
        this.selfBarPercentage = Math.round(dif * -100);
        this.selfBarText = this.$translate.instant('ENERGY.OVER').toLowerCase() + ' ' + this.typicalConsumptionTranslated;
      }
    } else {
      if (dif >= 0.2){
        backgroundColor = '#37B93C';
      } else {
        backgroundColor = '#F7C531';
      }
      if (barType === 'self'){
        this.selfBarPercentage = Math.round(dif * 100);
        this.selfBarText = this.$translate.instant('ENERGY.BELOW').toLowerCase() + ' ' + this.typicalConsumptionTranslated;
      }
    }

    let percentage = Math.round(barData * 100);


    if (percentage >= maxAllowedPercentage){
      percentage = maxAllowedPercentage + '%';
    } else {
      percentage += '%';
    }

    this.styles[barType] = {
      'background-color': backgroundColor,
      'width': percentage
    };
  }

  updateConsumptionData(selectedTab){
    this.updateSelectedTab(selectedTab);
    this.getConsumptionVsTypical(this.levels[this.selectedTab], this.period, this.energyConsumptionData.dateFrom, this.isDemo);
  }

  updateSelectedTab(selectedTab) {
    if (!angular.isUndefined(selectedTab)){
      this.selectedTab = selectedTab;
      for (let i = 0; i < this.levels.length; i++){
        document.getElementsByName(this.levels[i])[0].classList.remove('active');
      }
      document.getElementsByName(this.levels[this.selectedTab])[0].classList.add('active');
    }
  }

  changeDate(direction) {
    if (this.dateToShow) {
      let newDate = this.addPeriodToDate(this.moment(this.dateToShow), direction);
      this.getConsumptionVsTypical(this.levels[this.selectedTab], this.period, newDate.format('YYYY-MM-DD'), this.isDemo);
    }
  }

  addPeriodToDate(date, direction) {
    if (this.period === 'day') {
      date.add(direction, 'days');
    }
    else if (this.period === 'week') {
      date.add(direction * 7, 'days');
    }
    else if (this.period === 'month') {
      date.add(direction, 'months');
    }
    return date;
  }

  getConsumptionVsTypical(level, period, date, isDemo) {
    this.energyService.getConsumptionVsTypical(level, period, date, isDemo)
      .then(data => {
        this.updateFromData(data, period, date);
      });
    this.trackingService.track('energyconsumptiondetails.data.load.' + level + '.' + period);
  }

  getEnablePreviousPeriodButton() {
    if (this.isDemo) {
      return true;
    }
    if (this.dateToShow && this.energyConsumptionStats) {
      let dateToChangeTo = this.addPeriodToDate(this.moment(this.dateToShow), -1).format('YYYY-MM-DD');
      if (this.period === 'month') {
        if (dateToChangeTo.substr(0, 7) >= this.energyConsumptionStats.dateFirstElectricityData.substr(0, 7)) {
          return true;
        }
      } else {
        if (dateToChangeTo >= this.energyConsumptionStats.dateFirstHourlyElectricityData) {
          return true;
        }
      }
    }
    return false;
  }

  getEnableNextPeriodButton() {
    if (this.isDemo) {
      return true;
    }
    if (this.dateToShow && this.energyConsumptionStats) {
      let dateToChangeTo = this.addPeriodToDate(this.moment(this.dateToShow), 1).format('YYYY-MM-DD');
      let today = this.moment().format('YYYY-MM-DD');
      if (dateToChangeTo > today) {
        return false;
      }
      if (this.period === 'month') {
        if (dateToChangeTo.substr(0, 7) <= this.energyConsumptionStats.dateLastElectricityData.substr(0, 7)) {
          return true;
        }
      } else {
        if (dateToChangeTo <= this.energyConsumptionStats.dateLastElectricityData) {
          return true;
        }
      }
    }
    return false;
  }

  // https://stackoverflow.com/questions/7342957/how-do-you-round-to-1-decimal-place-in-javascript
  round(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}

angular.module('ll')
  .component('energyConsumptionDetails', {
    template: require('./energyconsumptiondetails.component.html'),
    controller: EnergyConsumptionDetailsController,
    controllerAs: 'vm',
    bindings: {
      energyConsumptionData: '<',
      showInFeed: '<',
      period: '<',
      level: '<'
    }
  })
  .config(createRoute({
    name: 'app.energyConsumptionDetails',
    component: 'energyConsumptionDetails',
    url: '/energyconsumptiondetails',
    data: {authenticate: true},
    params: {
      energyConsumptionData: null,
      showInFeed: false,
      period: 'day',
      level: null
    },
    resolve: {
      energyConsumptionData: ($transition$) => $transition$.params().energyConsumptionData,
      showInFeed: ($transition$) => $transition$.params().showInFeed,
      period: ($transition$) => $transition$.params().period,
      level: ($transition$) => $transition$.params().level
    }
  }));

import angular from 'angular';

class SurveyQuestionController {

  constructor($translate) {
    this.$translate = $translate;
  }

  $onInit() {
    this.hasError = false;
    this.setupForQuestionType();
    this.answeredQuestionChoices = new Map();
  }

  setupForQuestionType() {
    let type = this.question.type;
    if (type === 'scale' || type === 'multipleScale') {
      this.setScaleRange(this.question.minValue, this.question.maxValue);
    } else if (type !== 'yesNo' && type !== 'text' && type !== 'intValue' && type !== 'decimalValue' && type !== 'singleChoice' && type !== 'multipleChoice') {
      this.isUnknownQuestionType = true;
    }
  }

  setScaleRange(minValue, maxValue) {
    let range = [];
    for (let i = minValue; i <= maxValue; i++) {
      range.push(i);
    }
    this.question.scaleRange = range;
  }

  onValueChange(isDecimal) {
    this.hasUserSetValue = true;
    if (this.value !== undefined) {
      if (isDecimal) {
        this.value = parseFloat(this.value);
      } else {
        this.value = parseInt(this.value);
      }
      if (isNaN(this.value)) {
        this.value = null;
      }
    } else {
      this.value = null;
    }
    this.returnAnswers();
  }

  onTextChange() {
    this.hasUserSetValue = true;
    this.returnAnswers();
  }

  findQuestionChoice(questionChoiceId) {
    let questionChoices = this.question.questionChoices;
    if (questionChoices && questionChoices.length > 0) {
      for (let i = 0; i < questionChoices.length; i++) {
        if (questionChoices[i].id === questionChoiceId) {
          return questionChoices[i];
        }
      }
    }
    return null;
  }

  questionChoiceCallback(questionChoiceId, value, text) {
    let qc = this.findQuestionChoice(questionChoiceId);
    if (this.question.type === 'singleChoice') {
      this.answeredQuestionChoices.clear();
    }

    if (!(this.question.type === 'singleChoice' && questionChoiceId === undefined)) {
      this.answeredQuestionChoices.set(questionChoiceId, {
        id: questionChoiceId,
        key: qc.key,
        isText: qc.isText,
        value: value,
        text: text
      });
    }
    this.returnAnswers(); // TODO: do in a more efficient way?
  }

  returnAnswers() {
    let answer = {
      questionId: this.question.id,
      questionKey: this.question.key,
      value: this.value,
      text: this.text,
      questionChoices: []
    };
    if (this.question.type === 'singleChoice' || this.question.type === 'multipleChoice' || this.question.type === 'multipleScale') {
      let answeredQuestionChoicesArr = Array.from(this.answeredQuestionChoices.values());
      for (let i = 0; i < answeredQuestionChoicesArr.length; i++) {
        if (this.question.type === 'multipleScale' || answeredQuestionChoicesArr[i].value === 1) {
          answer.questionChoices.push(answeredQuestionChoicesArr[i]);
        }
      }
    }

    this.returnQuestionAnswer({questionId: this.question.id, answer: answer});
  }

  $onChanges(changes) {
    if (changes.question && changes.question.currentValue) {
      let question = changes.question.currentValue;
      if (question.hasError !== undefined) {
        this.setErrorState(question.hasError);
      }
    }
  }

  setErrorState(hasError) {
    this.hasError = hasError;
  }

}

angular.module('ll').component('surveyQuestion', {
  template: require('./survey-question.component.html'),
  controller: SurveyQuestionController,
  controllerAs: 'vm',
  bindings: {
    question: '<',
    returnQuestionAnswer: '&',
    translationVariables: '<'
  }
});

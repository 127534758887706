import angular from 'angular';

const ROOT_PATH_PART = '/api/sustainability';

class SustainabilityService {
  constructor($http, LoggingService, EnvironmentSettings, moment) {
    this.$http = $http;
    this.loggingService = LoggingService;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
    this.moment = moment;
  }

  getGeneralScores(scope, period, dateFrom, isDemo) {
    return this.$http.get(
      `${this.labradorUrl}${ROOT_PATH_PART}/scores/all/${scope}/${period}/${this.moment(dateFrom).format('YYYY-MM-DD')}/${isDemo}`
    )
      .then(resp => {
        if (resp.status === 204) return {};

        return resp.data;
      })
      .catch(resp => {
        this.loggingService.error('Error loading sustainability general scores', {scope, period, dateFrom, isDemo: isDemo, resp});
        return {};
      });
  }

  getCategoryDetails(category, scope, period, dateFrom, isDemo) {
    return this.$http.get(
      `${this.labradorUrl}${ROOT_PATH_PART}/${category}/details/${scope}/${period}/${this.moment(dateFrom).format('YYYY-MM-DD')}/${isDemo}`
    )
      .then(resp => {
        if (resp.status === 204) return {};

        return resp.data;
      })
      .catch(resp => {
        this.loggingService.error('Error loading sustainability category details',
          {category, scope, period, dateFrom, isDemo: isDemo, resp});
        return {};
      });
  }

  getChartMetadata(category, scope, period, dateFrom, isDemo) {
    return this.$http.get(
      `${this.labradorUrl}${ROOT_PATH_PART}/${category}/chart/metadata/${scope}/${period}/${this.moment(dateFrom).format('YYYY-MM-DD')}/${isDemo}`
    )
      .then(resp => {
        if (resp.status === 204) return {};

        return resp.data;
      })
      .catch(resp => {
        this.loggingService.error('Error loading sustainability category details',
          {category, scope, period, dateFrom, isDemo: isDemo, resp});
        return {};
      });
  }

  getISODateWithoutSeconds(date) {
    return this.moment(date).toISOString().replace(/:\d{2}.\d{3}/, '');
  }

  getChartValues(category, scope, period, dateFrom, dateTo, isDemo) {
    const dateFromString = this.getISODateWithoutSeconds(dateFrom);
    const dateToString = this.getISODateWithoutSeconds(dateTo);
    return this.$http.get(
      `${this.labradorUrl}${ROOT_PATH_PART}/${category}/chart/values/${scope}/${period}/${dateFromString}/${dateToString}/${isDemo}`
    )
      .then(resp => {
        if (resp.status === 204) return {};

        return resp.data;
      })
      .catch(resp => {
        this.loggingService.error('Error loading sustainability category details',
          {category, scope, period, dateFrom, isDemo: isDemo, resp});
        return {};
      });
  }

  saveWellbeingFeedback(feedback, isDemo) {
    const data = {
      community: {value: feedback.community},
      trust: {value: feedback.trust},
      safety: {value: feedback.safety},
      network: {value: feedback.network}
    };
    return this.$http.post(
      `${this.labradorUrl}${ROOT_PATH_PART}/wellbeing/categories/${isDemo}`, data
    )
      .then(resp => {
        if (resp.status === 204) return {};

        return resp.data;
      })
      .catch(resp => {
        this.loggingService.error('Error saving wellbeing feedback',
          {feedback, resp});
        return {};
      });
  }
}

angular.module('ll').service('SustainabilityService', SustainabilityService);

import angular from 'angular';

class GeographicAreaService {

  constructor($http, EnvironmentSettings) {
    this.$http = $http;
    this.baseUrl = EnvironmentSettings.baseUrl;
  }

  getRegion(address) {
    return this.$http({
      url: `${this.baseUrl}/api/region`,
      method: 'GET',
      params: {
        address: address
      }
    }).then(resp => resp.data, resp => null);
  }
}

angular.module('ll').service('GeographicAreaService', GeographicAreaService);

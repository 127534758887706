import angular from 'angular';

angular.module('ll.core').config(translationsConfiguration);

translationsConfiguration.$inject = ['$translateProvider'];

function translationsConfiguration($translateProvider) {
  $translateProvider
    .translations('sv_SE', require('./data/sv_SE'))
    .translations('pt_PT', require('./data/pt_PT'))
    .translations('en_US', require('./data/en_US'))
    .fallbackLanguage('en_US');

  $translateProvider.registerAvailableLanguageKeys(['sv_SE', 'pt_PT', 'en_US'], {
    'sv':'sv_SE',
    'pt_*':'pt_PT',
    'en_*':'en_US'
  });

  $translateProvider.determinePreferredLanguage();
  $translateProvider.useSanitizeValueStrategy('escapeParameters');
}

import angular from 'angular';

class NeighborhoodService {
  constructor($http, LoggingService, EnvironmentSettings, UserFactory) {
    this.$http = $http;
    this.loggingService = LoggingService;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
    this.userFactory = UserFactory;
  }

  getNeighborhoodDetails() {
    return this.$http.get(`${this.labradorUrl}/api/neighborhood/mine`)
      .then(resp => {
        let neighborhood = resp.data;
        neighborhood.admins = neighborhood.admins.map(admin => this.userFactory.createUser(admin));
        // neighborhood.members = neighborhood.members.map(member => this.UserFactory.createUser(member));
        return neighborhood;
      }, resp => this.loggingService.error('Error fetching neighborhood details', resp));
  }

  getNeighborhoodFiles() {
    return this.$http.get(`${this.labradorUrl}/api/neighborhood/files`)
      .then(resp => resp.data, resp => this.loggingService.error('Error fetching neighborhood files', resp));
  }

  uploadFile(file, neighborhoodId) {
    let data = new FormData();
    data.append('file', file);
    data.append('name', file.name);
    let config = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };
    return this.$http.post(`${this.labradorUrl}/api/neighborhood/${neighborhoodId}/file`, data, config)
      .then(resp => resp.data, resp => this.loggingService.error('Error uploading neighborhood file', resp));
  }

  deleteFile(file, neighborhoodId) {
    return this.$http.delete(`${this.labradorUrl}/api/neighborhood/${neighborhoodId}/file/${file.id}`)
      .then(resp => resp.data, resp => this.loggingService.error('Error deleting neighborhood file', resp));
  }

  saveDescription(description) {
    return this.$http.put(`${this.labradorUrl}/api/neighborhood/description`, {description})
      .then(resp => resp.data, resp => this.loggingService.error('Error saving neighborhood description', resp));
  }

  // function saveContactInfo(neighborhoodId, contactInfo) {
  //   return this.$http.put(`${baseUrl}/api/neighborhood/${neighborhoodId}/contactinfo`, {contactInfo})
  //     .then(resp => resp.data, resp => this.loggingService.error('Error saving neighborhood contact information', resp));
  // }
  //
}

angular.module('ll').service('NeighborhoodService', NeighborhoodService);

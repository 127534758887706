import angular from 'angular';

angular.module('ll')
  .factory('ContentFeedbackService', ContentFeedbackService);

ContentFeedbackService.$inject = ['uiAlertService', '$mdDialog', '$translate', '$http', 'EnvironmentSettings', 'LoggingService'];

function ContentFeedbackService(uiAlertService, $mdDialog, $translate, $http, EnvironmentSettings, LoggingService) {
  const labradorUrl = EnvironmentSettings.labradorUrl;

  return {
    createObjectionableReport,
    showReportContentDialog
  };

  function showReportContentDialog(type, foreignId, event) {
    let reportContentDialog = $mdDialog.prompt()
      .title($translate.instant('REPORT.REPORT_CONTENT_DIALOG_TITLE'))
      .textContent($translate.instant('REPORT.REPORT_CONTENT_DIALOG_CONTENT'))
      .placeholder($translate.instant('REPORT.REPORT_CONTENT_DIALOG_REASON_PLACEHOLDER'))
      .ok($translate.instant('BUTTON.REPORT'))
      .cancel($translate.instant('BUTTON.CANCEL'))
      .targetEvent(event)
      .required(true);

    return $mdDialog.show(reportContentDialog)
      .then(reason => reportContentAndShowConfirmation(type, foreignId, reason));
  }

  function reportContentAndShowConfirmation(type, foreignId, reason) {
    createObjectionableReport(type, foreignId, reason).then((response) => {
      uiAlertService.showInfo(
        null,
        $translate.instant('REPORT.REPORT_CONTENT_SUCCESS_MESSAGE'),
        5000
      );
    });
  }

  function createObjectionableReport(type, foreignId, reason) {
    return $http.post(`${labradorUrl}/api/objectionablereport`, {type, foreignId, reason})
      .then(resp => resp.data, resp => LoggingService.error('Error sending objectionable report'));
  }
}


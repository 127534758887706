/* eslint-disable no-undef */
import angular from 'angular';
import {createRoute} from '../../core/config';

class WasteInletMapComponentController {
  constructor($timeout, CustomPopupService, DateService, TrackingService, ToolbarService, $translate, moment, SessionService, WasteService) {
    this.$timeout = $timeout;
    this.customPopupService = CustomPopupService;
    this.dateService = DateService;
    this.trackingService = TrackingService;
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.moment = moment;
    this.sessionService = SessionService;
    this.wasteService = WasteService;
    this.map = null;
  }

  $onInit() {
    this.trackingService.track('wasteInletMap');
    this.toolbarService.setPageTitle(this.$translate.instant('WASTE_INLET_ERROR_REPORTING.MAP.TITLE'));
    this.canRecallErrorReports = this.sessionService.getUser().hasEnabledFeature('error_reporting_recall');

    this.zoomLevel = 17;
    this.zoomSettings = {
      '17': {'minDistanceMeters': 18.0},
      '18': {'minDistanceMeters': 9.0}
    };

    let userAddress = this.inletMap.userAddress;

    // eslint-disable-next-line no-unused-vars
    let sweden = [
      [5, 70],
      [5, 53],
      [27, 53],
      [27, 70],
      [5, 70],
    ];

    let neighborhood = this.inletMap.neighborhood;
    const inletList = this.getListOfSpreadOutInlets(this.inletMap.inletList);

    this.$timeout(() => {
      let map = new google.maps.Map(document.getElementsByClassName('llmap')[0], {
        center: {lng: userAddress.geometry.coordinates[0], lat: userAddress.geometry.coordinates[1]},
        restriction: {
          strictBounds: true,
          latLngBounds: {
            north: 70,
            south: 53,
            west: 5,
            east: 27
          }
        },
        zoom: this.zoomLevel,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: 'poi.business',
            stylers: [{visibility: 'off'}]
          }
        ]
      });

      // DEBUG
      let wasteFacilityList = this.inletMap.wasteFacilityList;
      // let wasteFacilityList = {
      //   'type': 'FeatureCollection',
      //   'features': [
      //     {
      //       'type': 'Feature',
      //       'geometry': {
      //         'type': 'Point',
      //         'coordinates': [
      //           18.09035000,
      //           59.35838000
      //         ]
      //       },
      //       'properties': {
      //         'type': 'WASTE_ROOM',
      //         'operationalStatus': 'REDUCED_AVAILABILITY',
      //         'statusChangedAt': '',
      //         'message': '',
      //         'hasActiveIssues': false,
      //         'id': '1',
      //         'mapId': 'facility_1',
      //         'description': 'Grythundsgatan 2',
      //         'wasteCategories': ['PaperPackaging']
      //       }
      //     },
      //     {
      //       'type': 'Feature',
      //       'geometry': {
      //         'type': 'Point',
      //         'coordinates': [
      //           18.0894708,
      //           59.3590458
      //         ]
      //       },
      //       'properties': {
      //         'type': 'WASTE_ROOM',
      //         'operationalStatus': 'AVAILABLE',
      //         'statusChangedAt': '',
      //         'message': '',
      //         'hasActiveIssues': false,
      //         'id': '2',
      //         'mapId': 'facility_2',
      //         'description': 'Grythundsgatan 18',
      //         'wasteCategories': ['PaperPackaging','PlasticPackaging']
      //       }
      //     }
      //   ]
      // };
      // wasteFacilityList = null;
      // this.inletMap.wasteFacilityList = wasteFacilityList;
      // END DEBUG

      map.data.addGeoJson(userAddress, {idPropertyName: 'id'});
      map.data.addGeoJson(neighborhood, {idPropertyName: 'id'});
      map.data.addGeoJson(inletList, {idPropertyName: 'mapId'});
      map.data.addGeoJson(wasteFacilityList, {idPropertyName: 'mapId'});
      map.data.addListener('click', event  => this.highlightFeature(event.feature.getProperty('mapId')));
      map.addListener('zoom_changed', () => this.onZoomChanged());

      map.data.setStyle(function (feature) {
        let icon = null;
        // Current location pin
        if (feature.getProperty('streetName')) {
          icon = 'map-pin.png';
        }
        // Waste inlet icons and waste room
        else if (feature.getProperty('operationalStatus')) {
          let fraction = feature.getProperty('wasteFraction');
          let type = feature.getProperty('type');
          let operationalStatus = feature.getProperty('operationalStatus');
          let color = 'green';
          switch (operationalStatus) {
            case 'REDUCED_AVAILABILITY':
              color = 'yellow';
              break;
            case 'NOT_AVAILABLE':
              color = 'red';
              break;
          }
          if (type === 'WASTE_ROOM') {
            icon = type.toLowerCase() + '_' + color + '.png';
          } else if (type === 'WASTE_STATION' || type === 'MOBILE_WASTE_STATION') {
            icon = 'waste_station.png'; // TODO change to correct icons
          }
          else if (fraction === 'UNKNOWN') {
            icon = color + '_trash.png';
          } else {
            icon = fraction.toLowerCase() + '_' + color + '.png';
          }
        }

        return {
          fillOpacity: 0.05,
          fillColor: '#faff00',
          strokeColor: '#b5ba00',
          strokeOpacity: 0.3,
          icon: {
            url: `https://storage.googleapis.com/user-content-develop/waste-icons/${icon}`,
            scaledSize: new google.maps.Size(30, 30)
          }
        };
      });

      this.map = map;
    }, 50);
  }

  onReadFacilityErrorReportsClick(facilityUuid) {
    this.reportedIssues = null;
    this.wasteService.getActiveIssuesForWasteFacility(facilityUuid).then(issues => {
      this.reportedIssues = issues;
      this.doShowReportedErrorsPopup = true;
      this.customPopupService.lock();
    }, err => {
      this.doShowReportedErrorsPopup = false;
    });
  }

  onZoomChanged() {
    // eslint-disable-next-line no-unused-vars
    this.zoomLevel = this.map.getZoom();
    // TODO: figure out how to redraw inlets
    // this.map.data.addGeoJson(this.getListOfSpreadOutInlets(this.inletMap.inletList));
  }

  getListOfSpreadOutInlets(inletList) {
    let inletGroups = this.groupInlets(inletList.features);
    let spreadInletsList = [];
    let minDistanceMeters = this.zoomSettings.hasOwnProperty(this.zoomLevel) ? this.zoomSettings[this.zoomLevel].minDistanceMeters : 9.0;
    for (let i = 0; i < inletGroups.length; i++) {
      let group = inletGroups[i];
      let spreadGroup = this.spreadOutInletsInGroup(group, minDistanceMeters);
      for (let j = 0; j < spreadGroup.length; j++) {
        spreadInletsList.push(spreadGroup[j]);
      }
    }

    // Sort by street and waste type
    spreadInletsList.sort((c1, c2) => {
      if (c1.properties.description && c2.properties.description) {
        if (c1.properties.description < c2.properties.description) return -1;
        if (c1.properties.description > c2.properties.description) return 1;
        return this.getFractionSortOrder(c1.properties.wasteFraction) - this.getFractionSortOrder(c2.properties.wasteFraction);
      }
      if (c1.properties.externalId && c2.properties.externalId) {
        if (c1.properties.externalId < c2.properties.externalId) return -1;
        if (c1.properties.externalId > c2.properties.externalId) return 1;
        return this.getFractionSortOrder(c1.properties.wasteFraction) - this.getFractionSortOrder(c2.properties.wasteFraction);
      }
      return 0;
    });

    inletList.features = spreadInletsList;

    return inletList;
  }

  getFractionSortOrder(wasteFraction) {
    switch (wasteFraction) {
      case 'REST': return 1;
      case 'PLASTIC': return 2;
      case 'PAPER_PACKAGING': return 3;
      case 'ORGANIC': return 4;
      case 'UNKNOWN': return 5;
    }
    return 6;
  }

  groupInlets(inletList) {
    let inletGroups = [];
    // Put all inlets into a inlet group based on how close to the closest inlet they are.
    for (let i = 0; i < inletList.length; i++) {
      let groupFound = false;
      let inlet = inletList[i];
      // Fix statusChangedAt date
      if (inlet.properties.statusChangedAt) {
        inlet.properties.statusChangedAt = this.moment(inlet.properties.statusChangedAt).format('YYYY-MM-DD HH:mm');
      }
      if (inlet.properties.firstActiveIssueCreatedAt) {
        inlet.properties.firstActiveIssueCreatedAt = this.moment(inlet.properties.firstActiveIssueCreatedAt).format('YYYY-MM-DD HH:mm');
      }
      // Add the inlet to a existing group
      for (let j = 0; j < inletGroups.length; j++) {
        let group = inletGroups[j];
        for (let k = 0; k < group.length; k++) {
          if (this.areInletsNear(inlet, group[k])) {
            groupFound = true;
            group.push(inlet);
            break;
          }
        }
        if (groupFound) {
          break;
        }
      }
      if (!groupFound) {
        // Create new group
        inletGroups.push([inlet]);
      }
    }

    // Sort inlets by longitude (x-axis), needed for the spreadout function later
    for (let i = 0; i < inletGroups.length; i++) {
      let inletArray = inletGroups[i];
      inletArray.sort((c1, c2) => c1.geometry.coordinates[0] - c2.geometry.coordinates[0]);
    }

    return inletGroups;
  }

  areInletsNear(inlet1, inlet2) {
    let distanceNearMeters = 15.0;
    let distance = this.getMetersBetweenCoordinates(inlet1.geometry.coordinates[1], inlet1.geometry.coordinates[0], inlet2.geometry.coordinates[1], inlet2.geometry.coordinates[0]);
    return distance <= distanceNearMeters;
  }

  spreadOutInletsInGroup(inletsArray, minDistanceMeters) {
    if (inletsArray.length === 1) {
      return inletsArray;
    }
    let middleCoords = this.getMiddleCoords(inletsArray);
    let metersPerDegLng = 111132.954 * Math.cos(middleCoords.lat * Math.PI / 180.0);
    let minDistanceLng = minDistanceMeters * 1.0 / metersPerDegLng;
    for (let i = 0; i < inletsArray.length; i++) {
      let inlet = inletsArray[i];
      inlet.geometry.coordinates[0] = middleCoords.lng + (i - (inletsArray.length - 1) / 2) * minDistanceLng;
    }
    return inletsArray;
  }

  getMiddleCoords(inletsArray) {
    let minLat = null, maxLat = null, minLng = null, maxLng = null;
    for (let i = 0; i < inletsArray.length; i++) {
      let inlet = inletsArray[i];
      let lat = inlet.geometry.coordinates[1];
      let lng = inlet.geometry.coordinates[0];
      if (i === 0) {
        // Init all to first inlet
        minLat = maxLat = lat;
        minLng = maxLng = lng;
        continue;
      }
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    }
    return {lat: (maxLat + minLat) / 2.0, lng: (maxLng + minLng) / 2.0};
  }

  // https://stackoverflow.com/questions/639695/how-to-convert-latitude-or-longitude-to-meters
  getMetersBetweenCoordinates(lat1, lon1, lat2, lon2){  // generally used geo measurement function
    let R = 6378.137; // Radius of earth in KM
    let dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    let dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return d * 1000; // meters
  }

  highlightFeature(mapId) {
    let element = document.getElementById(mapId);
    if (element) {
      // Remove any existing highlights
      let errorReportingCards = document.getElementsByClassName('ll-error-reporting-card');
      for (let element of errorReportingCards) {
        if (element.className.indexOf('flash-it highlight') !== -1) {
          element.className = element.className.replace('flash-it highlight', '').trim();
        }
      }
      // Highlight & scroll to clicked element
      element.scrollIntoView({behavior: 'smooth'});
      element.className = element.className + ' flash-it highlight';
    }
  }

  centerMapOnFeature(mapId) {
    const inletFeatures = [
      ...this.inletMap.inletList.features,
      ...this.inletMap.wasteFacilityList && this.inletMap.wasteFacilityList.features
    ];
    for (let i = 0; i < inletFeatures.length; i++) {
      let feature = inletFeatures[i];
      if (feature.properties.mapId === mapId) {
        let coordinates = feature.geometry.coordinates;
        this.map.panTo({lat: coordinates[1], lng: coordinates[0]});
        this.highlightFeature(mapId);
        break;
      }
    }
  }

}

angular.module('ll').component('wasteInletMap', {
  template: require('./waste-inlet-map.component.html'),
  controller: WasteInletMapComponentController,
  controllerAs: 'vm',
  bindings: {
    inletMap: '<'
  }
})
  .config(createRoute({
    name: 'app.wasteInlet',
    abstract: true,
    url: '/waste-inlet',
  }))
  .config(createRoute({
    name: 'app.wasteInlet.map',
    component: 'wasteInletMap',
    url: '/map',
    resolve: {
      inletMap: (WasteService) => WasteService.getInlets()
    },
  }));

import angular from 'angular';

class FeedService {

  constructor($http, LoggingService, EnvironmentSettings, FeedPostFactory, EventFactory, SessionService, $q) {
    this.$http = $http;
    this.loggingService = LoggingService;
    this.baseUrl = EnvironmentSettings.baseUrl;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
    this.feedPostFactory = FeedPostFactory;
    this.eventFactory = EventFactory;
    this.sessionService = SessionService;
    this.$q = $q;

    this.requestWithFormDataConfig = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };
  }

  getProfileFeed(userId, page, pageSize) {
    let params = {page, pageSize};
    return this.$http.get(`${this.labradorUrl}/api/publication/feed/profile/${userId}`, {params})
      .then(resp => {
        if (resp.status === 204) {
          return [];
        }
        return resp.data.map(this.feedPostFactory.createFeedPost);
      })
      .catch(resp => {
        this.loggingService.error('Error loading profile feed for user' + userId, {params, resp});
        return [];
      });
  }

  getPropertyFeed(page, pageSize, categoryId, onlyBoardMessages) {
    return this.getFeed('property', page, pageSize, categoryId, onlyBoardMessages);
  }

  getAreaFeed(categoryId, page, pageSize) {
    let params = {
      categoryId,
      offset: this.calculateOffset(page, pageSize),
    };
    return this.getFeedData('area', params);
  }

  getMainFeed(page, pageSize, categoryId) {
    let params = {page, pageSize, categoryId};
    return this.$http.get(`${this.labradorUrl}/api/publication/feed`, {params})
      .then(resp => {
        if (resp.status === 204) {
          return [];
        }
        return resp.data.map(this.feedPostFactory.createFeedPost);
      })
      .catch(resp => {
        this.loggingService.error('Error loading main feed', {params, resp});
        return [];
      });
  }

  getFeed(level, page, pageSize, categoryId, onlyBoardMessages = false) {
    if (level !== 'neighborhood' && level !== 'property') {
      throw new Error('Unsupported level' + level);
    }
    let url = url = `${this.labradorUrl}/api/publication/feed/${level}`;
    let params = {page, pageSize, categoryId};
    return this.$http.get(url, {params})
      .then(resp => {
        if (resp.status === 204)
          return [];

        if (onlyBoardMessages)
          return resp.data.filter(p => p.tags.find(t => t === 'property_board_message')).map(this.feedPostFactory.createFeedPost);

        return resp.data.map(this.feedPostFactory.createFeedPost);
      })
      .catch(resp => {
        this.loggingService.error('Error loading feed', {level, params, resp});
        return [];
      });
  }

  calculateOffset(page, pageSize) {
    return page * pageSize;
  }

  getFeedData(feedName, params, boardMessages) {
    return this.$http.get(`${this.baseUrl}/api/feed/${feedName}`, {params})
      .then(resp => {
        if (resp.status === 204) return [];
        if (boardMessages){
          return resp.data.filter(p => p.tags.find(t => t === 'property_board_message')).map(this.feedPostFactory.createFeedPost);
        }
        return resp.data.map(this.feedPostFactory.createFeedPost);
      })
      .catch(resp => {
        this.loggingService.error('Error loading feed', {feedName, params, resp});
        return [];
      });
  }

  postPostToFeed(postedTo, categoryId, subject, content, isPropertyBoardMessage, files) {
    let fd = new FormData();
    fd.append('postedTo', postedTo);
    fd.append('categoryId', categoryId);
    fd.append('subject', subject);
    fd.append('content', content);
    if (isPropertyBoardMessage){
      this.setBoardMessageTag(fd);
    }
    files.forEach((f) => fd.append('images', f));
    return this.$http.post(`${this.labradorUrl}/api/publication/post`, fd, this.requestWithFormDataConfig)
      .then(resp => this.feedPostFactory.createFeedPost(resp.data), resp => this.loggingService.error('Error posting post', resp));
  }

  postPollToFeed(collectionType, collectionId, categoryId, question, pollChoices, description, isPropertyBoardMessage) {
    let fd = new FormData();
    fd.append('collectionType', collectionType);
    fd.append('collectionId', collectionId);
    fd.append('categoryId', categoryId);
    fd.append('question', question);
    fd.append('pollChoices', pollChoices ? angular.toJson(pollChoices) : null);
    fd.append('description', description == null ? '' : description);
    if (isPropertyBoardMessage){
      this.setBoardMessageTag(fd);
    }
    let config = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };
    return this.$http.post(`${this.baseUrl}/api/feed/poll`, fd, config)
      .then(resp => this.feedPostFactory.createFeedPost(resp.data), resp => this.loggingService.error('Error posting poll', resp));
  }

  postEventToFeed(collectionType, collectionId, name, location, placeId, startDate, startTime, description, images, isPropertyBoardMessage) {
    let fd = this.getPopulatedFormDataForEvent(name, description, location, placeId, startDate, startTime, images);
    fd.append('postedTo', collectionType);
    // fd.append('collectionId', collectionId); // Not used in Labrador
    if (isPropertyBoardMessage){
      this.setBoardMessageTag(fd);
    }
    return this.$http.post(`${this.labradorUrl}/api/publication/event`, fd, this.requestWithFormDataConfig)
      .then(resp => this.feedPostFactory.createFeedPost(resp.data), resp => this.loggingService.error('Error posting event', resp));
  }

  postAlertToFeed(collectionType, collectionId, content, isPropertyBoardMessage) {
    let fd = new FormData();
    fd.append('collectionType', collectionType);
    fd.append('collectionId', collectionId);
    fd.append('content', content);
    if (isPropertyBoardMessage){
      this.setBoardMessageTag(fd);
    }

    let config = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity,
    };
    return this.$http.post(`${this.baseUrl}/api/feed/alert`, fd, config)
      .then(resp => this.feedPostFactory.createFeedPost(resp.data), resp => this.loggingService.error('Error posting alert', resp));
  }

  editEvent(feedpostId, name, description, location, placeId, startDate, startTime, removedAttachments, addedAttachments) {
    let fd = new FormData();
    fd.append('name', name);
    fd.append('startDate', startDate);
    fd.append('startTime', startTime == null ? '' : startTime);
    fd.append('location', location == null ? '' : location);
    fd.append('placeId', placeId == null ? '' : placeId);
    fd.append('description', description == null ? '' : description);
    fd.append('removedAttachments', JSON.stringify(removedAttachments));
    addedAttachments.forEach((f,ix) => fd.append(`images[${ix}]`, f));

    // Workaround to make it possible to send form data (which is needed for sending background images) within a PUT call to Symfony. See https://github.com/laravel/framework/issues/13457
    fd.append('_method', 'PUT');

    let config = {
      transformRequest: angular.identity,
      headers: {
        'Content-Type': undefined
      },
    };
    return this.$http.post(`${this.baseUrl}/api/feed/event/${feedpostId}`, fd, config)
      .then(resp => this.eventFactory.createEvent(resp.data), resp => this.loggingService.error('Error editing event', resp));
  }

  getCategoryFeed(categoryId, offset, limit) {
    let config = {params: {categoryId, limit, offset}};
    return this.$http.get(`${this.baseUrl}/api/feed/category`, config)
      .then(resp => {
        if (resp.status === 204) return [];
        return resp.data.items.map(this.feedPostFactory.createFeedPost);
      }, resp => this.loggingService.error('Error getting category feed', resp));
  }

  vote(postId, choiceId) {
    let fd = new FormData();
    fd.append('choiceId', choiceId);
    let config = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };
    return this.$http.post(`${this.baseUrl}/api/poll/${postId}/vote`, fd, config)
      .then(resp => this.feedPostFactory.createFeedPost(resp.data), resp => this.loggingService.error('Error voting in a poll', resp));
  }

  getPopulatedFormDataForEvent(name, description, location, placeId, startDate, startTime, images) {
    let fd = new FormData();

    /* Required params */
    fd.append('name', name); // Is null an option
    fd.append('startDate', startDate); // Is null an option
    fd.append('startTime', startTime == null ? '' : startTime);
    fd.append('location', location == null ? '' : location);
    fd.append('placeId', placeId == null ? '' : placeId);
    fd.append('description', description == null ? '' : description);
    images.forEach((f,ix) => fd.append(`images[${ix}]`, f));
    return fd;
  }

  getPostCategories() {
    let deferred = this.$q.defer();
    let postCategories = this.sessionService.getSession('postCategories');

    if (postCategories) deferred.resolve(postCategories);
    else this.fetchPostCategories(false).then(deferred.resolve);

    return deferred.promise;
  }

  getGroupedPostGategories() {
    let deferred = this.$q.defer();
    let groupedPostCategories = this.sessionService.getSession('groupedPostCategories');

    if (groupedPostCategories) deferred.resolve(groupedPostCategories);
    else this.fetchPostCategories(true).then(deferred.resolve);

    return deferred.promise;
  }

  fetchPostCategories(isGrouped = false) {
    return this.$http.get(`${this.labradorUrl}/api/publication/categories`)
      .then(resp => {
        let grouped = this.sessionService.setSession('groupedPostCategories', this.createCategoryGroups(resp.data));
        let flat = this.sessionService.setSession('postCategories', resp.data.sort((a, b) => a.sortOrder - b.sortOrder));
        if (isGrouped) {
          return grouped;
        } else {
          return flat;
        }
      })
      .catch(resp => this.loggingService.error('Error getting post categories', resp));
  }

  createCategoryGroups(categories) {
    let groups = {};
    if (categories) {
      for (let i = 0; i < categories.length; i++) {
        let category = categories[i];
        if (!groups.hasOwnProperty(category.group)) {
          groups[category.group] = [];
        }
        groups[category.group].push(category);
      }
    }
    return groups;
  }

  setBoardMessageTag(formData){
    formData.append('tags','property_board_message');
  }
}

angular.module('ll').service('FeedService', FeedService);


import angular from 'angular';
import {createRoute} from '../../core/config';

class MarketplaceComingSoonController {
  constructor(ToolbarService, TrackingService, $translate) {
    this.trackingService = TrackingService;
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
  }

  $onInit() {
    this.trackingService.track('MarketplaceComingSoon');
    this.toolbarService.setPageTitle(this.$translate.instant('MENU.MARKETPLACE'));
  }
}

angular.module('ll')
  .component('marketplaceComingSoon', {
    template: require('./marketplace-coming-soon.html'),
    controller: MarketplaceComingSoonController,
    controllerAs: 'vm',
  })
  .config(createRoute({
    name: 'app.marketplaceComingSoon',
    component: 'marketplaceComingSoon',
    url: '/marketplace-coming-soon'
  }));

import angular from 'angular';

class DirectMessageComposerController {

  constructor(ConversationService, $scope, $timeout, $mdMedia) {
    this.conversationService = ConversationService;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$mdMedia = $mdMedia;
  }

  $onInit() {
    this.newMessage = this.initialMessage || null;
    this.isSendingMessage = false;
  }

  sendMessage(event) {
    if (event){
      event.preventDefault();
    }

    if (this.$scope.directMessageForm.$pristine || this.$scope.directMessageForm.$invalid) {
      return;
    }

    let content = this.newMessage;
    this.isSendingMessage = true;
    this.conversationService.sendMessage(this.conversationId, content)
      .then(message => this.onSend({message}))
      .then(() => this.resetForm());
  }

  resetForm() {
    this.isSendingMessage = false;
    this.newMessage = null;
    this.$scope.directMessageForm.$setPristine();
    this.$scope.directMessageForm.$setUntouched();
    this.$timeout(() => document.getElementById('newMessage').focus());
  }
}

angular.module('ll').component('directMessageComposer', {
  template: require('./direct-message-composer.component.html'),
  controller: DirectMessageComposerController,
  controllerAs: 'vm',
  bindings: {
    onSend: '&',
    conversationId: '<',
    initialMessage: '<'
  }
});

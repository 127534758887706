import angular from 'angular';

class ForgotPasswordController {

  constructor(ToastMessageService, $translate, PasswordService, $state) {
    this.toastMessageService = ToastMessageService;
    this.translate = $translate;
    this.email = '';
    this.passwordService = PasswordService;
    this.$state = $state;
    this.$translate = $translate;
  }

  handleResetPasswordResponse(data){
    if (data !== null){
      this.toastMessageService.showMessage(this.$translate.instant('FORGOT_PASSWORD.EMAIL_SENT'));
      this.$state.go('welcome.login');
    } else {
      this.toastMessageService.showMessage(this.$translate.instant('FORGOT_PASSWORD.PASSWORD_RESET_ERROR'));
    }
  }

  cancel() {
    this.$state.go('welcome.login');
  }

  send(){
    this.passwordService.resetPassword(this.email)
      .then(data => this.handleResetPasswordResponse(data));
  }

}

angular.module('ll').component('forgotPassword', {
  template: require('./forgotpassword.component.html'),
  controller: ForgotPasswordController,
  controllerAs: 'vm'
});

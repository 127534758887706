import angular from 'angular';

angular.module('ll').directive('sticky', Sticky);

function Sticky($mdSticky) {
  return {
    restrict : 'A',
    link : function(scope, element) {
      $mdSticky(scope, element);
    }
  };
}


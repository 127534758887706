import angular from 'angular';

angular.module('ll')
  .factory('UserFactory', UserFactory);

function UserFactory(moment) {
  return {
    createUser: function (data) {
      return data ? new User(data) : null;
    }
  };

  function User(data) {
    return {
      ...data,
      isPropertyMember: function () {
        return !!this.property;
      },
      isAdmin: function () {
        return !!this.isSuperAdmin;
      },
      isPropertyVerified: function () {
        return this.isPropertyMember() && this.property.isVerified;
      },
      isPropertyAdmin: function () {
        return this.isPropertyMember() && this.property.roles.find(r => r === 'admin');
      },
      getFullName: function () {
        return `${this.firstName} ${this.lastName}`;
      },
      hasSignedElectricitydataConsent: function() {
        return this.household ? this.household.hasSignedElectricitydataConsent : false;
      },
      hasVerifiedElectricitydataConsent: function() {
        return this.household ? this.household.hasVerifiedElectricitydataConsent : false;
      },
      canCreateConsent: function() {
        return !!(!this.hasSignedElectricitydataConsent() && this.networkArea);
      },
      isCreatedToday: function() {
        let created = moment(this.created, 'YYYY-MM-DD HH:mm');
        return created.isSame(moment(), 'day');
      },
      signedButNotVerifiedElectricitydataConsentId: function() {
        return this.household ? this.household.signedButNotVerifiedElectricitydataConsentId : null;
      },
      showPropertyLevel: function() {
        return this.neighborhood ? this.neighborhood.showPropertyLevel : true;
      },
      hasEnergyFeature: function() {
        return !!this.property && this.property.isEnergyServiceEnabled;
      },
      hasEnabledFeature: function(feature) {
        return (
          (this.enabledFeatures ? this.enabledFeatures.includes(feature) : false) ||
          (this.household && this.household.enabledFeatures ? this.household.enabledFeatures.includes(feature) : false) ||
          (this.property && this.property.enabledFeatures ? this.property.enabledFeatures.includes(feature) : false) ||
          (this.neighborhood && this.neighborhood.enabledFeatures ? this.neighborhood.enabledFeatures.includes(feature) : false)
        );
      },
      getEnabledFeatures: function() {
        let features = [...new Set([
          ...this.enabledFeatures ? this.enabledFeatures : [],
          ...this.household && this.household.enabledFeatures ? this.household.enabledFeatures : [],
          ...this.property && this.property.enabledFeatures ? this.property.enabledFeatures : [],
          ...this.neighborhood && this.neighborhood.enabledFeatures ? this.neighborhood.enabledFeatures : []
        ])
        ];
        return features;
      }
    };
  }
}

import angular from 'angular';

const POINTS_PER_SCREEN_DEFAULT = 8;

class ChartVisualizationController {
  constructor(SVGService, $element, $scope) {
    this.SVGService = SVGService;
    this.$element = $element;
    this.$scope = $scope;

    this.lastUpdateDelta = 0;
    this.pointsShiftFromEnd = 0;
    this.noMoreToLoad = false;
  }

  $onInit() {
    this.initChart();

    window.addEventListener('resize', () => {
      this.initChart();
      this.$scope.$apply();
    });
  }

  initChart() {
    if (!this.$element[0] || !this.$element[0].parentNode || !this.$element[0].parentNode.parentNode || !this.$element[0].parentNode.parentNode.parentNode) {
      return;
    }

    this.availableWidth = this.$element[0].parentNode.parentNode.parentNode.clientWidth;
    this.containerHeight = 110;
    this.containerWidth = this.containerHeight / (this.availableHeight / this.availableWidth);

    this.plotLinesLeftPadding = this.containerWidth / 10;
    this.plotLinesRightPadding = this.containerWidth / 15;
    this.plotLinesTopShift = this.containerHeight / 30;

    this.yAxisMarksLeftPadding = this.containerWidth / 75;
    this.xAxisMarksBottomPadding = this.containerHeight / 10;

    this.chartBottomPadding = this.containerHeight / 4.5;

    this.chartPointRadius = this.containerHeight / 50;
    this.chartPointStrokeWidth = this.chartPointRadius / 5;

    this.smileSize = Math.max(10, this.containerHeight / 16);
    this.smileStrokeWidth = this.containerWidth / 250;
    this.smileStrokeY = -10;

    this.chartDrawingAreaHeight = this.containerHeight - this.chartBottomPadding;

    this.showTooltip = false;
    this.tooltipCornerRadius = this.containerWidth / 200;
    this.tooltipHeight = this.containerHeight / 15;
    this.tooltipWidth = this.containerWidth / 20;
    this.tooltipMarginBottom = this.tooltipHeight * 0.5;
    this.tooltipX = 0;
    this.tooltipY = 0;
    this.tooltipText = '';
    this.tooltipTrianglePpoints = '';

    this.screensShift = 0;
    this.pointsPerScreen = this.config.pointsPerScreen || POINTS_PER_SCREEN_DEFAULT;

    this.hasDynamicYAxis = !this.config.yAxis.maxForced;
    if (!this.hasDynamicYAxis) {
      this.config.yAxis.max = this.config.yAxis.maxForced;
      this.setStep(this.config.yAxis.max);
    }

    if (!this.config.dataPoints) {
      this.config.dataPoints = [];
      this.loadDataPointsChunk();
    } else {
      this.initChartData();
    }
  }

  initChartData() {
    this.plotLines = [];
    this.yAxisMarks = [];
    this.xAxisMarks = [];
    this.chartLines = [];
    this.chartPoints = [];
    this.filledChartAreaPoints = '';

    this.screensShift++;
    if (this.pointsPerScreen > this.config.dataPoints.length) {
      this.pointsPerScreen =  this.config.dataPoints.length;
    }

    this.pointsDistance = (this.containerWidth - this.plotLinesLeftPadding - this.plotLinesRightPadding) / (this.pointsPerScreen - 1);

    this.drawPlotLinesAndYAxisMarks();
    this.drawXAxisMarksPointsAndLines();
    this.drawChartFilledArea();
  }

  drawPlotLinesAndYAxisMarks() {
    let height = this.config.yAxis.min;
    while (height <= this.config.yAxis.max) {
      this.plotLines.push({
        y: (height - this.config.yAxis.min) / (this.config.yAxis.max - this.config.yAxis.min) * this.chartDrawingAreaHeight + this.plotLinesTopShift
      });
      this.yAxisMarks.push({
        y: (height - this.config.yAxis.min) / (this.config.yAxis.max - this.config.yAxis.min) * this.chartDrawingAreaHeight + this.plotLinesTopShift * 1.3,
        text: this.config.yAxis.max + this.config.yAxis.min - height
      });
      height += this.config.yAxis.step;
    }
    // Show suffix / prefix on top row only
    if (this.yAxisMarks.length > 0) {
      this.yAxisMarks[0].text = (this.config.yAxis.prefix || '') + this.yAxisMarks[0].text + (this.config.yAxis.suffix || '');
    }
  }

  calculatePointX(currentPointIndex, startFrom) {
    return (currentPointIndex - startFrom) * this.pointsDistance + this.plotLinesLeftPadding;
  }

  calculatePointY(val) {
    return (this.config.yAxis.max - val) / (this.config.yAxis.max - this.config.yAxis.min) * this.chartDrawingAreaHeight +
      this.plotLinesTopShift;
  }

  drawXAxisMarksPointsAndLines() {
    const startFrom = this.config.dataPoints.length - this.pointsPerScreen - this.pointsShiftFromEnd;
    const endAt =  this.config.dataPoints.length - this.pointsShiftFromEnd;
    let currentPointIndex = startFrom;

    let prevX = null;
    let prevY = null;
    let currentX = null;
    let currentY = null;

    while (currentPointIndex < endAt) {
      prevX = currentX;
      prevY = currentY;

      const val = this.config.dataPoints[currentPointIndex].val;
      const xValLines = this.config.dataPoints[currentPointIndex].xValLines;
      currentX = this.calculatePointX(currentPointIndex, startFrom);
      currentY = this.calculatePointY(val);

      this.xAxisMarks.push({
        x: currentX,
        textLines: xValLines
      });
      this.chartPoints.push({
        x: currentX,
        y: currentY,
        val
      });

      if (prevX !== null && prevY !== null) {
        this.chartLines.push({
          x1: prevX,
          y1: prevY,
          x2: currentX,
          y2: currentY
        });
      } else {
        let minusOnePoint = this.config.dataPoints[currentPointIndex - 1];
        if (minusOnePoint) {
          this.chartLines.push({
            x1: 0,
            y1: this.calculatePointY(minusOnePoint.val),
            x2: currentX,
            y2: currentY
          });
        } else {
          this.chartLines.push({
            x1: 0,
            y1: currentY,
            x2: currentX,
            y2: currentY
          });
        }
      }
      currentPointIndex++;
    }
    let plusOnePoint = this.config.dataPoints[currentPointIndex];
    if (plusOnePoint) {
      this.chartLines.push({
        x1: currentX,
        y1: currentY,
        x2: this.calculatePointX(currentPointIndex, startFrom),
        y2: this.calculatePointY(plusOnePoint.val),
      });
    } else {
      this.chartLines.push({
        x1: currentX,
        y1: currentY,
        x2: this.containerWidth,
        y2: currentY
      });
    }
  }

  drawChartFilledArea() {
    this.filledChartAreaPoints += `${this.chartLines[0].x1},${this.containerHeight} `;

    this.filledChartAreaPoints += this.chartLines.map(p => {
      return `${p.x1},${p.y1}`;
    }).join(' ');

    this.filledChartAreaPoints += ` ${this.chartLines[this.chartLines.length - 1].x2},${this.chartLines[this.chartLines.length - 1].y2}`;
    this.filledChartAreaPoints += ` ${this.chartLines[this.chartLines.length - 1].x2},${this.containerHeight}`;
  }

  drawSmileIndicator() {
    if (this.config.smile) {
      this.smileStrokeY = this.calculatePointY(this.config.smile.y);
    }
  }

  draggingChartStart() {
    if (this.config.doAllowChartHorizontalScroll) {
      this.preventParentDrag = true;
      this.showTooltip = false;
    }
  }

  draggingChart(event) {
    if (this.config.doAllowChartHorizontalScroll) {
      if (event.direction !== 4 && event.direction !== 2) {
        return;
      }

      const scaleRatio = this.containerWidth / event.element[0].clientWidth;
      const direction = event.deltaX > 0 ? 1 : -1;
      const svgInnerShiftDistance = event.deltaX * scaleRatio - this.lastUpdateDelta;

      if (Math.sqrt(Math.pow(svgInnerShiftDistance, 2)) > this.pointsDistance && this.pointsShiftFromEnd + direction >= 0) {
        if (this.pointsShiftFromEnd + direction + this.pointsPerScreen <= this.config.dataPoints.length) {
          this.lastUpdateDelta = event.deltaX * scaleRatio;
          this.pointsShiftFromEnd += direction;

          this.xAxisMarks = [];
          this.chartLines = [];
          this.chartPoints = [];
          this.filledChartAreaPoints = '';
          this.drawXAxisMarksPointsAndLines();
          this.drawChartFilledArea();
        } else if (!this.noMoreToLoad && !this.loading) {
          this.loadDataPointsChunk();
        }
      }
    }
  }

  draggingChartEnd() {
    if (this.config.doAllowChartHorizontalScroll) {
      this.lastUpdateDelta = 0;
      this.preventParentDrag = false;
    }
  }

  showHideTooltip(point) {
    if (this.config.tooltip) {
      if (!point) {
        this.showTooltip = false;
      } else {
        this.showTooltip = true;

        this.tooltipX = point.x;
        this.tooltipY = point.y;
        this.tooltipText =  + (this.config.tooltip.prefix || '') + point.val + ' ' + (this.config.tooltip.suffix || '');
        this.tooltipWidth = this.tooltipText.length * 2.5 + 6;

        this.tooltipTrianglePpoints =
          this.SVGService.buildTrianglePoints(this.tooltipHeight / 4, {
            x: this.tooltipX,
            y: this.tooltipY - this.tooltipHeight / 2
          }, 22.5);
      }
    }
  }

  loadDataPointsChunk() {
    this.loading = true;
    this.config.loadDataPoints(this.screensShift, this.pointsPerScreen).then((dataPoints) => {
      this.config.dataPoints = dataPoints.concat(this.config.dataPoints);

      if (this.pointsPerScreen > dataPoints.length) {
        this.noMoreToLoad = true;
      }

      if (this.hasDynamicYAxis) {
        this.setupYAxisStepAndMax(dataPoints);
      }
      this.initChartData();
      this.loading = false;
    });
  }

  setupYAxisStepAndMax(dataPoints) {
    const max = Math.max(...dataPoints.map(p => p.val));
    if (!this.config.yAxis.max || max > this.config.yAxis.max) {
      this.setStep(max);
      this.config.yAxis.max = Math.round(max + this.config.yAxis.step - max % this.config.yAxis.step);
      this.drawSmileIndicator();
    }
  }

  setStep(max) {
    this.config.yAxis.step = this.config.yAxis.isPercent ? 25 : max <= 5 ? 1 : max <= 10 ? 2 : max <= 20 ? 5 : max <= 100 ? 25 : max <= 1000 ? 100 : 1000;
  }
}

angular.module('ll').component('llChartVisualization', {
  template: require('./chart-visualization.component.html'),
  controller: ChartVisualizationController,
  controllerAs: 'vm',
  bindings: {
    config: '<',
    mainColor: '<',
    lighterColor: '<',
    preventParentDrag: '=',
    availableHeight: '<',
  }
});

import angular from 'angular';


class AdvertisementDetailController {

  constructor(ToolbarService, $state, ClassifiedsService, $scope, TrackingService, $mdMedia) {
    this.toolbarService = ToolbarService;
    this.classifiedsService = ClassifiedsService;
    this.$state = $state;
    this.$scope = $scope;
    this.attachments = [];
    this.trackingService = TrackingService;
    this.$mdMedia = $mdMedia;
  }

  $onInit(){
    this.classifiedsService.getAdvertisementCategories().then(data => this.adCategories = data);
    this.toolbarService.configure({title: this.item.title, showBackButton: true});
    this.attachments = this.item.images;
    this.changePriceModel();
  }

  save() {
    this.trackingService.track('classifieds.item.edit.save');
    this.item.images = this.attachments.map(a => a.blob ? a.blob : a.id);
    this.classifiedsService.editAdvertisement(this.item)
      .then(data => this.$state.go('app.advertisement.detail', {id: data.id}));
  }

  attachFile(file) {
    this.attachments.push({...file, feed: file.url});
  }

  removeAttachment(url) {
    this.attachments = this.attachments.filter(a => a.feed !== url);
  }

  changePriceModel() {
    this.isPriceDisabled = this.item.isShare();
    if (this.isPriceDisabled) {
      this.item.price = 0;
    }
  }
}

angular.module('ll')
  .component('advertisementEdit', {
    template: require('./item-advertisement-edit.component.html'),
    controller: AdvertisementDetailController,
    controllerAs: 'vm',
    bindings: {
      item: '<',
      currentUser: '<'
    }
  });

import angular from 'angular';

class LanesVisualizationController {
  constructor($timeout){
    this.$timeout = $timeout;
    this.laneLabelBreakpointPercent = 37; // determines if the value label should be placed inside or outside the bar
  }
  $onInit() {
    this.highlightColor = this.hexToHSL(this.mainColor, 60);
    this.sortLanes();

    this.config.lanes.forEach((lane) => {
      lane.percentageValue = 1;
      lane.maxPercentageValue = 1;
      lane.toppedPercentageValue = 1;
    });

    this.flagPosition = 1;

    this.$timeout(() => {
      this.initLanes();
    }, 100);
  }

  $onChanges() {
    this.sortLanes();
    // wrapped in timeout to avoid labels inside lanes from sometimes disappearing at reload
    this.$timeout(() => {
      this.initLanes();
    });
  }

  sortLanes() {
    this.config.lanes = this.config.lanes.sort((l1, l2) => l1.currentValue - l2.currentValue);
  }

  initLanes() {
    const minMaxDifference = this.config.max - this.config.min;

    this.config.lanes.forEach(lane => {
      lane.percentageValue = lane.currentValue / minMaxDifference * 100;
      lane.maxPercentageValue = 0;
      lane.toppedPercentageValue = 0;

      if (this.config.average) {
        if (lane.currentValue <= this.config.average) {
          lane.isCut = true;
          lane.maxPercentageValue = this.config.average / minMaxDifference * 100;
          lane.percentageValue = lane.currentValue  / this.config.average * 100;
        } else {
          lane.toppedPercentageValue = (lane.currentValue - this.config.average) / lane.currentValue * 100;
        }
      }
    });

    this.flagPosition = this.config.average / minMaxDifference * 100;
  }

  // Modified from https://css-tricks.com/converting-color-spaces-in-javascript/
  hexToHSL(H, lightenPercent = null) {
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (H.length === 4) {
      r = '0x' + H[1] + H[1];
      g = '0x' + H[2] + H[2];
      b = '0x' + H[3] + H[3];
    } else if (H.length === 7) {
      r = '0x' + H[1] + H[2];
      g = '0x' + H[3] + H[4];
      b = '0x' + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r,g,b),
      cmax = Math.max(r,g,b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

    if (delta === 0)
      h = 0;
    else if (cmax === r)
      // eslint-disable-next-line no-extra-parens
      h = ((g - b) / delta) % 6;
    else if (cmax === g)
      h = (b - r) / delta + 2;
    else
      h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
      h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    if (lightenPercent) {
      l = (l * (100 + lightenPercent) / 100.0).toFixed(1);
    }

    return 'hsl(' + h + ',' + s + '%,' + l + '%)';
  }
}

angular.module('ll').component('llLanesVisualization', {
  template: require('./lanes-visualization.component.html'),
  controller: LanesVisualizationController,
  controllerAs: 'vm',
  transclude: {
    top: '?top',
    bottom: '?bottom'
  },
  bindings: {
    config: '<',
    mainColor: '<',
    darkerColor: '<',
    availableHeight: '<'
  }
});

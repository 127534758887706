import angular from 'angular';
import {createRoute} from '../../core/config';

class NeighborsController {
  constructor(UserService, SessionService, $translate, ToolbarService) {
    this.sessionService = SessionService;
    this.userService = UserService;
    this.$translate = $translate;
    this.toolbarService = ToolbarService;
  }

  $onInit() {
    this.toolbarService.setPageTitle(this.$translate.instant('NEIGHBORS.TITLE'));
    this.tabs = [];
    this.loadNeighbors();
  }

  loadNeighbors() {
    if (this.user.isPropertyMember() && this.user.showPropertyLevel()) {
      this.getNeighbors('property', this.user.property.id).then(users => this.createTab(this.user.property.name, users));
    }
    this.getNeighbors('neighborhood', this.user.neighborhood.id).then(users => this.createTab(this.user.neighborhood.name, users));
  }

  createTab(title, users) {
    this.tabs.push({title, users});
  }

  getNeighbors(collection, collectionId) {
    return this.userService.getNeighbours(collection, collectionId, this.user.userId);
  }
}

angular.module('ll')
  .component('neighbors', {
    template: require('./neighbours.html'),
    controller: NeighborsController,
    controllerAs: 'vm',
    bindings: {
      user: '<'
    }
  })
  .config(createRoute({
    name: 'app.neighbors',
    component: 'neighbors',
    url: '/neighbors',
    resolve: {
      user: (SessionService) => SessionService.getUser()
    }
  }));

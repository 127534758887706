import angular from 'angular';

class NotificationItemController {

  constructor($state, NotificationService, $translate) {
    this.$state = $state;
    this.notificationService = NotificationService;
    this.$translate = $translate;
  }

  $onInit(){
    this.setTranslations();
  }

  navigate(){
    if (!this.item.isRead){
      this.notificationService.markAsRead([this.item.id]);
    }

    if (this.item.postType === 'message'){
      this.$state.go('app.profile.directMessage',{userId: this.item.author.userId});
    } else {
      if (this.item.postType === 'sell' || this.item.postType === 'rent'){
        this.$state.go('app.advertisement.detail', {id: this.item.feedpost.postId});
      } else {
        this.$state.go('app.singlePost', {postId: this.item.feedpost.postId});
      }

    }
  }

  markAsRead(){
    this.notificationService.markAsRead([this.item.id]);
  }

  markAsNoticed(){
    this.notificationService.markAsNoticed([this.item.id]);
  }

  setTranslations(){
    switch (this.item.postType){
      case 'message':
        this.postType = this.$translate.instant('NEWPOST.TYPE.MESSAGE');
        break;
      case 'post':
        this.postType = this.$translate.instant('NEWPOST.TYPE.POST');
        break;
      case 'poll':
        this.postType = this.$translate.instant('NEWPOST.TYPE.POLL');
        break;
      case 'event':
        this.postType = this.$translate.instant('NEWPOST.TYPE.EVENT');
        break;
      case 'alert':
        this.postType = this.$translate.instant('NEWPOST.TYPE.ALERT');
        break;
    }
    this.postTypeBy = this.$translate.instant('NOTIFICATIONS.POST_TYPE_BY', {postType: this.postType});
  }
}

angular.module('ll').component('notificationItem', {
  template: require('./notification-item.component.html'),
  controller: NotificationItemController,
  controllerAs: 'vm',
  bindings: {
    item: '<'
  }
});

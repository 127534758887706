import angular from 'angular';

angular.module('ll')
  .filter('UniqueFilter', function () {
    return function (collection) {
      let output = [], keys = [];

      angular.forEach(collection, function (item) {
        let key = item.userId;
        if (keys.indexOf(key) === -1) {
          keys.push(key);
          output.push(item);
        }
      });

      return output;
    };
  });

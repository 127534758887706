import angular from 'angular';

class PollComposerController {

  constructor(FeedService, $scope, $timeout, SessionService) {
    this.feedService = FeedService;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.sessionService = SessionService;
    this.isPropertyBoardMessage = false;
  }

  $onInit() {
    this.poll = this.initPoll();

    let preSelectedTo = this.toOptions.find(opt => opt.isPreSelected === true);
    if (preSelectedTo) {
      this.to = preSelectedTo.value;
    }

    let preSelectedCategory = Object.values(this.categoryOptions).flat().find(opt => opt.isPreSelected === true);
    if (preSelectedCategory) {
      this.toCategory = preSelectedCategory;
    }

    this.isPropertyAdmin = this.sessionService.getUser().isPropertyAdmin();
  }

  $onChanges(objectChange) {
    if (!objectChange.command) return;

    let command = objectChange.command.currentValue;
    if (command.postType !== 'poll') return;
    if (command.name === 'save') this.save();
  }

  initPoll() {
    return {options: []};
  }

  save() {
    this.$scope.pollForm.$setSubmitted();

    let hasNoOptions = this.poll.options.length === 1 && this.poll.options[0] === '';
    let invalidForm = !this.$scope.pollForm.$valid;

    if (invalidForm || hasNoOptions){
      this.onSaveError({error: 'invalid form'});
      return;
    }

    return this.feedService.postPollToFeed(this.to.name, this.to.id, this.toCategory.id, this.title, this.poll.options, this.description, this.isPropertyBoardMessage)
      .then(p => {
        this.clearForm();
        this.onSave({feedPost: p});
      })
      .catch(() => this.onSaveError({error: 'server error'}));
  }

  addPollOption(po) {
    if (this.poll.options.includes(po)) {
      return;
    }

    this.poll.options.push(po);
    this.myPoll = '';
    this.scrollPollToBottom(50);
    let pollInput =  document.getElementById('pollInput');
    pollInput.focus();
  }

  scrollPollToBottom(timeout = 1000) {
    this.$timeout(() => {
      document.getElementById('buttonsContainer').scrollIntoView();
    }, timeout);
  }

  removePollOption(option) {
    this.poll.options = this.poll.options.filter(o => o !== option);
    if (this.poll.options.length === 0) {
      this.poll = this.initPoll();
    }
  }

  pollOptionChanged(index, option){
    this.poll.options[index] = option;
  }

  clearForm() {
    this.to = null;
    this.toCategory = null;
    this.title = '';
    this.description = '';
    this.poll = this.initPoll();

    let form = this.$scope.pollForm;
    form.$setPristine();
    form.$setUntouched();
  }

}

angular.module('ll').component('pollComposer', {
  template: require('./pollcomposer.component.html'),
  controller: PollComposerController,
  controllerAs: 'vm',
  bindings: {
    toOptions: '<',
    categoryOptions: '<',
    command: '<',
    onSave: '&',
    onSaveError: '&'
  }
});

/* eslint-disable no-console */
import angular from 'angular';
import {createRoute} from '../../core/config';
import Shepherd from 'shepherd.js/dist/js/shepherd';

class AppController {
  constructor($rootScope, SessionService, OnboardingService, $timeout, $mdMedia) {
    this.sessionService = SessionService;
    this.onboardingService = OnboardingService;
    this.$timeout = $timeout;
    this.$mdMedia = $mdMedia;
  }

  $onInit() {
    let showOnboarding = this.user.hasEnabledFeature('onboarding');
    if (showOnboarding) {
      this.onboardingService.onboard(this.user, this.user.registrationFlow); // TODO: create separate parameters for registrationFlow and onboardingFlow later if needed
    }
    this.sessionService.onUserDataRefresh(u => this.updateUser(u));
  }

  // FIXME translate the onboarding and make sure that we can persist the resulting onbarding process
  // perhaps store that in the database
  onboardBottomToolbar() {
    if (!this.$mdMedia('xs') || this.sessionService.getStorage('newScreenOnboarding')) {
      return;
    }
    this.sessionService.setStorage('newScreenOnboarding', true);

    this.$timeout(() => {
      let tour = new Shepherd.Tour({
        defaultStepOptions: {
          classes: 'shadow-md bg-purple-dark',
          scrollTo: true,
        },
        useModalOverlay: true
      });


      let previous = {
        action: tour.back,
        classes: 'shepherd-button-secondary',
        text: 'Back'
      };

      let next = {
        action: tour.next,
        classes: 'shepherd-button-secondary',
        text: 'Next'
      };

      let end = {
        action: tour.next,
        classes: 'shepherd-button-secondary',
        text: 'End!'
      };

      tour.addStep('filter', {title: 'Welcome', text: 'We have changed a few things in you initial screen and will guide you through the changes.', buttons: [next]});
      tour.addStep('filter', {text: 'Filter the feed by a specific type of post, making it easier to find content', attachTo: '.feedCategoryFilter bottom', buttons: [previous, next]});
      tour.addStep('toolbar', {text: 'New toolbar that gives you access to the main functionalities', attachTo: '.ll-quick-access-bar top', buttons: [previous, next]});
      tour.addStep('property', {text: 'Access your house (BRF) feed', attachTo: '#quickAccessPropertyFeed top', buttons: [previous, next]});
      tour.addStep('neighborhood', {text: 'Access your neighborhood feed', attachTo: '#quickAccessNeighborhoodFeed top', buttons: [previous, next]});
      tour.addStep('createPost', {text: 'Create a new post, event or alert', attachTo: '#quickAccessCreatePost top', buttons: [previous, next]});
      tour.addStep('marketplace', {text: 'Marketplace. Buy, sell, share or rent out items', attachTo: '#quickAccessMarketplace top', buttons: [previous, next]});
      tour.addStep('notifications', {text: 'Notificaitons. Check your latest notifications', attachTo: '#quickAccessNotifications top', buttons: [end]});
      tour.start();
    }, 500);
  }

  updateUser(user) {
    this.user = user;
  }
}

angular.module('ll')
  .component('app', {
    template: require('./app.component.html'),
    controller: AppController,
    controllerAs: 'vm',
    bindings: {
      user: '<'
    }
  })
  .config(createRoute({
    abstract: true,
    name: 'app',
    resolve: {
      user: (SessionService) => SessionService.getUser()
    },
    data: {
      authenticate: true
    },
    views: {
      '': 'app',
      'desktopMenu@app': 'defaultMenu',
      'mobileMenu@app': 'defaultMenu'
    }
  }))
  .config(createRoute({
    name: 'logout',
    url: '/logout',
    controller: function (LoginService, $state) {
      LoginService.signOut().then(() => $state.go('welcome'));
    }
  }));

import angular from 'angular';
import {Collection, Feature, Map, View} from 'ol';
import {Point} from 'ol/geom';
import {defaults, DragRotateAndZoom, Draw, Modify, Select, Snap} from 'ol/interaction';
import {Tile, Vector as LayerVector} from 'ol/layer';
import {fromLonLat} from 'ol/proj';
import {OSM, Vector as SourceVector} from 'ol/source';
import {Fill, Icon, Stroke, Style, Text} from 'ol/style';
import {altKeyOnly, click, shiftKeyOnly, singleClick} from 'ol/events/condition';
import {createRoute} from '../../core/config';

angular.module('ll')
  .component('regionPolygons', {
    template: require('./regionpolygons.html'),
    controller: RegionpolygonsController,
    controllerAs: 'regionpolygonsVm',
  })
  .config(createRoute({
    parent: 'app',
    name: 'regionPolygons',
    component: 'regionPolygons',
    url: '/admin/regionpolygons'
  }));

function RegionpolygonsController($scope, $filter, $mdDialog, $mdToast, $timeout, RegionPolygonService, uiAlertService, EnvironmentSettings) {
  const vm = this;

  vm.country = EnvironmentSettings.country;

  // Variables
  vm.regions = [];
  vm.chosenRegion = null;
  vm.newRegion = {
    name: null,
    city: null,
    municipality: null,
    coordinates: null
  };

  // Accessable Functions
  vm.saveRegionPolygon = saveRegionPolygon;
  vm.onDeleteRegionPolygon = onDeleteRegionPolygon;
  vm.onResetAreas = onResetAreas;
  vm.onRegionSelectChange = onRegionSelectChange;
  vm.onMatchAddressFormSubmit = onMatchAddressFormSubmit;
  vm.createRegion = createRegion;

  // Internal variables and settings
  let autocomplete;
  const lonlatMiddleOfStockholm = [18.06861, 59.32944];

  init();

  // Functions
  function init() {
    fetchRegions();
    initAutocomplete();
  }

  // OpenLayers variables
  let view = new View({
    center: fromLonLat(lonlatMiddleOfStockholm),
    zoom: 12
  });

  let editableFeatures = new Collection();
  let selectedFeatures = new Collection(); // Only needed so it can be emptied on reset

  let apiWktSource = new SourceVector({
    features: []
  });

  let apiWktLayer = new LayerVector({
    source: apiWktSource,
    style: polygonStyleFunction
  });

  let editableFeaturesSource = new SourceVector({
    features: editableFeatures
  });

  let editableFeaturesLayer = new LayerVector({
    source: editableFeaturesSource,
    style: polygonStyleFunction
  });

  let searchResultSource = new SourceVector({
    features: []
  });

  let searchResultLayer = new LayerVector({
    source: searchResultSource
  });

  let map = new Map({
    interactions: defaults().extend([
      new DragRotateAndZoom()
    ]),
    layers: [
      new Tile({
        source: new OSM()
      }),
      apiWktLayer, // geoServerLayer,
      searchResultLayer,
      editableFeaturesLayer
    ],
    target: 'map',
    view: view
  });

  // Workaround to get map appear without having to manually resize the browser window
  function updateMap() {
    setTimeout(function () {
      map.updateSize();
    }, 200);
  }

  updateMap();

  let draw = new Draw({
    features: editableFeatures,
    type: 'Polygon'
  });
  draw.on('drawend', function (evt) {
    let f = evt.feature;
    f.set('name', vm.chosenRegion.name);
    f.setId(vm.chosenRegion.id);
    allowModify();
  });

  let modify = new Modify({
    features: editableFeatures,
    // the SHIFT key must be pressed to delete vertices, so
    // that new vertices can be drawn at the same position
    // of existing vertices
    deleteCondition: function (event) {
      return shiftKeyOnly(event) &&
        singleClick(event);
    }
  });

  let selectClick = new Select({
    // Alt-click
    condition: function (mapBrowserEvent) {
      return click(mapBrowserEvent) &&
        altKeyOnly(mapBrowserEvent);
    },
    style: polygonStyleFunction,
    features: selectedFeatures
  });
  selectClick.on('select', onMapSelectClick);
  map.addInteraction(selectClick);

  // Select / deselect surrounding regions by alt-shift-click
  map.on('singleclick', function (e) {
    if (e.originalEvent.altKey && e.originalEvent.shiftKey) {
      map.forEachFeatureAtPixel(e.pixel,
        function (feature) {
          let regionId = feature.getId();
          toggleSurroundingRegion(regionId);
        });
    }
  });


  // The snap interaction must be added after the Modify and Draw interactions
  // in order for its map browser event handlers to be fired first.
  let snap = new Snap({
    source: apiWktSource
  });

  function allowDraw() {
    map.removeInteraction(snap);
    map.addInteraction(draw);
    map.removeInteraction(modify);
    map.addInteraction(snap);
  }

  function allowModify() {
    map.removeInteraction(snap);
    map.addInteraction(modify);
    map.removeInteraction(draw);
    map.addInteraction(snap);
  }

  function initAutocomplete() {
    // eslint-disable-next-line no-undef
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocompleteAddress'),
      {types: ['address']} // Restricts to actual addresses
    );
    // Restrict search to supported countries
    autocomplete.setComponentRestrictions({'country': ['se', 'es']});

    //let autocompleteAddress = document.getElementById('autocompleteAddress');
    autocomplete.addListener('place_changed', onAutocompleteChoice);
  }

  function fetchRegions() {
    RegionPolygonService.getRegions().then(
      onFetchRegionsResult,
      (err) => {
        showError('Error: ' + err);
      }
    );
  }

  function onFetchRegionsResult(responseData) {
    if (responseData) {
      vm.regions = responseData;
      if (!vm.chosenRegion) {
        vm.chosenRegion = vm.regions[0];
      }
      fetchRegionFeatures(); // Needs to be done *after* we have a chosen region so that the chosen one can be marked as selected.
    } else {
      vm.chosenRegion = null;
      vm.regions = [];
    }
  }

  function fetchRegionFeatures() {
    RegionPolygonService.getRegionFeatures().then(
      onFetchRegionFeaturesResult,
      (err) => {
        showError('Error: ' + err);
      }
    );
  }

  function onFetchRegionFeaturesResult(featuresArray) {
    allowDraw();
    if (featuresArray) {
      for (let i = 0; i < featuresArray.length; i++) {
        let feature = featuresArray[i];
        if (feature.getId() === vm.chosenRegion.id) {
          editableFeaturesSource.addFeature(feature);
          allowModify();
        } else {
          apiWktSource.addFeature(feature);
        }
      }
    }
  }

  function onResetAreas() {
    showConfirm(
      'Reset areas?',
      'Are you sure you want to reset areas? All unsaved changes will be lost!',
      resetAreas
    );
  }

  function resetAreas() {
    apiWktSource.clear();
    editableFeaturesSource.clear();
    selectedFeatures.clear();
    fetchRegionFeatures();
  }

  function onRegionSelectChange(oldRegion) {
    changeRegion(oldRegion);
  }

  function onMapSelectClick(evt) {
    let feature = evt.selected[0];
    if (feature) {
      let regionId = feature.getId();
      let oldRegion = vm.chosenRegion;
      vm.chosenRegion = $filter('filter')(vm.regions, {'id': regionId})[0];
      changeRegion(oldRegion); // Need to do here (and not in timeout) to get the map update without needing to move it around

      // Currently the best way to get the view update after a scope variable has changed
      // https://stackoverflow.com/a/18996042/3150408
      $timeout(function () {
        // Need to do the assignment again in timeout to get the dropdown to update
        vm.chosenRegion = $filter('filter')(vm.regions, {'id': regionId})[0];
      });
    }
  }

  function toggleSurroundingRegion(regionId) {
    if (vm.chosenRegion) {
      if (vm.chosenRegion.id === regionId) {
        return; // Ignore clicks on self
      }
      let clickedRegion = $filter('filter')(vm.regions, {'id': regionId})[0];
      let isSurroundingRegion = false;
      for (let i = 0; i < vm.chosenRegion.surroundingRegions.length; i++) {
        if (vm.chosenRegion.surroundingRegions[i].id === regionId) {
          isSurroundingRegion = true;
          vm.chosenRegion.surroundingRegions.splice(i, 1); // Remove region
          break;
        }
      }
      if (!isSurroundingRegion) {
        vm.chosenRegion.surroundingRegions.push({
          id: clickedRegion.id,
          name: clickedRegion.name,
          municipality: clickedRegion.municipality
        });
      }
      apiWktSource.changed(); // Force redraw
    }
  }

  // Call when a new region is chosen
  function changeRegion(oldRegion) {
    makeRegionUneditable(oldRegion);
    if (vm.chosenRegion) {
      makeRegionEditable(vm.chosenRegion);
    }
    apiWktSource.changed(); // Force redraw
    editableFeaturesSource.changed(); // Force redraw
  }

  function makeRegionEditable(region) {
    let f = apiWktSource.getFeatureById(region.id);
    if (f) {
      apiWktSource.removeFeature(f);
      editableFeaturesSource.addFeature(f);
      allowModify();
    } else {
      allowDraw();
    }

  }

  function makeRegionUneditable(region) {
    let f = editableFeaturesSource.getFeatureById(region.id);
    if (f) {
      apiWktSource.addFeature(f);
      editableFeaturesSource.removeFeature(f);
    }
  }

  function removeRegionPolygonFromLayers(region) {
    let f = apiWktSource.getFeatureById(region.id);
    if (f) {
      apiWktSource.removeFeature(apiWktSource.getFeatureById(region.id));
    }
    f = editableFeaturesSource.getFeatureById(region.id);
    if (f) {
      editableFeaturesSource.removeFeature(editableFeaturesSource.getFeatureById(region.id));
    }
    selectedFeatures.clear();
  }

  function saveRegionPolygon() {
    if (!vm.chosenRegion) {
      showError('Cannot save', 'no region chosen');
      return;
    }

    let source = editableFeaturesLayer.getSource();
    let feature = null;

    let featureCount = 0;

    source.forEachFeature(function (f) {
      feature = f;
      featureCount += 1;
    });

    if (featureCount !== 1) {
      showError('Polygon save error', 'Found ' + featureCount + ' polygons to save, expected 1.');
      return;
    }

    RegionPolygonService.saveRegionPolygon(vm.chosenRegion, feature, apiWktSource).then(
      (response) => {
        showSuccess('Polygon saved', vm.chosenRegion.name);
      }, (err) => {
        showError('Polygon save error', err.message);
      });
  }

  function onDeleteRegionPolygon() {
    showConfirm(
      'Delete polygon?',
      'Are you sure you want to delete the polygon for region ' + vm.chosenRegion.name + '?',
      deleteRegionPolygon
    );
  }

  function deleteRegionPolygon() {
    if (!vm.chosenRegion) {
      showError('Cannot delete polygon', 'no region chosen');
      return;
    }
    let regionId = parseInt(vm.chosenRegion.id);
    RegionPolygonService.deleteRegionPolygon(regionId).then((response) => {
      removeRegionPolygonFromLayers(vm.chosenRegion);
      allowDraw();
      showSuccess('Polygon deleted', vm.chosenRegion.name);
    }, (err) => {
      showError('Polygon delete error', formatError(err));
    });
  }

  function createRegion() {

    if (!vm.newRegion.name) {
      showError(null, 'Name is required!');
      return;
    }

    if (!vm.newRegion.city) {
      showError(null, 'City is required!');
      return;
    }

    if (!vm.newRegion.municipality) {
      showError(null, 'Municipality is required!');
      return;
    }

    RegionPolygonService.createRegion(vm.newRegion).then((region) => {
      vm.regions.push(region);
      vm.chosenRegion = region;
      showSuccess('Region created', vm.chosenRegion.name + ' (' + vm.chosenRegion.municipality + ')');
    }, (err) => {
      showError('Region creation error', formatError(err));
    });
  }

  function formatError(err) {
    return (err.messageCode ? err.messageCode + ' ' : '') + (err.message || '');
  }

  function showConfirm(title, message, callbackFunction) {
    uiAlertService.showConfirm(title, message).then(callbackFunction);
  }

  function showSuccess(title, message) {
    uiAlertService.showSuccess(title, message);
  }

  function showInfo(title, message) {
    uiAlertService.showInfo(title, message);
  }

  function showWarning(title, message) {
    uiAlertService.showWarning(title, message);
  }

  function showError(title, message) {
    uiAlertService.showError(title, message);
  }

  function onMatchAddressFormSubmit(evt) {
    evt.preventDefault();
    let place = autocomplete.getPlace();
    findRegionForGooglePlace(place);
    return false;
  }

  function onAutocompleteChoice(evt) {
    let place = autocomplete.getPlace();
    findRegionForGooglePlace(place);
  }

  function findRegionForGooglePlace(place) {
    let errorMessage = validatePlace(place);
    if (errorMessage) {
      showError(errorMessage);
      return;
    }

    let lonlat = getLonLatFromGoogleResult(place);
    fetchRegionForLocation(lonlat[0], lonlat[1]);

    let geocodingResponse = {results: [place]};
    zoomToLocations(geocodingResponse);
  }

  function validatePlace(place) {
    let errorMessage = '';
    /*jshint camelcase: false */
    let streetNumber = findGoogleAddressComponent(place.address_components, 'street_number');
    if (!streetNumber) {
      if (vm.country === 'es') {
        let route = findGoogleAddressComponent(place.address_components, 'route');
        if (!route) {
          errorMessage += 'Street number or route type is required!';
        }
      } else {
        errorMessage += 'Street number is required!';
      }
    }

    if (errorMessage === '') {
      return null;
    } else {
      return errorMessage;
    }
  }

  function fetchRegionForLocation(lon, lat) {
    RegionPolygonService.getRegionForLocation(lon, lat).then(
      onFetchRegionForLocationResult,
      (err) => {
        let errMsg = err.message ? err.message : err.error.message ? err.error.message : err;
        showError('fetchRegionForLocation error', errMsg);
      }
    );
  }

  function onFetchRegionForLocationResult(result) {
    let place = autocomplete.getPlace();
    /*jshint camelcase: false */
    if (result) {
      showInfo('Match', '[' + place.formatted_address + '] matches region [' + result.name + '].');
    } else {
      showWarning('No match', '[' + place.formatted_address + '] does not match any region.');
    }
  }

  function zoomToLocations(geocodingResponse) {
    /*jshint camelcase: false */
    if (geocodingResponse.error_message && geocodingResponse.error_message !== '') {
      showError('Address lookup error', geocodingResponse.error_message);
      return;
    }

    let centerLonLats = handleGoogleGeolocationResponse(geocodingResponse);
    let centerLonLat = getCenterFromCoords(centerLonLats);
    if (!centerLonLat) {
      return;
    }

    let center = fromLonLat(centerLonLat);
    view.setCenter(center);
    view.setZoom(14);
  }

  function findGoogleAddressComponent(googleAddressComponents, componentName) {
    if (!googleAddressComponents) {
      return null;
    }
    for (let i = 0; i < googleAddressComponents.length; i++) {
      let ac = googleAddressComponents[i];
      if (ac.types.includes(componentName)) {
        return ac;
      }
    }
    return null;
  }

  function handleGoogleGeolocationResponse(geocodingResponse) {
    if (!geocodingResponse || !geocodingResponse.results || geocodingResponse.results.length < 1) {
      return null;
    }

    let coords = [];

    geocodingResponse.results.forEach(function (result) {
      coords.push(getLonLatFromGoogleResult(result));
      addGoogleResultToMap(result);
    });

    return coords;
  }

  function getCenterFromCoords(lonLatCoords) {
    let lon = 0;
    let lat = 0;
    for (let i = 0; i < lonLatCoords.length; i++) {
      lon += lonLatCoords[i][0];
      lat += lonLatCoords[i][1];
    }
    return [lon / lonLatCoords.length, lat / lonLatCoords.length];
  }

  function getLonLatFromGoogleResult(result) {
    let googleLocation = result.geometry.location;
    return [googleLocation.lng(), googleLocation.lat()];
  }

  function addGoogleResultToMap(r) {
    let lonlat = getLonLatFromGoogleResult(r);
    /*jshint camelcase: false */
    let name = r.formatted_address;
    addPointWithNameToMap(lonlat, name);
  }

  function addPointWithNameToMap(lonlat, name) {
    let center = fromLonLat(lonlat);

    let searchResultFeature = new Feature({
      geometry: new Point(center),
      name: name
    });

    searchResultFeature.setStyle(new Style({
      image: new Icon({
        color: '#4271AE',
        src: 'https://storage.googleapis.com/user-content-master/ol/circle-10px.png'
      })
    }));

    searchResultSource.addFeature(searchResultFeature);
  }

  function getText(feature, resolution) {
    let type = 'wrap';
    let maxResolution = 1200;
    let text = feature.get('name');

    if (resolution > maxResolution) {
      text = '';
    } else if (type === 'hide') {
      text = '';
    } else if (type === 'shorten') {
      text = truncate(text, 12);
    } else if (type === 'wrap') {
      text = stringDivider(text, 16, '\n');
    }

    return text;
  }

  function createTextStyle(feature, resolution) {
    let align = 'center';
    let baseline = 'middle';
    let size = '10px';
    let offsetX = 0;
    let offsetY = 0;
    let weight = 'bold';
    let rotation = 0;
    let fontName = 'Verdana';
    let font = weight + ' ' + size + ' ' + fontName;
    let fillColor = 'blue';
    let outlineColor = '#fff';
    let outlineWidth = 3;

    return new Text({
      textAlign: align,
      textBaseline: baseline,
      font: font,
      text: getText(feature, resolution),
      fill: new Fill({color: fillColor}),
      stroke: new Stroke({color: outlineColor, width: outlineWidth}),
      offsetX: offsetX,
      offsetY: offsetY,
      rotation: rotation
    });
  }

  function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str.substr(0);
  }

  function stringDivider(str, width, spaceReplacer) {
    if (!str) {
      return str;
    }
    if (str.length > width) {
      let p = width;
      while (p > 0 && (str[p] !== ' ' && str[p] !== '-')) {
        p--;
      }
      if (p > 0) {
        let left;
        if (str.substring(p, p + 1) === '-') {
          left = str.substring(0, p + 1);
        } else {
          left = str.substring(0, p);
        }
        let right = str.substring(p + 1);
        return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
      }
    }
    return str;
  }

  function polygonStyleFunction(feature, resolution) {
    // Standard style for region polygons
    let color = 'blue';
    let width = 1;
    let fillColor = 'rgba(0, 0, 255, 0.1)';
    let featureId = feature.getId();

    // Special style for the chosen region
    if (featureId === vm.chosenRegion.id) {
      color = 'green';
      width = 2;
      fillColor = 'rgba(50, 128, 50, 0.2)';
    }

    // Special style for surrounding regions belonging to the chosen region
    if (vm.chosenRegion) {
      let surroundingRegions = vm.chosenRegion.surroundingRegions;
      for (let i = 0; i < surroundingRegions.length; i++) {
        let surroundingRegion = surroundingRegions[i];
        if (surroundingRegion.id === featureId) {
          color = 'orangered';
          fillColor = 'rgba(255, 0, 0, 0.2)';
        }
      }
    }

    return new Style({
      stroke: new Stroke({
        color: color,
        width: width
      }),
      fill: new Fill({
        color: fillColor
      }),
      text: createTextStyle(feature, resolution)
    });
  }
}

import angular from 'angular';


class ItemReviewsController {

  constructor(ClassifiedsService, $mdDialog, $state, TrackingService) {
    this.classifiedsService = ClassifiedsService;
    this.$mdDialog = $mdDialog;
    this.$state = $state;
    this.trackingService = TrackingService;
  }

  $onInit() {
    this.reviewScore = 0;
  }

  showReviewDialog() {
    this.$mdDialog.show({
      parent: angular.element(document.body),
      template: require('./review-dialog.html'),
      clickOutsideToClose: true,
      fullscreen: false,
      controllerAs: 'vm',
      preserveScope: true,
      bindToController: true,
      locals: {itemTitle: this.item.title, itemId: this.item.id},
      controller: ItemReviewsController
    });
  }

  sendReview() {
    this.trackingService.track('classifieds.item.review', {itemId: this.locals.itemId, reviewScore: this.reviewScore});
    this.classifiedsService.writeAdvertisementReview(this.locals.itemId, this.reviewScore, this.reviewComment)
      .then(() => {
        this.closeDialog();
        this.refreshPage();
      });
  }

  closeDialog() {
    this.$mdDialog.hide();
  }

  refreshPage() {
    this.$state.reload();
  }
}

angular.module('ll')
  .component('itemReviews', {
    template: require('./item-reviews.component.html'),
    controller: ItemReviewsController,
    controllerAs: 'vm',
    bindings: {
      item: '<',
      reviews: '<',
      canReview: '<'
    }
  });

import angular from 'angular';

class DatePeriodPickerController {
  constructor($element, $scope, $interval, $timeout, moment, TrackingService){
    this.$element = $element;
    this.$scope = $scope;
    this.$interval = $interval;
    this.$timeout = $timeout;
    this.moment = moment;
    this.trackingService = TrackingService;
  }
  $onInit() {
    let i = 0;
    const interval = this.$interval(() => {
      this.setWidth();
      if (++i >= 20) {
        this.$interval.cancel(interval);
      }
    }, 50);

    window.addEventListener('resize', () => {
      this.setWidth();
      this.$scope.$apply();
    });

    this.selectedPeriod = this.getSelectedPeriod();
    this.periodShift = this.initialDateFrom ? this.calculatePeriodShift(this.initialDateFrom, this.selectedPeriod.key) : 0;


    this.setDateFrom(this.initialDateFrom);
  }

  calculatePeriodShift(date, periodKey) {
    let diff = 0;
    let dateMoment = this.moment(date);
    switch (periodKey) {
      case 'month':
        diff = dateMoment.diff(this.moment(), 'month', true);
        break;
      case 'week':
        diff = dateMoment.diff(this.moment(), 'week', true);
        break;
      case 'day':
        diff = dateMoment.diff(this.moment(), 'day', true) + 1;
        break;
        // Add other cases if needed.
    }
    return Math.ceil(diff);
  }

  setWidth() {
    this.datePeriodPickerWidth = this.$element[0].parentNode.clientWidth;
  }

  getSelectedPeriod() {
    return this.periods.find(p => p.selected);
  }

  setDateFrom(overrideDateFrom) {
    const selectedPeriod = this.selectedPeriod;
    if (selectedPeriod.daysValue) {
      this.dateFrom = this.moment().add(selectedPeriod.daysValue * (this.periodShift - 1), 'days').toDate();
      this.dateTo = this.moment().add(selectedPeriod.daysValue * this.periodShift - 1, 'days').toDate();
    } else {
      const now = this.moment();
      const key = this.selectedPeriod.key;
      switch (key) {
        case 'day':
          this.dateFrom = overrideDateFrom ? this.moment(overrideDateFrom).toDate() : this.moment().add('days', this.periodShift - 1).toDate();
          this.dateTo = this.dateFrom;
          break;
        case 'week':
        case 'month': {
          let dateFromMoment = overrideDateFrom ? this.moment(overrideDateFrom) : this.moment().startOf(key).add(this.periodShift, key);
          this.dateFrom = dateFromMoment.toDate();
          let endOfPeriod = this.moment(dateFromMoment).add(1, key).add(-1, 'day');
          this.dateTo = endOfPeriod.isAfter(now) ? now.toDate() : endOfPeriod.toDate();
          break;
        }
      }
    }
    this.onChange({dateFrom: this.dateFrom, dateTo: this.dateTo, period: selectedPeriod});
  }

  selectPeriod(period) {
    this.trackingService.track('sustainability.periodSelected', {'period': period});
    this.periods.forEach(p => {
      p.selected = p === period;
    });
    this.selectedPeriod = period;

    this.$timeout(() => {
      this.choosePeriodPopupShown = false;
    }, 100);

    this.setDateFrom();
  }

  shiftPeriod(direction) {
    if (this.periodShift + direction <= 0) {
      this.trackingService.track('sustainability.periodShifted', {'direction': direction});
      this.periodShift += direction;

      this.setDateFrom();
    }
  }

  showChoosePeriodPopup() {
    this.choosePeriodPopupShown = true;
  }
}

angular.module('ll').component('llDatePeriodPicker', {
  template: require('./date-period-picker.component.html'),
  controller: DatePeriodPickerController,
  controllerAs: 'vm',
  bindings: {
    periods: '=',
    onChange: '&',
    initialDateFrom: '<',
    initialPeriod: '<'
  }
});

import angular from 'angular';
import {createRoute} from '../../core/config';

class EnergyController{
  constructor(EnergyService, moment, $state, ToolbarService, TrackingService, $translate){
    this.energyService = EnergyService;
    this.moment = moment;
    this.$state = $state;
    this.toolbarService = ToolbarService;
    this.trackingService = TrackingService;
    this.$translate = $translate;
    this.pauseHoursParticipated = {};
    this.pauseHourTipTranslated = null;
  }

  $onInit(){
    this.trackingService.track('energy.summary.init');
    this.pauseHourData = this.pauseHourData || null;
    this.toolbarService.configure({
      title: this.$translate.instant('ENERGY.MY_ENERGY')
    });
    if (this.pauseHourData !== null){
      this.setPauseHour();
    } else {
      this.energyService.getPauseHourSummary(false)
        .then(data => {
          this.pauseHoursParticipated = data;
        });
    }
  }

  setPauseHour(){
    let pauseHourLocalTimeStart = this.moment.utc(this.pauseHourData.datetimeUTC).local();
    let pauseHourLocalTimeEnd = this.moment.utc(this.pauseHourData.datetimeUTC).local().add(this.pauseHourData.lengthInHours,'hours');
    this.newPauseHourTitleTranslated = this.$translate.instant('ENERGY_PAUSE_HOUR.NEW_PAUSE_HOUR_' + Math.round(this.pauseHourData.lengthInHours));
    this.pauseHourDate = pauseHourLocalTimeStart.format('YYYY-MM-DD');
    this.pauseHourStart = pauseHourLocalTimeStart.calendar().toLowerCase();
    this.pauseHourStartRelative = this.moment().to(pauseHourLocalTimeStart);
    this.pauseHourTimeStart = pauseHourLocalTimeStart.format('HH:mm');
    this.pauseHourTimeEnd = pauseHourLocalTimeEnd.format('HH:mm');
    this.pauseHourTipTranslated = this.energyService.getPauseHourTip();
  }

  goToPauseHourDetails(){
    this.$state.go('app.energyPauseHour', {pauseHourData: this.pauseHourData});
  }
}

angular.module('ll')
  .component('energy', {
    template: require('./energy.html'),
    controller: EnergyController,
    controllerAs: 'vm',
    bindings: {
      pauseHourData: '<',
    }
  })
  .config(createRoute({
    name: 'app.energy',
    url: '/energy',
    redirectTo: (trans) => {
      return trans.injector().get('EnergyService').getStateBasedOnUser();
    }
  }))
  .config(createRoute({
    name: 'app.energy.summary',
    component: 'energy',
    url: '/summary',
    resolve: {
      pauseHourData: (EnergyService) => EnergyService.getNextPauseHourAndSavingsGoal(),
    }
  }));

import angular from 'angular';

class ProgressBarController {
  constructor($timeout) {
    this.$timeout = $timeout;
  }
  $onInit() {
    this.doShowProgressBarComparisons = this.config.doShowProgressBarComparisons;
    this.progressPercentage = 1;

    this.hollowTrianglePercentage = 0;
    this.filledTrianglePercentage = 0;

    this.hollowTriangleZeroValShift = 0;
    this.filledTriangleZeroValShift = 0;

    this.$timeout(() => {
      this.initProgress();
    }, 100);
  }

  $onChanges() {
    this.initProgress();
  }

  initProgress() {
    this.roundedValue = this.config.currentValue ? Math.round(this.config.currentValue * 10) / 10 : null;
    this.progressPercentage = this.config.currentValue / (this.config.max - this.config.min) * 100;

    this.hollowTrianglePercentage = this.config.hollowTriangleValue / (this.config.max - this.config.min) * 100;
    this.filledTrianglePercentage = this.config.filledTriangleValue / (this.config.max - this.config.min) * 100;

    this.hollowTriangleZeroValShift = this.hollowTrianglePercentage === 0 ? 1 : 0;
    this.filledTriangleZeroValShift = this.filledTrianglePercentage === 0 ? 1 : 0;
  }
}

angular.module('ll').component('llProgressBar', {
  template: require('./progress-bar.component.html'),
  controller: ProgressBarController,
  controllerAs: 'vm',
  bindings: {
    config: '<',
    mainColor: '<'
  }
});

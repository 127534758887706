import angular from 'angular';
import {createRoute} from '../../core/config';

angular.module('ll')
  .component('energyJoinSuccess', {
    template: require('./energyJoinSuccess.html'),
    controller: EnergyJoinSuccessController,
    controllerAs: 'vm',
    bindings: {
      consent: '<',
      dsoName: '<'
    }
  })
  .config(createRoute({
    name: 'app.energyJoinSuccess',
    component: 'energyJoinSuccess',
    url: '/energyjoinsuccess',
    resolve: {
      consent: (SessionService, EnergyService) => {
        let consentId = SessionService.getUser().household.signedElectricitydataConsentId;
        return EnergyService.getConsent(consentId, false);
      },
      dsoName: (SessionService) => {
        return SessionService.getUser().networkArea.dsoCode;
      }
    },
    data: {authenticate: true}
  }));

function EnergyJoinSuccessController(EnergyService, SessionService, TrackingService, $mdDialog, $translate, $sce) {
  const vm = this;
  vm.consentSignedMessage = null;
  vm.dsoName = null;

  vm.slides = [
    {
      type: 'pause_hour',
      title: $translate.instant('ENERGY_EXPLANATIONS.PAUSE_HOUR.TITLE'),
      image: 'images/energy/house_small.png',
    },
    {
      type: 'you_and_your_neighbours',
      title: $translate.instant('ENERGY_EXPLANATIONS.YOU_AND_YOUR_NEIGHBOURS.TITLE'),
      image: 'images/energy/house_small.png',
    },
    {
      type: 'research',
      title: $translate.instant('ENERGY_EXPLANATIONS.RESEARCH.TITLE'),
      image: 'images/energy/house_small.png',
    },
    {
      type: 'comparable',
      title: $translate.instant('ENERGY_EXPLANATIONS.COMPARABLE.TITLE'),
      image: 'images/energy/house_small.png',
    },
    {
      type: 'anonymous',
      title: $translate.instant('ENERGY_EXPLANATIONS.ANONYMOUS.TITLE'),
      image: 'images/energy/house_small.png',
    },
    {
      type: 'compete',
      title: $translate.instant('ENERGY_EXPLANATIONS.COMPETE.TITLE'),
      image: 'images/energy/house_small.png',
    },
  ];

  vm.openExplanationDialog = EnergyService.openExplanationDialog;
  vm.openConsentDialog = openConsentDialog;
  vm.hideDialog = hideDialog;
  vm.onCarouselInit = onCarouselInit;
  vm.$onInit = init;

  function init() {
    TrackingService.track('energyjoinsuccess.init');
    vm.user = SessionService.getUser();
    vm.dsoName = vm.user.networkArea ? vm.user.networkArea.dsoCode : null;
    vm.consentSignedMessage = $sce.trustAsHtml('<span>' + $translate.instant('ELECTRICITYDATACONSENT.MESSAGE.CONSENT_SIGNED_MESSAGE',  {'dsoName': vm.dsoName}) + '</span>');
  }

  function onCarouselInit(carouselScope){
    let ctrl = carouselScope.$$childHead.ctrl;
    ctrl.openExplanationDialog = vm.openExplanationDialog;
    // unbindClickFromCarousel();
  }

  function hideDialog(){
    $mdDialog.hide();
  }

  function openConsentDialog($event){
    TrackingService.track('energyjoinsuccess.consent_dialog.open');
    $mdDialog.show({
      targetEvent: $event,
      template: require('./consentDialog.tmpl.html'),
      parent: angular.element(document.body),
      clickOutsideToClose:true,
      fullscreen: false,
      controllerAs: 'vm',
      preserveScope : true,
      bindToController: true,
      controller: EnergyJoinSuccessController,
      locals: vm.consent
    });
  }
}

import angular from 'angular';

class SimpleCardController {
  constructor($sce, $translate, ToastMessageService) {
    this.$sce = $sce;
    this.$translate = $translate;
    this.toastMessageService = ToastMessageService;
    this.isEditing = false;
  }

  $onInit() {
    this.title = this.title || null;
    if (this.isHtml) {
      this.text = this.text ? this.$sce.trustAsHtml(this.text) : null;
    } else {
      this.text = this.text || null;
    }
    this.placeholder = this.placeholder || null;
    this.enableEdit = this.enableEdit || false;
  }

  $onChanges(changes) {
    this.text = changes.text ? changes.text.currentValue : this.text;
  }

  toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  save() {
    this.onSave({text: this.text})
      .then(() => this.showMessage(this.$translate.instant('TEXT_CARD.TEXT_SAVED', {title: this.title.toLowerCase()})))
      .catch(() => this.showMessage(this.$translate.instant('TEXT_CARD.ERROR_SAVING_TEXT', {title: this.title.toLowerCase()})))
      .then(() => this.isEditing = false);
  }

  showMessage(message) {
    this.toastMessageService.showMessage(message);
  }
}

angular.module('ll').component('simpleCard', {
  template: require('./simplecard.component.html'),
  controller: SimpleCardController,
  controllerAs: 'vm',
  bindings: {
    enableEdit: '<',
    onSave: '&',
    title: '<',
    text: '<',
    emptyTextDescription: '<',
    placeholder: '<',
    isHtml: '<'
  },
  transclude: true
});

import angular from 'angular';

class BellBadgeController {
  constructor(NotificationService){
    this.notificationService = NotificationService;
  }

  $onInit() {
    this.unnoticedNotifications = 0;
    this.fetchNotifications();
  }

  fetchNotifications(){
    this.notificationService.getNotificationsCount()
      .then(data => this.unnoticedNotifications = data.unnoticed);
  }

  clearBadge(){
    this.unnoticedNotifications = 0;
  }
}

angular.module('ll').component('bellBadge', {
  template: require('./bell-badge.component.html'),
  controller: BellBadgeController,
  controllerAs: 'vm'
});

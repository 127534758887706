import angular from 'angular';

class LoginController {

  constructor($scope, $translate, $state, LoginService, LoggingService, TrackingService, uiAlertService) {
    this.$scope = $scope;
    this.$translate = $translate;
    this.$state = $state;
    this.loginService = LoginService;
    this.loggingService = LoggingService;
    this.trackingService = TrackingService;
    this.uiAlertService = uiAlertService;
  }

  $onInit() {
    this.trackingService.track('Login Form');
    this.isInvalidCredentials = false;
    this.email = '';
    this.password = '';
    this.isLoggingIn = false;
  }

  login() {
    this.isLoggingIn = false;
    this.isInvalidCredentials = false;
    if (!this.$scope.loginForm.$valid) {
      return;
    }
    this.isLoggingIn = true;
    this.loginService.signIn(this.email, this.password)
      .then(() => {
        this.isLoggingIn = false;
        this.$state.go('app.neighborhood');
      })
      .catch(resp => {
        this.isLoggingIn = false;
        let data = resp.data;
        if (data && data.status === 401) {
          this.isInvalidCredentials = true;
        } else {
          this.displayMessage(this.$translate.instant('LOGIN.BACKEND_COMMUNICATION_PROBLEM'));
          this.loggingService.error('Unknown error on user login', resp);
        }
      });
  }

  displayMessage(message) {
    this.uiAlertService.showAlert('', message);
  }

  toggleActivationCode(){

  }

  toggleForgotPassword(){
    this.$scope.$parent.vm.showLogin = false;
    this.$scope.$parent.vm.showForgotPassword = true;
  }
}

angular.module('ll').component('login', {
  template: require('./login.component.html'),
  controller: LoginController,
  controllerAs: 'vm',
  bindings: {
    onCancel: '&',
    onLogin: '&'
  }
});

import angular from 'angular';
import {createRoute} from '../../core/config';

class NeighborhoodAboutController {
  constructor(NeighborhoodService) {
    this.neighborhoodService = NeighborhoodService;
  }

  saveDescription(description) {
    return this.neighborhoodService.saveDescription(description);
  }

  // saveContactInfo(contactInfo) {
  //   return this.neighborhoodService.saveContactInfo(this.neighborhood.id, contactInfo);
  // }
}

angular.module('ll')
  .component('neighborhoodAbout', {
    template: require('./neighborhood-about.component.html'),
    controller: NeighborhoodAboutController,
    controllerAs: 'vm',
    bindings:{
      canEdit: '<',
      neighborhood: '<'
    }
  })
  .config(createRoute({
    name: 'app.neighborhood.about',
    url: '/about',
    resolve: {
      canEdit: (SessionService) => SessionService.getUser().isNeighborhoodAdmin,
      neighborhood: (NeighborhoodService) => NeighborhoodService.getNeighborhoodDetails()
    },
    views: {
      neighborhood: 'neighborhoodAbout'
    }
  }));

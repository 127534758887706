import angular from 'angular';
import {createRoute} from '../../core/config';

class NeighborhoodFilesController {
  constructor(NeighborhoodService) {
    this.neighborhoodService = NeighborhoodService;

    // TODO: replace with correct call
    this.files = [
      {
        'id': 19,
        'name': 'Områdeskarta - Mariestrand.gif',
        'size': 348616,
        'url': 'https://www.googleapis.com/download/storage/v1/b/user-content-master/o/Omra%CC%8Adeskarta%20-%20Mariestrand.gif_5f5864b1e7dd7.gif?generation=1599628466074262&alt=media',
        'author': {
          'userId': 286,
          'firstName': 'Sonja',
          'lastName': 'Shahi'
        },
        'lastModified': {
          'date': '2020-09-09 07:14:26.000000',
          'timezone_type': 3,
          'timezone': 'Europe/Stockholm'
        },
        'type': 'image/gif'
      },
      {
        'id': 20,
        'name': 'Karta boendeparkering - Mariestrand.jpg',
        'size': 66092,
        'url': 'https://www.googleapis.com/download/storage/v1/b/user-content-master/o/Karta%20boendeparkering%20-%20Mariestrand.jpg_5f586636721b0.jpeg?generation=1599628854578575&alt=media',
        'author': {
          'userId': 286,
          'firstName': 'Sonja',
          'lastName': 'Shahi'
        },
        'lastModified': {
          'date': '2020-09-09 07:20:54.000000',
          'timezone_type': 3,
          'timezone': 'Europe/Stockholm'
        },
        'type': 'image/jpeg'
      },
      {
        'id': 21,
        'name': 'Karta - Möjligheter för sopsortering.jpg',
        'size': 259764,
        'url': 'https://www.googleapis.com/download/storage/v1/b/user-content-master/o/Karta%20-%20Mo%CC%88jligheter%20fo%CC%88r%20sopsortering.jpg_5f5866d0e79da.jpeg?generation=1599629009117889&alt=media',
        'author': {
          'userId': 286,
          'firstName': 'Sonja',
          'lastName': 'Shahi'
        },
        'lastModified': {
          'date': '2020-09-09 07:23:29.000000',
          'timezone_type': 3,
          'timezone': 'Europe/Stockholm'
        },
        'type': 'image/jpeg'
      }
    ];
  }

  deleteFile(file) {
    return this.neighborhoodService.deleteFile(file);
  }

  uploadFile(file) {
    return this.neighborhoodService.uploadFile(file);
  }
}

angular.module('ll')
  .component('neighborhoodFiles', {
    template: ' <file-list files="vm.files" on-file-delete="vm.deleteFile(file)" on-file-select="vm.uploadFile(file)" enable-edit="vm.canEdit"></file-list>',
    controller: NeighborhoodFilesController,
    controllerAs: 'vm',
    bindings: {
      canEdit: '<',
      // files: '<' // TODO: enable
    }
  })
  .config(createRoute({
    name: 'app.neighborhood.files',
    url: '/files',
    resolve: {
      canEdit: (SessionService) => SessionService.getUser().isNeighborhoodAdmin,
      // files: (neighborhoodService) => neighborhoodService.getNeighborhoodFiles() // TODO: create and enable
    },
    views: {
      neighborhood: 'neighborhoodFiles'
    }
  }));

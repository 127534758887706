require('./advertisement/feedpost-advertisement.component');
require('./alert/feedpost-alert.component');
require('./event/feedpost-event.component');
require('./feedbackpost');
require('./poll/feedpost-poll.component');
require('./post');
require('./feedpost.component');
require('./html-post');
require('./feedpost-header.component');
require('./feedpost-status.component');

import angular from 'angular';
import {createRoute} from '../../core/config';

class HtmlPostPageController {
  constructor($state, TrackingService) {
    this.$state = $state;
    this.trackingService = TrackingService;
  }

  $onInit() {
    if (this.post) {
      this.trackingService.track('feed.post.htmlPost', {postId: this.post.postId, subject: this.post.subject});
    }
  }

  onDelete(postId) {
    this.$state.go('app.neighborhood');
  }
}

angular.module('ll')
  .component('htmlPostPage', {
    template: require('./html-post-page.html'),
    controller: HtmlPostPageController,
    controllerAs: 'vm',
    bindings: {
      post: '<'
    }
  })
  .config(createRoute({
    name: 'app.htmlPostPageNew',
    component: 'htmlPostPage',
    url: '/htmlpost'
  }))
  .config(createRoute({
    name: 'app.htmlPostPage',
    component: 'htmlPostPage',
    url: '/htmlpost/:postId',
    resolve: {
      post: ($transition$, PostService, ToastMessageService, $state, $translate) => PostService.getPost($transition$.params().postId).then((post) => {
        return post;
      }, (err) => {
        ToastMessageService.showMessage($translate.instant('ERROR_MESSAGE.POST_NOT_ACCESSIBLE'));
        $state.go('app.neighborhood');
      })
    }
  }));

import angular from 'angular';

class FeedController {
  constructor($q, FeedPostComposerDialogService, SessionService, $mdMedia, FeedService, $mdBottomSheet, $state, $translate) {
    this.feedPostComposerDialogService = FeedPostComposerDialogService;
    this.sessionService = SessionService;
    this.feedService = FeedService;
    this.$mdBottomSheet = $mdBottomSheet;
    this.$state = $state;
    this.$q = $q;
    this.$translate = $translate;
  }

  $onInit() {
    this.posts = [];
    this.page = 0;
    this.pageSize = 10;
    this.loading = false;
    this.isFeedEnd = false;
    this.currentUser = this.sessionService.getUser();
    this.postCategories = [];
    this.categoryId = Number(this.categoryId) || null;
    this.readOnly = this.readOnly || false;
    this.disableComposer = this.disableComposer || false;
    this.showSfa = this.showSfa != null ? this.showSfa : true;
    this.level = this.level || null;
    if (this.readOnly) {
      this.disableComposer = true;
    }
    this.loadFeed();
    this.feedService.getPostCategories().then(categories => this.postCategories = categories.slice())
      .then(() => this.addAllPostsCategory())
      .then(() => this.translateNames(this.postCategories))
      .then(() => this.toggleSelectedCategory());
  }

  translateNames(postCategories) {
    this.postCategories.map(c => c.visibleName = this.$translate.instant(c.name));
  }

  toggleSelectedCategory() {
    this.selectedCategory = this.postCategories.find(cat => this.currentCategory(cat));
  }

  addAllPostsCategory() {
    this.postCategories.unshift({
      id: null,
      name: 'CATEGORY.ALL',
      icon: '',
      sortOrder: -1
    });
  }

  currentCategory(category) {
    return category.id === this.categoryId;
  }

  showCategoriesMenu() {
    let title = this.$translate.instant('FEED.SELECT_POST_CATEGORY');
    let items = this.postCategories;
    let selectedItemFn = item => item.id === this.categoryId;
    this.showBottomSheet(title, items, selectedItemFn)
      .then(opts => {
        document.body.style.touchAction = 'auto';
        return this.doFilter({categoryId: opts.id});
      }).catch(() => {
        document.body.style.touchAction = 'auto';
      });
  }

  selectCategory(cat) {
    this.doFilter({categoryId: cat.id});
  }

  doFilter(opts) {
    let params = this.$state.params;
    this.$state.go(this.$state.current, {...params, ...opts});
  }

  showBottomSheet(title, items, selectedItemFn) {
    return this.$mdBottomSheet.show({
      template: `
      <md-bottom-sheet class="md-list md-has-header">
        <div layout="row" layout-align="space-between center">
            <md-subheader style="font-weight: 500" ng-cloak>{{vm.title}}</md-subheader>
            <a ng-click="vm.close()"><i class="far fa-times" style="font-size: 20px; cursor: pointer"></i></a>
        </div>
        <md-list ng-cloak>
          <md-list-item ng-repeat="item in vm.items | orderBy:'sortOrder'">
            <md-button ng-click="vm.select(item)" md-autofocus="vm.selectedItemFn(item)">
              <md-icon class="{{'fas fa-'+item.icon}}" style="vertical-align: unset"></md-icon>
              <span class="md-inline-list-icon-label">{{ item.visibleName }}</span>
            </md-button>
          </md-list-item>
        </md-list>
      </md-bottom-sheet>
      `,
      controller: BottomSheetController,
      controllerAs: 'vm',
      locals: {title, items, selectedItemFn}
    });
  }

  shouldStopLoadingFeedPosts() {
    return this.loading || this.isFeedEnd;
  }

  loadFeed() {
    if (this.shouldStopLoadingFeedPosts()) return;

    this.loading = true;

    let feedPromise = this.feedPosts ? this.loadUsingFeedData() : this.loadUsingCallback();
    feedPromise.then(posts => this.appendPosts(posts))
      .then(posts => this.incrementPageCount(posts))
      .then(posts => this.checkIfEndOfFeed(posts))
      .then(posts => this.disableLoading());
  }

  loadUsingCallback() {
    return this.onFeedBottom({page: this.page, pageSize: this.pageSize});
  }

  loadUsingFeedData() {
    let deferred = this.$q.defer();
    deferred.resolve(this.feedPosts);
    return deferred.promise;
  }

  appendPosts(posts) {
    this.posts = this.posts.concat(posts);
    return posts;
  }

  incrementPageCount(posts) {
    if (posts.length !== 0) this.page++;
    return posts;
  }

  checkIfEndOfFeed(posts) {
    this.isFeedEnd = posts.length < this.pageSize;
    return posts;
  }

  disableLoading() {
    this.loading = false;
  }

  removeFeedPost(postId) {
    this.posts = this.posts.filter(p => p.postId !== postId);
  }
}

class BottomSheetController {
  constructor($mdBottomSheet, title, items, selectedItemFn) {
    this.$mdBottomSheet = $mdBottomSheet;
    this.title = title;
    this.items = items;
    this.selectedItemFn = selectedItemFn;
  }

  select(item) {
    this.$mdBottomSheet.hide(item);
  }

  close() {
    this.$mdBottomSheet.cancel();
  }
}

angular.module('ll').component('feed', {
  template: require('./feed.component.html'),
  controller: FeedController,
  controllerAs: 'vm',
  bindings: {
    onFeedBottom: '&',
    disableComposer: '<',
    feedPosts: '<',
    readOnly: '<',
    categoryId: '<',
    level: '<',
    showSfa: '<'
  }
});

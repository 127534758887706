import angular from 'angular';

class UploadImageDialogController {
  constructor($mdDialog, imageUrl, imageOptions) {
    this.$mdDialog = $mdDialog;
    this.imageOptions = imageOptions;
    this.image = {url: imageUrl};
  }

  close() {
    this.$mdDialog.cancel();
  }

  setImage(file) {
    this.image = file;
  }

  save() {
    this.$mdDialog.hide({action: 'save', image: this.image});
  }

  remove() {
    this.$mdDialog.hide({action: 'remove', image: this.image});
  }
}

class ImageUploadService {
  constructor($mdDialog) {
    this.$mdDialog = $mdDialog;
  }

  openDialog(event, imageUrl, imageOptions) {
    return this.$mdDialog.show({
      controller: UploadImageDialogController,
      controllerAs: 'vm',
      template: require('./imageupload.html'),
      targetEvent: event,
      clickOutsideToClose:true,
      locals: {imageUrl, imageOptions}
    });
  }
}

angular.module('ll').service('ImageUploadService', ImageUploadService);

import angular from 'angular';

angular.module('ll').service('ProfileService', ProfileService);

function ProfileService($http, EnvironmentSettings, LoggingService) {
  let baseUrl = EnvironmentSettings.baseUrl;
  let labradorUrl = EnvironmentSettings.labradorUrl;

  return {
    changeProfileImage,
    changeBackgroundImage
  };

  function changeProfileImage(image) {
    if (image == null) {
      return resetImage();
    } else {
      return uploadImage(`${labradorUrl}/api/users/me/avatar`, image);
    }
  }

  function changeBackgroundImage(userId, image) {
    return uploadImage(`${baseUrl}/api/user/${userId}/edit/backgroundimage`, image);
  }

  function resetImage() {
    return $http.delete(`${labradorUrl}/api/users/me/avatar`).then(resp => resp.data).catch(resp => null);
  }

  function uploadImage(url, image) {
    let data = new FormData();
    data.append('image', image);
    let config = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };
    return $http.post(url, data, config).then(resp => resp.data).catch(resp => LoggingService.error('Error uploading image', resp));
  }
}

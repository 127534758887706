import angular from 'angular';

const SCREEN_SHIFT_MIN_PERCENTAGE = 15;

class ExpandableVisualizationController {
  constructor($element, $interval, $timeout, $scope, CustomPopupService) {
    this.$scope = $scope;
    this.$element = $element;
    this.$interval = $interval;
    this.$timeout = $timeout;
    this.customPopupService = CustomPopupService;
    this.expanded = false;
    this.moveScreenShift = 0;
    this.moveScreenDirection = 0;
    this.preventScreenDrag = false;
  }

  $onInit() {
    this.loading = true;
    this.activeScreenIndex = 0;

    this.expanded = this.config.expanded || false;

    this.$scope.$watch(() => this.config, () => {
      this.reinit();
    });
  }

  reinit() {
    if (!this.screens) {
      this.screens = [...this.config.screens.map(screen => { return {...screen}; })];
    } else {
      this.config.screens.forEach((screen, i) => {
        for (let key in screen) {
          this.screens[i][key] = screen[key];
        }
      });
    }

    if (this.expanded) {
      this.setCalculateScreensAvailableHeightInterval();
    }

    window.addEventListener('resize', () => {
      this.calculateScreensAvailableHeight();
      this.$scope.$apply();
    });

    this.calculateScreenShifts();
    this.$timeout(() => this.loading = false, 300);
  }

  expandCollapse() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.setCalculateScreensAvailableHeightInterval();
    }
  }

  isNoWayToMove() {
    return this.moveScreenDirection < 0 && this.activeScreenIndex === 0 ||
      this.moveScreenDirection > 0 && this.activeScreenIndex === this.screens.length - 1;
  }

  startMovingScreen(event) {
    if (this.preventScreenDrag || event.direction !== 4 && event.direction !== 2) {
      return;
    }
    this.dragging = true;

    this.moveScreenDirection = event.deltaX < 0 ? 1 : -1;

    if (this.isNoWayToMove()) {
      return;
    }

    this.moveScreenShift = event.deltaX / event.element[0].offsetWidth * 100;
    this.moveScreenShift = this.moveScreenShift > 100 ? 100 : this.moveScreenShift;
    this.moveScreenShift = this.moveScreenShift < -100 ? -100 : this.moveScreenShift;

    this.calculateScreenShifts();
    this.setCalculateScreensAvailableHeightInterval();
  }

  changeScreen(event) {
    this.dragging = false;
    if (this.preventScreenDrag || this.isNoWayToMove()) {
      return;
    }

    this.$timeout(() => {
      const shiftMinPercentageWithSwipeVelocityFactor = SCREEN_SHIFT_MIN_PERCENTAGE * (1 - Math.sqrt(Math.pow(event.velocityX, 2)));
      const shouldFinishSwipe =
        this.moveScreenShift >= shiftMinPercentageWithSwipeVelocityFactor || this.moveScreenShift <= -shiftMinPercentageWithSwipeVelocityFactor;

      if (shouldFinishSwipe) {
        this.activeScreenIndex += this.moveScreenDirection;
      }

      this.moveScreenShift = 0;

      this.calculateScreenShifts();
    }, 10);
  }

  calculateScreenShifts() {
    for (let i = 0; i < this.screens.length; i++) {
      this.screens[i].shift = (i - this.activeScreenIndex) * 100 + this.moveScreenShift;
    }
  }

  calculateScreensAvailableHeight() {
    for (let i = 0; i < this.screens.length; i++) {
      const screenElement = this.$element[0].querySelector(`.expandable-visualization__screen[data-index="${i}"]`);
      if (!screenElement) {
        return;
      }
      const screenElementFooter = screenElement.querySelector('.expandable-visualization__footer');

      this.screens[i].availableHeight = screenElementFooter ? screenElement.clientHeight - screenElementFooter.clientHeight : screenElement.clientHeight;
    }
    return true;
  }

  setCalculateScreensAvailableHeightInterval() {
    const calculateAvailableHeightInterval = this.$interval(() => {
      if (this.calculateScreensAvailableHeight()) {
        this.$interval.cancel(calculateAvailableHeightInterval);
      }
    }, 100);
  }

  showQuestionMarkPopup() {
    this.config.popupShown = true;
    this.customPopupService.lock();
  }
}

angular.module('ll').component('llExpandableVisualization', {
  template: require('./expandable-visualization.component.html'),
  controller: ExpandableVisualizationController,
  controllerAs: 'vm',
  bindings: {
    config: '<'
  },
  transclude: {
    'columns0top': '?columns0top',
    'chart0top': '?chart0top',
    'lanes0top': '?lanes0top',
    'plain0top': '?plain0top',
    'columns1top': '?columns1top',
    'chart1top': '?chart1top',
    'lanes1top': '?lanes1top',
    'plain1top': '?plain1top',
    'columns2top': '?columns2top',
    'chart2top': '?chart2top',
    'lanes2top': '?lanes2top',
    'plain2top': '?plain2top',
    'columns3top': '?columns3top',
    'chart3top': '?chart3top',
    'lanes3top': '?lanes3top',
    'plain3top': '?plain3top',
    'columns4top': '?columns4top',
    'chart4top': '?chart4top',
    'lanes4top': '?lanes4top',
    'plain4top': '?plain4top',
    'columns0bottom': '?columns0bottom',
    'chart0bottom': '?chart0bottom',
    'lanes0bottom': '?lanes0bottom',
    'plain0bottom': '?plain0bottom',
    'columns1bottom': '?columns1bottom',
    'chart1bottom': '?chart1bottom',
    'lanes1bottom': '?lanes1bottom',
    'plain1bottom': '?plain1bottom',
    'columns2bottom': '?columns2bottom',
    'chart2bottom': '?chart2bottom',
    'lanes2bottom': '?lanes2bottom',
    'plain2bottom': '?plain2bottom',
    'columns3bottom': '?columns3bottom',
    'chart3bottom': '?chart3bottom',
    'lanes3bottom': '?lanes3bottom',
    'plain3bottom': '?plain3bottom',
    'columns4bottom': '?columns4bottom',
    'chart4bottom': '?chart4bottom',
    'lanes4bottom': '?lanes4bottom',
    'plain4bottom': '?plain4bottom',
    'questionMarkPopup': '?questionMarkPopup',
  }
});

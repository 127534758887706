import angular from 'angular';

class CommentsController {
  constructor(SessionService, PostService, $scope, moment) {
    this.sessionService = SessionService;
    this.postService = PostService;
    this.$scope = $scope;
    this.moment = moment;
  }

  $onInit() {
    this.showComments = this.showComments || false;
    this.currentUser = this.sessionService.getUser();
    this.feedPost.comments = this.feedPost.comments || [];
    this.isDisablePostComment = true;
    this.pageSize = 5;
    this.newComment = '';
    this.setLoadPreviousCommentsVisibility();
    this.setPreviousCommentsCount();
  }

  $onChanges(changes) {
    if (changes.toggleVisibility) {
      this.showComments = changes.toggleVisibility.currentValue;
    }
  }

  getOffset() {
    return this.feedPost.comments.length;
  }

  setLoadPreviousCommentsVisibility() {
    this.showLoadPreviousComments = this.feedPost.comments.length < this.feedPost.totalComments;
  }

  loadComments() {
    this.postService.getComments(this.feedPost.postId, this.getOffset(), this.pageSize)
      .then(comments => this.feedPost.comments = [].concat(this.feedPost.comments, comments))
      .then(() => this.setLoadPreviousCommentsVisibility())
      .then(() => this.setPreviousCommentsCount());
  }

  setPreviousCommentsCount() {
    this.previousCommentsCount = this.feedPost.totalComments - this.getOffset();
  }

  postComment() {
    this.$scope.newCommentForm.$setSubmitted();
    if (this.$scope.newCommentForm.$invalid) {
      return;
    }
    this.postService.comment(this.newComment, this.feedPost.postId)
      .then(c => this.appendComment(c))
      .then(c => this.onCreate({comment:c}))
      .then(() => this.resetForm())
      .then(() => this.increaseTotalCommentsCount());
  }

  increaseTotalCommentsCount() {
    this.feedPost.totalComments++;
  }

  decreaseTotalCommentsCount() {
    this.feedPost.totalComments--;
  }

  appendComment(comment) {
    this.feedPost.comments.unshift(comment);
    return comment;
  }

  resetForm() {
    this.newComment = '';
    this.$scope.newCommentForm.$setPristine();
    this.$scope.newCommentForm.$setUntouched();
  }

  removeComment(comment) {
    this.feedPost.comments = this.feedPost.comments.filter(c => c.commentId !== comment.commentId);
    this.decreaseTotalCommentsCount();
  }
}

angular.module('ll').component('comments', {
  template: require('./comments.component.html'),
  controller: CommentsController,
  controllerAs: 'vm',
  bindings: {
    feedPost: '<',
    onCreate: '&',
    onDelete: '&',
    toggleVisibility: '<'
  }
});

import angular from 'angular';
import {createRoute} from '../../core/config';

class NeighborhoodController {
  constructor(ToolbarService, SessionService) {
    this.toolbarService = ToolbarService;
    this.sessionService = SessionService;
    this.user = null;
  }

  $onInit() {
    this.user = this.sessionService.getUser();
    this.toolbarService.setPageTitle(this.user.neighborhood.name);
    this.showAboutNeighborhood = this.user.hasEnabledFeature('neighborhood_about');
    this.showNeighborhoodFiles = this.user.hasEnabledFeature('neighborhood_files');
  }
}

angular.module('ll')
  .component('neighborhood', {
    template: require('./neighborhood.html'),
    controller: NeighborhoodController,
    controllerAs: 'vm'
  })
  .config(createRoute({
    name: 'app.neighborhood',
    component: 'neighborhood',
    url: '/neighborhood',
    redirectTo: 'app.neighborhood.feed'
  }));

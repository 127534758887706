import angular from 'angular';
import {createRoute} from '../../core/config';

class PropertyBoardController {
  constructor(FeedService) {
    this.feedService = FeedService;
  }

  loadPosts(page, pageSize, onlyBoardMessages) {
    return this.feedService.getPropertyFeed(page, pageSize, this.categoryId, onlyBoardMessages);
  }
}

angular.module('ll')
  .component('propertyBoard', {
    template: '<feed on-feed-bottom="vm.loadPosts(page, pageSize, true)" category-id="vm.categoryId" disable-composer="true"></feed>',
    controller: PropertyBoardController,
    controllerAs: 'vm',
    bindings: {
      property: '<',
      categoryId: '<'
    }
  })
  .config(createRoute({
    name: 'app.property.board',
    url: '/board',
    resolve: {
      categoryId: ($transition$) => $transition$.params().categoryId,
      property: (PropertyService) => PropertyService.getPropertyDetails()
    },
    views: {
      property: 'propertyBoard'
    }
  }));

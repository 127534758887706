import angular from 'angular';

class TimeSelectorController {
  constructor(moment, $translate) {
    this.moment = moment;
    this.$translate = $translate;
  }

  $onInit() {
    this.selectedTime = this.selectedTime ? this.moment(this.selectedTime) : this.moment();
    this.hours = Array(24).fill().map((_, i) => `${i}`.padStart(2, '0'));
    this.minutes = Array(60).fill().map((_, i) => `${i}`.padStart(2, '0'));
    this.label = this.label || this.$translate.instant('GENERAL.TIME');
    this.setDefaultTime();
    this.triggerCallback();
  }

  setDefaultTime() {
    if (!this.editMode) {
      this.selectedHour = `${this.selectedTime.add(1, 'h').hour()}`.padStart(2, '0');
      this.selectedMinute = '00';
    } else {
      this.selectedHour = `${this.selectedTime.hour()}`.padStart(2, '0');
      this.selectedMinute = `${this.selectedTime.minute()}`.padStart(2, '0');
    }
  }

  onHourSelected() {
    if (!this.selectedMinute)
      return;

    this.triggerCallback();
  }

  onMinuteSelected() {
    if (!this.selectedHour)
      return;

    this.triggerCallback();
  }

  triggerCallback() {
    this.onTimeSet({hour: this.selectedHour, minute: this.selectedMinute});
  }
}

angular.module('ll').component('llTimeSelector', {
  template: require('./time-selector-component.html'),
  controller: TimeSelectorController,
  controllerAs: 'vm',
  bindings: {
    editMode: '<',
    selectedTime: '<',
    label: '<',
    onTimeSet: '&'
  }
});

import angular from 'angular';

class ProfileHeaderController {
  constructor($translate, ToastMessageService, ImageUploadService, ProfileService) {
    this.translate = $translate;
    this.profileService = ProfileService;
    this.toastMessageService = ToastMessageService;
    this.imageUploadService = ImageUploadService;
  }

  $onInit() {
    this.canEdit = this.canEdit || false;
    this.profilePicture = this.profilePicture || '';
    this.backgroundPicture = this.backgroundPicture || '';
    this.userId = this.userId || null;
  }

  $onChanges(changes) {
    this.canEdit = changes.canEdit ? changes.canEdit.currentValue : this.canEdit;
    if (changes.profilePicture.currentValue){
      this.profilePicture = changes.profilePicture.currentValue;
      this.setProfilePicture();
    }
    if (changes.backgroundPicture.currentValue){
      this.backgroundPicture = changes.backgroundPicture.currentValue;
      this.setBackgroundPicture();
    }
  }

  getImageBackgroundStyle(imageUrl) {
    return {'background-image': `url('${imageUrl}')`, 'background-size': 'cover', 'background-position': 'center'};
  }

  setProfilePicture() {
    let style = angular.isUndefined( this.profilePicture.original) ? this.getImageBackgroundStyle( this.profilePicture) : this.getImageBackgroundStyle( this.profilePicture.original);
    //let style = this.getImageBackgroundStyle(this.profilePicture);
    this.profilePictureStyle = {...style, 'height': '90px', 'width': '90px'};
  }

  setBackgroundPicture() {
    if (! this.backgroundPicture) {
      this.backgroundPicture = {original: require('../../images/leaves.png')};
    }
    this.backgroundPictureStyle = angular.isUndefined( this.backgroundPicture.original) ? this.getImageBackgroundStyle( this.backgroundPicture) : this.getImageBackgroundStyle( this.backgroundPicture.original);
  }

  openChangeProfilePictureDialog(e) {
    this.imageUploadService.openDialog(e, this.checkImageUrl(this.profilePicture), {})
      .then(result => this.updateProfilePicture(result))
      .then(resp => {
        this.profilePicture = resp.s150;
        this.setProfilePicture();
        return resp.s150;
      })
      .then(() => this.toastMessageService.showMessage(this.translate.instant('PROFILE.IMAGE_UPDATED')))
      .catch(() => this.toastMessageService.showMessage(this.translate.instant('PROFILE.IMAGE_UPLOAD_ERROR')));
  }

  openChangeBackgroundPictureDialog(e) {
    this.imageUploadService.openDialog(e, this.checkImageUrl(this.backgroundPicture), {})
      .then(result => this.updateBackgroundPicture(result))
      .then(resp => {
        this.backgroundPicture = resp;
        this.setBackgroundPicture();
        return resp.original;
      })
      .then(() => this.toastMessageService.showMessage(this.translate.instant('PROFILE.IMAGE_UPDATED')))
      .catch(() => this.toastMessageService.showMessage(this.translate.instant('PROFILE.IMAGE_UPLOAD_ERROR')));
  }

  updateProfilePicture(result) {
    let file = result.action === 'save' ? result.image.blob : null;
    return this.profileService.changeProfileImage(this.userId, file);
  }

  updateBackgroundPicture(result) {
    let file = result.action === 'save' ? result.image.blob : null;
    return this.profileService.changeBackgroundImage(this.userId, file);
  }

  checkImageUrl(image){
    if (image){
      return angular.isUndefined(image.original) ? image : image.original;
    }
    return '';
  }

}

angular.module('ll').component('profileheader', {
  template: require('./profileheader.component.html'),
  controller: ProfileHeaderController,
  controllerAs: 'vm',
  bindings: {
    canEdit: '<',
    profilePicture: '<',
    backgroundPicture: '<',
    userId: '<'
  },
  transclude: true
});

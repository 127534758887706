import angular from 'angular';

class FeedPostFeedbackPostComponentController {
  constructor(DateService, moment, $state, $translate) {
    this.dateService = DateService;
    this.moment = moment;
    this.$state = $state;
    this.$translate = $translate;
  }

  $onInit() {
    this.isEditing = false;
    let data = this.feedback.data;
    this.formattedDate = this.getFormattedDate(data.dateFrom, data.period);
    this.isHouseholdLevel = data.level === 'household';
    this.showTopSection = !this.isHouseholdLevel;
    this.showBottomSection = !this.isHouseholdLevel;
    this.showFeedpostStats = !this.isHouseholdLevel;
  }

  getFormattedDate(dateAsString, period) {
    let formattedDate = period === 'week' ? this.$translate.instant('ENERGY.PERIOD.WEEK') + ' ' + this.moment(dateAsString).isoWeek() + ' (' : '';
    formattedDate += this.dateService.getFormattedDateForPeriod(dateAsString, period);
    formattedDate += period === 'week' ? ')' : '';
    return formattedDate;
  }

  goToDetails() {
    this.$state.go('app.energyConsumptionDetails', {period: 'month', energyConsumptionData: this.feedback.data});
  }

  toggleComments() {
    this.showComments = !this.showComments;
  }

  updateLikes(likes) {
    this.feedback.likes = likes;
  }
}

angular.module('ll').component('feedpostFeedbackPost', {
  template: require('./feedpost-feedbackpost.component.html'),
  controller: FeedPostFeedbackPostComponentController,
  controllerAs: 'vm',
  bindings: {
    feedback: '<',
    isInFeed: '<'
  }
});

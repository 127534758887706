import angular from 'angular';

class ScoringScaleController {
  $onInit() {
    this.icon = this.icon || 'favorite';
    this.size = this.size || '14px';
    this.score = this.score || 0;
    this.roundedScore = Math.round(this.score);
    this.color = this.color || 'red';
  }
}

angular.module('ll').component('scoringScale', {
  template: require('./scoring-scale.component.html'),
  controller: ScoringScaleController,
  controllerAs: 'vm',
  bindings: {
    icon: '<',
    size: '<',
    score: '<',
    color: '<'
  }
});

import angular from 'angular';

class FeedPostHeaderComponentController {

  constructor($translate, ContentFeedbackService, $scope) {
    this.$translate = $translate;
    this.contentFeedbackService = ContentFeedbackService;
    this.$scope = $scope;
  }

  $onInit() {
    this.disableEdit = this.disableEdit || false;
    this.author = this.post.getAuthor();
    this.postedTo = this.post.postedTo;
    let postedIn = this.post.isEvent() ? 'MENU.EVENTS' : this.post.postedIn;
    this.category = this.$translate.instant(postedIn);
    this.isPostAuthor = this.post.isAuthor;
    this.isBoardMessage = this.post.isBoardMessage();
  }

  toggleEditPost(event) {
    this.onEdit({event});
  }

  deletePost(event) {
    this.onDelete({event});
  }

  flagPost(event) {
    this.contentFeedbackService.showReportContentDialog('feedpost', this.post.postId, event);
  }
}

angular.module('ll').component('feedpostHeader', {
  template: require('./feedpost-header.component.html'),
  controller: FeedPostHeaderComponentController,
  controllerAs: 'vm',
  bindings: {
    post: '<',
    onEdit: '&',
    onDelete: '&',
    disableEdit: '<'
  }
});

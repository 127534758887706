import angular from 'angular';

class UserService {

  constructor($http, SessionService, $q, EnvironmentSettings, UserFactory, LoggingService) {
    this.$http = $http;
    this.sessionService = SessionService;
    this.$q = $q;
    this.baseUrl = EnvironmentSettings.baseUrl;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
    this.userFactory = UserFactory;
    this.loggingService = LoggingService;
  }

  muteUser(userId) {
    return this.$http.post(`${this.labradorUrl}/api/users/mute/${userId}`, {})
      .then(resp => resp.data);
  }

  unmuteUser(userId) {
    return this.$http.post(`${this.labradorUrl}/api/users/unmute/${userId}`, {})
      .then(resp => resp.data);
  }

  getUserProfile(id) {
    return this.$http.get(`${this.labradorUrl}/api/users/profile/${id}`).then(resp => resp.data);
  }

  /* TODO: not used anymore, remove? */
  getLoggedInUser() {
    let deferred = this.$q.defer();
    let user = this.sessionService.getUser();

    if (user) deferred.resolve(user);
    else this.fetchLoggedInUser().then(deferred.resolve);

    return deferred.promise;
  }

  fetchLoggedInUser() {
    return this.$http.get(`${this.labradorUrl}/api/users/me`)
      .then(resp => this.sessionService.setUser(this.userFactory.createUser(resp.data)), resp => this.loggingService.error('Error fetching logged in user', resp));
  }

  getNeighbours(collection, collectionId, userId) {
    let opts = {
      params: {
        collectionType: collection,
        collectionId,
        userId
      }
    };
    return this.$http.get(`${this.labradorUrl}/api/users/neighbors/${collection}`, opts)
      .then(resp => resp.data.map(u => this.userFactory.createUser(u)), resp => []);
  }

  editAbout(about) {
    let data = {about};
    return this.$http.put(`${this.labradorUrl}/api/users/me/about`, data);
  }

  editAccountDetails(accountDetails) {
    let data = {
      language: accountDetails.language,
      deviceId: this.sessionService.getDeviceId()
    };
    return this.$http.put(`${this.labradorUrl}/api/users/me/language`, data);
  }

  editUserInformation(userId, userInfo) {
    let data = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      phoneNumber: userInfo.contactInformation.phoneNumber,
      mobileNumber: userInfo.contactInformation.mobileNumber,
      about: userInfo.about,
      bestWithNeighbourhood: userInfo.bestWithNeighbourhood,
      language: userInfo.language
    };
    return this.$http.put(`${this.baseUrl}/api/user/${userId}`, data)
      .then(resp => resp.data, resp => null);
  }

  editUserEmail(userid, email, password) {
    let deferred = this.$q.defer();
    this.$http.put(`${this.labradorUrl}/api/users/me/email`, {email, password})
      .then(resp => deferred.resolve(resp.data), resp => {
        switch (resp.status) {
          case 400:
            deferred.reject(resp.data);
            break;
          case 422:
            deferred.reject(resp.data);
            break;
          case 403:
          case 500:
          default:
            deferred.reject('server_error');
            break;
        }
      });
    return deferred.promise;
  }

  editUserPassword(currentPassword, newPassword) {
    let data = {currentPassword, newPassword};
    return this.$http.put(`${this.labradorUrl}/api/users/me/password`, data);
  }

  getNotificationPreferences() {
    return this.$http.get(`${this.labradorUrl}/api/notifications/preferences`)
      .then(resp => resp.data, resp => null);
  }

  saveNotificationPreferences(notificationSettings) {
    return this.$http.post(`${this.labradorUrl}/api/notifications/preferences`, notificationSettings)
      .then(resp => resp.data, resp => null);
  }

  registerInterest(data) {
    return this.$http.post(`${this.labradorUrl}/api/registration/interest`, data);
  }

  deleteAccount(password) {
    return this.$http.post(`${this.baseUrl}/api/user/delete`, {password});
  }
}

angular.module('ll').service('UserService', UserService);

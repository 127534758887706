import angular from 'angular';

angular.module('ll')
  .factory('InterestService', InterestService);
InterestService.$inject = ['$http', 'EnvironmentSettings', 'LoggingService'];

function InterestService($http, EnvironmentSettings, LoggingService) {
  return {
    getInterests: function (userId) {
      return $http.get(`${EnvironmentSettings.baseUrl}/api/user/${userId}/interests`)
        .then(resp => resp.data, resp => LoggingService.caputreMessage('Error getting user interests', resp));
    },
    saveInterest: function (userId, name) {
      return $http.post(`${EnvironmentSettings.baseUrl}/api/user/${userId}/interest`, {name})
        .then(resp => resp.data, resp => LoggingService.captureMessage('Error saving user interests', resp));
    },
    deleteInterest: function (userId, interestId) {
      return $http.delete(`${EnvironmentSettings.baseUrl}/api/user/${userId}/interest/${interestId}`)
        .then(resp => resp.data, resp => LoggingService.captureMessage('Error deleting user tag', resp));
    }
  };
}

import angular from 'angular';
import {createRoute} from '../../core/config';

class PropertyAboutController {
  constructor(PropertyService) {
    this.propertyService = PropertyService;
  }

  saveDescription(description) {
    return this.propertyService.saveDescription(this.property.id, description);
  }

  saveContactInfo(contactInfo) {
    return this.propertyService.saveContactInfo(this.property.id, contactInfo);
  }
}

angular.module('ll')
  .component('propertyAbout', {
    template: require('./property-about.component.html'),
    controller: PropertyAboutController,
    controllerAs: 'vm',
    bindings:{
      canEdit: '<',
      property: '<'
    }
  })
  .config(createRoute({
    name: 'app.property.about',
    url: '/about',
    resolve: {
      canEdit: (SessionService) => SessionService.getUser().isPropertyAdmin(),
      property: (PropertyService) => PropertyService.getPropertyDetails()
    },
    views: {
      property: 'propertyAbout'
    }
  }));

import angular from 'angular';

class SurveyQuestionChoiceSingleChoiceController {

  constructor($translate) {
    this.$translate = $translate;
  }

  $onInit() {
    this.setInitialValue();
  }

  setInitialValue() {
    this.chosenQuestionChoiceId = null;
    if (Array.isArray(this.question.questionChoices) && this.question.questionChoices > 0) {
      this.chosenQuestionChoiceId = this.question.questionChoices[0].id;
    }
  }

  onChoiceChange() {
    this.hasUserSetValue = true;
    this.callCallback();
  }

  onTextChange() {
    this.hasUserSetValue = true;
    this.callCallback();
  }

  callCallback() {
    let chosenQuestionChoice = this.question.questionChoices.find(qc => { return qc.id === this.chosenQuestionChoiceId; });
    let text = chosenQuestionChoice && chosenQuestionChoice.text ? chosenQuestionChoice.text : null;
    this.outputValues({questionChoiceId: this.chosenQuestionChoiceId, value: 1, text: text});
  }
}

angular.module('ll').component('surveyQuestionChoiceSingleChoice', {
  template: require('./survey-question-choice-single-choice.component.html'),
  controller: SurveyQuestionChoiceSingleChoiceController,
  controllerAs: 'vm',
  bindings: {
    question: '<',
    outputValues: '&'
  }
});

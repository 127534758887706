import angular from 'angular';
import {createRoute} from '../../core/config';

class NewNeighborhoodDisclaimerController {

  constructor(SessionService, $state, $translate, ToolbarService, TrackingService) {
    this.sessionService = SessionService;
    this.$state = $state;
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.trackingService = TrackingService;

    this.registrationData = null;
    this.isEmailTaken = false;

    this.setPageTitle();
    this.loadRegistrationData();
  }

  $onInit() {
    this.trackingService.trackTime('New Neighborhood Disclaimer');
  }

  setPageTitle() {
    this.toolbarService.setPageTitle(this.$translate.instant('REGISTRATION.NEW_NEIGHBORHOOD.START_MY_NEIGHBORHOOD'));
  }

  loadRegistrationData() {
    this.registrationData = this.sessionService.getSession('registrationData');
    if (!this.registrationData) {
      this.$state.go('welcome');
    }
  }

  registerNewNeighborhood() {
    this.trackingService.track('New Neighborhood Disclaimer');
    this.$state.go('registration.newNeighborhood');
  }

  registerInterest() {
    this.trackingService.track('New Neighborhood Disclaimer');
    this.$state.go('registration.registerInterest');
  }
}

angular.module('ll')
  .component('newNeighborhoodDisclaimer', {
    template: require('./new-neighborhood-disclaimer.html'),
    controller: NewNeighborhoodDisclaimerController,
    controllerAs: 'vm'
  })
  .config(createRoute({
    name: 'registration.newNeighborhoodDisclaimer',
    component: 'newNeighborhoodDisclaimer',
    url: '/new-neighborhood-disclaimer',
  }));

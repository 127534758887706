import angular from 'angular';

class DeletePromptController {

  constructor($mdDialog, user) {
    this.$mdDialog = $mdDialog;
    this.user = user;
    this.password = null;
  }

  ok() {
    this.$mdDialog.hide(this.password);
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}

class DeleteAccountService {

  constructor($mdDialog, $translate, $state, SessionService, UserService, uiAlertService) {
    this.$mdDialog = $mdDialog;
    this.$translate = $translate;
    this.$state = $state;
    this.sessionService = SessionService;
    this.userService = UserService;
    this.uiAlertService = uiAlertService;
  }

  prompt(e) {
    this.$mdDialog.show({
      template: require('./delete-account.service.html'),
      controller: DeletePromptController,
      controllerAs: 'vm',
      parent: angular.element(document.body),
      resolve: {
        user: () => this.userService.fetchLoggedInUser()
      },
      targetEvent: e
    }).then(pwd => this.deleteAccount(pwd));
  }

  deleteAccount(pwd) {
    this.userService.deleteAccount(pwd)
      .then(() => this.$state.go('logout'))
      .catch(() => this.uiAlertService.showAlert(this.$translate.instant('USERSETTINGS.DELETE_ACCOUNT.INVALID_PASSWORD.TITLE'), this.$translate.instant('USERSETTINGS.DELETE_ACCOUNT.INVALID_PASSWORD.MESSAGE')));
  }
}

angular.module('ll').service('DeleteAccountService', DeleteAccountService);



import angular from 'angular';

class SfaService {

  constructor($http, EnvironmentSettings) {
    this.labradorUrl = EnvironmentSettings.labradorUrl;
    this.$http = $http;
  }

  loadSfa() {
    return this.$http({
      url: `${this.labradorUrl}/api/sfa`,
      method: 'GET'
    }).then(
      resp => {
        return resp.data;
      },
      resp => null
    );
  }
}

angular.module('ll').service('SfaService', SfaService);



import angular from 'angular';

class UserSimpleBadgeController {
  $onInit() {
    this.avatarSize = this.avatarSize || 50;
    this.displayLocation = !!this.location;
  }
}

angular.module('ll').component('userSimpleBadge', {
  template: require('./user-simple-badge.component.html'),
  controller: UserSimpleBadgeController,
  controllerAs: 'vm',
  bindings: {
    user: '<',
    avatarSize: '<',
    location: '<',
    reviewScore: '<'
  }
});

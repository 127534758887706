import angular from 'angular';

angular.module('ll')
  .filter('MomentFilter', MomentFilter);
MomentFilter.$inject = ['moment'];

function MomentFilter(moment) {
  return function (dateString, formatMask) {
    let date = moment(dateString);
    return formatMask ? date.format(formatMask) : date.fromNow();
  };
}

import angular from 'angular';
import {createRoute} from '../../core/config';

class PropertyFeedController {
  constructor(FeedService) {
    this.feedService = FeedService;
  }

  loadPosts(page, pageSize, onlyBoardMessages) {
    return this.feedService.getPropertyFeed(page, pageSize, this.categoryId, onlyBoardMessages);
  }
}

angular.module('ll')
  .component('propertyFeed', {
    template: '<feed level="brf" category-id="vm.categoryId" on-feed-bottom="vm.loadPosts(page, pageSize)"></feed>',
    controller: PropertyFeedController,
    controllerAs: 'vm',
    bindings: {
      categoryId: '<',
      propertyId: '<',
    }
  })
  .config(createRoute({
    name: 'app.property.feed',
    url: '/feed',
    resolve: {
      categoryId: ($transition$) => $transition$.params().categoryId,
      propertyId: (SessionService) => SessionService.getUser().property.id
    },
    views: {
      property: 'propertyFeed'
    }
  }));

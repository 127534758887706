/* eslint-disable no-console,no-undef */
import angular from 'angular';
import {createRoute} from '../../core/config';

class OneTimeRegionEditController {

  constructor(RegionRepository, ToastMessageService, $translate, $timeout, $scope) {
    this.regionRepository = RegionRepository;
    this.toastMessageService = ToastMessageService;
    this.$translate = $translate;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.tokenFound = false;
    this.saved = false;
    this.loading = true;
  }

  $onInit() {
    this.regionRepository.getRegionForToken(this.token)
      .then(r => {
        this.region = r;
        this.setCenter();
        this.tokenFound = true;
        this.loading = false;
      })
      .catch(() => {
        this.tokenFound = false;
        this.loading = false;
      });
  }

  setCenter() {
    this.center = {
      lat: this.region.center.lat,
      lng: this.region.center.lon
    };
  }

  onMapReset() {
    console.log('reset');
  }

  onPolygonComplete(polygonVertices) {
    this.$timeout(() => {
      const TWO_SQ_KM = 2000000;
      let location = new google.maps.LatLng(this.center);
      let poly = new google.maps.Polygon();
      poly.setPaths(polygonVertices);
      let isLocationInsidePolygon = google.maps.geometry.poly.containsLocation(location, poly);
      let isPolygonLessThan2SqKm = google.maps.geometry.spherical.computeArea(poly.getPath()) < TWO_SQ_KM;

      this.$scope.editNeighborhoodForm.polygon.$setValidity('outsidePolygon', isLocationInsidePolygon);
      this.$scope.editNeighborhoodForm.polygon.$setValidity('tooBigPolygon', isPolygonLessThan2SqKm);
      this.polygon = polygonVertices;
    });
  }

  submit() {
    this.regionRepository.saveRegionForToken(this.token, this.polygon)
      .then(() => this.saved = true)
      .catch(resp => {
        if (resp.data.messageCode === 'ERROR_REGION_OVERLAPPING_POLYGON') {
          this.toastMessageService.showMessage(this.$translate.instant('REGISTRATION.NEW_NEIGHBORHOOD.AREA_OVERLAP_ADJACENT_NEIGHBORS'));
        } else {
          this.toastMessageService.showMessage(this.$translate.instant('GENERAL.ERROR_SAVING'));
        }
      });
  }
}

angular.module('ll')
  .component('oneTimeRegionEdit', {
    template: require('./onetimeregionedit.html'),
    controller: OneTimeRegionEditController,
    controllerAs: 'vm',
    bindings: {
      token: '<'
    }
  })
  .config(createRoute({
    name: 'app.oneTimeRegionEdit',
    component: 'oneTimeRegionEdit',
    url: '/regionpolygon/edit/:token',
    params: {
      token: null
    },
    resolve: {
      token: $transition$ => $transition$.params().token
    }
  }));

import angular from 'angular';
import {createRoute} from '../../core/config';

class PropertyBoardController {
  constructor(PropertyService) {
    this.propertyService = PropertyService;
  }

  deleteFile(file) {
    return this.propertyService.deleteFile(file, this.propertyId);
  }

  uploadFile(file) {
    return this.propertyService.uploadFile(file, this.propertyId);
  }
}

angular.module('ll')
  .component('propertyFiles', {
    template: ' <file-list files="vm.files" on-file-delete="vm.deleteFile(file)" on-file-select="vm.uploadFile(file)" enable-edit="vm.canEdit"></file-list>',
    controller: PropertyBoardController,
    controllerAs: 'vm',
    bindings: {
      propertyId: '<',
      canEdit: '<',
      files: '<'
    }
  })
  .config(createRoute({
    name: 'app.property.files',
    url: '/files',
    resolve: {
      propertyId: (SessionService) => SessionService.getUser().property.id,
      canEdit: (SessionService) => SessionService.getUser().isPropertyAdmin(),
      files: (PropertyService) => PropertyService.getPropertyFiles()
    },
    views: {
      property: 'propertyFiles'
    }
  }));

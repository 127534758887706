import angular from 'angular';

angular.module('ll').component('facilityList', {
  template: require('./facility-list.component.html'),
  controller: FacilityListController,
  bindings: {
    facilities: '<',
    onFacilityDelete: '&',
    onFacilityCreateOrEdit: '&',
    enableEdit: '<',
    level: '<'
  }
});

FacilityListController.$inject = ['$mdDialog', '$translate', '$state', 'ToastMessageService'];

function FacilityListController($mdDialog, $translate, $state, ToastMessageService) {
  const vm = this;
  vm.deleteFacility = deleteFacility;
  vm.createFacility = createFacility;
  vm.editFacility = editFacility;
  vm.goToFacility = goToFacility;
  vm.$onInit = init;
  vm.$state = $state;

  function init() {
    vm.enableEdit = vm.enableEdit || false;
  }

  function deleteFacility(e, facility) {
    let confirm = $mdDialog.confirm()
      .title(facility.name)
      .textContent($translate.instant('FACILITY_LIST.DELETE_FACILITY_CONFIRM'))
      .targetEvent(e)
      .ok($translate.instant('BUTTON.YES'))
      .cancel($translate.instant('BUTTON.NO'));

    $mdDialog.show(confirm)
      .then(() => vm.onFacilityDelete({facility: facility}))
      .then(() => vm.facilities = vm.facilities.filter(f => f.id !== facility.id))
      .then(() => ToastMessageService.showMessage($translate.instant('FACILITY_LIST.DELETE_SUCCESS', {facilityName: facility.name})));
  }

  function editFacility(facilityId) {
    this.$state.go('app.facilityEdit', {level: vm.level, facilityId});
  }

  function createFacility() {
    this.$state.go('app.facilityCreate', {level: vm.level});
  }

  function goToFacility(facilityId) {
    this.$state.go('app.facility', {level: vm.level, facilityId});
  }
}


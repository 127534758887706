import angular from 'angular';

class QuickAccessBarController {
  constructor(FeedPostComposerDialogService, SessionService) {
    this.feedPostComposerDialogService = FeedPostComposerDialogService;
    this.sessionService = SessionService;
  }

  $onInit() {
    this.currentUser = this.sessionService.getUser();
    this.showPropertyLevel = this.currentUser.showPropertyLevel();
    this.showMarketplace = this.currentUser.hasEnabledFeature('marketplace');
    this.showSustainability = this.currentUser.hasEnabledFeature('sustainability');
  }

  createPost(event) {
    this.feedPostComposerDialogService.open(event, {currentUser: this.currentUser});
  }
}

angular.module('ll').component('quickAccessBar', {
  template: require('./quick-access-bar.component.html'),
  controller: QuickAccessBarController,
  controllerAs: 'vm'
});

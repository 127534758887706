import '@babel/polyfill';
import angular from 'angular';
import llCore from './core/index';

angular.module('ll', [llCore]);

require('./styles/main.scss');
require('./3rdparty/index');
require('./components/index');
require('./directives/index');
require('./filters/index');
require('./pages/index');
require('./services/index');

import angular from 'angular';

class PostComposerController {

  constructor(FeedService, $scope, ClassifiedsService, $state, SessionService, EnvironmentSettings, $translate) {
    this.feedService = FeedService;
    this.$scope = $scope;
    this.classifiedsService = ClassifiedsService;
    this.$state = $state;
    this.sessionService = SessionService;
    this.environmentSettings = EnvironmentSettings;
    this.$translate = $translate;
  }

  $onInit() {
    this.attachments = [];
    this.isClassifieds = false;
    this.isPropertyBoardMessage = false;

    let preSelectedTo = this.toOptions.find(opt => opt.isPreSelected === true);
    if (preSelectedTo) {
      this.to = preSelectedTo.value;
    }

    let preSelectedCategory = Object.values(this.categoryOptions).flat().find(opt => opt.isPreSelected === true);
    if (preSelectedCategory) {
      this.toCategory = preSelectedCategory;
      this.onPostCategoryChange();
    }

    this.isPropertyAdmin = this.sessionService.getUser().isPropertyAdmin();
    this.currency = this.environmentSettings.currency;
    this.priceText = this.$translate.instant('NEWPOST.ADVERTISEMENT.PRICE');
    this.perDayText = this.$translate.instant('CLASSIFIEDS.PRICE_PER_DAY');
  }

  $onChanges(objectChange) {
    if (!objectChange.command) return;

    let command = objectChange.command.currentValue;
    if (command.postType !== 'post') return;
    switch (command.name) {
      case 'save':
        return this.save();
      case 'attachFile':
        return this.setAttachment(command.value);
    }
  }

  onPostCategoryChange() {
    this.isClassifieds = this.toCategory.name === 'CATEGORY.RENT_OUT' || this.toCategory.name === 'CATEGORY.SALE_AND_FREE';
    if (this.isClassifieds) {
      this.classifiedsService.getAdvertisementTypes()
        .then(data => { this.adType = data.find(t => t.postCategory === this.toCategory.name);})
        .then(() => this.classifiedsService.getAdvertisementCategoriesByAdType(this.adType.id))
        .then(adCategories => this.adCategoryOptions = adCategories)
        .then(() => this.setPricePlaceholderText());
    }
  }

  setPricePlaceholderText() {
    this.pricePlaceholderText = `${this.priceText} ${this.currency}`;
    this.pricePlaceholderText += this.toCategory.name === 'CATEGORY.RENT_OUT' ? ` ${this.perDayText}` : '';
  }

  toggleFreeItem() {
    if (this.isFreeItem) {
      this.price = 0;
    }
  }

  setAttachment(file) {
    if (this.attachments.length <= 4) {
      this.attachments.push(file);
    }
  }

  removeAttachment(src) {
    this.attachments = this.attachments.filter(a => a.url !== src);
  }

  save() {
    this.$scope.postForm.$setSubmitted();

    if (this.$scope.postForm.$invalid){
      this.onSaveError({error:'invalid form'});
      return;
    }

    if (this.isClassifieds) {
      return this.saveClassifiedsPost();
    }

    return this.saveNormalPost()
      .catch(() => this.onSaveError({error: 'server error'}));
  }

  saveNormalPost() {
    return this.feedService.postPostToFeed(this.to.name, this.toCategory.id, this.title, this.description, this.isPropertyBoardMessage, this.attachments.map(a => a.blob))
      .then(p => {
        this.clearForm(this.$scope.postForm);
        this.onSave({feedPost: p});
      });
  }

  saveClassifiedsPost() {
    return this.classifiedsService.postBuySellAdvertisement(this.to.name, this.to.id, this.toCategory.id, this.toadCategory.id, this.title, this.description, this.adType.id, this.price, this.attachments.map(a => a.blob))
      .then(ad => {
        this.clearForm(this.$scope.postForm);
        this.onSave({feedPost: {...ad, postType: 'advertisement'}});
      });
  }

  clearForm() {
    this.to = null;
    this.toCategory = null;
    this.title = '';
    this.description = '';
    this.attachment = null;

    let form = this.$scope.postForm;
    form.$setPristine();
    form.$setUntouched();
  }
}

angular.module('ll').component('postComposer', {
  template: require('./postcomposer.component.html'),
  controller: PostComposerController,
  controllerAs: 'vm',
  bindings: {
    toOptions: '<',
    categoryOptions: '<',
    command: '<',
    onSave: '&',
    onSaveError: '&'
  }
});

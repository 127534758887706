import angular from 'angular';

class FileSelectorController {

  constructor($timeout) {
    this.$timeout = $timeout;
  }

  loadFile(file, invalidFiles) {
    if (!file) {
      return;
    }
    let url = URL.createObjectURL(file);
    let fr = new FileReader();
    let callback = this.onSelected;
    fr.onload = f => {
      this.$timeout(() => {
        let blob = FileSelectorController.getBlobFromDataURL(f.target.result);
        let base64 = FileSelectorController.getBase64FromDataUrl(f.target.result);
        callback({file: {url, file, base64, blob}});
      });
    };
    fr.readAsDataURL(file);
  }

  static getBase64FromDataUrl(dataUrl) {
    if (dataUrl == null || dataUrl === '') return null;

    let [, data] = dataUrl.split(',');
    return data;
  }

  static getBlobFromDataURL(dataUrl) {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs

    if (dataUrl == null || dataUrl === '') return null;

    let [mime, data] = dataUrl.split(',');
    data = atob(data);
    mime = mime.split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(data.length);

    // create a view into the buffer
    let ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < data.length; i++) {
      ia[i] = data.charCodeAt(i);
    }

    return new Blob([ab], {type: mime});
  }
}

angular.module('ll').component('fileSelector', {
  template: require('./fileselector.component.html'),
  controller: FileSelectorController,
  controllerAs: 'vm',
  bindings: {
    text: '<',
    onSelected: '&',
    isImage: '<'
  }
});

import angular from 'angular';
import {createRoute} from '../../core/config';

class PropertyMembersController {
  constructor() {
  }
}

angular.module('ll')
  .component('propertyMembers', {
    template: require('./property-members.component.html'),
    controller: PropertyMembersController,
    controllerAs: 'vm',
    bindings: {
      property: '<'
    }
  })
  .config(createRoute({
    name: 'app.property.members',
    url: '/members',
    resolve: {
      property: (PropertyService) => PropertyService.getPropertyDetails()
    },
    views: {
      property: 'propertyMembers'
    }
  }));

import angular from 'angular';
import {createRoute} from '../../core/config';

class GmhubEnergyJoinSuccessController{
  constructor(EnergyService, SessionService, TrackingService, $mdDialog, $translate, $sce) {
    this.EnergyService = EnergyService;
    this.SessionService = SessionService;
    this.TrackingService = TrackingService;
    this.$mdDialog = $mdDialog;
    this.$translate = $translate;
    this.$sce = $sce;
  }
  
  $onInit() {
    this.TrackingService.track('gmhub-energyjoinsuccess.init');
    this.user = this.SessionService.getUser();
    this.joinSuccessMessage = this.$sce.trustAsHtml('<span>' + this.$translate.instant('GMHUB.JOIN_SUCCESS.MESSAGE') + '</span>');
    this.setupSlides();
    this.importHistoricalData();
    this.hasCheckedForElectricityData = false;
    this.hasElectricityData = false;
  }
  
  setupSlides() {
    this.slides = [
      {
        type: 'pause_hour',
        title: this.$translate.instant('ENERGY_EXPLANATIONS.PAUSE_HOUR.TITLE'),
        image: 'images/energy/house_small.png',
      },
      {
        type: 'you_and_your_neighbours',
        title: this.$translate.instant('ENERGY_EXPLANATIONS.YOU_AND_YOUR_NEIGHBOURS.TITLE'),
        image: 'images/energy/house_small.png',
      },
      {
        type: 'research',
        title: this.$translate.instant('ENERGY_EXPLANATIONS.RESEARCH.TITLE'),
        image: 'images/energy/house_small.png',
      },
      {
        type: 'comparable',
        title: this.$translate.instant('ENERGY_EXPLANATIONS.COMPARABLE.TITLE'),
        image: 'images/energy/house_small.png',
      },
      {
        type: 'anonymous',
        title: this.$translate.instant('ENERGY_EXPLANATIONS.ANONYMOUS.TITLE'),
        image: 'images/energy/house_small.png',
      },
      {
        type: 'compete',
        title: this.$translate.instant('ENERGY_EXPLANATIONS.COMPETE.TITLE'),
        image: 'images/energy/house_small.png',
      },
    ];
  }

  importHistoricalData() {
    this.EnergyService.gmhubImportHistoricalData().then((resp)=>{
      if (resp.numValuesFetched > 0) {
        this.EnergyService.getConsumptionStats(true).then((resp2) => {
          if (resp2.dateFirstElectricityData !== null) {
            this.hasElectricityData = true;
          }
          this.hasCheckedForElectricityData = true;
        });
      } else {
        this.hasCheckedForElectricityData = true;
      }
    });
  }

  onCarouselInit(carouselScope){
    let ctrl = carouselScope.$$childHead.ctrl;
    ctrl.openExplanationDialog = this.openExplanationDialog;
  }

  hideDialog(){
    this.$mdDialog.hide();
  }
}

angular.module('ll')
  .component('gmhubEnergyJoinSuccess', {
    template: require('./gmhub-energy-join-success.html'),
    controller: GmhubEnergyJoinSuccessController,
    controllerAs: 'vm'
  })
  .config(createRoute({
    name: 'app.gmhubEnergyJoinSuccess',
    component: 'gmhubEnergyJoinSuccess',
    url: '/gmhub-energy-join-success'
  }));

import angular from 'angular';
import {createRoute} from '../../core/config';

angular.module('ll')
  .component('welcome', {
    template: require('./welcome.html'),
    controller: WelcomeController,
    controllerAs: 'vm',
    bindings: {
      platformKey: '<'
    }
  })
  .config(createRoute({
    name: 'welcome',
    component: 'welcome',
    url: '?platformKey',
    params: {
      platformKey: null
    },
    resolve: {
      platformKey: ($transition$) => $transition$.params().platformKey
    },
    redirectTo: 'welcome.findMyArea'
  }))
  .config(createRoute({
    name: 'welcome.findMyArea',
    component: 'findMyArea',
    url: '/find-neighborhood',
  }))
  .config(createRoute({
    name: 'welcome.login',
    component: 'login',
    url: '/login'
  }))
  .config(createRoute({
    name: 'welcome.forgotPassword',
    component: 'forgotPassword',
    url: '/forgotpassword'
  }))
  .config(createRoute({
    name: 'welcome.changePassword',
    component: 'changePassword',
    url: '/changepassword/:token',
    params: {
      token: null,
      platformKey: null
    },
    resolve: {
      token: ($transition$) => $transition$.params().token,
      platformKey: ($transition$) => $transition$.params().platformKey
    }
  }))
  .config(createRoute({
    name: 'welcome.changePasswordSuccess',
    component: 'changePasswordSuccess',
    url: '/changepassword-success'
  }))
  .config(createRoute({
    name: 'welcome.activationCode',
    component: 'activationCode',
    url: '/activationcode'
  }))
  .config(createRoute({
    name: 'welcome.vykort',
    url: '/vykort',
    redirectTo: 'welcome.activationCode'
  }));

function WelcomeController($interval, EnvironmentSettings, TrackingService) {
  const vm = this;
  vm.$onInit = init;
  // Standard LocalLife colors
  vm.gradientColor1 = '#7add2b';
  vm.gradientColor2 = '#22b942';

  function init() {
    vm.platformKey = vm.platformKey ? vm.platformKey : 'locallife_1';
    if (vm.platformKey === 'envac_reflow') {
      vm.gradientColor1 = '#A7A921';
      vm.gradientColor2 = '#A7A921';
    }
    vm.aboutUrl = EnvironmentSettings.aboutUrl;
    TrackingService.track('Welcome');
  }
}

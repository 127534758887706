import angular from 'angular';

class CouponListController {
  constructor($state, CouponService, uiAlertService, $translate, $mdDialog) {
    // this.$state = $state;
    this.couponService = CouponService;
    this.uiAlertService = uiAlertService;
    this.$translate = $translate;
    this.$mdDialog = $mdDialog;
  }

  $onInit() {
    this.hasLoaded = true;
  }

  $onChanges(changes) {
    this.newChanges = changes;
    // this.users = changes.users.currentValue ? this.sortUsers(changes.users.currentValue) : this.users;
  }

  markCouponAsUsed(couponHandoutId) {
    for (let i = 0; i < this.coupons.length; i++) {
      let coupon = this.coupons[i];
      if (coupon.couponHandoutId === couponHandoutId) {
        coupon.isUsed = true;
        // this.coupons.splice(i, 1);
        break;
      }
    }
  }

  useCoupon(couponHandoutId) {
    this.couponService.useCouponHandout(couponHandoutId).then(() => {
      return this.markCouponAsUsed(couponHandoutId);
    }).catch(data => {
      this.uiAlertService.showError(this.$translate.instant('COUPONS.USE_ERROR'));
    });
  }

  showConfirmDialog(e, coupon) {
    let confirm = this.$mdDialog.confirm()
      .title(this.$translate.instant('COUPONS.CONFIRM_TITLE'))
      .textContent(this.$translate.instant('COUPONS.CONFIRM_MESSAGE', {couponTitle: this.$translate.instant('COUPONS.COUPON_DATA.' +  coupon.couponTranslationKey + '.TITLE')}))
      .targetEvent(e)
      .ok(this.$translate.instant('BUTTON.YES'))
      .cancel(this.$translate.instant('BUTTON.NO'));

    this.$mdDialog.show(confirm)
      .then(() => this.useCoupon(coupon.couponHandoutId));
  }
}

angular.module('ll').component('couponList', {
  template: require('./coupon-list.component.html'),
  controller: CouponListController,
  controllerAs: 'vm',
  bindings: {
    coupons: '<'
  }
});

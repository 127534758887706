import angular from 'angular';

angular.module('ll')
  .filter('Mapper', function () {
    return function (data, type) {

      if (type === 'name' || type === 'email' || type === 'phoneNumber' || type === 'streetAddress' || type === 'postalCode' || type === 'postalCity' || type === 'mobileNumber') {
        if (data !== undefined && data !== '') {
          /* Return "-" if address isn't updated (Remove when all addresses are updated) */
          if (data.substring(0, 4) === 'a:2:') {
            return '-';
          }
          return data === null ? '-' : data;
        } else {
          return '-';
        }
      }
    };
  });

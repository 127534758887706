/* eslint-disable no-console */
import angular from 'angular';

class ToolbarController {
  constructor($mdSidenav, ToolbarService, LoaderInterceptorService, $state, FeedPostComposerDialogService, $window, SessionService) {
    this.$mdSidenav = $mdSidenav;
    this.toolbarService = ToolbarService;
    this.loaderInterceptorService = LoaderInterceptorService;
    this.$state = $state;
    this.pageTitle = '';
    this.isLoading = false;
    this.feedPostComposerDialogService = FeedPostComposerDialogService;
    this.$window = $window;
    this.sessionService = SessionService;
  }

  $onInit() {
    this.toolbarService.onConfigurationChange(settings => this.configureToolbar(settings));
    this.loaderInterceptorService.onLoading(isLoading => this.setLoading(isLoading));
    this.currentUser = this.sessionService.getUser();
  }

  configureToolbar(settings) {
    this.categoryId = settings.categoryId;
    this.pageTitle = settings.title;
    if (settings.backgroundColor) {
      this.toolbarColor = {'background-color': settings.backgroundColor};
    } else {
      this.toolbarColor = {};
    }
    this.showBackButton = settings.showBackButton;
  }

  goBack() {
    this.$window.history.back();
  }

  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  openMenu() {
    this.$mdSidenav('sideMenu').open();
  }

  goToUserProfile() {
    this.$state.go('app.profile', {userId: this.user.userId});
  }

  createPost(event) {
    if (!this.categoryId){
      this.categoryId = this.$state.params.categoryId;
    }
    this.feedPostComposerDialogService.open(event, {currentUser: this.currentUser, selectedCategory: this.categoryId});
  }
}

angular.module('ll').component('toolbar', {
  template: require('./toolbar.component.html'),
  controller: ToolbarController,
  controllerAs: 'vm',
  bindings: {
    user: '<',
    isComposerDialog: '<'
  }
});

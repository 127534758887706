import angular from 'angular';

class WasteService {
  constructor($http, EnvironmentSettings, LoggingService) {
    this.$http = $http;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
    this.loggingService = LoggingService;
  }

  getInlets() {
    return this.$http.get(`${this.labradorUrl}/api/sustainability/waste/inlets`)
      .then(resp => resp.data, resp => []);
  }

  getInlet(id) {
    return this.$http.get(`${this.labradorUrl}/api/sustainability/waste/inlets/${id}`)
      .then(resp => resp.data, resp => []);
  }

  getWasteFacility(uuid) {
    return this.$http.get(`${this.labradorUrl}/api/sustainability/waste/facility/${uuid}`)
      .then(resp => resp.data, resp => []);
  }

  getInletIssue(accessCode) {
    return this.$http.get(`${this.labradorUrl}/api/sustainability/waste/show-inlet-issue/${accessCode}`)
      .then(resp => resp.data);
  }

  reportError(inletId, errorDescription, image) {
    let fd = new FormData();
    fd.append('image', image);
    fd.append('description', errorDescription);

    let config = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };

    return this.$http.post(`${this.labradorUrl}/api/sustainability/waste/inlets/${inletId}/error`, fd, config)
      .then(resp => resp.data);
  }

  reportFacilityError(uuid, wasteCategory, errorDescription, image) {
    let fd = new FormData();
    fd.append('wasteCategory', wasteCategory);
    fd.append('image', image);
    fd.append('description', errorDescription);

    let config = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };

    return this.$http.post(`${this.labradorUrl}/api/sustainability/waste/facility/${uuid}/error`, fd, config)
      .then(resp => resp.data);
  }

  getActiveIssuesForWasteFacility(uuid) {
    return this.$http.get(`${this.labradorUrl}/api/sustainability/waste/facility/${uuid}/issues`)
      .then(resp => resp.data, resp => []);
  }

  recallError(inletId, solutionDescription, image) {
    let fd = new FormData();
    fd.append('image', image);
    fd.append('description', solutionDescription ? solutionDescription : '');

    let config = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };

    return this.$http.post(`${this.labradorUrl}/api/sustainability/waste/inlets/${inletId}/recall_error`, fd, config)
      .then(resp => resp.data);
  }
}

angular.module('ll').service('WasteService', WasteService);

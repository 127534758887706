import angular from 'angular';
import Viewer from 'viewerjs';

function ImageViewerDirective($mdMedia) {

  function link(scope, element, attr) {
    let opts = {
      navbar: false,
      title: true,
      keyboard: false,
      loop: false,
      rotatable: false,
      tooltip: false,
      url: attr.originalSrc ? 'data-original-src' : 'src',
      scalable: false,
      fullscreen: false
    };

    if ($mdMedia('xs')) {
      opts.toolbar = false;
    } else {
      opts.toolbar = {
        zoomIn: {
          show: 1,
          size: 'large'
        },
        zoomOut: {
          show: 1,
          size: 'large'
        },
        reset: {
          show: 1,
          size: 'large'
        }
      };
    }

    new Viewer(element[0], opts);
  }

  return {
    restrict: 'A',
    link
  };
}

ImageViewerDirective.$inject = ['$mdMedia'];

angular.module('ll').directive('llImageViewer', ImageViewerDirective);

import angular from 'angular';

class ItemAdvertisementCardController {}

angular.module('ll').component('itemAdvertisementCard', {
  template: require('./item-advertisement-card.component.html'),
  controller: ItemAdvertisementCardController,
  controllerAs: 'vm',
  bindings: {
    ad: '<'
  }
});

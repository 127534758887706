import angular from 'angular';
import {createRoute} from '../../core/config';

class PropertyFacilitiesController {
  constructor(FacilityService) {
    this.facilityService = FacilityService;
  }

  createOrEditFacility(facility) {
    return this.facilityService.createOrEditFacility(facility);
  }

  deleteFacility(facility) {
    return this.facilityService.deleteFacility(facility.id);
  }
}

angular.module('ll')
  .component('propertyFacilities', {
    template: require('./property-facilities.component.html'),
    controller: PropertyFacilitiesController,
    controllerAs: 'vm',
    bindings: {
      canEdit: '<',
      facilities: '<',
    }
  })
  .config(createRoute({
    name: 'app.property.facilities',
    url: '/facilities',
    resolve: {
      canEdit: (SessionService) => SessionService.getUser().isPropertyAdmin(),
      facilities: (FacilityService) => FacilityService.getFacilities('property')
    },
    views: {
      property: 'propertyFacilities'
    }
  }));

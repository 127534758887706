import angular from 'angular';

angular.module('ll').filter('fileSize', FileSizeFilter);

function FileSizeFilter() {

  // extracted from https://gist.github.com/thomseddon/3511330
  return function (bytes, precision) {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
      return '-';
    }

    if (typeof precision === 'undefined') {
      precision = 1;
    }

    let units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
    let number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
  };
}

import angular from 'angular';

class ChangePasswordController {

  constructor(PasswordService, ToastMessageService, $state, $translate, LoggingService) {
    this.passwordService = PasswordService;
    this.toastMessageService = ToastMessageService;
    this.$state = $state;
    this.$translate = $translate;
    this.loggingService = LoggingService;

    this.tokenData = {};
    this.tokenVerified = true;
    this.forgotpass = {
      pass1: '',
      pass2: ''
    };
  }

  $onInit() {
    this.passwordService.verifyToken(this.token)
      .then(d => this.tokenData = d)
      .catch(d => {
        this.tokenVerified = false;
      });
  }

  resetPassword() {
    this.passwordService.changePassword(this.tokenData.userId, this.tokenData.token, this.forgotpass.pass1, this.forgotpass.pass2)
      .then(() => {
        this.toastMessageService.showMessage(this.$translate.instant('CHANGE_PASSWORD.SAVE_SUCCESS'));
        if (this.platformKey !== 'envac_reflow') {
          this.$state.go('welcome.login');
        } else {
          this.$state.go('welcome.changePasswordSuccess', {platformKey: this.platformKey});
        }
      })
      .catch(() => {
        this.loggingService.error('Error saving new password');
        this.toastMessageService.showMessage(this.$translate.instant('CHANGE_PASSWORD.SAVE_ERROR'));
      });
  }

  goToStart() {
    this.$state.go('welcome');
  }
}

angular.module('ll')
  .component('changePassword', {
    template: require('./changePassword.component.html'),
    controller: ChangePasswordController,
    controllerAs: 'vm',
    bindings: {
      token: '<',
      platformKey: '<'
    }
  });


import angular from 'angular';

angular.module('ll.core').run(showCookieWarning);

showCookieWarning.$inject = ['$mdToast', '$timeout', 'SessionService', '$translate', '$window'];
function showCookieWarning($mdToast, $timeout, SessionService, $translate, $window) {
  let isApp = $window.navigator.userAgent.indexOf('gonative') !== 0;
  if (isApp || SessionService.getAcceptCookies()) return;

  const toast = $mdToast.simple()
    .textContent($translate.instant('GENERAL.COOKIES_WARNING'))
    .action('Acceptera')
    .highlightAction(true)
    .hideDelay(0)
    .position('top left');

  $timeout(() => $mdToast.show(toast).then((res) => SessionService.setAcceptCookies()), 500);
}

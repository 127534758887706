import angular from 'angular';
import {createRoute} from '../../core/config';

class MarketplaceController {
  constructor(ToolbarService, $translate) {
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
  }

  $onInit() {
    this.toolbarService.setPageTitle(this.$translate.instant('MENU.MARKETPLACE'));
    this.rentImg = require('./sync-alt-solid.svg');
    this.sellImg = require('./tags-solid.svg');
  }
}

angular.module('ll')
  .component('marketplace', {
    template: require('./marketplace.component.html'),
    controllerAs: 'vm',
    controller: MarketplaceController
  })
  .config(createRoute({
    name: 'app.marketplace',
    component: 'marketplace',
    url: '/marketplace'
  }));

import angular from 'angular';

angular.module('ll').directive('llViewBox', ViewBoxDirective);

function ViewBoxDirective() {
  return {
    link: function (scope, element, attrs) {
      attrs.$observe('llViewBox', function (value) {
        element[0].setAttribute('viewBox', value);
      });
    }
  };
}

import angular from 'angular';

angular.module('ll').factory('SessionService', SessionService);

function SessionService($localStorage, $sessionStorage, UserFactory, $rootScope, $translate) {
  $localStorage.acceptCookies = $localStorage.acceptCookies || false;
  $localStorage.authenticated = $localStorage.authenticated || false;
  $localStorage.isUserOnboarded = $localStorage.isUserOnboarded || false;
  $localStorage.deviceId = $localStorage.deviceId || null;

  let refreshUserCallbacks = [];

  return {
    reset,
    setUser,
    getUser,
    setAcceptCookies,
    getAcceptCookies,
    doAuthenticate,
    isAuthenticated,
    getSession,
    setSession,
    onUserDataRefresh,
    isUserOnboarded,
    setUserOnboarded,
    hasAuthenticationToken,
    getAuthenticationToken,
    getDeviceId,
    setDeviceId,
    getStorage,
    setStorage
  };

  function setUser(user) {
    $localStorage.user = user;
    $translate.use(user.language);
    notifyUserRefreshListeners(user);
    return user;
  }

  function setAuthenticated(authenticated) {
    $rootScope.$emit('authenticationStatus', authenticated);
    $localStorage.authenticated = authenticated;
  }

  function isAuthenticated() {
    return $localStorage.authenticated;
  }

  function doAuthenticate(token) {
    $localStorage.authenticationToken = token;
    setAuthenticated(true);
  }

  function hasAuthenticationToken() {
    return !!$localStorage.authenticationToken;
  }

  function getAuthenticationToken() {
    return $localStorage.authenticationToken;
  }

  function getDeviceId() {
    return $localStorage.deviceId;
  }

  function setDeviceId(deviceId) {
    $localStorage.deviceId = deviceId;
  }

  function getUser() {
    return UserFactory.createUser($localStorage.user);
  }

  function reset() {
    $localStorage.$reset();
    $sessionStorage.$reset();
    $localStorage.acceptCookies = false;
    setAuthenticated(false);
  }

  function setAcceptCookies() {
    $localStorage.acceptCookies = true;
  }

  function getAcceptCookies() {
    return $localStorage.acceptCookies;
  }

  function setStorage(key, data) {
    $localStorage.storage = $localStorage.storage || {};
    $localStorage.storage[key] = data;
    return data;
  }

  function getStorage(key) {
    return $localStorage.storage ? $localStorage.storage[key] : null;
  }

  function setSession(key, data) {
    $sessionStorage.storage = $sessionStorage.storage || {};
    $sessionStorage.storage[key] = data;
    return data;
  }

  function getSession(key) {
    return $sessionStorage.storage ? $sessionStorage.storage[key] : null;
  }

  function notifyUserRefreshListeners(user) {
    refreshUserCallbacks.forEach(cb => cb(user));
  }

  function onUserDataRefresh(cb) {
    refreshUserCallbacks.push(cb);
  }

  function setUserOnboarded() {
    $localStorage.isUserOnboarded = true;
  }

  function isUserOnboarded() {
    return $localStorage.isUserOnboarded;
  }
}

import angular from 'angular';

class PasswordService {

  constructor($http, EnvironmentSettings) {
    this.baseUrl = EnvironmentSettings.baseUrl;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
    this.$http = $http;
  }

  resetPassword(email) {
    return this.$http({
      url: `${this.labradorUrl}/api/forgotpassword/generate_reset_token`,
      method: 'POST',
      data: {
        email: email,
        platformKey: 'locallife_1'
      }
    }).then(resp => resp.data, resp => null);
  }

  verifyToken(token) {
    return this.$http.get(`${this.labradorUrl}/api/forgotpassword/verify`, {params: {token}}).then(resp => resp.data);
  }

  changePassword(userId, token, password, passwordConfirm) {
    return this.$http({
      url: `${this.labradorUrl}/api/forgotpassword/change`,
      method: 'PUT',
      data: {userId, token, password, passwordConfirm}
    }).then(resp => resp.status, resp => null);
  }
}

angular.module('ll').service('PasswordService', PasswordService);

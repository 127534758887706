import angular from 'angular';
import {createRoute} from '../../core/config';

angular.module('ll')
  .component('showElectricityDataConsent', {
    template: require('./showConsent.html'),
    controller: ShowElectricitydataConsentController,
    controllerAs: 'vm',
    bindings: {state: '<', consentId: '<', doUpdateStatus: '<'}
  })
  .config(createRoute({
    name: 'app.showElectricityDataConsent',
    component: 'showElectricityDataConsent',
    url: '/consent/:consentId/:state',
    resolve: {
      state: ($transition$) => $transition$.params().state,
      consentId: ($transition$) => Number($transition$.params().consentId),
      doUpdateStatus: ($transition$) => $transition$.params().doUpdateStatus
    },
    params: {
      state: null,
      doUpdateStatus: false
    }
  }));

function ShowElectricitydataConsentController($stateParams, $translate, EnergyService, LoggingService, SessionService, TrackingService, uiAlertService, UserService, $window, $state) {
  /* globals */
  const vm = this;

  // Variables
  vm.consent = null;
  vm.error = null;
  vm.warning = null;
  vm.success = null;

  // Accessable Functions
  vm.goToSigning = goToSigning;
  vm.$onInit = init;

  // Functions

  function init() {
    TrackingService.track('showconsent.init' + (vm.state ? '.' + vm.state : ''));
    loadConsent(vm.consentId);
  }

  function loadConsent(consentId) {
    if (vm.state && (vm.state === 'signed' || vm.state === 'rejected' || vm.state === 'failedSigning')) {
      vm.doUpdateStatus = true;
    }
    EnergyService.getConsent(consentId, vm.doUpdateStatus).then((consent) => {
      vm.doUpdateStatus = false;
      if (!vm.error && consent.sendError) {
        vm.error = {
          title: $translate.instant('ELECTRICITYDATACONSENT.MESSAGE.SEND_ERROR_TITLE'),
          message:  $translate.instant('ELECTRICITYDATACONSENT.MESSAGE.SEND_ERROR_MESSAGE', {id: vm.consentId}),
        };
      }
      if (!vm.warning && (!consent.datetimeSent || !consent.datetimeSigned)) {
        vm.warning = {
          title: $translate.instant('ELECTRICITYDATACONSENT.MESSAGE.NOT_SENT_TITLE'),
          message: $translate.instant('ELECTRICITYDATACONSENT.MESSAGE.NOT_SENT_MESSAGE')
        };
      }
      if ((consent.wasJustSigned || vm.state === 'signed' || vm.state === 'waitingForApproval') && consent.status === 'SIGNED_BY_ALL') {
        // If we get back a 'signed' status, it is assumed that the user is doing the signup flow and need to immediately
        // go to the step for editing household details.
        if (consent.wasJustSigned || vm.state === 'signed') {
          UserService.fetchLoggedInUser().then((resp) => {
            $state.go('app.editHouseholdDetails', {forwardTo: 'serviceEnabled'});
          });
          return;
        }

        let user = SessionService.getUser();
        let dsoName = user.networkArea.dsoCode;
        vm.success = {
          title: $translate.instant('ELECTRICITYDATACONSENT.MESSAGE.CONSENT_SIGNED_TITLE'),
          message:  $translate.instant('ELECTRICITYDATACONSENT.MESSAGE.CONSENT_SIGNED_MESSAGE', {dsoName: dsoName}),
        };
      }
      vm.consent = consent;
    }, (err) => {
      LoggingService.error('Error fetching electricitydata consent', err);
      uiAlertService.showError($translate.instant('ELECTRICITYDATACONSENT.MESSAGE.COULD_NOT_FETCH_CONSENT'), '');
    });
  }


  function goToSigning() {
    if (vm.consent.signingUrl) {
      $window.location.href = vm.consent.signingUrl;
    } else {
      LoggingService.error('Error - missing signing url for electricitydata consent', vm.consent);
      uiAlertService.showError($translate.instant('ELECTRICITYDATACONSENT.MESSAGE.CONSENT_CANNOT_BE_SIGNED'), '');
    }
  }
}

import angular from 'angular';

class ChangePasswordSuccessController {

  constructor($translate) {
    this.$translate = $translate;
  }

  $onInit() {
  }
}

angular.module('ll')
  .component('changePasswordSuccess', {
    template: require('./changePasswordSuccess.component.html'),
    controller: ChangePasswordSuccessController,
    controllerAs: 'vm',
    bindings: {
      platformKey: '<'
    }
  });


import angular from 'angular';

class ImageSliderController {

  constructor($translate) {
    this._$translate = $translate;
    this._index = 0;
    this._lastIndex = 0;
    this.visibleImage = {};
  }

  $onInit() {
    this.images = this.images || [];
    this._lastIndex = this.images.length - 1;
    this._updateUIElements();
    this.isSingleImage = this.images.length === 1;
  }

  $onChanges(changes) {
    if (changes.images) {
      this.images = changes.images.currentValue;
      this.$onInit();
    }
  }

  next(e) {
    if (this._isCurrentLastIndex()) return;
    this._index++;
    this._updateUIElements();
  }

  previous(e) {
    if (this._isCurrentFirstIndex()) return;
    this._index--;
    this._updateUIElements();
  }

  _updateUIElements() {
    this._setVisibleImage();
    this._setCounterText();
    this._toggleNavigationButtons();
  }

  _isCurrentLastIndex() {
    return this._index >= this._lastIndex;
  }

  _isCurrentFirstIndex() {
    return this._index <= 0;
  }

  _toggleNavigationButtons() {
    this.isNextDisabled = this._isCurrentLastIndex();
    this.isPreviousDisabled = this._isCurrentFirstIndex();
  }

  _setVisibleImage() {
    this.visibleImage = this.images.length === 0 ? {} : this.images[this._index];
    this.visibleImage.feed = this.visibleImage.feed || this.visibleImage.original;
  }

  _setCounterText() {
    let current = this.images.length > 0 ? this._index + 1 : 0;
    this.counterText = `${current} ${this._$translate.instant('GENERAL.OF')} ${this.images.length}`;
  }
}

angular.module('ll').component('imageSlider', {
  template: require('./image-slider.component.html'),
  controller: ImageSliderController,
  controllerAs: 'vm',
  bindings: {
    images: '<',
    alt: '<'
  },
  transclude: true
});

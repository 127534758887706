import angular from 'angular';

class CollapsableTextComponent {

  constructor() {
    this.CHARS_LIMIT = 400;
    this.showExpandCollapse = false;
    this.isCollapsed = true;
  }

  $onChanges(changes) {
    if (changes.text) {
      this.text = changes.text.currentValue;
      this.$onInit();
    }
  }

  $onInit() {
    this.visibleParagraphs = [];
    this.hiddenParagraphs = [];
    this.extractParagraphs(this.text);
    this.showExpandCollapse = this.hiddenParagraphs.length > 0;
    this.isCollapsed = !this.isExpanded;
  }

  extractParagraphs(text) {
    let paragraphs = [];
    let charsCounter = 0;

    if (text) {
      paragraphs = text.trim().split('\n');
    }

    for (let i = 0; i < paragraphs.length; i++) {
      let p = {ix: i, content: paragraphs[i]};
      charsCounter += p.content.length;
      if (charsCounter > this.CHARS_LIMIT || this.visibleParagraphs.length > 3) {
        if (this.visibleParagraphs.length > 0) {
          this.hiddenParagraphs.push(p);
        } else {
          this.visibleParagraphs.push(p);
        }
      } else {
        this.visibleParagraphs.push(p);
      }
    }
  }

  expandHiddenParagraphs() {
    this.isCollapsed = !this.isCollapsed;
    if (!this.isCollapsed && this.onExpand) {
      this.onExpand();
    }
  }
}

angular.module('ll')
  .component('collapsableText', {
    template: require('./collapsable-text.component.html'),
    controller: CollapsableTextComponent,
    controllerAs: 'vm',
    bindings: {
      text: '<',
      isExpanded: '<',
      onExpand: '&'
    }
  });


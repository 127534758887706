import angular from 'angular';
import {createRoute} from '../../../core/config';

class FacilityCreateEditController {
  constructor($translate, $window, $scope, moment, FacilityService, ToolbarService, ToastMessageService, HtmlPostService, SessionService) {
    this.$translate = $translate;
    this.$window = $window;
    this.$scope = $scope;
    this.moment = moment;
    this.facilityService = FacilityService;
    this.toolbarService = ToolbarService;
    this.htmlPostService = HtmlPostService;
    this.sessionService = SessionService;
    this.toastMessageService = ToastMessageService;

    this.hours = Array.from(Array(25).keys());
    this.weekDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
  }

  $onInit() {
    this.toolbarService.configure({
      title: this.facility ? this.$translate.instant('NEW_FACILITY.EDIT_FACILITY') + ': ' + this.facility.name : this.$translate.instant('NEW_FACILITY.NEW_FACILITY'),
      showBackButton: true
    });

    this.standardAllowedTimes = [{from: 8, to: 18, enabled: true}, {from: 8, to: 18, enabled: true}, {from: 8, to: 18, enabled: true}, {from: 8, to: 18, enabled: true}, {from: 8, to: 18, enabled: true}, {from: 8, to: 18, enabled: true}, {from: 8, to: 18, enabled: true}];

    if (!this.facility) {
      this.facility = {
        images: [],
        allowedTimes: [...this.standardAllowedTimes],
        blockedDates: []
        // visibility: this.visibilityCategories.find(cat => cat.id === this.level).id//todo maybe make it set to the level by default
      };
    } else {
      this.facilityTitleCopy = this.$translate.instant('NEW_FACILITY.EDIT_FACILITY') + ': ' + this.facility.name;
    }

    let allowedTimesToMap = this.facility.allowedTimes ? this.facility.allowedTimes : [...this.standardAllowedTimes];

    this.weekDays = allowedTimesToMap.map((d, idx) => {
      return {
        ...d,
        title: this.weekDays[idx]
      };
    });

    this.startDate = this.facility.startDate ? this.moment(this.facility.startDate, 'YYYY-MM-DD').toDate() : this.moment().toDate();
    this.endDate = this.facility.endDate ? this.moment(this.facility.endDate, 'YYYY-MM-DD').toDate() : null;
    this.facility.blockedDates = this.facility.blockedDates.filter(d => d).map(d => this.moment(d, 'YYYY-MM-DD').toDate());

    this.attachments = this.facility.images.map(i => {
      return {
        url: i
      };
    }) || [];
    this.originalAttachments = this.attachments;

    this.notifyCommunityCategories = [
      {
        id: 'no',
        name: 'NEW_FACILITY.NOTIFY_CATEGORIES.NO'
      },
      {
        id: 'feed',
        name: 'NEW_FACILITY.NOTIFY_CATEGORIES.FEED'
      },
      {
        id: 'notification_and_feed',
        name: 'NEW_FACILITY.NOTIFY_CATEGORIES.NOTIFICATION_PLUS_FEED'
      },
    ];

    this.slotLengthCategories = [
      {
        id: '1h',
        name: 'NEW_FACILITY.SLOT_LENGTH_CATEGORIES.1H'
      },
      {
        id: '2h',
        name: 'NEW_FACILITY.SLOT_LENGTH_CATEGORIES.2H'
      },
      {
        id: '3h',
        name: 'NEW_FACILITY.SLOT_LENGTH_CATEGORIES.3H'
      },
      {
        id: '4h',
        name: 'NEW_FACILITY.SLOT_LENGTH_CATEGORIES.4H'
      },
      {
        id: '1d',
        name: 'NEW_FACILITY.SLOT_LENGTH_CATEGORIES.1D'
      },
    ];

    this.activeBookingsLimitCategories = [
      {
        id: '1',
        name: 'NEW_FACILITY.ACTIVE_BOOKINGS_LIMIT_CATEGORIES.1'
      },
      {
        id: '2',
        name: 'NEW_FACILITY.ACTIVE_BOOKINGS_LIMIT_CATEGORIES.2'
      },
      {
        id: '3',
        name: 'NEW_FACILITY.ACTIVE_BOOKINGS_LIMIT_CATEGORIES.3'
      },
      {
        id: '4',
        name: 'NEW_FACILITY.ACTIVE_BOOKINGS_LIMIT_CATEGORIES.4'
      },
      {
        id: 'unlimited',
        name: 'NEW_FACILITY.ACTIVE_BOOKINGS_LIMIT_CATEGORIES.UNLIMITED'
      },
    ];

    const user = this.sessionService.getUser();
    this.visibilityCategories = this.htmlPostService.getToOptions(user).map(cat => {
      return {
        id: cat.value.name,
        name: cat.text
      };
    });
    this.showNotifyCommunity = false; // TODO: show if/when the functionality is implemented in backend
  }

  attachFile(file) {
    if (this.attachments.length <= 4) {
      this.attachments.push({
        url: file.url,
        base64String: file.base64,
      });
    }
  }

  removeAttachment(url) {
    this.attachments = this.attachments.filter(a => a.url !== url);
  }

  removeBlockedDate(idx) {
    this.facility.blockedDates.splice(idx, 1);
  }

  addBlockedDate() {
    this.facility.blockedDates.push(new Date());
  }
  blockedDateChange(date, $index) {
    this.facility.blockedDates[$index] = date;
  }

  saveFacility() {
    this.$scope.editFacilityForm.$setSubmitted();
    this.validateEndDate();

    if (!this.$scope.editFacilityForm.$valid) {
      return;
    }

    let removedAttachments = this.originalAttachments.filter(a => !this.attachments.find(o => o.url === a.url)).map(o => o.url);
    let addedAttachments = this.attachments.filter(a => a.base64String);

    let facility = {
      ...this.facility,
      startDate: this.moment(this.startDate).format('YYYY-MM-DD'),
      endDate: this.endDate ? this.moment(this.endDate).format('YYYY-MM-DD') : null,
      blockedDates: this.facility.blockedDates.map(d => this.moment(d).format('YYYY-MM-DD')),
      removedAttachments,
      newImages: addedAttachments,
      allowedTimes: this.weekDays.map(({from, to, enabled}) => {
        return {from, to, enabled};
      })
    };

    this.facilityService.createOrUpdateFacility(facility).then(() => {
      this.toastMessageService.showMessage(this.$translate.instant('NEW_FACILITY.FACILITY_SAVED'));
      this.$window.history.back();
    });
  }

  validateEndDate() {
    let isEndTimeValid = !this.endDate || this.moment(this.endDate).isAfter(this.startDate);
    this.$scope.editFacilityForm.endDate.$setValidity('endLaterThanStart', isEndTimeValid);
  }

  cancel() {
    this.$window.history.back();
  }
}

angular.module('ll')
  .component('facilityCreateEdit', {
    template: require('./facility-create-edit.component.html'),
    controller: FacilityCreateEditController,
    controllerAs: 'vm',
    bindings: {
      facility: '<',
      level: '<',
      canEdit: '<'
    }
  })
  .config(createRoute({
    name: 'app.facilityCreate',
    component: 'facilityCreateEdit',
    url: '/:level/facility-create',
    resolve: {
      level: ($transition$) => $transition$.params().level,
    }
  }))
  .config(createRoute({
    name: 'app.facilityEdit',
    component: 'facilityCreateEdit',
    url: '/:level/facility-edit/:facilityId',
    resolve: {
      level: ($transition$) => $transition$.params().level,
      facility: ($transition$, $state, FacilityService) => FacilityService.getFacilitySettings($transition$.params().facilityId)
    }
  }));

import angular from 'angular';

class OnboardingDialogController {

  constructor($mdDialog, $translate, SessionService, $timeout, TrackingService, pageCount) {
    this.$translate = $translate;
    this.$mdDialog = $mdDialog;
    this.$timeout = $timeout;
    this.pageCount = pageCount ? pageCount : 5;
    this.currentPageIndex = 1;
    this.changePage(this.currentPageIndex);
    this.neighborhoodName = SessionService.getUser().neighborhood.name;
    this.trackingService = TrackingService;
    this.trackingService.trackTime('Onboarding');
  }

  checkDisableNavigation() {
    this.isNextDisabled = this.currentPageIndex === this.pageCount;
    this.isBackDisabled = this.currentPageIndex === 1;
  }

  back() {
    if (this.currentPageIndex === 1) return;
    this.currentPageIndex--;
    this.changePage(this.currentPageIndex);

  }

  next() {
    if (this.currentPageIndex === this.pageCount) return;
    this.currentPageIndex++;
    this.changePage(this.currentPageIndex);
  }

  close($event, reason) {
    this.trackingService.track('Onboarding', {reason});
    this.$mdDialog.hide();
  }

  changePage(pageIndex) {
    //this.pageTitle = this.$translate.instant(`ONBOARDING.STEP${pageIndex}.TITLE`);
    this.currentPage = `step${this.currentPageIndex}`;
    this.checkDisableNavigation();
    this.scrollTop();
  }

  scrollTop() {
    this.$timeout(() => {
      let onboardingContent = document.getElementsByClassName('onboardingContent');
      if (onboardingContent.length > 0) {
        onboardingContent[0].scrollTop = 0;
      }
    });
  }
}

class OnboardingService {

  constructor($mdDialog, $mdCompiler, $q, SessionService) {
    this.$mdDialog = $mdDialog;
    this.currentUser = SessionService.getUser();
    this.sessionService = SessionService;
    this.$q = $q;
  }

  onboard(user, onboardingFlow = null) {
    if (user.isCreatedToday() && !this.sessionService.isUserOnboarded()) {
      return this.open(onboardingFlow).then(() => {
        document.body.style.touchAction = 'auto'; // Fix for disabled scrolling
        this.sessionService.setUserOnboarded();
      }).catch(() => {
        document.body.style.touchAction = 'auto';
      });
    }
    return this.$q.resolve();
  }

  open(onboardingFlow) {
    switch (onboardingFlow) {
      case 'hornslandet':
        return this.$mdDialog.show({
          parent: angular.element(document.body),
          template: require('./onboarding_hornslandet.html'),
          controller: OnboardingDialogController,
          controllerAs: 'vm',
          fullscreen: true,
          locals: {pageCount: 5}
        });
      default:
        return this.$mdDialog.show({
          parent: angular.element(document.body),
          template: require('./onboarding.html'),
          controller: OnboardingDialogController,
          controllerAs: 'vm',
          fullscreen: true,
          locals: {pageCount: 5}
        });
    }
  }
}

angular.module('ll')
  .service('OnboardingService', OnboardingService);

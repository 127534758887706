import angular from 'angular';

let config = angular.module('ll.core');

config.config(angularConfiguration);
angularConfiguration.$inject = ['$locationProvider', '$compileProvider', '$httpProvider', '$uiRouterProvider'];
function angularConfiguration($locationProvider, $compileProvider, $httpProvider, $uiRouterProvider) {
  $locationProvider.html5Mode(true);
  // eslint-disable-next-line no-undef
  $compileProvider.debugInfoEnabled(__IS_DEVELOPMENT__);
  $httpProvider.interceptors.push('TokenInterceptor');
  $httpProvider.interceptors.push('LoaderInterceptorService');
  // eslint-disable-next-line no-undef
  if (__IS_LOCAL__) {
    $uiRouterProvider.trace.enable();
  }
  $uiRouterProvider.urlService.rules.initial({state:'welcome.findMyArea'});
  $uiRouterProvider.urlService.rules.otherwise({state: 'app.neighborhood'});
}

config.config(mixpanelConfiguration);
mixpanelConfiguration.$inject = ['mixpanel', 'EnvironmentSettings'];

function mixpanelConfiguration(mixpanel, EnvironmentSettings) {
  mixpanel.init(EnvironmentSettings.mixpanel);
}

export function createRoute(config) {
  let RouteConfiguration = function ($stateRegistry) {
    $stateRegistry.register(config);
  };
  RouteConfiguration.$inject = ['$stateRegistryProvider'];
  return RouteConfiguration;
}

import angular from 'angular';

class DefaultMenuController {
  constructor(SessionService, EnvironmentSettings, Version) {
    this.sessionService = SessionService;
    this.environmentSettings = EnvironmentSettings;
    this.version = Version;
  }

  $onInit() {
    this.sessionService.onUserDataRefresh(user => {
      this.user = user;
      this.setupMenu();
    });
    this.setupMenu();
  }

  setupMenu() {
    this.menuVisibility = {
      showPropertyLevel: this.user.showPropertyLevel(),
      showEnergy: this.user.hasEnergyFeature(),
      showSustainability: this.user.hasEnabledFeature('sustainability'),
      showErrorReporting: this.user.hasEnabledFeature('error_reporting'),
      showMarketplace: this.user.hasEnabledFeature('marketplace'),
      showMarketplaceComingSoon: this.user.hasEnabledFeature('marketplace_coming_soon'),
      showEvents: this.user.hasEnabledFeature('events'),
      showDirectMessages: this.user.hasEnabledFeature('direct_messages'),
      showInviteNeighbors: this.user.hasEnabledFeature('invite_neighbors'),
      showForum: this.user.hasEnabledFeature('forum'),
      showCoupons: this.user.hasEnabledFeature('coupons'),
      showAboutProjectHornslandet: this.user.hasEnabledFeature('about_project_hornslandet'),
      showNeighborhoodGuideHornslandet: this.user.hasEnabledFeature('about_project_hornslandet'), // TODO: change to own DB setting if more fine-grained control is needed
      showCreateAdvancedPost: this.user.hasEnabledFeature('advanced_post')
    };
  }
}

angular.module('ll').component('defaultMenu', {
  template: require('./default-menu.component.html'),
  controller: DefaultMenuController,
  controllerAs: 'vm',
  bindings: {
    user: '<'
  }
});

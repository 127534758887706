import angular from 'angular';

class LikeButtonComponent {
  constructor(PostService) {
    this.postService = PostService;
  }

  toggleLike() {
    let result;
    if (this.post.liked) {
      result = this.postService.unlikePost(this.post.postId);
    } else {
      result = this.postService.likePost(this.post.postId);
    }
    result.then(post => {
      this.post = post;
      this.onLike({likes:post.likes});
    });
  }
}

angular.module('ll').component('likeButton', {
  template: require('./like-button.component.html'),
  controller: LikeButtonComponent,
  controllerAs: 'vm',
  bindings: {
    post: '<',
    onLike: '&',
  }
});

import angular from 'angular';

angular.module('ll').factory('AdvertisementFactory', AdvertisementFactory);

function AdvertisementFactory(moment) {

  return {
    create: function(data) {
      return new Advertisement(data);
    }
  };

  function Advertisement(data) {

    return {
      ...data,
      category: this.itemCategory,
      adType: this.aType,
      getBookings: function() {
        return this.rentApplications || [];
      },
      isRental() {
        return this.type === 'rent';
      },
      isShare() {
        return this.type === 'share';
      },
      isBuy() {
        return this.type === 'buy';
      },
      isSell() {
        return this.type === 'sell';
      },
      isBookable: function() {
        return this.isRental() || this.isShare();
      },
      isFree: function() {
        return this.price === 0;
      },
      hasDiscount: function() {
        return this.price < this.oldPrice;
      },
      getDiscountAmount: function() {
        return this.oldPrice - this.price;
      },
      getFacebookUrl: function() {
        return `https://www.facebook.com/dialog/share?app_id=262042197574228&display=popup&href=${this.shareUrl}&redirect_uri=${this.shareUrl}`;
      },
      getAuthorFullName() {
        return `${this.author.firstName} ${this.author.lastName}`;
      },
      getUpcomingBookings: function() {
        return this.getBookings().filter(b => b.status === 'APPROVED' && moment().isSameOrBefore(b.startDate, 'day'));
      },
      getPendingBookings: function() {
        return this.getBookings().filter(b => b.status === 'PENDING' && moment().isSameOrBefore(b.startDate, 'day'));
      }
    };
  }
}

import angular from 'angular';
import {createRoute} from '../../core/config';

class ProfileAboutController {
  constructor(UserService, $translate) {
    this.userService = UserService;
    this.$translate = $translate;
  }

  $onInit() {
    // eslint-disable-next-line no-extra-parens
    this.emptyTextDescription = this.user.about ? null : (this.user.isSelf ? this.$translate.instant('PROFILE.ABOUT_ME_PLACEHOLDER') : null);
  }

  saveAboutMe(text) {
    return this.userService.editAbout(text)
      .then(u => this.userService.fetchLoggedInUser())
      .then(u => this.user = u);
  }
}

angular.module('ll')
  .component('profileAbout', {
    template: require('./profileAbout.component.html'),
    controller: ProfileAboutController,
    controllerAs: 'vm',
    bindings: {
      user: '<'
    }
  })
  .config(createRoute({
    name: 'app.profile.about',
    url: '/about',
    views: {
      profile: 'profileAbout'
    }
  }));

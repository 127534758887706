import angular from 'angular';

class RentBookings {

  constructor(ToolbarService, $translate, SessionService, FeedService, TrackingService) {
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.sessionService = SessionService;
    this.feedService = FeedService;
    this.trackingService = TrackingService;
  }

  $onInit() {
    this.items = this.items || [];
    this.userId = this.sessionService.getUser().userId;
    this.title = this.$translate.instant('CLASSIFIEDS.RENT_BOOKINGS');
    this.configureToolbar();

    let mapPendingRentApplications = ad => {
      return ad.rentApplications.filter(rent => !rent.isAccepted && rent.leaseholder.userId === this.userId)
        .map(rent => ({adId: ad.id, title: ad.title, type: ad.type, price: ad.price, currency: ad.currency, images: ad.images, publicationDate: ad.publicationDate, author: ad.author, ...rent}));
    };

    this.pending = this.items.flatMap(mapPendingRentApplications);

    let mapUpcomingRentApplications = ad => {
      return ad.rentApplications.filter(rent => rent.isAccepted && rent.leaseholder.userId === this.userId)
        .map(rent => ({adId: ad.id, title: ad.title, type: ad.type, price: ad.price, currency: ad.currency, images: ad.images, publicationDate: ad.publicationDate, author: ad.author, ...rent}));
    };

    this.upcoming = this.items.flatMap(mapUpcomingRentApplications);
    this.trackingService.track('classifieds.rentBookings', {pending: this.pending.length, upcoming: this.upcoming.length});
  }

  configureToolbar(){
    this.toolbarService.configure({
      title: this.title
    });
  }
}

angular.module('ll').component('rentBookings', {
  template: require('./rent-bookings.component.html'),
  controller: RentBookings,
  controllerAs: 'vm',
  bindings: {
    items: '<',
    userId: '<',
    postCategory: '<'
  }
});

import angular from 'angular';

class ItemBookingController {

  constructor(moment, ClassifiedsService, uiAlertService, $translate, ToastMessageService, TrackingService) {
    this.moment = moment;
    this.classifiedsService = ClassifiedsService;
    this.uiAlertService = uiAlertService;
    this.$translate = $translate;
    this.toastMessageService = ToastMessageService;
    this.trackingService = TrackingService;
  }

  $onInit() {
    this.selectedDates = [];
    this.isItemOwner = this.item.author.userId === this.currentUser.userId;

    let now = this.moment();

    this.blockedDates = this.item.getUpcomingBookings()
      .map(b => ({start: this.moment(b.startDate).toDate(), end: this.moment(b.endDate).toDate()}));

    this.settings = {
      display: 'inline',
      layout: 'liquid',
      min: now.toDate(),
      invalid: this.blockedDates,
      colors: this.blockedDates.map(b => ({...b, background: 'red'})),
      showSelector: false,
      yearChange: false,
      theme: 'locallife'
    };
  }

  getOverlappingDates(selectionStart, selectionEnd, blockedDates) {
    if (selectionStart === null || selectionEnd === null || blockedDates === null || blockedDates.length === 0)
      return [];

    [selectionStart, selectionEnd] = [this.moment(selectionStart), this.moment(selectionEnd)];

    return blockedDates
      .filter(b => selectionEnd.isSameOrAfter(b.start))
      .filter(b => selectionStart.isSameOrBefore(b.end));
  }

  bookItem() {
    let [startDate, endDate] = this.selectedDates;
    if (!startDate)
      return;

    if (!endDate)
      endDate = startDate;

    let overlappingDates = this.getOverlappingDates(startDate, endDate, this.blockedDates);

    if (overlappingDates.length > 0) {
      let dates = overlappingDates
        .flatMap(d => Array.from(this.moment.range(d.start, d.end).by('day')))
        .map(d => d.format('YYYY-MM-DD'))
        .reverse()
        .join(', ');
      let title = this.$translate.instant('CLASSIFIEDS.OVERLAPPING_DATES');
      let message = this.$translate.instant('CLASSIFIEDS.OVERLAPPING_DATES_TEXT', {dates});
      this.uiAlertService.showAlert(title, message);
      return;
    }

    this.trackingService.track('classifieds.item.book', {itemId: this.item.id});

    this.classifiedsService.bookItem(this.item.id, this.moment(startDate).format('YYYY-MM-DD'), this.moment(endDate).format('YYYY-MM-DD'))
      .then(() => this.onBookingComplete())
      .then(() => this.toastMessageService.showMessage(this.$translate.instant('CLASSIFIEDS.BOOKING_REQUEST_SENT')));
  }

  showBookingList(){
    this.pending = this.item.getPendingBookings();
    this.upcoming = this.item.getUpcomingBookings();

    return this.pending.length || this.upcoming.length;
  }

}

angular.module('ll').component('itemBooking', {
  template: require('./item-booking.component.html'),
  controller: ItemBookingController,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    currentUser: '<',
    onBookingComplete: '&'
  }
});

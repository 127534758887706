import angular from 'angular';
import {createRoute} from '../../core/config';

class NeighborhoodGuideHornslandetController {
  constructor(ToolbarService, TrackingService) {
    this.trackingService = TrackingService;
    this.toolbarService = ToolbarService;
  }

  $onInit() {
    this.trackingService.track('NeighborhoodGuideHornslandet');
    this.toolbarService.setPageTitle('Hornslandet-guiden');
  }
}

angular.module('ll')
  .component('neighborhoodGuideHornslandet', {
    template: require('./neighborhoodGuideHornslandet.html'),
    controller: NeighborhoodGuideHornslandetController,
    controllerAs: 'vm',
  })
  .config(createRoute({
    name: 'app.neighborhoodGuideHornslandet',
    component: 'neighborhoodGuideHornslandet',
    url: '/neighborhood-guide-hornslandet'
  }));

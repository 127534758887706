import angular from 'angular';

class UiAlertService {
  constructor($mdDialog, $mdToast, $translate) {
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$translate = $translate;
  }

  get okButtonText() { return this.$translate.instant('BUTTON.OK'); }
  get cancelButtonText() { return this.$translate.instant('BUTTON.CANCEL'); }
  get hideDelayStandardMilliseconds() { return 4000; }
  get hideDelayErrorStandardMilliseconds() { return 0; }

  showConfirm(title, message) {
    let confirm = this.$mdDialog.confirm()
      .title(title)
      .textContent(message)
      .ok(this.okButtonText)
      .cancel(this.cancelButtonText);

    return this.$mdDialog.show(confirm);
  }

  showAlert(title, textContent) {
    let alert = this.$mdDialog.alert({title, textContent, ok: this.okButtonText});
    return this.$mdDialog.show(alert);
  }

  showSuccess(title, message, hideDelay) {
    const toast = this.$mdToast.simple()
      .textContent(this.getCombinedText(title, message))
      .highlightAction(true)
      .hideDelay(hideDelay ? hideDelay : this.hideDelayStandardMilliseconds)
      .position('bottom right');
    return this.$mdToast.show(toast);
  }

  showInfo(title, message, hideDelay) {
    const toast = this.$mdToast.simple()
      .textContent(this.getCombinedText(title, message))
      .highlightAction(true)
      .hideDelay(hideDelay ? hideDelay : this.hideDelayStandardMilliseconds)
      .position('bottom right');
    return this.$mdToast.show(toast);
  }

  showWarning(title, message, hideDelay) {
    const toast = this.$mdToast.simple()
      .textContent(this.getCombinedText(title, message))
      .highlightAction(true)
      .hideDelay(hideDelay ? hideDelay : this.hideDelayStandardMilliseconds)
      .position('bottom right');
    return this.$mdToast.show(toast);
  }

  showError(title, message, hideDelay) {
    const toast = this.$mdToast.simple()
      .textContent(this.getCombinedText(title, message))
      .action('OK')
      .highlightAction(true)
      .hideDelay(hideDelay ? hideDelay : this.hideDelayErrorStandardMilliseconds)
      .position('bottom right');
    return this.$mdToast.show(toast);
  }

  errorToString(errorResponse) {
    if (!errorResponse) {
      return '';
    }
    if (errorResponse.message) {
      return errorResponse.message;
    }
    if (errorResponse.messageCode) {
      return errorResponse.messageCode;
    }
    else return errorResponse;
  }

  getCombinedText(str1, str2, separator = ': ') {
    return (str1 ? str1 : '') + (str1 && str2 ? separator : '') + (str2 ? str2 : '');
  }
}

angular.module('ll').service('uiAlertService', UiAlertService);

import angular from 'angular';
import {createRoute} from '../../core/config';

class NeighborhoodFeedController {
  constructor(FeedService) {
    this.feedService = FeedService;
  }

  loadPosts(page, pageSize) {
    return this.feedService.getMainFeed(page, pageSize, this.categoryId);
  }
}

angular.module('ll')
  .component('neighborhoodFeed', {
    template: '<feed level="region" category-id="vm.categoryId" on-feed-bottom="vm.loadPosts(page, pageSize)"></feed>',
    controller: NeighborhoodFeedController,
    controllerAs: 'vm',
    bindings: {
      categoryId: '<'
    }
  })
  .config(createRoute({
    name: 'app.neighborhood.feed',
    url: '/feed?{categoryId:int}',
    params: {
      categoryId: null
    },
    resolve: {
      categoryId: ($transition$) => $transition$.params().categoryId
    },
    views: {
      neighborhood: 'neighborhoodFeed'
    }
  }));

require('./chooseProperty/choosepropertytype');
require('./energy/index');
require('./eventList/eventlist');
require('./invite/invite');
require('./neighborhood/index');
require('./neighbors/neighbours');
require('./profile');
require('./property');
require('./sustainability/sustainability');
require('./regionpolygons');
require('./registration');
require('./singlePost/singlepost');
require('./survey/index');
require('./vicinity/vicinity');
require('./welcome/welcome');
require('./electricityDataConsent');
require('./termsandconditions');
require('./categoryFeed/categoryFeed');
require('./classifieds/index');
require('./my-messages/my-messages.component');
require('./notifications/notifications');
require('./not-found/not-found');
require('./forum/forum');
require('./coupons/coupons');
require('./aboutProject/aboutProjectHornslandet');
require('./neighborhoodGuide/neighborhoodGuideHornslandet');
require('./marketplace-coming-soon/marketplace-coming-soon');
require('./html-post/html-post-page');
require('./inlet-issue/show-inlet-issue');

import angular from 'angular';
import {createRoute} from '../../core/config';

angular.module('ll')
  .component('chooseProperty', {
    template: require('./choosepropertygroup.html'),
    controller: ChoosePropertyTypeCtrl,
    controllerAs: 'vm',
    bindings: {
      forwardTo: '<'
    }
  })
  .config(createRoute({
    name: 'app.chooseProperty',
    component: 'chooseProperty',
    url: '/choosepropertygroup?{forwardTo:string}',
    params: {
      forwardTo: null
    },
    resolve: {
      forwardTo: ($transition$) => $transition$.params().forwardTo
    }
  }));

function ChoosePropertyTypeCtrl(PropertyService, SessionService, $state, UserService, ToolbarService, $translate) {
  const vm = this;
  let propertiesInNeighborhood = [];
  let currentNeighborhood = null;
  let currentUser = null;
  vm.propertyTypesList = [];
  vm.availableProperties = [];
  vm.selectedPropertyType = null;
  vm.selectedProperty = null;
  vm.hasAvailableProperties = false;
  vm.isPropertyTypeSelected = false;
  vm.shouldCreateProperty = false;
  vm.isPropertyNotListed = false;
  vm.newPropertyName = '';

  vm.onPropertyTypeSelected = onPropertyTypeSelected;
  vm.onPropertyNotListed = onPropertyNotListed;
  vm.createProperty = createProperty;
  vm.chooseProperty = chooseProperty;
  vm.$onInit = init;

  function init() {
    ToolbarService.setPageTitle($translate.instant('LEVELS.CHOOSE_YOUR_PROPERTY'));
    checkUserAlreadyLinked();
    currentUser = SessionService.getUser();
    currentNeighborhood = currentUser.neighborhood;
    PropertyService.getPropertyGroupTypes().then(types => vm.propertyTypesList = types);
    PropertyService.getRegionProperties(currentNeighborhood.id).then(props => propertiesInNeighborhood = props);
  }

  function checkUserAlreadyLinked() {
    if (SessionService.getUser().isPropertyMember()) {
      $state.go('app.property');
    }
  }

  function onPropertyTypeSelected() {
    vm.availableProperties = propertiesInNeighborhood.filter(prop => prop.propertygrouptypeId === vm.selectedPropertyType.id);
    vm.isPropertyTypeSelected = true;
    vm.hasAvailableProperties = vm.availableProperties.length > 0;
    vm.shouldCreateProperty = !vm.hasAvailableProperties;
  }

  function onPropertyNotListed() {
    vm.shouldCreateProperty = vm.isPropertyNotListed;
  }

  function createProperty(form) {
    if (form.$invalid) return;
    PropertyService.createProperty(vm.newPropertyName, vm.selectedPropertyType.id, currentNeighborhood.id)
      .then(() => UserService.fetchLoggedInUser())
      .then(() => forward());
  }

  function chooseProperty() {
    PropertyService.chooseProperty(vm.selectedProperty.id, currentUser.userId)
      .then(() => UserService.fetchLoggedInUser())
      .then(() => forward());
  }

  function forward() {
    if (vm.forwardTo === 'energyJoin') {
      $state.go('app.energyJoin', {'showActivation': true});
    } else {
      $state.go('app.property');
    }
  }
}

import angular from 'angular';

class HtmlPostService {
  constructor($http, LoggingService, EnvironmentSettings, FeedPostFactory, FeedPostComposerDialogService) {
    this.$http = $http;
    this.loggingService = LoggingService;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
    this.feedPostFactory = FeedPostFactory;
    this.feedPostComposerDialogService = FeedPostComposerDialogService;
  }

  createOrUpdateHtmlPost(publicationId, postedTo, categoryId, subject, content) {
    return this.$http.post(`${this.labradorUrl}/api/publication/htmlpost`, {publicationId, postedTo, subject, content, categoryId})
      .then(resp => this.feedPostFactory.createFeedPost(resp.data), resp => this.loggingService.error('Error creating html post', resp));
  }

  getToOptions(user) {
    return this.feedPostComposerDialogService.getToOptions(user, null);
  }

  getCategoryOptions(categories, selectedCategoryId) {
    return this.feedPostComposerDialogService.getToCategoryOptions(categories, selectedCategoryId, ['discussion']);
  }
}

angular.module('ll').service('HtmlPostService', HtmlPostService);


import angular from 'angular';
import {createRoute} from '../../core/config';

class Base2Controller {

  constructor(ToolbarService, $translate) {
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
  }

  $onInit() {
    this.toolbarService.setPageTitle(this.$translate.instant('HOMESERVICE.TITLE'));
  }

}

angular.module('ll')
  .component('base2', {
    template: require('./base2.component.html'),
    controller: Base2Controller,
    controllerAs: 'vm'
  })
  .config(createRoute({
    name: 'app.homeservice',
    component: 'base2',
    url: '/homeservice',
  }));

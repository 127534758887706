import angular from 'angular';
import ClipboardJS from '../../../node_modules/clipboard/dist/clipboard';

class AdvertisementDetailController {

  constructor(ToolbarService, $state, SessionService, ClassifiedsService, $location, $anchorScroll, $timeout, uiAlertService, $translate, TrackingService, $mdMedia){
    this.toolbarService = ToolbarService;
    this.$state = $state;
    this.sessionService = SessionService;
    this.classifiedsService = ClassifiedsService;
    this.$location = $location;
    this.$anchorScroll = $anchorScroll;
    this.$timeout = $timeout;
    this.uiAlertService = uiAlertService;
    this.$translate = $translate;
    this.trackingService = TrackingService;
    this.$mdMedia = $mdMedia;
  }

  $onInit(){
    this.item = this.item || {};
    this.toolbarService.configure({title: this.item.title, showBackButton: true});
    this.currentUser = this.sessionService.getUser();
    this.isAuthor = this.currentUser.userId === this.item.author.userId;
    this.classifiedsService.getAdvertisementCategoryById(this.item.itemCategory).then(data => {
      this.adCategory = data;
    }
    );
    this.classifiedsService.getAdvertisementTypeById(this.item.type).then(data => {
      this.adType = data;
    }
    );

    this.$timeout(() => {
      new ClipboardJS('#copyAdUrl');
    });

    this.trackingService.track('classifieds.item.detail', {itemId: this.item.id});
  }

  markSold() {
    this.classifiedsService.markAsSold(this.item).then(() => this.item.isSold = true);
    this.trackingService.track('classifieds.item.markSold');
  }

  refreshPage() {
    this.$state.reload();
  }

  goToReviews(){
    this.trackingService.track('classifieds.item.reviews');
    this.$location.hash('reviews');
    this.$anchorScroll();
  }

  deleteAdvertisement(e) {
    this.uiAlertService.showConfirm(this.$translate.instant('NEWPOST.ADVERTISEMENT.DELETE'), this.$translate.instant('POST.DELETE.ARE_YOU_SURE'))
      .then(() => {
        this.classifiedsService.deleteAdvertisement(this.item.id);
        this.$state.go('app.classifieds.list', {adType: this.adType.id});
        this.trackingService.track('classifieds.item.delete');
      });
  }
}

angular.module('ll')
  .component('advertisementDetail', {
    template: require('./item-advertisement-detail.component.html'),
    controller: AdvertisementDetailController,
    controllerAs: 'vm',
    bindings: {
      item: '<',
      reviews: '<'
    }
  });

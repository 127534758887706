import angular from 'angular';

class MyAdvertisementsController {

  constructor(ToolbarService, $translate, FeedService, TrackingService) {
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.feedService = FeedService;
    this.trackingService = TrackingService;
  }

  $onInit() {
    this.title = this.title = this.$translate.instant('CLASSIFIEDS.MY_ADS');
    this.items = this.items || [];
    this.active = this.items.filter(i => !i.isSold);
    this.inactive = this.items.filter(i => i.isSold);
    this.trackingService.track('classifieds.myItems');
    this.configureToolbar();
  }

  configureToolbar(){
    this.toolbarService.setPageTitle(this.title);
  }
}

angular.module('ll').component('myAdvertisements', {
  template: require('./my-advertisements.component.html'),
  controller: MyAdvertisementsController,
  controllerAs: 'vm',
  bindings: {
    items: '<',
    postCategory: '<'
  }
});

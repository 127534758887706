import angular from 'angular';
import {createRoute} from '../../core/config';

class MyMessagesComponentController {

  constructor(ToolbarService, $translate, moment) {
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.moment = moment;
  }

  $onInit() {
    this.conversations = this.conversations
      .filter(c => this.hasLastMessage(c))
      .map(c => this.removeSelfFromParticipants(c))
      .sort((c1, c2) => this.sortConversationsByLastMessageDateDesc(c1, c2));
    this.toolbarService.setPageTitle(this.$translate.instant('CONVERSATIONS.CONVERSATIONS'));
  }

  sortConversationsByLastMessageDateDesc(c1, c2) {
    return this.moment(c1.lastMessage.createdAt).isBefore(this.moment(c2.lastMessage.createdAt)) ? 1 : -1;
  }

  hasLastMessage(conversation) {
    return conversation.lastMessage != null;
  }

  removeSelfFromParticipants(conversation) {
    let participant = conversation.participants.filter(cu => cu.user.id !== this.currentUser.userId);
    conversation.user = participant[0].user;
    return conversation;
  }
}

angular.module('ll')
  .component('myMessages', {
    template: require('./my-messages.component.html'),
    controller: MyMessagesComponentController,
    controllerAs: 'vm',
    bindings: {
      conversations: '<',
      currentUser: '<'
    }
  })
  .config(createRoute({
    name: 'app.myMessages',
    component: 'myMessages',
    url: '/mymessages',
    resolve: {
      conversations: (ConversationService) => ConversationService.getAllConversations(),
      currentUser: (SessionService) => SessionService.getUser()
    }
  }));

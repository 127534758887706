import angular from 'angular';
import {createRoute} from '../../core/config';

class RegistrationController {

  constructor(LoaderInterceptorService, ToolbarService) {
    this.loaderInterceptorService = LoaderInterceptorService;
    this.toolbarService = ToolbarService;
  }

  $onInit() {
    this.isLoading = false;
    this.pageTitle = '';
    this.loaderInterceptorService.onLoading(isLoading => this.isLoading = isLoading);
    this.toolbarService.onConfigurationChange(settings => this.pageTitle = settings.title);
  }
}

angular.module('ll')
  .component('registration', {
    template: require('./registration.html'),
    controller: RegistrationController,
    controllerAs: 'vm'
  })
  .config(createRoute({
    abstract: true,
    name: 'registration',
    component: 'registration'
  }));

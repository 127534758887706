import angular from 'angular';

class FeedPostEventComponentController {
  constructor(uiAlertService, PostService, $translate) {
    this.uiAlertService = uiAlertService;
    this.postService = PostService;
    this.$translate = $translate;
  }

  $onInit() {
    this.showComments = false;
    this.title = this.event.getTitle();
    this.content = this.event.getContent();
  }

  changeEventRsvpStatus(status) {
    this.event.status = status.status;
  }

  deleteEvent(event) {
    this.uiAlertService.showConfirm(this.$translate.instant('POST.DELETE.POST'), this.$translate.instant('POST.DELETE.ARE_YOU_SURE'))
      .then(() => this.postService.deletePost(this.event.postId))
      .then(() => this.onDelete());
  }

  toggleComments() {
    this.showComments = !this.showComments;
  }

  updateLikes(likes) {
    this.event.likes = likes;
  }
}

angular.module('ll').component('feedpostEvent', {
  template: require('./feedpost-event.component.html'),
  controller: FeedPostEventComponentController,
  controllerAs: 'vm',
  bindings: {
    event: '<',
    onDelete: '&'
  }
});

import angular from 'angular';

class BookingListController {

  constructor(moment, uiAlertService, $translate, ClassifiedsService, $state, TrackingService) {
    this.moment = moment;
    this.uiAlertService = uiAlertService;
    this.$translate = $translate;
    this.classifiedsService = ClassifiedsService;
    this.$state = $state;
    this.trackingService = TrackingService;
  }

  $onInit() {
    this.now = this.moment();
    this.pending = this.item.getPendingBookings().filter(b => b.leaseholder);
    this.upcoming = this.item.getUpcomingBookings().filter(b => b.leaseholder);
  }

  cancelBooking(booking) {
    let title = this.$translate.instant('CLASSIFIEDS.CANCEL_BOOKING');
    let text = this.$translate.instant('CLASSIFIEDS.CANCEL_BOOKING_TEXT');
    this.uiAlertService.showConfirm(title, text)
      .then(() => this.classifiedsService.cancelBooking(booking.id))
      .then(() => this.trackingService.track('classifieds.item.booking.cancel'))
      .then(() => this.$state.reload());
  }

  approveBooking(booking) {
    let title = this.$translate.instant('CLASSIFIEDS.ACCEPT_BOOKING');
    let text = this.$translate.instant('CLASSIFIEDS.ACCEPT_BOOKING_TEXT', {from: this.formatDate(booking.startDate), to: this.formatDate(booking.endDate)});
    this.uiAlertService.showConfirm(title, text)
      .then(() => this.classifiedsService.acceptBooking(booking.id))
      .then(() => this.trackingService.track('classifieds.item.booking.approve'))
      .then(() => this.$state.reload());
  }

  formatDate(dateString) {
    return this.moment(dateString).format('L');
  }
}

angular.module('ll').component('bookingList', {
  template: require('./booking-list.component.html'),
  controller: BookingListController,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    currentUser: '<'
  }
});

import angular from 'angular';

class ConversationService {
  constructor($http, LoggingService, EnvironmentSettings) {
    this.$http = $http;
    this.loggingService = LoggingService;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
  }

  getAllConversations() {
    return this.$http.get(`${this.labradorUrl}/api/conversation/all`)
      .then(resp => resp.data, resp => this.loggingService.error('Error getting all user conversations', resp));
  }

  findOrCreateConversation(userIds) {
    return this.$http.post(`${this.labradorUrl}/api/conversation`, {userIds: userIds})
      .then(resp => resp.data, resp => this.loggingService.error('Error finding or creating conversation', resp));
  }

  archiveConversation(conversationId) {
    return this.$http.delete(`${this.labradorUrl}/api/conversation/${conversationId}`)
      .then(resp => resp.data, resp => this.loggingService.error('Error deleting conversation', resp));
  }

  getMessagesForConversationById(conversationId, direction = null, lastMessageId = null) {
    let params = {direction, lastMessageId};

    return this.$http.get(`${this.labradorUrl}/api/conversation/${conversationId}/messages`, {params})
      .then(resp => resp.data, resp => this.loggingService.error('Error getting conversation by id', resp));
  }

  sendMessage(conversationId, content) {
    return this.$http.post(`${this.labradorUrl}/api/conversation/${conversationId}`, {content})
      .then(resp => resp.data, resp => this.loggingService.error('Error sending message to direct message conversation', resp));
  }
}

angular.module('ll').service('ConversationService', ConversationService);


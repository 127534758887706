import angular from 'angular';

class FeedPostPostViewComponentController {
  constructor(TrackingService) {
    this.trackingService = TrackingService;
  }

  $onInit() {
    this.showComments = false;
  }

  $onChanges(changes) {
    if (changes.post) {
      this.post = changes.post.currentValue;
    }
  }

  toggleComments() {
    this.showComments = !this.showComments;
  }

  updateLikes(likes) {
    this.post.likes = likes;
  }

  onExpand() {
    this.trackingService.track('feed.post.see_more', {postId: this.post.postId, subject: this.post.subject});
  }
}

angular.module('ll').component('feedpostPostView', {
  template: require('./feedpost-post-view.component.html'),
  controller: FeedPostPostViewComponentController,
  controllerAs: 'vm',
  bindings: {
    post: '<',
    isExpanded: '<'
  }
});

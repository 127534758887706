import angular from 'angular';

class RentOutItemController {
  $onInit() {
    this.item = this.item || {};
    this.rentApplication = this.rentApplication || null;
  }
}

angular.module('ll').component('rentOutItem', {
  template: require('./rent-out-item.component.html'),
  controller: RentOutItemController,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    rentApplication: '<'
  }
});

import angular from 'angular';

angular.module('ll').filter('abs', AbsFilter);

function AbsFilter() {

  return function (number) {
    return Math.sqrt(Math.pow(number, 2));
  };
}

import angular from 'angular';

class AlertComposerController {

  constructor(FeedService, $scope, SessionService) {
    this.feedService = FeedService;
    this.$scope = $scope;
    this.sessionService = SessionService;
    this.isPropertyBoardMessage = false;
  }

  $onInit() {
    let preSelectedTo = this.toOptions.find(opt => opt.isPreSelected === true);
    if (preSelectedTo) {
      this.to = preSelectedTo.value;
    }
    this.isPropertyAdmin = this.sessionService.getUser().isPropertyAdmin();
  }

  $onChanges(objectChange) {
    if (!objectChange.command) return;

    let command = objectChange.command.currentValue;
    if (command.postType !== 'alert') return;
    switch (command.name) {
      case 'save':
        this.save();
        break;
      default:
        break;
    }
  }

  save() {
    this.$scope.alertForm.$setSubmitted();

    if (!this.$scope.alertForm.$valid){
      this.onSaveError({error:'invalid form'});
      return;
    }

    this.feedService.postAlertToFeed(this.to.name, this.to.id, this.description, this.isPropertyBoardMessage)
      .then(p =>  {
        this.clearForm();
        this.onSave({feedPost: p});
      })
      .catch(() => this.onSaveError({error: 'server error'}));
  }

  clearForm() {
    let form = this.$scope.alertForm;
    this.to = null;
    this.description = '';
    form.$setPristine();
    form.$setUntouched();
  }

}

angular.module('ll').component('alertComposer', {
  template: require('./alertcomposer.component.html'),
  controller: AlertComposerController,
  controllerAs: 'vm',
  bindings: {
    toOptions: '<',
    command: '<',
    onSave: '&',
    onSaveError: '&'
  }
});

import angular from 'angular';

class SurveyController {

  constructor($anchorScroll, $location, ToastMessageService, $timeout, $translate, SurveyService, $state, UserService, SessionService) {
    this.$anchorScroll = $anchorScroll;
    this.$location = $location;
    this.$timeout = $timeout;
    this.toastMessageService = ToastMessageService;
    this.surveyService = SurveyService;
    this.$state = $state;
    this.$translate = $translate;
    this.userService = UserService;
    this.sessionService = SessionService;
    this.answers = new Map();
  }

  $onInit() {
    let user = this.sessionService.getUser();
    this.questionTranslationVariables = {
      neighborhood: user.neighborhood.name
    };
    this.getSurvey(this.surveyKey, this.surveyRoundKey);
  }

  getSurvey(surveyKey, surveyRoundKey) {
    this.surveyService.getSurvey(surveyKey, surveyRoundKey).then((surveyData) => {
      this.survey = surveyData;
      this.createFlatQuestionMap();
    });
  }

  returnQuestionAnswers(questionId, answers) {
    this.answers.set(questionId, answers);
  }

  createFlatQuestionMap() {
    this.questions = new Map();
    if (Array.isArray(this.survey.questionGroups)) {
      for (let i = 0; i < this.survey.questionGroups.length; i++) {
        let questionGroup = this.survey.questionGroups[i];
        if (Array.isArray(questionGroup.questions)) {
          for (let j = 0; j < questionGroup.questions.length; j++) {
            let question = questionGroup.questions[j];
            this.questions.set(question.id, question);
          }
        }
      }
    }
  }

  saveAnswers() {
    this.hasErrors = false;
    this.removeErrorMarkFromAllQuestions();
    let answersArr = Array.from(this.answers.values());
    this.surveyService.saveSurveyRound(this.survey.surveyRound.id, answersArr).then(() => {
      this.toastMessageService.showMessage(this.$translate.instant('SURVEY.MESSAGES.SAVE_SUCCESS'));
      this.userService.fetchLoggedInUser().then(() => {
        if (this.forwardTo) {
          this.forward(this.forwardTo);
        }
      });
    }, (err) => {
      if (err.data && err.data.questionErrors) {
        this.showErrors(err.data.questionErrors);
        this.toastMessageService.showMessage(this.$translate.instant('SURVEY.MESSAGES.SAVE_FAILURE'));
      }
    });
  }

  forward(forwardTo) {
    this.$state.go(forwardTo);
  }

  removeErrorMarkFromAllQuestions() {
    for (let i = 0; i < this.survey.questionGroups.length; i++) {
      for (let j = 0; j < this.survey.questionGroups[i].questions.length;j++) {
        // Replace the existing object with a copy to trigger the onChanges function in the question component
        let questionCopy = {};
        Object.assign(questionCopy, this.survey.questionGroups[i].questions[j]);
        questionCopy.hasError = false;
        this.survey.questionGroups[i].questions[j] = questionCopy;
      }
    }
  }

  markQuestionWithError(questionId) {
    for (let i = 0; i < this.survey.questionGroups.length; i++) {
      for (let j = 0; j < this.survey.questionGroups[i].questions.length;j++) {
        if (this.survey.questionGroups[i].questions[j].id === questionId) {
          // Replace the existing object with a copy to trigger the onChanges function in the question component
          let questionCopy = {};
          Object.assign(questionCopy, this.survey.questionGroups[i].questions[j]);
          questionCopy.hasError = true;
          this.survey.questionGroups[i].questions[j] = questionCopy;
          return;
        }
      }
    }
  }

  showErrors(questionErrors) {
    let requiredQuestions = [];
    let tooManyAnswersQuestions = [];
    let unknownQuestionErrors = [];

    for (let i = 0; i < questionErrors.length; i++) {
      let questionError = questionErrors[i];
      let question = this.questions.get(questionError.questionId);
      if (question) {
        this.markQuestionWithError(question.id);
        if (questionError.errorType === 'REQUIRED') {
          requiredQuestions.push(question);
        } else if (questionError.errorType === 'TOO_MANY_ANSWERS') {
          tooManyAnswersQuestions.push(question);
        } else {
          unknownQuestionErrors.push(question);
        }
      }
    }

    let errorHtml = '';

    if (requiredQuestions.length > 0) {
      requiredQuestions.sort((q1, q2) => q1.presentedOrder - q2.presentedOrder);
      errorHtml += '<div>' + this.$translate.instant('SURVEY.ERROR.REQUIRED_TITLE') + '</div>';
      errorHtml += '<ul>';
      for (let i = 0; i < requiredQuestions.length; i++) {
        errorHtml += this.getListItemFromQuestion(requiredQuestions[i]);
      }
      errorHtml += '</ul>';
    }

    if (tooManyAnswersQuestions.length > 0) {
      errorHtml += '<div>' + this.$translate.instant('SURVEY.ERROR.TOO_MANY_ANSWERS_TITLE') + '</div>';
      errorHtml += '<ul>';
      for (let i = 0; i < tooManyAnswersQuestions.length; i++) {
        errorHtml += this.getListItemFromQuestion(tooManyAnswersQuestions[i]);
      }
      errorHtml += '</ul>';
    }

    if (unknownQuestionErrors.length > 0) {
      errorHtml += '<div>' + this.$translate.instant('SURVEY.ERROR.UNKNOWN_TITLE') + '</div>';
      errorHtml += '<ul>';
      for (let i = 0; i < unknownQuestionErrors.length; i++) {
        errorHtml += this.getListItemFromQuestion(unknownQuestionErrors[i]);
      }
      errorHtml += '</ul>';
    }

    this.surveyErrorHtml = errorHtml;
    this.hasErrors = true;
    this.$timeout(function(anchorScroll){
      anchorScroll('survey_errors');}, 0, true, this.$anchorScroll);

  }

  getListItemFromQuestion(question) {
    return '<li>' + question.presentedOrder + '. ' + this.$translate.instant('SURVEY.QUESTIONS.' + question.key + '.TITLE') + '</li>';
  }
}

angular.module('ll').component('survey', {
  template: require('./survey.component.html'),
  controller: SurveyController,
  controllerAs: 'vm',
  bindings: {
    surveyKey: '<',
    surveyRoundKey: '<',
    forwardTo: '<'
  }
});

import angular from 'angular';

angular.module('ll').factory('EventFactory', EventFactory);

function EventFactory(moment) {
  return {
    createEvent: function (data) {
      return new Event(data);
    }
  };

  function Event(data) {
    return {
      ...data,
      startDate: moment(data.startDatetimeLocal),
      attendeesGoing: data.attendees.filter(a => a.status === 'going'),
      attendeesMaybe: data.attendees.filter(a => a.status === 'maybe'),
    };
  }
}

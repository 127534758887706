/* eslint-disable no-undef */
import angular from 'angular';
import {createRoute} from '../../core/config';

class NewNeighborhoodController {

  constructor(SessionService, $state, $translate, ToolbarService, $scope, TrackingService, $timeout) {
    this.sessionService = SessionService;
    this.$state = $state;
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.$scope = $scope;
    this.trackingService = TrackingService;
    this.$timeout = $timeout;
    this.registrationData = null;
    this.showDrawNeighborhood = false;
    this.setPageTitle();
    this.loadRegistrationData();
  }

  setPageTitle() {
    this.toolbarService.setPageTitle(this.$translate.instant('REGISTRATION.NEW_NEIGHBORHOOD.REGISTER_MY_NEIGHBORHOOD'));
  }

  toggleDrawNow() {
    this.showDrawNeighborhood = !this.showDrawNeighborhood;
  }

  loadRegistrationData() {
    this.registrationData = this.sessionService.getSession('registrationData');
    if (!this.registrationData) {
      this.$state.go('welcome');
    }
    this.registrationData.geometry.polygon = null;
    this.registrationData.geometry.isDefaultPolygon = false;
  }

  onMapReset() {
    this.registrationData.geometry.polygon = null;
  }

  onPolygonComplete(polygon) {
    this.$timeout(() => {
      const TWO_SQ_KM = 2000000;
      let location = new google.maps.LatLng(this.registrationData.geometry.location);
      let poly = new google.maps.Polygon();
      poly.setPaths(polygon);
      let isLocationInsidePolygon = google.maps.geometry.poly.containsLocation(location, poly);
      let isPolygonLessThan2SqKm = google.maps.geometry.spherical.computeArea(poly.getPath()) < TWO_SQ_KM;

      this.$scope.newNeighborhoodForm.polygon.$setValidity('outsidePolygon', isLocationInsidePolygon);
      this.$scope.newNeighborhoodForm.polygon.$setValidity('tooBigPolygon', isPolygonLessThan2SqKm);
      this.registrationData.geometry.polygon = polygon;
    });
  }

  proceed() {
    this.$scope.newNeighborhoodForm.$setSubmitted();
    if (this.$scope.newNeighborhoodForm.$invalid) {
      return;
    }
    this.registrationData.isNewNeighborhood = true;
    this.trackingService.track('New Neighborhood', {
      address: this.registrationData.address,
      neighborhoodName: this.registrationData.neighborhoodName
    });
    this.$state.go('registration.form');
  }

  skip() {
    this.registrationData.geometry.polygon = this.getCirclePolygonAroundLocation(this.registrationData.geometry.location);
    this.registrationData.geometry.isDefaultPolygon = true;
    this.proceed();
  }

  getCirclePolygonAroundLocation(location) {
    location = new google.maps.LatLng(location);
    const TWO_HUNDERD_METERS = 200;
    const STEPS = 4;
    const DEGREE_STEP = 360 / STEPS;
    let polygon = [];

    for (let i = 0; i < STEPS; i++){
      let pos = google.maps.geometry.spherical.computeOffset(location, TWO_HUNDERD_METERS, DEGREE_STEP * i);
      polygon.push(pos.toJSON());
    }
    return polygon;
  }
}

angular.module('ll')
  .component('newNeighborhood', {
    template: require('./new-neighborhood.html'),
    controller: NewNeighborhoodController,
    controllerAs: 'vm'
  })
  .config(createRoute({
    name: 'registration.newNeighborhood',
    component: 'newNeighborhood',
    url: '/new-neighborhood'
  }));

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

Sentry.init({
  // eslint-disable-next-line no-undef
  dsn: __SENTRY_DSN__,
  // eslint-disable-next-line no-undef
  environment: __ENVIRONMENT__,
  integrations: [
    new Integrations.Angular(),
  ],
  ignoreErrors: [/^SideNav 'sideMenu' is not available.*$/]
});

import angular from 'angular';

class FacilityService {
  constructor($http, LoggingService, EnvironmentSettings) {
    this.$http = $http;
    this.loggingService = LoggingService;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
  }

  getFacilities(level) {
    return this.$http.get(`${this.labradorUrl}/api/facility/list/${level}`)
      .then(resp => {
        // resp.data[0].images = [resp.data[0].imageUrl];//todo remove that line once the images array is added in the api
        return resp.data;
      }, resp => this.loggingService.error('Error fetching facilities', resp));
  }

  getFacility(id) {
    return this.$http.get(`${this.labradorUrl}/api/facility/${id}`)
      .then(
        resp => resp.data,
        resp => this.loggingService.error('Error fetching facility', resp));
  }

  getFacilitySettings(id) {
    return this.$http.get(`${this.labradorUrl}/api/facility/${id}/settings`)
      .then(resp => resp.data,
        resp => this.loggingService.error('Error getting facility settings', resp)
      );
  }

  createOrUpdateFacility(facility) {
    return this.$http.post(`${this.labradorUrl}/api/facility`, facility)
      .then(resp => resp.data, resp => this.loggingService.error('Error creating or updating facility', resp));
  }

  deleteFacility(facilityId) {
    return this.$http.delete(`${this.labradorUrl}/api/facility/${facilityId}`)
      .then(resp => resp.data, resp => this.loggingService.error('Error deleting facility', resp));
  }

  createBooking(booking) {
    return this.$http.post(`${this.labradorUrl}/api/facility/${booking.facilityId}/booking`, booking)
      .then(resp => resp.data, resp => this.loggingService.error('Error creating booking', resp));
  }

  getBookingSlotsForFacility(facilityId, yearMonth) {
    return this.$http.get(`${this.labradorUrl}/api/facility/${facilityId}/slots`, {params: {yearMonth}})
      .then(resp => resp.data, resp => this.loggingService.error('Error getting booking slots for facility', resp));
  }

  getBookingsForFacility(facilityId, yearMonth) {
    return this.$http.get(`${this.labradorUrl}/api/facility/${facilityId}/booking/${yearMonth}`)
      .then(resp => resp.data, resp => this.loggingService.error('Error getting bookings for facility', resp));
  }

  getMyBookingsForFacility(facilityId) {
    return this.$http.get(`${this.labradorUrl}/api/facility/${facilityId}/booking/my`)
      .then(resp => resp.data, resp => this.loggingService.error('Error getting my bookings for facility', resp));
  }

  getBookingDetails(bookingId) {
    return this.$http.get(`${this.labradorUrl}/api/facility/booking/${bookingId}`)
      .then(resp => {
        return resp.data;
      }, resp => this.loggingService.error('Error fetching booking details', resp));
  }

  deleteBooking(bookingId) {
    return this.$http.delete(`${this.labradorUrl}/api/facility/booking/${bookingId}`)
      .then(resp => resp.data, resp => this.loggingService.error('Error deleting booking', resp));
  }
}

angular.module('ll').service('FacilityService', FacilityService);

import angular from 'angular';

class DateService {
  constructor(moment, $translate) {
    this.moment = moment;
    this.$translate = $translate;
  }

  getFormattedDateForPeriod(date, period) {
    let formattedDate = date;
    let startDate = this.moment(date);
    if (period === PERIOD_TYPES.DAY) {
      formattedDate = startDate.format('dd D MMM YYYY');
    } else if (period === PERIOD_TYPES.WEEK) {
      let lastDateOfWeek = this.moment(date).endOf('isoWeek');
      let doesEndInSameMonth = startDate.format('YYYY MM') === lastDateOfWeek.format('YYYY MM');
      let weekDateString = startDate.format('D');
      if (doesEndInSameMonth) {
        weekDateString += '-' + lastDateOfWeek.format('D');
      } else {
        weekDateString += ' ' + startDate.format('MMM') + ' - ' + lastDateOfWeek.format('D');
      }
      weekDateString += ' ' + lastDateOfWeek.format('MMM YYYY');
      formattedDate = weekDateString;
    } else if (period === PERIOD_TYPES.MONTH) {
      formattedDate = startDate.format('MMM YYYY');
    }
    return formattedDate;
  }

  getDaysFromNowRelative(daysFromNow) {
    if (daysFromNow !== null && daysFromNow !== undefined) {
      if (daysFromNow === 0) {
        return this.$translate.instant('GENERAL.RELATIVE_DATE.TODAY');
      } else if (daysFromNow === 1) {
        return this.$translate.instant('GENERAL.RELATIVE_DATE.TOMORROW');
      } else {
        let weekdayIndex = this.moment().add(daysFromNow, 'days').format('e'); // 0-6
        let weekday = this.$translate.instant('GENERAL.WEEKDAYS_LONG.' + weekdayIndex);
        return this.$translate.instant('GENERAL.RELATIVE_DATE.ON_WEEKDAY', {weekday});
      }
    } else {
      return '';
    }
  }
}

angular.module('ll').service('DateService', DateService);

const PERIOD_TYPES = {
  'DAY': 'day',
  'WEEK': 'week',
  'MONTH': 'month'
};

import angular from 'angular';

class RegistrationApi {
  constructor($http, EnvironmentSettings) {
    this.$http = $http;
    this.apiUrl = EnvironmentSettings.labradorUrl;
  }

  getRegistrationDetailsByActivationCode(activationCode) {
    let params = {activationCode};
    return this.$http.get(`${this.apiUrl}/api/registration`, {params}).then(resp => resp.data).catch(resp => ({activationCode}));
  }

  getRegistrationDetailsByPlaceId(placeId) {
    let params = {placeId};

    return this.$http.get(`${this.apiUrl}/api/registration`, {params}).then(resp => resp.data).catch(resp => {
      return {placeId};
    });
  }

  createAccount(registrationData) {
    return this.$http.post(`${this.apiUrl}/api/registration`, registrationData);
  }

  checkEmail(email) {
    let params = {email};
    return this.$http.get(`${this.apiUrl}/api/registration/check`, {params});
  }
}

angular.module('ll').service('RegistrationApi', RegistrationApi);

import angular from 'angular';

angular.module('ll').factory('SVGService', SVGService);

function SVGService() {
  return {
    buildTrianglePoints(radius, center, angle) {
      const points = [];

      for (let i = 0; i < 3; i++) {
        points.push({
          x: center.x + radius * Math.cos(angle + i * 2 * Math.PI / 3),
          y: center.y + radius * Math.sin(angle + i * 2 * Math.PI / 3)
        });
      }
      return `${points[0].x} ${points[0].y}, ${points[1].x} ${points[1].y}, ${points[2].x} ${points[2].y}`;
    }
  };
}

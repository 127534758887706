import angular from 'angular';
import {createRoute} from '../../core/config';

class PropertyController {

  constructor(SessionService, ToolbarService, $mdMedia, ToastMessageService, $translate) {
    this.sessionService = SessionService;
    this.toolbarService = ToolbarService;
    this.$mdMedia = $mdMedia;
    this.toastMessageService = ToastMessageService;
    this.$translate = $translate;
  }

  $onInit(){
    this.toolbarService.setPageTitle(this.property.name);
    let currentUser = this.sessionService.getUser();
    this.showPropertyFiles = currentUser.hasEnabledFeature('property_files');
    this.showPropertyBoardMessages = currentUser.hasEnabledFeature('property_board_messages');
    this.showPropertyFacilities = currentUser.hasEnabledFeature('property_facilities');
  }
}

angular.module('ll')
  .component('property', {
    template: require('./property.html'),
    controller: PropertyController,
    controllerAs: 'vm',
    bindings: {
      property: '<'
    }
  })
  .config(createRoute({
    name: 'app.property',
    component: 'property',
    url: '/property?categoryId',
    params: {
      categoryId: null
    },
    resolve: {
      property: (PropertyService) => PropertyService.getPropertyDetails()
    },
    redirectTo: 'app.property.feed'
  }));

import angular from 'angular';

angular.module('ll')
  .filter('mimeTypeIcon', MimeTypeIcon);

function MimeTypeIcon() {
  return function (mimeType) {
    switch (mimeType) {
      case 'application/pdf':
        return 'insert_drive_file';
      case 'image/png':
      case 'image/gif':
      case 'image/jpeg':
      case 'image/bmp':
        return 'image';
      default:
        return 'cloud_download';
    }
  };
}

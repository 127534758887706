import angular from 'angular';

class SlideMenuController {
  constructor($state, $mdSidenav) {
    this.$state = $state;
    this.$mdSidenav = $mdSidenav;
  }

  $onChanges(change) {
    this.user = change.user ? change.user.currentValue : this.user;
  }
}


angular.module('ll').component('slideMenu', {
  transclude: true,
  template: require('./slide-menu.component.html'),
  controller: SlideMenuController,
  controllerAs: 'vm',
  bindings: {
    user: '<'
  }
});

import angular from 'angular';

// HACK TO MAKE THE NG-MAP DIRECTIVE CALLBACK HAVE ACCESS TO THE CORRECT 'this' REFERENCE
let __THIS;

class FindMyAreaController {
  constructor($state, $scope, EnvironmentSettings, TrackingService) {
    this.$state = $state;
    this.$scope = $scope;
    this.country = {country: EnvironmentSettings.country};
    this.trackingService = TrackingService;
  }

  $onInit() {
    this.isAddressSelected = false;
    this.placeId = null;
    this.trackingService.track('Find My Area');
    this.isDoingCall = false;
    __THIS = this;
  }

  onAddressSelected() {
    let place = this.getPlace();
    if (!place.geometry) {
      return;
    }

    let notStreetAddressOrPremise = !(place.types.includes('street_address') || place.types.includes('premise') || __THIS.country.country === 'pt' && place.types.includes('route'));
    if (notStreetAddressOrPremise) {
      __THIS.$scope.addressForm.address.$setValidity('streetAddress', false);
      __THIS.askStreetNumber = true;
      return;
    }
    __THIS.$scope.addressForm.address.$setValidity('streetAddress', true);
    __THIS.placeId = place.place_id;
    __THIS.isAddressSelected = true;
  }

  findNeighborhood() {
    this.isDoingCall = true;
    this.$state.go('registration.form', {placeId: this.placeId});
  }


}

angular.module('ll').component('findMyArea', {
  template: require('./findmyarea.component.html'),
  controller: FindMyAreaController,
  controllerAs: 'vm'
});

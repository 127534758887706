import angular from 'angular';
import {createRoute} from '../../core/config';

class ProfileFeedComponent {
  constructor(FeedService) {
    this.feedService = FeedService;
  }

  loadPosts(page, pageSize) {
    return this.feedService.getProfileFeed(this.user.userId, page, pageSize);
  }
}

angular.module('ll')
  .component('profileFeed', {
    template: '<feed disable-composer="true" on-feed-bottom="vm.loadPosts(page, pageSize)" show-sfa="false"></feed>',
    controller: ProfileFeedComponent,
    controllerAs: 'vm',
    bindings: {
      user: '<',
    }
  })
  .config(createRoute({
    name: 'app.profile.feed',
    url: '/feed',
    views: {
      profile: 'profileFeed'
    }
  }));

import angular from 'angular';
import {createRoute} from '../../core/config';

class SurveyPageController {
  constructor(ToolbarService, $translate) {
    this.$translate = $translate;
    this.toolbarService = ToolbarService;
  }
  $onInit() {
    // TODO: come up with better way of knowing when to show the message
    this.showNeedToCompleteMessage = this.surveyRoundKey === 'SIGNUP'; // Temporary fix: show for signup surveys.
    this.toolbarService.setPageTitle(this.$translate.instant('SURVEY.TOOLBAR_TITLE'));
  }
}

angular.module('ll')
  .component('surveyPage', {
    template: require('./surveyPage.html'),
    controller: SurveyPageController,
    controllerAs: 'vm',
    bindings: {surveyKey: '<', surveyRoundKey: '<'}
  })
  .config(createRoute({
    name: 'app.surveyPage',
    component: 'surveyPage',
    url: '/survey/:surveyKey/:surveyRoundKey',
    resolve: {
      surveyKey: ($transition$) => $transition$.params().surveyKey,
      surveyRoundKey: ($transition$) => $transition$.params().surveyRoundKey
    },
  }));

import angular from 'angular';

class FeedPostPostEditComponentController {

  constructor(PostService) {
    this.postService = PostService;
  }

  $onInit() {
    this.attachments = this.post.attachedImages || [];
    this.originalAttachments = this.attachments;
  }

  cancel() {
    this.onCancel();
  }

  save() {
    let oldImageIds = this.originalAttachments.filter(a => this.attachments.find(o => o.id === a.id)).map(o => o.id);
    let newImages = this.attachments.filter(a => a.blob).map(a => a.blob);
    this.postService.editPost(this.post.postId, this.post.subject, this.post.content, oldImageIds, newImages)
      .then(p => {
        this.post = p;
        this.$onInit();
        this.onSave({post: p});
      });
  }

  attachFile(file) {
    if (this.attachments.length <= 4) {
      this.attachments.push({...file, feed: file.url});
    }
  }

  removeAttachment(url) {
    this.attachments = this.attachments.filter(a => a.feed !== url);
  }
}

angular.module('ll').component('feedpostPostEdit', {
  template: require('./feedpost-post-edit.component.html'),
  controller: FeedPostPostEditComponentController,
  controllerAs: 'vm',
  bindings: {
    post: '<',
    onSave: '&',
    onCancel: '&'
  }
});

import angular from 'angular';

angular.module('ll')
  .factory('EnergyService', EnergyService);

function EnergyService($http, EnvironmentSettings, SessionService, TrackingService, $translate, $mdDialog) {

  const baseUrl = EnvironmentSettings.baseUrl;
  const labradorUrl = EnvironmentSettings.labradorUrl;

  return {
    createConsent,
    signExistingLiveInLabConsent,
    getHouseholdDetails,
    updateHouseholdDetails,
    getConsent,
    getConsents,
    getConsentByAccessCode,
    saveDsoDataByAccessCode,
    getConsumptionStats,
    getConsumptionVsTypical,
    getElectricityData,
    getNextPauseHour,
    getNextPauseHourAndSavingsGoal,
    pauseHourParticipate,
    pauseHourCancel,
    getPauseHourSummary,
    getPauseHourHistory,
    getPauseHoursParticipated,
    checkGmhubActivationCode,
    updateGmhubStatus,
    gmhubImportHistoricalData,
    openExplanationDialog,
    getStateBasedOnUser,
    getEnergyTip,
    getPauseHourTip
  };

  function createConsent(consent) {

    const data = {
      personalNumber: consent.personalNumber,
      electricityFacilityId: consent.electricityFacilityId,
      moveInYearMonth: consent.moveInYearMonth,
      apartmentNumber: consent.apartmentNumber,
      householdType: consent.householdType,
      electricityRetailer: consent.electricityRetailer
    };

    return $http.post(`${baseUrl}/api/electricitydata_consent`, data)
      .then(resp => resp.data);
  }

  function signExistingLiveInLabConsent() {
    return $http.put(`${labradorUrl}/api/data_consent/liveinlab/sign_existing`)
      .then(resp => resp.data);
  }

  function getHouseholdDetails() {
    return $http.get(`${baseUrl}/api/household`)
      .then(resp => resp.data);
  }

  function updateHouseholdDetails(householdDetails) {

    const data = {
      householdType: householdDetails.householdType,
      householdArea: householdDetails.householdArea,
      numberOfAdults: householdDetails.numberOfAdults,
      numberOfChildren: householdDetails.numberOfChildren,
      numberOfPeopleAtHomeDuringWorkHours: householdDetails.numberOfPeopleAtHomeDuringWorkHours,
      hasWashingMachine: householdDetails.hasWashingMachine ? 1 : 0
    };

    return $http.post(`${baseUrl}/api/household/update`, data)
      .then(resp => resp.data);
  }

  function getConsent(consentId, doUpdateStatusFromEgreement) {
    let updateStatus = doUpdateStatusFromEgreement === true ? 1 : 0;
    return $http.get(`${baseUrl}/api/electricitydata_consent/${consentId}/${updateStatus}`)
      .then(resp => resp.data);
  }

  function getConsents() {
    return $http.get(`${baseUrl}/api/electricitydata_consent/list`)
      .then(resp => resp.data);
  }

  function getConsentByAccessCode(accessCode) {
    return $http.get(`${baseUrl}/api/electricitydata_consent/accesscode/${accessCode}`)
      .then(resp => resp.data);
  }

  function saveDsoDataByAccessCode(facilityId, electricityDataInterval, needsAttention, dsoComment, accessCode) {
    needsAttention = needsAttention ? 1 : 0;
    return $http.post(`${baseUrl}/api/electricitydata_consent/accesscode/${accessCode}/dsodata`, {facilityId, electricityDataInterval, needsAttention, dsoComment})
      .then(resp => resp.data);
  }

  function getConsumptionStats(force) {
    let forceBit = force ? 1 : 0;
    return $http.get(`${baseUrl}/api/electricitydata/stats/${forceBit}`)
      .then(resp => resp.data);
  }

  function getConsumptionVsTypical(level, period, date, isDemo) {
    let isDemoBit = isDemo ? 1 : 0;
    let dateParam = date ? date : 0;
    return $http.get(`${baseUrl}/api/electricitydata/vs_typical/${level}/${period}/${dateParam}/${isDemoBit}`)
      .then(resp => resp.data);
  }

  function getElectricityData(datetimeFrom, datetimeTo, timePeriodNK, isDemo) {
    let isDemoBit = isDemo ? 1 : 0;
    return $http.get(`${baseUrl}/api/electricitydata/from/${datetimeFrom}/to/${datetimeTo}/${timePeriodNK}/${isDemoBit}`)
      .then(resp => resp.data);
  }

  function getNextPauseHour() {
    return $http.get(`${labradorUrl}/api/energy/pausehour/next`)
      .then(resp => resp.data);
  }

  function getNextPauseHourAndSavingsGoal() {
    return $http.get(`${baseUrl}/api/electricitydata/pausehour/next`)
      .then(resp => resp.data);
  }

  function pauseHourParticipate(pauseHourId) {
    return $http.get(`${baseUrl}/api/electricitydata/pausehour/${pauseHourId}/participate`)
      .then(resp => resp.data);
  }

  function pauseHourCancel(pauseHourId) {
    return $http.get(`${baseUrl}/api/electricitydata/pausehour/${pauseHourId}/cancel`)
      .then(resp => resp.data);
  }

  function getPauseHourSummary(isDemo) {
    return $http.get(`${labradorUrl}/api/sustainability/energy/pausehour/summary/${isDemo}`)
      .then(resp => resp.data);
  }

  function getPauseHourHistory() {
    return $http.get(`${labradorUrl}/api/sustainability/energy/pausehour/history`)
      .then(resp => resp.data);
  }

  function getPauseHoursParticipated(level, period, limit = 10, offset = 0) {
    return $http.get(`${baseUrl}/api/electricitydata/pausehour/participated/${level}/${period}/${limit}/${offset}`)
      .then(resp => resp.data);
  }

  function updateGmhubStatus(consentId) {
    return $http.get(`${baseUrl}/api/gmhub/updatestatus/${consentId}`)
      .then(resp => resp.data);
  }

  function gmhubImportHistoricalData() {
    return $http.get(`${baseUrl}/api/gmhub/import_historical_data/0`)
      .then(resp => resp.data);
  }

  function openExplanationDialog(type){
    TrackingService.track('energy.explanation_dialog.' + type + '.open');
    let title = 'ENERGY_EXPLANATIONS.' + type.toUpperCase() + '.TITLE';
    let content = 'ENERGY_EXPLANATIONS.' + type.toUpperCase() + '.CONTENT';
    let image = '/images/energy/' + type + '.png';

    return $mdDialog.show({
      template: require('./explanationDialog.tmpl.html'),
      locals: {
        title: title,
        content: content,
        image: image,
        alt: type
      },
      clickOutsideToClose: true,
      controllerAs: 'vm',
      controller: ExplanationDialogCtrl,
    });
  }

  function ExplanationDialogCtrl(title, content, image, alt, $mdDialog) {
    this.title = title;
    this.content = content;
    this.image = image;
    this.alt = alt;
    this.hideDialog = function () {
      $mdDialog.hide();
    };
  }

  function checkGmhubActivationCode(activationCode) {
    return $http.get(`${baseUrl}/api/gmhub/check_activation_code/${activationCode}`)
      .then(resp => resp.data);
  }

  async function getStateBasedOnUser() {
    let energyGoToPage = null;
    let energyParameters = null;
    let user = SessionService.getUser();
    let service = user.networkArea ? user.networkArea.service : null;
    let household = user.household;

    let hasElectricityData = false;

    if (household && household.electricityFacilityId) {
      let promise = getConsumptionStats();
      let consumptionStats = await promise;
      hasElectricityData = !!consumptionStats.dateFirstElectricityData;
    }

    if (hasElectricityData) {
      if (!household.hasGivenAllHouseholdDetails) {
        energyGoToPage = 'app.editHouseholdDetails';
        energyParameters = {forwardTo: 'energySummary'};
      } else {
        if (user.hasEnabledFeature('sustainability') && user.hasEnabledFeature('electricity')) {
          energyGoToPage = 'app.sustainability'; // New feedback page
        } else {
          energyGoToPage = 'app.energy.summary'; // Old feedback page
        }
      }
    } else if (user.hasSignedElectricitydataConsent() || user.hasVerifiedElectricitydataConsent()) {
      if (!household.hasGivenAllHouseholdDetails) {
        energyGoToPage = 'app.editHouseholdDetails';
        energyParameters = {forwardTo: 'serviceEnabled'};
      } else {
        if (service === 'gmhub') {
          energyGoToPage = 'app.gmhubEnergyJoinSuccess';
        } else {
          energyGoToPage = 'app.energyJoinSuccess';
        }
      }
    } else if (household && household.createdElectricitydataConsentId) {
      energyParameters = {consentId: user.household.createdElectricitydataConsentId};
      if (service === 'gmhub') {
        energyGoToPage = 'app.gmhubSign';
      } else {
        energyGoToPage = 'app.showElectricityDataConsent';
        energyParameters.doUpdateStatus = true;
      }

    } else if (user.canCreateConsent()) {
      energyGoToPage = 'app.energyJoin';
    }

    return {
      state: energyGoToPage,
      params: energyParameters
    };
  }

  function getEnergyTip() {
    let numberOfEnergyTips = 13;
    let randomIndex = Math.floor(Math.random() * numberOfEnergyTips);
    return $translate.instant('ENERGY_TIPS.ENERGY.' + randomIndex.toString());
  }

  function getPauseHourTip() {
    let numberOfPauseHourTips = 13;
    let randomIndex = Math.floor(Math.random() * numberOfPauseHourTips);
    return $translate.instant('ENERGY_TIPS.PAUSE_HOUR.' + randomIndex.toString());
  }
}

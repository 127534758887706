import angular from 'angular';
import {createRoute} from '../../core/config';

class AboutProjectHornslandetController {
  constructor(ToolbarService, TrackingService) {
    this.trackingService = TrackingService;
    this.toolbarService = ToolbarService;
  }

  $onInit() {
    this.trackingService.track('AboutProjectHornslandet');
    this.toolbarService.setPageTitle('Om projektet');
  }
}

angular.module('ll')
  .component('aboutProjectHornslandet', {
    template: require('./aboutProjectHornslandet.html'),
    controller: AboutProjectHornslandetController,
    controllerAs: 'vm',
  })
  .config(createRoute({
    name: 'app.aboutProjectHornslandet',
    component: 'aboutProjectHornslandet',
    url: '/about-project-hornslandet'
  }));

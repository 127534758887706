/* eslint-disable no-undef */
import angular from 'angular';

class PrinciplesController {
  constructor(TrackingService) {
    this.trackingService = TrackingService;
  }

  $onInit() {
    //this.trackingService.track('Principles');
  }
}

angular.module('ll').component('principles', {
  template: function(LanguageService){
    if (__ENVIRONMENT__ === 'portugal') {
      return require('./principles.component.pt.html');
    } else {
      let language = LanguageService.getLanguage();
      return language.startsWith('en') ? require('./principles.component.en.html') : require('./principles.component.sv.html');
    }
  },
  controller: PrinciplesController,
  controllerAs: 'vm'
});

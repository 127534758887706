import angular from 'angular';

class PostService {
  constructor($http, LoggingService, EnvironmentSettings, FeedPostFactory) {
    this.$http = $http;
    this.loggingService = LoggingService;
    this.feedPostFactory = FeedPostFactory;
    this.baseUrl = EnvironmentSettings.baseUrl;
    this.labradorUrl = EnvironmentSettings.labradorUrl;

    this.requestWithFormDataConfig = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };
  }

  comment(content, publicationId) {
    return this.$http.post(`${this.labradorUrl}/api/publication/${publicationId}/comment`, {content})
      .then(resp => resp.data, resp => this.loggingService.error('Error commenting on post', resp));
  }

  deleteComment(commentId) {
    return this.$http.delete(`${this.labradorUrl}/api/publication/comment/${commentId}`)
      .then(resp => resp.data, resp => this.loggingService.error('Error deleting post comment', resp));
  }

  getPost(postId) {
    return this.$http.get(`${this.labradorUrl}/api/publication/${postId}`)
      .then(resp => this.feedPostFactory.createFeedPost(resp.data))
      .catch(resp => this.loggingService.error('Error getting post', resp));
  }

  likePost(postId) {
    return this.$http.post(`${this.baseUrl}/api/post/${postId}/like`)
      .then(resp => this.feedPostFactory.createFeedPost(resp.data), resp => this.loggingService.error('Error liking post', resp));
  }

  unlikePost(postId) {
    return this.$http.delete(`${this.baseUrl}/api/post/${postId}/like`)
      .then(resp => this.feedPostFactory.createFeedPost(resp.data), resp => this.loggingService.error('Error disliking post', resp));
  }

  likeComment(commentId) {
    return this.$http.post(`${this.baseUrl}/api/comment/${commentId}/like`)
      .then(resp => resp.data, resp => this.loggingService.error('Error liking comment', resp));
  }

  unlikeComment(commentId) {
    return this.$http.delete(`${this.baseUrl}/api/comment/${commentId}/like`)
      .then(resp => resp.data, resp => this.loggingService.error('Error disliking comment', resp));
  }

  getComments(publicationId, offset, limit) {
    return this.$http.get(`${this.labradorUrl}/api/publication/${publicationId}/comments`, {params: {offset, limit}})
      .then(resp => resp.data, resp => this.loggingService.error('Error getting comments', resp));
  }

  editComment(commentId, content) {
    return this.$http.put(`${this.labradorUrl}/api/publication/comment/${commentId}`, {content})
      .then(resp => resp.data, resp => this.loggingService.error('Error editing comment', resp));
  }

  deletePost(publicationId) {
    return this.$http.delete(`${this.labradorUrl}/api/publication/${publicationId}`)
      .then(resp => resp.data, resp => this.loggingService.error('Error deleting post', resp));
  }

  // Not used in the current frontend. Was used previously by admins.
  // hidePost(postId, toggle) {
  //   let status = toggle ? 'hide' : 'show';
  //   return this.$http.put(`${this.baseUrl}/api/admin/post/${postId}`, {status})
  //     .then(resp => resp.data, resp => this.loggingService.error('Error hiding post', resp));
  // }

  editPost(publicationId, subject, content, oldImageIds, newImages) {
    let fd = new FormData();
    fd.append('subject', subject);
    fd.append('content', content);
    if (oldImageIds != null) {
      oldImageIds.forEach((id) => fd.append('oldImageIds', id));
    }
    newImages.forEach((f) => fd.append('newImages', f));

    return this.$http.put(`${this.labradorUrl}/api/publication/post/${publicationId}`, fd, this.requestWithFormDataConfig)
      .then(resp => this.feedPostFactory.createFeedPost(resp.data), resp => this.loggingService.error('Error editing a post', resp));
  }
}

angular.module('ll').service('PostService', PostService);

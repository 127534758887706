import angular from 'angular';

class NotificationService{
  constructor($http, EnvironmentSettings, LoggingService){
    this.$http = $http;
    this.baseUrl = EnvironmentSettings.baseUrl;
    this.loggingService = LoggingService;
  }

  getNotifications(page = 1, size = 10, sort = null, isRead = null, isNoticed = null){
    let data = {
      params: {
        page,
        size,
        ['_sort[createdAt]']: sort,
        isRead,
        isNoticed
      }
    };

    return this.$http.get(`${this.baseUrl}/api/notifications`, data)
      .then(resp => resp.data, resp => this.loggingService.error('Error getting notifications', resp));
  }

  getNotificationsCount(hideLoader = true){
    return this.$http.get(`${this.baseUrl}/api/notifications/count`,{hideLoader})
      .then(resp => resp.data, resp => this.loggingService.error('Error getting notifications count', resp));
  }

  markAsRead(notificationsIds){
    return this.$http.put(`${this.baseUrl}/api/notifications/read`, {notificationsIds})
      .then(resp => resp.data, resp => this.loggingService.error('Error marking notification as read', resp));
  }

  markAsNoticed(notificationsIds){
    return this.$http.put(`${this.baseUrl}/api/notifications/noticed`, {notificationsIds})
      .then(resp => resp.data, resp => this.loggingService.error('Error marking notification as noticed', resp));
  }

  markAllAsNoticed(hideLoader = true){
    return this.$http.put(`${this.baseUrl}/api/notifications/noticed/all`,{hideLoader})
      .then(resp => resp.data, resp => this.loggingService.error('Error marking all notifications as noticed', resp));
  }

  markAllAsRead(){
    return this.$http.put(`${this.baseUrl}/api/notifications/read/all`)
      .then(resp => resp.data, resp => this.loggingService.error('Error marking all notifications as read', resp));
  }
}


angular.module('ll').service('NotificationService', NotificationService);

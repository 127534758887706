import angular from 'angular';

class UserListController {
  constructor($state, SessionService) {
    this.$state = $state;
    this.sessionService = SessionService;
    this.selfId = 0;
  }

  $onInit() {
    this.users = this.sortUsers(this.users);
    this.selfId = this.sessionService.getUser().userId;
  }

  $onChanges(changes) {
    this.users = changes.users.currentValue ? this.sortUsers(changes.users.currentValue) : this.users;
  }

  sortUsers(users) {
    let sortedUsers = [];
    if (users) {
      sortedUsers = users.sort((n1, n2) => n1.getFullName().localeCompare(n2.getFullName(), 'sv'));
    }
    return sortedUsers;
  }

  goToProfile(userId) {
    this.$state.go('app.profile', {userId});
  }

  goToDirectMessage(userId) {
    this.$state.go('app.profile.directMessage', {userId});
  }
}

angular.module('ll').component('userList', {
  template: require('./userlist.component.html'),
  controller: UserListController,
  controllerAs: 'vm',
  bindings: {
    users: '<'
  }
});

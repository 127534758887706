import angular from 'angular';

class CommentEntryController {
  constructor(PostService, uiAlertService, $translate, ContentFeedbackService, $scope, ToastMessageService, LinkyService) {
    this.postService = PostService;
    this.uiAlertService = uiAlertService;
    this.$translate = $translate;
    this.contentFeedbackService = ContentFeedbackService;
    this.$scope = $scope;
    this.toastMessageService = ToastMessageService;
    this.linkyService = LinkyService;
  }

  $onInit() {
    this.showEdit = false;
  }

  flag(e) {
    this.contentFeedbackService.showReportContentDialog('comment', this.comment.commentId, e);
  }

  delete(e) {
    this.uiAlertService.showConfirm(this.$translate.instant('POST.DELETE.COMMENT'), this.$translate.instant('POST.DELETE.ARE_YOU_SURE'))
      .then(() => this.postService.deleteComment(this.comment.commentId))
      .then(() => this.onDelete({comment: this.comment}));
  }

  edit(e) {
    this.toggleEdit();
    if (this.showEdit) {
      this.resetEditForm();
    }
  }

  save(e) {
    this.$scope.editCommentForm.$setSubmitted();
    if (this.$scope.editCommentForm.$invalid) {
      return;
    }
    this.postService.editComment(this.comment.commentId, this.editedContent)
      .then(c => this.comment.content = this.editedContent)
      .then(c => this.toggleEdit())
      .catch(c => this.handleSaveError());
  }

  handleSaveError() {
    let errorMessage = this.$translate.instant('GENERAL.ERROR_SAVING');
    this.toastMessageService.showMessage(errorMessage);
  }

  toggleEdit() {
    this.showEdit = !this.showEdit;
  }

  resetEditForm() {
    this.editedContent = this.comment.content;
    this.$scope.editCommentForm.$setPristine();
    this.$scope.editCommentForm.$setUntouched();
  }
}

angular.module('ll').component('commentEntry', {
  template: require('./comment-entry.component.html'),
  controller: CommentEntryController,
  controllerAs: 'vm',
  bindings: {
    comment: '<',
    onDelete: '&'
  }
});

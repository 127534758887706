/* eslint-disable no-console,no-undef */
import angular from 'angular';

angular.module('ll').factory('TokenInterceptor', TokenInterceptor);

function TokenInterceptor($q, $state, SessionService, LoggingService) {
  return {
    request: function (config) {
      config.headers = config.headers || {};
      if (SessionService.hasAuthenticationToken()) {
        config.headers.Authorization = 'Bearer ' + SessionService.getAuthenticationToken();
      }
      return config;
    },

    requestError: function (rejection) {
      LoggingService.addBreadcrumb('Request data', {rejection});
      LoggingService.error('Request error');
      return $q.reject(rejection);
    },

    responseError: function (rejection) {
      if (rejection !== null && (rejection.status === 401 || rejection.status === -1)) {
        if (rejection.config !== null && rejection.config.url.indexOf('login_check') === 0) {
          let msg = rejection.status === -1 ? 'Preflight request rejected' : 'Request unauthorized';
          LoggingService.addBreadcrumb(msg, {rejection});
          LoggingService.error('TokenInterceptor Rejection');
          $state.go('logout');
        }
      }
      return $q.reject(rejection);
    }
  };
}

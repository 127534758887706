import angular from 'angular';

class CouponService {
  constructor($http, EnvironmentSettings) {
    this.$http = $http;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
  }

  getCoupons() {
    return this.$http.get(`${this.labradorUrl}/api/coupon`)
      .then(resp => {
        return resp.data;
      });
  }

  useCouponHandout(couponHandoutId) {
    return this.$http.post(`${this.labradorUrl}/api/coupon/use/${couponHandoutId}`)
      .then(resp => resp.data);
  }
}

angular.module('ll').service('CouponService', CouponService);

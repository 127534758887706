import angular from 'angular';
import {createRoute} from '../../core/config';

class InviteController {
  constructor($http, EnvironmentSettings, LoggingService, ToolbarService, $translate, $state, ToastMessageService, SessionService, TrackingService, $localStorage) {
    this.$http = $http;
    this.$translate = $translate;
    this.$state = $state;
    this.environmentSettings = EnvironmentSettings;
    this.loggingService = LoggingService;
    this.toastMessageService = ToastMessageService;
    this.trackingService = TrackingService;
    this.toolbarService = ToolbarService;
    this.sessionService = SessionService;
    this.$localStorage = $localStorage;
  }

  $onInit() {
    this.neighborhoodName = this.sessionService.getUser().neighborhood.name;
    this.trackingService.track('Invite');
    this.toolbarService.setPageTitle(this.$translate.instant('INVITE.TITLE'));
    this.disableInvite = this.checkDisplayInviteButton();
    this.showRequestSuccessMessage = false;
    this.showInviteNotice = !this.sessionService.getUser().isPropertyMember();
    this.username = this.sessionService.getUser().getFullName();
  }

  checkDisplayInviteButton() {
    let isPropertyMember = this.sessionService.getUser().isPropertyMember();
    if (!isPropertyMember) return false;
    return this.$localStorage.disableInvite || false;
  }

  send() {
    this.$http.post(`${this.environmentSettings.baseUrl}/api/service/users/invite`)
      .then(data => {
        this.$localStorage.disableInvite = true;
        this.showRequestSuccessMessage = true;
        return this.toastMessageService.showMessage(this.$translate.instant('INVITE.INVITATION_SENT_LINE_1'));
      })
      .catch(data => {
        this.loggingService.error('Error sending invitation', data);
        return this.toastMessageService.showMessage(this.$translate.instant('INVITE.SEND_ERROR'));
      });
  }
}

angular.module('ll')
  .component('invite', {
    template: require('./invite.html'),
    controller: InviteController,
    controllerAs: 'vm',
  })
  .config(createRoute({
    name: 'app.invite',
    component: 'invite',
    url: '/invite'
  }));

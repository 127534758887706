import angular from 'angular';

const GAUGE_FRACTION_OF_A_CIRCLE = 0.75;
const PADDING = 20;

class GaugeController {
  constructor(SVGService, $location){
    this.SVGService = SVGService;

    this.pagePath = $location.path();

    this.progressStyle = {};
    this.meterStyle = {};

    this.rotateDegree = 180 * (1 - GAUGE_FRACTION_OF_A_CIRCLE) + 90;
    this.strokeWidth = 20;
    this.emoticonMargin = 2.5;
    this.emoticonSize = this.strokeWidth - this.emoticonMargin * 2;
    this.triangleSize = 7.5;
    this.hollowTriangleStrokeWidth = this.triangleSize / 3;
    this.containerSize = 220;
    this.containerCenter = this.containerSize / 2;
    this.radius = this.containerCenter - PADDING - this.strokeWidth / 2;
  }
  $onInit() {
    this.minMaxRange = this.maxValue - this.minValue;
    this.progress = this.currentValue / this.minMaxRange;
    this.hollowTriangleProgress = this.hollowTriangleValue / this.minMaxRange;
    this.filledTriangleProgress = this.filledTriangleValue / this.minMaxRange;

    this.circumference = 2 * Math.PI * this.radius;

    this.meterStyle.strokeDasharray = this.circumference;
    this.meterStyle.strokeDashoffset =  this.circumference  * (1 - GAUGE_FRACTION_OF_A_CIRCLE);

    this.hollowTrianglePoints = this.SVGService.buildTrianglePoints( this.triangleSize - this.hollowTriangleStrokeWidth, {
      x: PADDING + this.radius * 2 + this.strokeWidth + this.triangleSize,
      y: this.containerCenter
    }, 45);

    this.filledTrianglePoints = this.SVGService.buildTrianglePoints( this.triangleSize, {
      x: PADDING + this.radius * 2 + this.strokeWidth + this.triangleSize,
      y: this.containerCenter
    }, 45);

    this.updateGaugeProgress();
  }

  updateGaugeProgress() {
    if (this.progress <= 0.33) {
      this.gradientType = 'low';
    } else if (this.progress <= 0.66) {
      this.gradientType = 'middle';
    } else {
      this.gradientType = 'high';
    }

    this.progressStyle.strokeDasharray = this.circumference;
    this.progressStyle.strokeDashoffset =  this.circumference * (1 - this.progress * GAUGE_FRACTION_OF_A_CIRCLE);

    this.emoticonX = this.containerCenter - this.emoticonSize / 2 + this.radius *
      Math.cos(GAUGE_FRACTION_OF_A_CIRCLE * 360 * this.progress * (Math.PI / 180));
    this.emoticonY = this.containerCenter - this.emoticonSize / 2  + this.radius *
      Math.sin(GAUGE_FRACTION_OF_A_CIRCLE * 360 * this.progress * (Math.PI / 180));
    this.emoticonCenterX = this.emoticonX + this.emoticonSize / 2;
    this.emoticonCenterY = this.emoticonY + this.emoticonSize / 2;

    this.hollowTriangleRotateDegree = GAUGE_FRACTION_OF_A_CIRCLE * 360 * this.hollowTriangleProgress;
    this.filledTriangleRotateDegree = GAUGE_FRACTION_OF_A_CIRCLE * 360 * this.filledTriangleProgress;
  }
}

angular.module('ll').component('llGauge', {
  template: require('./gauge.component.html'),
  controller: GaugeController,
  controllerAs: 'vm',
  bindings: {
    minValue: '<',
    maxValue: '<',
    currentValue: '<',
    hollowTriangleValue: '<',
    filledTriangleValue: '<',
    showSmile: '<'
  }
});

import angular from 'angular';
import {createRoute} from '../../core/config';

class CategoryFeedController {
  constructor(ToolbarService, SessionService, FeedService, $translate) {
    this.toolbarService = ToolbarService;
    this.sessionService = SessionService;
    this.feedService = FeedService;
    this.currentUser = null;
    this.$translate = $translate;
  }

  $onInit() {
    this.currentUser = this.sessionService.getUser();
    this.categoryId = this.category.id;
    this.toolbarService.setPageTitle(this.$translate.instant(this.category.name));
  }

  loadPosts(page, pageSize) {
    return this.feedService.getCategoryFeed(this.category.id, page, pageSize);
  }
}


angular.module('ll')
  .component('categoryFeed', {
    template: '<div><feed category-id="vm.categoryId" on-feed-bottom="vm.loadPosts(page, pageSize)"></feed></div>',
    controller: CategoryFeedController,
    controllerAs: 'vm',
    bindings: {
      category: '<'
    }
  })
  .config(createRoute({
    name: 'app.categoryFeed',
    component: 'categoryFeed',
    url: '/category/:categoryId',
    params: {
      categoryId: {
        type: 'int',
        value: 1
      }
    },
    resolve: {
      category: ($transition$, FeedService) => {
        let categoryId = $transition$.params().categoryId;
        return FeedService.getPostCategories().then(cs => cs.find(cat => cat.id === categoryId));
      }
    }
  }));

/* eslint-disable no-undef */
import angular from 'angular';
import {createRoute} from '../../core/config';

class ErrorReportWasteFacilityComponentController {

  constructor(DateService, ToolbarService, TrackingService, $mdMedia, FileReaderService, WasteService, $state, LoggingService, ToastMessageService, $translate) {
    this.$mdMedia = $mdMedia;
    this.dateService = DateService;
    this.toolbarService = ToolbarService;
    this.trackingService = TrackingService;
    this.fileReaderService = FileReaderService;
    this.wasteService = WasteService;
    this.$state = $state;
    this.loggingService = LoggingService;
    this.toastMessageService = ToastMessageService;
    this.$translate = $translate;
  }

  $onInit() {
    // this.wasteFacility = {
    //   'uuid': 682bc2d3-2d7c-4691-9f73-d0658b86f2f7,
    //   'type': 'waste_room',
    //   'description': 'Grythyndsgatan 2',
    //   'wasteCategories': [{name: 'PaperPackaging', isReported: false}, {name: 'PlasticPackaging', isReported: true}],
    //   'message': '',
    //   'statusChangedAt': null
    // }; // DEBUG
    // this.trackingService.track('wasteFacilityErrorReportForm');
    this.toolbarService.configure({title: this.$translate.instant('WASTE_INLET_ERROR_REPORTING.FACILITY_FORM.TITLE'), showBackButton: true});
    this.attachedFile = null;
    this.errorDescription = '';
    this.wasteFacilityDescription = this.wasteFacility.description;
  }

  selectFile(file, invalidFiles) {
    this.fileReaderService.loadFile(file, f => {
      this.attachedFile = f.file;
    });
  }

  send() {
    let blob = this.attachedFile ? this.attachedFile.blob : null;
    let maxAllowedFileSizeMB = 10;
    let photoFileSizeMb = blob ? Math.round( 100 * blob.size / 1024 / 1024) / 100 : null; // round to 2 decimals
    if (photoFileSizeMb && photoFileSizeMb > maxAllowedFileSizeMB) {
      let errorMessage = this.$translate.instant('WASTE_INLET_ERROR_REPORTING.FORM.ERROR_PHOTO_TOO_LARGE', {'sizeMB': photoFileSizeMb, 'maxAllowedSizeMB': maxAllowedFileSizeMB});
      this.toastMessageService.showMessage(errorMessage);
      return;
    }
    this.wasteService.reportFacilityError(this.wasteFacility.uuid, this.chosenWasteCategory, this.errorDescription, blob)
      .then(
        () => {
          this.$state.go('app.wasteFacility.errorReportSent', {issueType: this.chosenWasteCategory === 'OtherIssue' ? 'suggestion' : 'issue'});
        },
        (err) => {
          this.loggingService.error('Error saving error report for waste facility', err);
          let errorMessage = this.$translate.instant('WASTE_INLET_ERROR_REPORTING.FORM.ERROR_POST');
          this.toastMessageService.showMessage(errorMessage);
        });
  }
}

angular.module('ll').component('wasteFacilityErrorReportForm', {
  template: require('./error-report-waste-facility.component.html'),
  controller: ErrorReportWasteFacilityComponentController,
  controllerAs: 'vm',
  bindings: {
    wasteFacility: '<'
  }
})
  .config(createRoute({
    name: 'app.wasteFacility',
    abstract: true,
    url: '/waste-facility',
  }))
  .config(createRoute({
    name: 'app.wasteFacility.errorReport',
    component: 'wasteFacilityErrorReportForm',
    url: '/error',
    params: {
      wasteFacilityUuid: null
    },
    resolve: {
      wasteFacility: (WasteService, $transition$) => WasteService.getWasteFacility($transition$.params().wasteFacilityUuid)
    }
  })).component('wasteFacilityErrorReportSent', {
    template: require('./error-report-waste-facility-sent.component.html'),
    controllerAs: 'vm',
    bindings: {
      issueType: '<'
    }
  }).config(createRoute({
    name: 'app.wasteFacility.errorReportSent',
    component: 'wasteFacilityErrorReportSent',
    url: '/sent',
    params: {
      issueType: 'issue'
    },
    resolve: {
      issueType: ($transition$) => $transition$.params().issueType
    }
  }));

import angular from 'angular';
import {createRoute} from '../../core/config';

class EnergyPauseHourController{
  constructor($state, moment, EnergyService, ToolbarService, TrackingService, $translate){
    this.$state = $state;
    this.moment = moment;
    this.energyService = EnergyService;
    this.toolbarService = ToolbarService;
    this.trackingService = TrackingService;
    this.$translate = $translate;
    this.openExplanationDialog = EnergyService.openExplanationDialog;
    this.circle = {
      centerX: 125,
      centerY: 125,
      radius: 125
    };
    this.degreesArray = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360];
    this.previousPausHours = [];
    this.powerInWatts = {
      'vacuumCleaning': 1600,
      'ovenBaking': 2000,
      'microwaveOven': 800,
      'energySavingBulb': 11,
      'computer': 60
    };
  }

  $onInit(){
    this.trackingService.track('pausehourdetails.init');
    this.toolbarService.configure({
      title: this.$translate.instant('ENERGY_PAUSE_HOUR.PAUSE_HOUR'),
      showBackButton: false
    });
    if (this.pauseHourData !== null){
      this.setPauseHour();
      this.setSavingsActions();
    }
    this.energyService.getPauseHourSummary(false).then(data => this.pauseHourStats = data);
    this.energyService.getPauseHourHistory().then(data => this.setPreviousPauseHours(data));

    angular.element(document).ready(() => { if (this.pauseHourData) {this.drawClockArc();} });
    this.selectedTab = this.tab && this.tab === 'previous' ? 1 : 0;
  }

  polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    let angleInRadians = ( angleInDegrees - 90 ) * Math.PI / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    };
  }

  describeArc(x, y, radius, startAngle, endAngle){
    let start = this.polarToCartesian(x, y, radius, endAngle);
    let end = this.polarToCartesian(x, y, radius, startAngle);

    let largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    let d = [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(' ');

    return d;
  }

  setPauseHour(){
    let pauseHourLocalTimeStart = this.moment.utc(this.pauseHourData.datetimeUTC).local();
    let pauseHourLocalTimeEnd = this.moment.utc(this.pauseHourData.datetimeUTC).local().add(this.pauseHourData.lengthInHours,'hours');
    this.newPauseHourTitleTranslated = this.$translate.instant('ENERGY_PAUSE_HOUR.NEW_PAUSE_HOUR_' + Math.round(this.pauseHourData.lengthInHours));
    this.pauseHourDate = pauseHourLocalTimeStart.format('YYYY-MM-DD');
    this.pauseHourTimeStart = pauseHourLocalTimeStart.format('HH:mm');
    this.pauseHourTimeEnd = pauseHourLocalTimeEnd.format('HH:mm');
    this.pauseHourStart = pauseHourLocalTimeStart.calendar().toLowerCase();
    this.pauseHourStartRelative = this.moment().to(pauseHourLocalTimeStart);
  }

  setSavingsActions() {
    let savingsGoalKwh = this.pauseHourData.savingsGoalKwh;
    this.vacuumCleaningMinutes = Math.ceil(savingsGoalKwh / this.powerInWatts['vacuumCleaning'] * 1000 * 60);
    this.ovenBakingMinutes = Math.ceil(savingsGoalKwh / this.powerInWatts['ovenBaking'] * 1000 * 60);
    this.microwaveOvenMinutes = Math.ceil(savingsGoalKwh / this.powerInWatts['microwaveOven'] * 1000 * 60);
    this.energySavingBulbsAmount = Math.ceil(savingsGoalKwh * 1000 / this.powerInWatts['energySavingBulb']);
    this.computersAmount = Math.ceil(savingsGoalKwh * 1000 / this.powerInWatts['computer']);
  }

  drawClockArc(){
    this.pauseHourTimeStartInt =  parseInt(this.moment.utc(this.pauseHourData.datetimeUTC).local().format('h'));
    if (this.pauseHourTimeStartInt === 12) {
      this.pauseHourTimeStartInt = 0;
    }
    this.pauseHourTimeEndtInt = this.pauseHourTimeStartInt + parseInt(this.pauseHourData.lengthInHours);
    let startAngle = this.degreesArray[this.pauseHourTimeStartInt];
    let endAngle = this.degreesArray[this.pauseHourTimeEndtInt];
    document.getElementById('arc').setAttribute('d',this.describeArc(this.circle.centerX, this.circle.centerY, this.circle.radius, startAngle, endAngle));
  }

  setPreviousPauseHours(pauseHours){
    if (pauseHours.length > 0 && pauseHours[0].savedKwh === null){
      this.processingPauseHour = pauseHours[0];
      this.processingPauseHour.datetimeUTC = this.moment.utc(this.processingPauseHour.datetimeUTC).local().format('YYYY-MM-DD HH:mm');
      pauseHours.splice(0, 1);
    }
    this.previousPausHours = pauseHours;
  }

  participateEvent(){
    this.energyService.pauseHourParticipate(this.pauseHourData.id).then(() => {
      this.setSavingsActions();
      this.pauseHourData.isParticipating = true;
      this.pauseHourData.participants++;
    });
  }

  cancelEventParticipation(){
    this.energyService.pauseHourCancel(this.pauseHourData.id).then(() => {this.pauseHourData.isParticipating = false; this.pauseHourData.participants--;});
  }

}

angular.module('ll')
  .component('energyPauseHour', {
    template: require('./energypausehour.component.html'),
    controller: EnergyPauseHourController,
    controllerAs: 'vm',
    bindings: {
      tab: '<',
      pauseHourData: '<'
    }
  })
  .config(createRoute({
    name: 'app.energyPauseHour',
    component: 'energyPauseHour',
    url: '/energypausehour?{tab:string}',
    params: {
      pauseHourData: null,
      tab: null
    },
    resolve: {
      tab: ($transition$) => $transition$.params().tab,
      pauseHourData: ($transition$, EnergyService) => $transition$.params().pauseHourData ? $transition$.params().pauseHourData : EnergyService.getNextPauseHourAndSavingsGoal()
    },
    data: {authenticate: true}
  }));

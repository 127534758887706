import angular from 'angular';

angular.module('ll').service('PropertyService', PropertyService);

function PropertyService($http, LoggingService, EnvironmentSettings, UserFactory) {
  let baseUrl = EnvironmentSettings.baseUrl;
  let labUrl = EnvironmentSettings.labradorUrl;

  return {
    getPropertyDetails,
    getPropertyGroupTypes,
    getRegionProperties,
    getPropertyFiles,
    uploadFile,
    deleteFile,
    createProperty,
    chooseProperty,
    saveDescription,
    saveContactInfo,
    changeBackgroundImage
  };

  function getPropertyDetails() {
    return $http.get(`${labUrl}/api/property/my`)
      .then(resp => {
        // FIXME: fix to make the brf page show up. Board members are returned by other endpoints. Replaces the old PHP ${baseUrl}/api/brf endpoint.
        let property = resp.data;
        property.admins = []; // property.admins.map(admin => UserFactory.createUser(admin));
        property.board = []; // property.board.map(boardMember => UserFactory.createUser(boardMember));
        property.members = []; // property.members.map(member => UserFactory.createUser(member));
        return property;
      }, resp => LoggingService.error('Error fetching property details', resp));
  }

  function getPropertyGroupTypes() {
    return $http.get(`${baseUrl}/api/brf/propertygrouptypes`)
      .then(resp => resp.data, resp => LoggingService.error('Error fetching propertyGroupTypes', resp));
  }

  function getRegionProperties(regionId) {
    return $http.get(`${baseUrl}/api/region/${regionId}/brfs`)
      .then(resp => resp.data, resp => LoggingService.error('Error fetching region properties', resp));
  }

  function getPropertyFiles() {
    return $http.get(`${baseUrl}/api/brf/files`)
      .then(resp => resp.data, resp => LoggingService.error('Error fetching property files', resp));
  }

  function uploadFile(file, propertyId) {
    let data = new FormData();
    data.append('file', file);
    data.append('name', file.name);
    let config = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };
    return $http.post(`${baseUrl}/api/brf/${propertyId}/file`, data, config)
      .then(resp => resp.data, resp => LoggingService.error('Error uploading property file', resp));
  }

  function deleteFile(file, propertyId) {
    return $http.delete(`${baseUrl}/api/brf/${propertyId}/file/${file.id}`)
      .then(resp => resp.data, resp => LoggingService.error('Error deleting property file', resp));
  }

  function createProperty(name, propertyTypeId, regionId, doConnectUser = true) {
    let data = {
      regionId,
      name,
      propertygrouptypeId: propertyTypeId,
      doConnectUser
    };
    return $http.post(`${baseUrl}/api/brf`, data)
      .then(resp => resp.data, resp => LoggingService.error('Error creating property', resp));
  }

  function chooseProperty(propertyId, userId) {
    return $http.post(`${baseUrl}/api/brf/${propertyId}/users/${userId}`)
      .then(resp => resp.data, resp => LoggingService.error('Error linking user to property', resp));
  }

  function saveDescription(propertyId, description) {
    return $http.put(`${baseUrl}/api/brf/${propertyId}/description`, {description})
      .then(resp => resp.data, resp => LoggingService.error('Error saving property description', resp));
  }

  function saveContactInfo(propertyId, contactInfo) {
    return $http.put(`${baseUrl}/api/brf/${propertyId}/contactinfo`, {contactInfo})
      .then(resp => resp.data, resp => LoggingService.error('Error saving property contact information', resp));
  }

  function changeBackgroundImage(propertyId, image) {
    let fd = new FormData();
    fd.append('image', image);
    return uploadImage(`${baseUrl}/api/brf/${propertyId}/backgroundimage`, fd);
  }

  function uploadImage(url, data) {
    let config = {
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity
    };
    return $http.post(url, data, config).then(resp => resp.data)
      .catch(resp => LoggingService.error('Error uploading image', resp));
  }
}

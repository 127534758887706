import angular from 'angular';
import {createRoute} from '../../core/config';

class GmhubSignController{
  constructor($state, moment, EnergyService, LoggingService, $mdDialog, SessionService, ToolbarService, TrackingService, $translate, uiAlertService, UserService){
    this.$state = $state;
    this.moment = moment;
    this.energyService = EnergyService;
    this.loggingService = LoggingService;
    this.$mdDialog = $mdDialog;
    this.sessionService = SessionService;
    this.toolbarService = ToolbarService;
    this.trackingService = TrackingService;
    this.$translate = $translate;
    this.uiAlertService = uiAlertService;
    this.userService = UserService;
    this.isUpdating = false;
    this.showConsentNotSignedMessage = false;
  }

  $onInit(){
    this.trackingService.track('gmhub-sign.init');
    this.consentId = this.$state.params.consentId;
  }

  updateGmhubStatus(){
    this.isUpdating = true;
    this.showConsentNotSignedMessage = false;
    this.energyService.updateGmhubStatus(this.consentId).then(consent => {
      this.isUpdating = false;
      if (consent.status === 'SIGNED_BY_ALL') {
        this.userService.fetchLoggedInUser().then(() => {
          this.showConsentSignedDialog();
        });
      } else {
        this.showConsentNotSignedMessage = true;
      }
    }, resp => {
      this.isUpdating = false;
      this.loggingService.error('Error when calling checkGmhubStatus', resp);
      this.uiAlertService.showError(this.$translate.instant('GMHUB.SIGN.CHECK_CONSENT_ERROR'), '');
    });
  }

  showConsentSignedDialog(){
    this.$mdDialog.show({
      template: require('./gmhub-consent-signed-dialog.tmpl.html'),
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      fullscreen: false,
      controllerAs: 'vm',
      preserveScope : true,
      bindToController: true,
      // locals: vm.newConsent,
      controller: GmhubSignController
    });
  }

  onProceedClickInConsentSignedDialog() {
    this.closeDialog();
    this.$state.go('app.energy');
  }

  closeDialog() {
    this.$mdDialog.hide();
  }
}

angular.module('ll')
  .component('gmhubSign', {
    template: require('./gmhub-sign.component.html'),
    controller: GmhubSignController,
    controllerAs: 'vm',
  })
  .config(createRoute({
    name: 'app.gmhubSign',
    component: 'gmhubSign',
    url: '/gmhub-sign',
    params: {
      consentId: null
    },
    data: {authenticate: true}
  }));

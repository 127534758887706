/* eslint-disable no-console */
import angular from 'angular';

angular.module('ll.core').run(initializationHook);

function initializationHook(TrackingService, LoginService, LoggingService, SessionService, LanguageService) {
  TrackingService.track('App Init');
  if (SessionService.isAuthenticated()) {
    LoginService.initializeSessionService()
      .then(LoginService.initializeExternalServices);
  }
}

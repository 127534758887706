import angular from 'angular';
import {lock} from 'tua-body-scroll-lock';

class CustomPopupService {
  constructor($http, EnvironmentSettings, LoggingService) {
    this.$http = $http;
    this.labradorUrl = EnvironmentSettings.labradorUrl;
    this.loggingService = LoggingService;
  }

  lock() {
    const popupScrollableArea = document.querySelector('.custom-popup__inner'); // Area that should still be scrollable
    lock(popupScrollableArea);
  }
}

angular.module('ll').service('CustomPopupService', CustomPopupService);

import angular from 'angular';

class ImageThumbController {

  remove() {
    this.onRemove({src: this.src});
  }

}

angular.module('ll').component('imageThumb', {
  template: require('./imagethumb.component.html'),
  controller: ImageThumbController,
  controllerAs: 'vm',
  bindings: {
    src: '<',
    onRemove: '&'
  }
});

/* eslint-disable no-console */
import angular from 'angular';

const SORT_OPTIONS = [
  {name: 'SORT_BY.DATE_DESC', icon: 'calendar-star', value: {field: 'publication.createdAt', direction: 'desc'}},
  {name: 'SORT_BY.PRICE_ASC', icon: 'sort-numeric-up', value: {field: 'price', direction: 'asc'}},
  {name: 'SORT_BY.PRICE_DESC', icon: 'sort-numeric-down', value: {field: 'price', direction: 'desc'}}
];

class ClassifiedsListController {

  constructor(ClassifiedsService, ToolbarService, SessionService, $state, $translate, FeedPostComposerDialogService, $mdBottomSheet, $mdDialog, FeedService, TrackingService) {
    this.classifiedsService = ClassifiedsService;
    this.toolbarService = ToolbarService;
    this.sessionService = SessionService;
    this.$state = $state;
    this.$translate = $translate;
    this.feedPostComposerDialogService = FeedPostComposerDialogService;
    this.$mdBottomSheet = $mdBottomSheet;
    this.$mdDialog = $mdDialog;
    this.feedService = FeedService;
    this.trackingService = TrackingService;
  }

  $onInit() {
    this.title = this.$translate.instant('MENU.MARKETPLACE'); //this.$translate.instant(this.adType.postCategory);
    this.toolbarService.setPageTitle(this.title);
    this.pageSize = 20;
    this.page = 0;
    this.advertisements = [];
    this.sortField = this.sortField || 'publication.createdAt';
    this.sortDirection = this.sortDirection || 'desc';
    this.sortBy = this.getSortOption(this.sortField, this.sortDirection);
    this.searchText = this.searchText || null;
    this.disableScroll = false;
    this.isLoading = false;
    this.sortByOptions = this.getSortByOptions();
    this.freeItemsToggleName = 'CLASSIFIEDS.FREE_FILTER_BUTTON';
    this.currentUser = this.sessionService.getUser();
    this.trackingService.track('classifieds.list', {type: this.adType, category: this.adCategory});
    this.configureToolbar();
    this.loadItems();
  }

  getSortOption(sortField, sortDirection) {
    let sortOption = this.getSortByOptions().find(opt => opt.value.field === sortField && opt.value.direction === sortDirection);
    return sortOption ? sortOption : SORT_OPTIONS[0];
  }

  getSortByOptions() {
    return SORT_OPTIONS;
  }

  postItem($event) {
    this.feedPostComposerDialogService.open($event, {
      currentUser: this.currentUser,
      categoryGroups: ['marketplace'],
      showEventTab: false,
      showPollTab: false,
      showAlertTab: false,
      titleKey: 'CLASSIFIEDS.CREATE_AD',
      postTabTitleKey: 'NEWPOST.TYPE.AD'
    });
  }

  filterByAdCategory(adCategory) {
    this.adCategory = adCategory;
    this.doSearch();
  }

  doSort(opt) {
    this.sortBy = opt || this.sortBy;
    this.doSearch();
  }

  doSearch() {
    this.$state.go('app.classifieds.list', {
      search: this.searchText,
      sortField: this.sortBy.value.field,
      sortDirection: this.sortBy.value.direction,
      adType: this.adType.id,
      adCategory: this.adCategory.id,
      onlyFreeItems: this.onlyFreeItems
    });
  }

  loadItems() {
    if (this.disableScroll) return;

    this.disableScroll = true;
    this.isLoading = true;

    this.fetchData()
      .then(data => this.appendAdvertisements(data))
      .then(ads => this.disableScroll = ads.length < this.pageSize)
      .then(() => this.page++)
      .then(() => this.isLoading = false);
  }

  fetchData() {
    const sortBy = {field: this.sortField, direction: this.sortDirection};
    return this.classifiedsService.fetchAdvertisements(this.adType.id, this.adCategory.id, this.page, this.searchText, sortBy, this.pageSize, this.onlyFreeItems);
  }

  appendAdvertisements(advertisements) {
    advertisements.forEach(ad => this.advertisements.push(ad));
    return advertisements;
  }

  showBottomSheet(title, items, isCurrent) {
    return this.$mdBottomSheet.show({
      template: require('./classifieds-list-sort.html'),
      controller: BottomSheetController,
      controllerAs: 'vm',
      locals: {title, items, isCurrent}
    });
  }

  showMobileSortOptions() {
    return this.showBottomSheet('SORT_BY.SORT_BY', SORT_OPTIONS, (item) => item.name === this.sortBy.name)
      .then(sortOpts =>{
        document.body.style.touchAction = 'auto';
        this.doSort(sortOpts);
      }).catch(() => {
        document.body.style.touchAction = 'auto';
      });
  }

  showMobileAdCategories(event) {
    let title = 'CLASSIFIEDS.FILTER_BY_CATEGORIES';
    this.classifiedsService.getAdvertisementCategoriesFiltersGrouped()
      .then(categories => this.$mdDialog.show({
        template: require('./classifieds-list-filter.html'),
        controller: CategoriesFilterDialogController,
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true,
        fullscreen: true,
        locals: {title, categories}
      })).then(category => this.filterByAdCategory(category));
  }

  toggleFreeItems() {
    this.onlyFreeItems = !this.onlyFreeItems;
    this.doSearch();
  }

  configureToolbar(){
    this.toolbarService.configure({
      title: this.title,
      categoryId: this.adType.id
    });
  }
}

class CategoriesFilterDialogController {
  constructor(title, categories, $mdDialog) {
    this.title = title;
    this.categories = categories;
    this.$mdDialog = $mdDialog;
  }

  select(category) {
    this.$mdDialog.hide(category);
  }

  close() {
    this.$mdDialog.cancel();
  }
}

class BottomSheetController {
  constructor($mdBottomSheet, items, title, isCurrent) {
    this.$mdBottomSheet = $mdBottomSheet;
    this.items = items;
    this.title = title;
    this.isCurrent = isCurrent;
  }

  select(item) {
    this.$mdBottomSheet.hide(item);
  }
}

angular.module('ll')
  .component( 'advertisementList', {
    template: require('./classifieds-list.component.html'),
    controller: ClassifiedsListController,
    controllerAs: 'vm',
    bindings: {
      adCategory: '<',
      adType: '<',
      searchText: '<',
      sortField: '<',
      sortDirection: '<',
      onlyFreeItems: '<',
      postCategory: '<'
    }
  });

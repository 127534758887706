import angular from 'angular';

class CommentLikeController {
  constructor(PostService) {
    this.postService = PostService;
  }

  toggleLike() {
    let result;
    if (this.comment.liked) {
      result = this.postService.unlikeComment(this.comment.commentId);
    } else {
      result = this.postService.likeComment(this.comment.commentId);
    }
    result
      .then(c => this.comment = c.comments[0])
      .then(this.onLike({liked: this.comment.liked}));
  }
}

angular.module('ll').component('commentLike', {
  template: require('./comment-like.component.html'),
  controller: CommentLikeController,
  controllerAs: 'vm',
  bindings: {
    comment: '<',
    onLike: '&'
  }
});

import angular from 'angular';
import {createRoute} from '../../core/config';

const SUSTAINABILITY_SCOPES = ['household', 'building', 'neighborhood'];

class SustainabilityController {
  constructor($location, CustomPopupService, ToolbarService, $translate, $scope, $timeout, moment, $mdDialog, EnergyService, SustainabilityService, SessionService, TrackingService) {
    this.$location = $location;
    this.customPopupService = CustomPopupService;
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.moment = moment;
    this.$mdDialog = $mdDialog;
    this.energyService = EnergyService;
    this.sustainabilityService = SustainabilityService;
    this.sessionService = SessionService;
    this.trackingService = TrackingService;

    this.scopes = [];

    this.wellbeingEstimateValues = {
      community: 0,
      trust: 0,
      safety: 0,
      network: 0
    };

    this.chartScreensCache = {};

    this.apiColumnsMapping = {
      recycling: {
        showSmileFunc: (columnData) => false,
        mainValueKey: 'weightInKg',
        toppedValueKey: 'typicalWeightInKg',
        valueOnTopSuffix: 'kg',
        columns: [
          {
            apiKey: 'organic',
            titleKey: 'SUSTAINABILITY.ORGANIC'
          },
          {
            apiKey: 'paper',
            titleKey: 'SUSTAINABILITY.PAPER'
          },
          {
            apiKey: 'paperPackaging',
            titleKey: 'SUSTAINABILITY.PAPER_PACKAGING'
          },
          {
            apiKey: 'plastic',
            titleKey: 'SUSTAINABILITY.PLASTIC'
          },
          {
            apiKey: 'rest',
            titleKey: 'SUSTAINABILITY.REST'
          },
          {
            apiKey: 'unknown',
            titleKey: 'SUSTAINABILITY.UNKNOWN'
          },
        ]
      }
    };

    this.neighborhoodSettings = [
      {
        key: 'hornslandet',
        categorySettings: {
          recycling: {
            doShowProgressBarComparisons: false,
            doShowTopLabels: false,
            columnColors: [
              {
                apiKey: 'paper',
                'color': '#5090cf'
              },
              {
                apiKey: 'plastic',
                'color': '#ffb800'
              },
              {
                apiKey: 'rest',
                'color': '#9f9f9f'
              }
            ]
          }
        }
      }
    ];

    this.periods = [
      {
        key: 'day'
      },
      {
        key: 'week',
      },
      {
        key: 'month'
      },
      {
        key: 'p14days',
        daysValue: 14
      },
      {
        key: 'p30days',
        daysValue: 30
      },
      {
        key: 'p60days',
        daysValue: 60
      },
      {
        key: 'p90days',
        daysValue: 90
      },
      {
        key: 'p365days',
        daysValue: 365
      }
    ];

    this.defaultMaxValues = {
      recycling: null
    };

    this.maxValues = {... this.defaultMaxValues};

    this.dateFrom = null;

    this.hideGauge = true;
    this.showSharing = false;
    this.showRecycling = false;
    this.showElectricity = false;
    this.showWater = false;
    this.showWellbeing = false;

    this.isDemoScores = false;
    this.isDemoSharing = false;
    this.isDemoRecycling = false;
    this.isDemoElectricity = false;
    this.isDemoWater = false;
    this.isDemoWellbeing = false;

    this.whatDoesThisMeanPopupShown = false;

    this.user = this.sessionService.getUser();

    for (let scope of SUSTAINABILITY_SCOPES) {
      if (scope === 'household' || this.user.hasEnabledFeature('level_' + scope)) {
        this.scopes.push({
          id: scope,
          sref: 'app.sustainability.' + scope,
          titleKey: 'SUSTAINABILITY.TAB_TITLE.' + scope.toUpperCase(),
        });
      }
    }
  }

  $onInit() {
    this.trackingService.track('sustainability.init');
    let selectedPeriodKey = this.initialPeriod ? this.initialPeriod : 'month';
    this.selectedPeriod = this.periods.find(p => p.key === selectedPeriodKey);
    this.selectedPeriod.selected = true; // needed for date-period-picker
    this.neighborhoodName = this.user.neighborhood.name;
    this.propertyName = this.user.property ? this.user.property.name : null;
    this.currentScope = this.$location.path().split('/')[2];
    this.currentScopeTextTranslated = this.$translate.instant('SUSTAINABILITY.MY_' + this.currentScope.toUpperCase(), {neighborhood: this.neighborhoodName});
    this.comparedToTranslated = this.$translate.instant('SUSTAINABILITY.COMPARED_TO_OTHERS_' + this.currentScope.toUpperCase());
    this.ofTranslated = this.$translate.instant('GENERAL.OF');
    this.toolbarService.setPageTitle(this.$translate.instant('SUSTAINABILITY.TITLE'));
    this.enabledFeatures = this.user.getEnabledFeatures();
    this.setCategoriesVisibility();
    this.setIsDemoParameters();
    this.currentNeighbourhoodSettings = this.neighborhoodSettings.find(s => s.key === this.user.registrationFlow);
    let household = this.user.household;
    this.hasStartedSigningProcess = household && !!household.createdElectricitydataConsentId;
    this.hasSignedElectricityDataConsent = household && household.hasSignedElectricitydataConsent && household.hasGivenAllHouseholdDetails;
    this.hasElectricityData = household && !!household.datetimeFirstElectricityData;
    if (this.reportPopup) {
      this.openReportPopup(this.reportPopup);
    }
  }

  setCategoriesVisibility() {
    let enabledFeatures = this.enabledFeatures;
    this.isComingSoonMode = enabledFeatures.includes('is_coming_soon_mode');
    this.showTitleBox = enabledFeatures.includes('sustainability_title_box');
    this.isComingSoonExpanded = !this.isComingSoonMode;
    this.feedbackTypesCounter = 0;
    if (enabledFeatures) {
      this.showSharing = enabledFeatures.includes('sharing');
      this.showRecycling = enabledFeatures.includes('recycling');
      this.showElectricity = enabledFeatures.includes('electricity');
      this.showWater = enabledFeatures.includes('water');
      this.showWellbeing = enabledFeatures.includes('wellbeing');
      this.feedbackTypesCounter = this.showSharing + this.showRecycling + this.showElectricity + this.showWater + this.showWellbeing;
    }
  }

  setIsDemoParameters() {
    let enabledFeatures = this.enabledFeatures;
    if (enabledFeatures) {
      this.isDemoScores = enabledFeatures.includes('scores_demo');
      this.isDemoSharing = enabledFeatures.includes('sharing_demo');
      this.isDemoRecycling = enabledFeatures.includes('recycling_demo');
      this.isDemoElectricity = enabledFeatures.includes('electricity_demo');
      this.isDemoWater = enabledFeatures.includes('water_demo');
      this.isDemoWellbeing = enabledFeatures.includes('wellbeing_demo');
    }
  }

  onWhatDoesThisMeanClick() {
    this.trackingService.track('sustainability.whatDoesThisMean.click');
    this.whatDoesThisMeanPopupShown = true;
    this.customPopupService.lock();
  }

  dateFromOrPeriodUpdated(dateFrom, dateTo, period) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    if (this.selectedPeriod !== period) {
      this.maxValues = {... this.defaultMaxValues};
    }
    this.selectedPeriod = period;

    this.initAll();
  }

  loadGeneralScores() {
    return this.sustainabilityService.getGeneralScores(this.currentScope, this.selectedPeriod.key, this.dateFrom, this.isDemoScores);
  }

  loadCategoryData(generalScores) {
    return Promise.all([
      // Total score
      generalScores,
      // Category details
      this.showSharing ? this.sustainabilityService.getCategoryDetails('sharing', this.currentScope, this.selectedPeriod.key, this.dateFrom, this.isDemoSharing) : null,
      this.showRecycling ? this.sustainabilityService.getCategoryDetails('recycling', this.currentScope, this.selectedPeriod.key, this.dateFrom, this.isDemoRecycling) : null,
      this.showElectricity ? this.sustainabilityService.getCategoryDetails('energy', this.currentScope, this.selectedPeriod.key, this.dateFrom, this.isDemoElectricity) : null,
      // Special calls for electricity
      this.showElectricity ? this.energyService.getPauseHourSummary(this.isDemoElectricity) : null,
      this.showElectricity ? this.energyService.getNextPauseHour() : null,
      // End special calls
      this.showWater ? this.sustainabilityService.getCategoryDetails('water', this.currentScope, this.selectedPeriod.key, this.dateFrom, this.isDemoWater) : null,
      this.showWellbeing ? this.sustainabilityService.getCategoryDetails('wellbeing', this.currentScope, this.selectedPeriod.key, this.dateFrom, this.isDemoWellbeing) : null,
      // Chart metadata
      this.showSharing ?  this.sustainabilityService.getChartMetadata('sharing', this.currentScope, this.selectedPeriod.key, this.dateFrom, this.isDemoSharing) : null,
      this.showRecycling ? this.sustainabilityService.getChartMetadata('recycling', this.currentScope, 'month', this.dateFrom, this.isDemoRecycling) : null,
      this.showWater ? this.sustainabilityService.getChartMetadata('water', this.currentScope, this.selectedPeriod.key, this.dateFrom, this.isDemoWater) : null,
      this.showWellbeing ? this.sustainabilityService.getChartMetadata('wellbeing', this.currentScope, this.selectedPeriod.key, this.dateFrom, this.isDemoWellbeing) : null
    ]);
  }

  initSharing({generalScores, sharingCategoryDetails, sharingCategoryChartMetadata}) {
    const category = 'sharing';
    const suffixTranslated = this.$translate.instant('SUSTAINABILITY.N_THINGS');

    const chartScreen = this.chartScreensCache[category] || this.buildChartScreen({
      metadata: sharingCategoryChartMetadata,
      category: 'sharing',// todo change it later when the api gets available for other categories
      isPercent: false,
      suffix: suffixTranslated
    });
    this.chartScreensCache[category] = chartScreen;

    let numberOfTips = 1;
    let randomIndexTips = Math.floor(Math.random() * numberOfTips);

    this.sharingVisualizationConfig = {
      expanded: false,
      mainColor: '#8231F4',
      darkerColor: '#4a08a6',
      lighterColor: '#F3EBFE',
      mainIcon: 'hand-holding-box',
      mainTitleKey: 'SUSTAINABILITY.SHARING_AND_REUSING',
      popupShown: false,
      progressBar: this.getProgressBarValuesForCategory(generalScores, category),
      screens: [
        {
          type: 'columns',
          config: {
            min: 0,
            max: 5,
            columns: [
              {
                titleKey: 'SUSTAINABILITY.SHARING',
                valueText: sharingCategoryDetails.shared.value.toString() + suffixTranslated,
                currentValue: sharingCategoryDetails.shared.value.toString() ,
                showSmile: true
              },
              {
                titleKey: 'SUSTAINABILITY.REUSING',
                valueText: sharingCategoryDetails.reused.value.toString() + suffixTranslated,
                currentValue: sharingCategoryDetails.reused.value.toString() ,
                showSmile: true
              }
            ]
          },
          footer: {
            titleKey: 'SUSTAINABILITY.SHARING_PROGRESS.TITLE',
            text: this.$translate.instant('SUSTAINABILITY.SHARING_PROGRESS.TEXT', {numberOfTrees: 3})
          }
        },
        chartScreen,
        {
          type: 'plain',
          config: {
            iconProgress: {
              iconClass: 'tree-alt',
              value: 3,
              max: 5
            },
            titleKey: 'SUSTAINABILITY.SHARING_TREES.TITLE',
            text: this.$translate.instant('SUSTAINABILITY.SHARING_TREES.TEXT', {reducedCO2eWeightInKg: 120, numberOfTrees: 3, numberOfTreesToGo: 2})
          }
        },
        {
          type: 'plain',
          config: {
            iconClass: 'lightbulb-on',
            titleKey: 'SUSTAINABILITY.TIPS.SHARING_TITLE',
            text: this.$translate.instant('SUSTAINABILITY.TIPS.SHARING.' + randomIndexTips)
          }
        }
      ]
    };
  }

  initRecycling({recyclingCategoryDetails, recyclingCategoryChartMetadata}) {
    const category = 'recycling';

    const categoryColumnsMapping = this.apiColumnsMapping[category];
    const total = recyclingCategoryDetails.total[categoryColumnsMapping.mainValueKey];
    const prevTotal = recyclingCategoryDetails.totalWeightInKgLastPeriod;
    const differenceWithPreviousPeriod = Math.round((total - prevTotal) * 10) / 10;
    recyclingCategoryDetails.belowColumnsText = this.$translate.instant('SUSTAINABILITY.WASTE_GENERATED_SHORT', {totalWeightInKg: total ? total.toFixed(total < 10 ? 1 : 0) : '0'});
    const categorySettings = this.currentNeighbourhoodSettings && this.currentNeighbourhoodSettings.categorySettings && this.currentNeighbourhoodSettings.categorySettings[category];
    const doShowProgressBarComparisons = categorySettings && categorySettings.doShowProgressBarComparisons !== undefined ? categorySettings.doShowProgressBarComparisons : true;

    const columnsScreen = this.buildColumnsScreen({
      categoryDetails: recyclingCategoryDetails,
      footerTextContent: recyclingCategoryDetails.textContentRecycling,
      categoryColumnsMapping,
      maxValueFormula: this.maxValueBiggestPlus10Formula,
      categorySettings
    });

    recyclingCategoryChartMetadata.textContent.data.period = recyclingCategoryChartMetadata.dateLastFullMonthOfData ? this.getPeriodTranslated('month', recyclingCategoryChartMetadata.dateLastFullMonthOfData) : '';  // The chart shows months so always use month as period to the function
    recyclingCategoryChartMetadata.textContent.data.neighborhood = this.neighborhoodName;

    const chartScreen = this.chartScreensCache[category] || this.buildChartScreen({
      metadata: recyclingCategoryChartMetadata,
      category,
      isPercent: false,
      suffix: 'kg',
      doAllowChartHorizontalScroll: false,
      numDecimals: 1
    });
    if (this.chartScreensCache[category]) {
      chartScreen.footer = this.buildScreenFooter({textContent: recyclingCategoryChartMetadata.textContent});
    }
    this.chartScreensCache[category] = chartScreen;

    let numberOfTips = 7; // Change this if you add or remove tips in the translation files!
    let randomIndexTips = Math.floor(Math.random() * numberOfTips);

    // Lanes screen
    this.typicalConsumptionTranslated = this.$translate.instant('SUSTAINABILITY.TYPICAL_CONSUMPTION').toLowerCase();
    let weightInKgPerPerson = recyclingCategoryDetails.rest.weightInKgPerPerson;
    let weightInKgPerPersonAveragePerformers = recyclingCategoryDetails.rest.weightInKgPerPersonAveragePerformers;
    let weightInKgPerPersonTopPerformers = recyclingCategoryDetails.rest.weightInKgPerPersonTopPerformers;
    let weightInKgPerPersonTypical = recyclingCategoryDetails.rest.weightInKgPerPersonTypical;
    let percentRanking = recyclingCategoryDetails.rest.percentRanking;
    // TODO: decide if percentDiffVsTypical and selfBarTextValue should be calculated and if so, against which mean value.
    // let percentDiffVsTypical = weightInKgPerPerson != null ? Math.round(Math.abs(<typical weight> - weightInKgPerPerson) * 100) : null;
    // const selfBarTextValue = weightInKgPerPerson != null ? percentDiffVsTypical + '% ' + this.$translate.instant('ENERGY.' + (weightInKgPerPerson > <typical weight> ? 'OVER' : 'BELOW') ).toLowerCase() + ' ' + this.typicalConsumptionTranslated : null;
    let numberOfThumbs = 0;
    if (weightInKgPerPerson) {
      numberOfThumbs = percentRanking && percentRanking >= 90.0 ? 2 : weightInKgPerPerson < weightInKgPerPersonAveragePerformers ? 1 : 0;
    }

    const barLabelTranslateKeyBase = 'SUSTAINABILITY.BAR_LABELS.LEVEL.' + this.currentScope.toUpperCase() + '.';
    const maxIncomingValue = Math.max(weightInKgPerPerson, weightInKgPerPersonAveragePerformers);
    const selfValue = this.roundValue(maxIncomingValue >= 10000 ? weightInKgPerPerson / 1000.0 : weightInKgPerPerson);
    const avgValue = this.roundValue(maxIncomingValue >= 10000 ? weightInKgPerPersonAveragePerformers / 1000.0 : weightInKgPerPersonAveragePerformers);
    const bestValue = this.roundValue(maxIncomingValue >= 10000 ? weightInKgPerPersonTopPerformers / 1000.0 : weightInKgPerPersonTopPerformers);
    const typicalValue = this.roundValue(maxIncomingValue >= 10000 ? weightInKgPerPersonTypical / 1000.0 : weightInKgPerPersonTypical);
    const consumptionUnitShort = (maxIncomingValue >= 10000 ? 'ton' : 'kg') + '/p';
    const consumptionUnit = (maxIncomingValue >= 10000 ? 'ton' : 'kg') + '/' + this.$translate.instant('SUSTAINABILITY.PERSON');

    // Reuse most of recyclingCategoryChartMetadata.textContent for the lanes text content, just change the period and restWeightInKg

    let textContentRest = recyclingCategoryDetails.textContentRest.data;
    textContentRest.period = this.getPeriodTranslated(this.selectedPeriod.key, this.dateFrom); // TODO: make nicer period text
    textContentRest.perPerson = this.$translate.instant('SUSTAINABILITY.PER_PERSON') + ' ';
    textContentRest.neighborhood = this.neighborhoodName;

    this.maxValues.recycling = Math.max(weightInKgPerPerson, weightInKgPerPersonAveragePerformers, typicalValue * 1.1); // update the max value loaded this far

    const lanesScreenTitle = this.$translate.instant('SUSTAINABILITY.REST_WASTE');

    const lanesScreen = {
      type: 'lanes',
      config: {
        min: 0,
        average: typicalValue, // The typical flag
        max: this.maxValues.recycling,
        flagTitle: this.$translate.instant('SUSTAINABILITY.SWEDISH_AVERAGE') + '<br />' + typicalValue + ' ' + consumptionUnitShort,
        flagTextNoWrap: true,
        title: lanesScreenTitle,
        lanes: [
          {
            title: this.$translate.instant(barLabelTranslateKeyBase + 'SELF', {
              building: this.propertyName,
              neighborhood: this.neighborhoodName
            }),
            textValue: null, // selfBarTextValue,
            textValueNumberOfThumbs: null, // Thumbs are shown in the bottom text instead.
            laneLabel: (selfValue != null ? selfValue : '?') + ' ' + consumptionUnit,
            currentValue: weightInKgPerPerson,
            highlight: true,
            showSmile: false
          },
          {
            title: this.$translate.instant(barLabelTranslateKeyBase + 'BEST', {
              scope: this.$translate.instant('SUSTAINABILITY.' + this.currentScope.toUpperCase()),
              neighborhood: this.neighborhoodName
            }),
            currentValue: weightInKgPerPersonTopPerformers,
            laneLabel: (bestValue != null ? bestValue : '?') + ' ' + consumptionUnit,
          },
          {
            title: this.$translate.instant(barLabelTranslateKeyBase + 'AVERAGE', {
              scope: this.$translate.instant('SUSTAINABILITY.' + this.currentScope.toUpperCase()),
              neighborhood: this.neighborhoodName
            }),
            currentValue: weightInKgPerPersonAveragePerformers,
            laneLabel: (avgValue != null ? avgValue : '?') + ' ' + consumptionUnit,
            showSmile: false
          }
        ]
      },
      footer: this.buildScreenFooter({textContent: recyclingCategoryDetails.textContentRest, numberOfThumbs})
    };

    // Total recycling config
    this.recyclingVisualizationConfig = {
      totalKg: total,
      differenceWithPreviousPeriod: differenceWithPreviousPeriod,
      expanded: true,
      mainColor: '#27B942', // originally green '#27B942'
      lighterColor: '#F3FCF6', // originally light green '#0A5317'
      darkerColor: '#0F8024',
      mainIcon: 'recycle',
      mainTitleKey: 'SUSTAINABILITY.RECYCLING',
      popupShown: false,
      progressBar: this.getProgressBarValues(recyclingCategoryDetails.scores, doShowProgressBarComparisons),
      screens: [
        lanesScreen,
        columnsScreen,
        chartScreen,
        {
          type: 'plain',
          config: {
            iconClass: 'lightbulb-on',
            titleKey: 'SUSTAINABILITY.TIPS.RECYCLING_TITLE',
            text:  this.$translate.instant('SUSTAINABILITY.TIPS.RECYCLING.' + randomIndexTips) + '<br /><br />' + this.$translate.instant('SUSTAINABILITY.TIPS.RECYCLING_FOOTER')
          }
        }
      ]
    };
  }

  initElectricity({electricityCategoryDetails, pauseHourStats, nextPauseHour}) {
    let numberOfTips = 13;
    let randomIndexTips = Math.floor(Math.random() * numberOfTips);

    let energyMessages = this.getEnergyMessages(this.selectedPeriod.key, this.currentScope, this.dateFrom, electricityCategoryDetails.textContent.titleKey, electricityCategoryDetails.textContent.messageKey, this.hasSignedElectricityDataConsent, this.hasElectricityData);

    this.typicalConsumptionTranslated = this.$translate.instant('SUSTAINABILITY.TYPICAL_CONSUMPTION').toLowerCase();
    let selfFactor = electricityCategoryDetails.selfFactor;
    let percentDiffVsTypical = selfFactor != null ? Math.round(Math.abs(1 - selfFactor) * 100) : null;
    const selfBarTextValue = selfFactor != null ? percentDiffVsTypical + '% ' + this.$translate.instant('ENERGY.' + (selfFactor > 1.0 ? 'OVER' : 'BELOW') ).toLowerCase() + ' ' + this.typicalConsumptionTranslated : null;
    let numberOfThumbs = 0;
    if (selfFactor) {
      numberOfThumbs = selfFactor <= 0.4 ? 2 : selfFactor < 1 ? 1 : 0;
    }

    const barLabelTranslateKeyBase = 'SUSTAINABILITY.BAR_LABELS.LEVEL.' + this.currentScope.toUpperCase() + '.';
    const selfConsumptionValue = this.roundValue(electricityCategoryDetails.selfKwh >= 10000 ? electricityCategoryDetails.selfKwh / 1000.0 : electricityCategoryDetails.selfKwh);
    const selfConsumptionUnit = electricityCategoryDetails.selfKwh >= 10000 ? 'MWh' : 'kWh';

    this.electricityVisualizationConfig = {
      mainColor: '#FFCF00',
      darkerColor: '#D4AB00',
      lighterColor: '#FFFDF2',
      mainIcon: 'bolt',
      mainTitleKey: 'SUSTAINABILITY.POWER_CONSUMPTION',
      popupShown: false,
      progressBar: this.getProgressBarValues(electricityCategoryDetails.scores),
      expanded: this.feedbackTypesCounter === 1,
      nextPauseHour,
      screens: [
        {
          type: 'lanes',
          config: {
            min: 0,
            average: 1.0,
            max: Math.max(1.0, electricityCategoryDetails.averageFactor, electricityCategoryDetails.selfFactor),
            flagTitle: this.$translate.instant('SUSTAINABILITY.TYPICAL_CONSUMPTION'),
            lanes: [
              {
                title: this.$translate.instant(barLabelTranslateKeyBase + 'SELF', {
                  building: this.propertyName,
                  neighborhood: this.neighborhoodName
                }),
                textValue: selfBarTextValue,
                textValueNumberOfThumbs: numberOfThumbs,
                currentValue: selfFactor,
                laneLabel: (selfConsumptionValue != null ? selfConsumptionValue : '?') + ' ' + selfConsumptionUnit,
                highlight: true,
                showSmile: false
              },
              {
                title: this.$translate.instant(barLabelTranslateKeyBase + 'BEST', {
                  scope: this.$translate.instant('SUSTAINABILITY.' + this.currentScope.toUpperCase()),
                  neighborhood: this.neighborhoodName
                }),
                currentValue: electricityCategoryDetails.bestFactor,
              },
              {
                title: this.$translate.instant(barLabelTranslateKeyBase + 'AVERAGE', {
                  scope: this.$translate.instant('SUSTAINABILITY.' + this.currentScope.toUpperCase()),
                  neighborhood: this.neighborhoodName
                }),
                currentValue: electricityCategoryDetails.averageFactor,
                showSmile: false
              }
            ]
          },
          footer: {
            title: energyMessages.translatedTitle,
            text: energyMessages.translatedMessage + (energyMessages.translatedEndText ? ' ' + energyMessages.translatedEndText : '')
          }
        },
        {
          type: 'lanes',
          config: {
            min: 0,
            max: pauseHourStats ? pauseHourStats.attempted : 0,
            lanes: [
              {
                title: this.$translate.instant('SUSTAINABILITY.PAUSE_HOURS'),
                currentValue: pauseHourStats ? pauseHourStats.completed : 0,
                textValue: pauseHourStats ? pauseHourStats.completed + ' ' + this.ofTranslated + ' ' + pauseHourStats.attempted : '0 ' + this.ofTranslated + ' 0',
                showSmile: pauseHourStats.completed && pauseHourStats.attempted && pauseHourStats.completed / pauseHourStats.attempted >= 0.75
              },
            ]
          }
        },
        {
          type: 'plain',
          config: {
            iconClass: 'lightbulb-on',
            titleKey: 'SUSTAINABILITY.TIPS.ENERGY_TITLE',
            text: this.$translate.instant('ENERGY_TIPS.ENERGY.' + randomIndexTips)
          }
        }
      ]
    };
  }

  getEnergyMessages(period, level, dateFrom, outcome, messageKey, hasSignedElectricityDataConsent, hasElectricityData) {
    if (!messageKey) {
      if (!hasElectricityData) {
        if (!hasSignedElectricityDataConsent) {
          return {
            translatedTitle: this.$translate.instant('SUSTAINABILITY.ELECTRICITY_JOIN.TITLE'),
            translatedMessage: this.$translate.instant('SUSTAINABILITY.ELECTRICITY_JOIN.TEXT'),
            translatedEndText: null
          };
        } else {
          return {
            translatedTitle: this.$translate.instant('SUSTAINABILITY.ELECTRICITY_WAITING_FOR_DATA.TITLE'),
            translatedMessage: this.$translate.instant('SUSTAINABILITY.ELECTRICITY_WAITING_FOR_DATA.TEXT'),
            translatedEndText: null
          };
        }
      }
      return {
        translatedTitle: this.$translate.instant('SUSTAINABILITY.NO_DATA.TITLE'),
        translatedMessage: this.$translate.instant('SUSTAINABILITY.NO_DATA.TEXT'),
        translatedEndText: null
      };
    }

    let today = this.moment();
    let lastPeriodKey = null;
    let dateCurrentPeriod = null;
    let dateData = this.moment(dateFrom);
    if (period === 'day') {
      dateCurrentPeriod = today.startOf('day');
      if (dateCurrentPeriod.isSame(dateData)) {
        lastPeriodKey = 'YESTERDAY';
      } else if (dateCurrentPeriod.add(-1, 'days').isSame(dateData)) {
        lastPeriodKey = 'DAY_BEFORE_YESTERDAY';
      } else {
        lastPeriodKey = 'THE_DAY_BEFORE';
      }
    } else if (period === 'week') {
      dateCurrentPeriod = today.startOf('week');
      if (dateCurrentPeriod.isSame(dateData)) {
        lastPeriodKey = 'LAST_WEEK';
      } else {
        lastPeriodKey = 'THE_WEEK_BEFORE';
      }
    } else if (period === 'month') {
      dateCurrentPeriod = today.startOf('month');
      if (dateCurrentPeriod.isSame(dateData)) {
        lastPeriodKey = 'LAST_MONTH';
      } else {
        lastPeriodKey = 'THE_MONTH_BEFORE';
      }
    }
    let lastPeriodTranslated = this.$translate.instant('ENERGY.PERIOD.' + lastPeriodKey);

    let levelUppercase = level.toUpperCase();
    let youSingularOrPlural = this.$translate.instant('ENERGY.FEEDBACK.YOU.' + (levelUppercase === 'HOUSEHOLD' ? 'SINGULAR' : 'PLURAL'));
    let randomIndexTitle = Math.floor(Math.random() * 3);
    let randomIndexEndText = Math.floor(Math.random() * 3);

    let outcomeEmoji = this.getOutcomeEmoji(outcome);

    return {
      translatedTitle: this.$translate.instant('ENERGY.FEEDBACK.TITLE.' + outcome.toUpperCase() + '.' + randomIndexTitle, {youSingularOrPlural: youSingularOrPlural}) + (outcomeEmoji ? ' ' + outcomeEmoji : ''),
      translatedMessage: this.$translate.instant('ENERGY.FEEDBACK.' + levelUppercase + '.' + messageKey,
        {
          lastPeriod: lastPeriodTranslated,
          neighbourhood: this.neighborhoodName,
          property: this.propertyName,
          propertyType: this.propertyTypeTranslated,
          propertyTypePlural: this.propertyTypePluralTranslated
        }),
      translatedEndText: this.$translate.instant('ENERGY.FEEDBACK.END_TEXT.' + outcome.toUpperCase() + '.' + randomIndexEndText, {youSingularOrPlural: youSingularOrPlural})
    };
  }

  getPeriodTranslated(period, date) {
    if (period === 'month') {
      return this.$translate.instant('SUSTAINABILITY.IN_PERIOD.MONTH') + ' ' + this.moment(date).format('MMMM');
    } else {
      return this.$translate.instant('SUSTAINABILITY.IN_PERIOD.DEFAULT');
    }
  }

  getOutcomeEmoji(outcome) {
    switch (outcome) {
      case 'negative':
        return '🤔';
      case 'neutral':
        return '😏';
      case 'positive':
        return '😊';
      case 'very_positive':
        return '😄👍';
      default:
        return null;
    }
  }

  initWellbeing({generalScores, wellbeingCategoryDetails, wellbeingCategoryChartMetadata}) {
    const category = 'wellbeing';

    let numberOfTips = 1;
    let randomIndexTips = Math.floor(Math.random() * numberOfTips);

    this.canSubmitWellbeingFeedback = true;
    this.wellbeingFeedbackButtonTranslateKey = 'SUSTAINABILITY.TELL_US_HOW_YOU_FEEL';

    if (wellbeingCategoryChartMetadata.textContent.data == null) {
      wellbeingCategoryChartMetadata.textContent.data = {};
    }

    wellbeingCategoryChartMetadata.textContent.data.neighborhood = this.neighborhoodName;

    const chartScreen = this.chartScreensCache[category] || this.buildChartScreen({
      metadata: wellbeingCategoryChartMetadata,
      category: 'wellbeing',
      isPercent: false,
      maxForced: 10,
      numDecimals: 1
    });
    this.chartScreensCache[category] = chartScreen;

    let barValues = {
      community: wellbeingCategoryDetails.community ? wellbeingCategoryDetails.community.value : null,
      trust: wellbeingCategoryDetails.trust ? wellbeingCategoryDetails.trust.value : null,
      safety: wellbeingCategoryDetails.safety ? wellbeingCategoryDetails.safety.value : null,
      network: wellbeingCategoryDetails.network ? wellbeingCategoryDetails.network.value : null,
    };

    this.wellbeingVisualizationConfig = {
      expanded: false,
      mainColor: '#F43073',
      darkerColor: '#A6083E',
      lighterColor: '#FEEAF1',
      mainIcon: 'heart',
      mainTitleKey: 'SUSTAINABILITY.WELLBEING',
      popupShown: false,
      progressBar: this.getProgressBarValuesForCategory(generalScores, category),
      dateLastAnswered: wellbeingCategoryDetails.dateLastAnswered,
      screens: [
        {
          type: 'columns',
          config: {
            min: 0,
            max: 10,
            columns: [
              {
                titleKey: 'SUSTAINABILITY.COMMUNITY',
                valueText: barValues.community ? barValues.community.toString() : '',
                currentValue: barValues.community ? barValues.community : null,
              },
              {
                titleKey: 'SUSTAINABILITY.TRUST',
                valueText: barValues.trust ? barValues.trust.toString() : '',
                currentValue: barValues.trust ? barValues.trust : null,
              },
              {
                titleKey: 'SUSTAINABILITY.SAFETY',
                valueText: barValues.safety ? barValues.safety.toString() : '',
                currentValue: barValues.safety ? barValues.safety : null,
              },
              {
                titleKey: 'SUSTAINABILITY.NETWORK',
                valueText: barValues.network ? barValues.network.toString() : '',
                currentValue: barValues.network ? barValues.network : null,
              }
            ]
          },
          footer: {
            titleKey: 'SUSTAINABILITY.' + wellbeingCategoryDetails.textContent.titleKey,
            text: this.$translate.instant('SUSTAINABILITY.' + wellbeingCategoryDetails.textContent.messageKey)
          }
        },
        chartScreen,
        {
          type: 'plain',
          config: {
            iconClass: 'lightbulb-on',
            titleKey: 'SUSTAINABILITY.TIPS.WELLBEING.' + randomIndexTips + '.TITLE',
            text: this.$translate.instant('SUSTAINABILITY.TIPS.WELLBEING.' + randomIndexTips + '.TEXT')
          }
        }
      ]
    };
  }

  initWater({generalScores, waterCategoryDetails, waterCategoryChartMetadata}) {
    const category = 'water';

    const chartScreen = this.chartScreensCache[category] || this.buildChartScreen({
      metadata: waterCategoryChartMetadata,
      category: 'water',// todo change it later when the api gets available for other categories
      isPercent: false,
      suffix: 'l'
    });
    this.chartScreensCache[category] = chartScreen;

    let numberOfTips = 1;
    let randomIndexTips = Math.floor(Math.random() * numberOfTips);

    this.waterVisualizationConfig = {
      expanded: false,
      mainColor: '#2FC4F4',
      darkerColor: '#087fa5',
      lighterColor: '#CEF4FB',
      mainIcon: 'tint',
      mainTitleKey: 'SUSTAINABILITY.WATER_USAGE',
      popupShown: false,
      progressBar: this.getProgressBarValuesForCategory(generalScores, category),
      screens: [
        {
          type: 'lanes',
          config: {
            min: 0,
            average: 20,
            max: 30,
            flagTitle:  this.$translate.instant('SUSTAINABILITY.TYPICAL_CONSUMPTION'),
            lanes: [
              {
                title: this.$translate.instant('SUSTAINABILITY.WATER_MY_SCOPE', {
                  scope: this.$translate.instant('SUSTAINABILITY.' + this.currentScope.toUpperCase()),
                  neighborhood: this.neighborhoodName,
                  liter: 28,
                  highlight: true
                }),
                currentValue: 28,
                showSmile: false
              },
              {
                title: this.$translate.instant('SUSTAINABILITY.ELECTRICITY_TOP_10', {
                  scope: this.$translate.instant('SUSTAINABILITY.' + this.currentScope.toUpperCase()),
                  neighborhood: this.neighborhoodName
                }),
                currentValue: 9,
              },
              {
                title: this.$translate.instant('SUSTAINABILITY.ELECTRICITY_OTHER_SCOPES', {
                  scope: this.$translate.instant('SUSTAINABILITY.' + this.currentScope.toUpperCase()),
                  neighborhood: this.neighborhoodName
                }),
                currentValue: 19,
                showSmile: false
              }
            ]
          },
          footer: {
            titleKey: 'SUSTAINABILITY.WATER_PROGRESS.TITLE',
            text: this.$translate.instant('SUSTAINABILITY.WATER_PROGRESS.TEXT', {
              weeklyLiter: 1148,
              dailyLiter: 164,
              increase: 12
            })
          }
        },
        chartScreen,
        {
          type: 'plain',
          config: {
            iconClass: 'lightbulb-on',
            titleKey: 'SUSTAINABILITY.TIPS.WATER_TITLE',
            text:  this.$translate.instant('SUSTAINABILITY.TIPS.WATER.' + randomIndexTips)
          }
        }
      ]
    };
  }

  getProgressBarValuesForCategory(generalScores, category, doShowProgressBarComparisons = true, min = 0, max = 10) {
    const categoryScores = generalScores[category];
    return this.getProgressBarValues(categoryScores, doShowProgressBarComparisons, min, max);
  }

  getProgressBarValues(scores, doShowProgressBarComparisons = true, min = 0, max = 10) {
    return {
      min,
      max,
      currentValue: scores ? scores.score : null,
      hollowTriangleValue: scores ? scores.average : null,
      filledTriangleValue: scores ? scores.best : null,
      doShowProgressBarComparisons
    };
  }

  buildColumnsConfig({categoryDetails, categoryColumnsMapping, maxValueFormula, categorySettings}) {
    const config = {
      min: 0,
      max: maxValueFormula.call(this, categoryDetails, categoryColumnsMapping),
      doShowTopLabels: categorySettings ? categorySettings.doShowTopLabels : false,
      belowColumnsText: categoryDetails.belowColumnsText,
      columns: []
    };

    for (let column of categoryColumnsMapping.columns) {
      const columnData = categoryDetails[column.apiKey];
      if (columnData.isAvailable) {
        const columnValue = columnData[categoryColumnsMapping.mainValueKey];
        const columnToppedValueSubstrate = columnData[categoryColumnsMapping.toppedValueKey];
        const columnValuePercentage = columnValue ? Math.round(columnValue / categoryDetails.total[categoryColumnsMapping.mainValueKey] * 100) : 0;
        const colorObj = categorySettings && categorySettings.columnColors ? categorySettings.columnColors.find(s => s.apiKey === column.apiKey) : null;
        config.columns.push({
          titleKey: column.titleKey,
          valueText: columnValuePercentage + '%',
          currentValue: columnValue,
          valueOnTop: (columnValue ? columnValue.toFixed(columnValue < 10 ? 1 : 0) : '0') + ' ' + categoryColumnsMapping.valueOnTopSuffix,
          showSmile: categoryColumnsMapping.showSmileFunc(columnData),
          toppedValue: columnToppedValueSubstrate && columnValue > columnToppedValueSubstrate ? columnValue - columnToppedValueSubstrate : null,
          color: colorObj && colorObj.color ? colorObj.color : null
        });
      }
    }


    return config;
  }

  maxValueBiggestPlus10Formula(categoryDetails, categoryColumnsMapping) {
    let maxValue = 0;

    for (let column of categoryColumnsMapping.columns) {
      const columnValue = categoryDetails[column.apiKey][categoryColumnsMapping.mainValueKey];
      maxValue = columnValue > maxValue ? columnValue : maxValue;
    }

    let maxValuePercentage = maxValue / categoryDetails.total[categoryColumnsMapping.mainValueKey] + 0.1;
    maxValuePercentage = maxValuePercentage + 0.1 > 1 ? 1 : maxValuePercentage + 0.1;

    return categoryDetails.total[categoryColumnsMapping.mainValueKey] * maxValuePercentage;
  }

  initAll() {
    this.loadGeneralScores().then(generalScores => {
      this.loadCategoryData(generalScores).then(([generalScores, sharingCategoryDetails, recyclingCategoryDetails, electricityCategoryDetails, pauseHourStats, nextPauseHour, waterCategoryDetails, wellbeingCategoryDetails,
        sharingCategoryChartMetadata, recyclingCategoryChartMetadata, waterCategoryChartMetadata, wellbeingCategoryChartMetadata]) => {
        if (generalScores.total) {
          this.totalScore = generalScores.total.score;
          this.averageInScopeTotalScore = generalScores.total.average;
          this.bestInScopeTotalScore = generalScores.total.best;
        }

        if (this.showSharing) {
          this.initSharing({generalScores, sharingCategoryDetails, sharingCategoryChartMetadata});
        }

        if (this.showRecycling) {
          this.initRecycling({recyclingCategoryDetails, recyclingCategoryChartMetadata});
        }

        if (this.showElectricity) {
          this.initElectricity({electricityCategoryDetails, pauseHourStats, nextPauseHour});
        }

        if (this.showWellbeing) {
          this.initWellbeing({generalScores, wellbeingCategoryDetails, wellbeingCategoryChartMetadata});
        }

        if (this.showWater) {
          this.initWater({generalScores, waterCategoryDetails, waterCategoryChartMetadata});
        }

        // Show total score if we have at least two types of feedback
        if ( this.feedbackTypesCounter > 1) {
          this.hideGauge = false;
        }

        this.allDataLoaded = true;
        this.$scope.$apply();
      });
    });
  }

  buildColumnsScreen({categoryDetails, footerTextContent, categoryColumnsMapping, maxValueFormula, categorySettings}) {
    return {
      type: 'columns',
      config: this.buildColumnsConfig({categoryDetails, categoryColumnsMapping, maxValueFormula, categorySettings}),
      footer: this.buildScreenFooter({textContent: footerTextContent})
    };
  }

  buildScreenFooter({textContent, numberOfThumbs = null}) {
    return {
      titleKey: this.$translate.instant('SUSTAINABILITY.' + textContent.titleKey, textContent.data),
      text: this.$translate.instant('SUSTAINABILITY.' + textContent.messageKey, textContent.data),
      numberOfThumbs
    };
  }

  buildChartScreen({metadata, category, isPercent, maxForced, suffix, numDecimals, doAllowChartHorizontalScroll}) {
    return {
      type: 'chart',
      config: {
        yAxis: {
          min: 0,
          maxForced, // Only set if the max should not change dynamically, such as for 0-10 scales
          suffix: isPercent ? '%' : suffix ? ' ' + suffix : '',
          isPercent
        },
        tooltip: {
          suffix: isPercent ? '%' : suffix ? suffix : ''
        },
        doAllowChartHorizontalScroll,
        smile: metadata.successThreshold ? {
          y: metadata.successThreshold * (isPercent ? 100 : 1)
        } : null,
        loadDataPoints: (shift, pointsPerScreen) => {
          return this.loadChartData(category, shift, pointsPerScreen, isPercent, numDecimals);
        }
      },
      footer: this.buildScreenFooter({textContent: metadata.textContent})
    };
  }

  loadChartData(category, shift, pointsPerScreen, isPercent, numDecimals) {
    let multiplier = isPercent ? 100 : 1;
    numDecimals = numDecimals ? numDecimals : 0;
    let decimalDivisor = Math.pow(10, numDecimals);
    let startOfCurrentMonth = this.moment().startOf('month');
    let isDemoForCategory = false;
    switch (category) {
      case 'sharing': isDemoForCategory = this.isDemoSharing; break;
      case 'recycling': isDemoForCategory = this.isDemoRecycling; break;
      case 'electricity': isDemoForCategory = this.isDemoElectricity; break;
      case 'water': isDemoForCategory = this.isDemoWater; break;
      case 'wellbeing': isDemoForCategory = this.isDemoWellbeing; break;
    }
    return this.sustainabilityService.getChartValues(category, this.currentScope,
      'month',
      this.moment(startOfCurrentMonth).add((shift + 1) * pointsPerScreen * -1 + 1, 'months'),
      this.moment(startOfCurrentMonth).add(shift * pointsPerScreen * -1, 'months'),
      isDemoForCategory
    ).then(data => {
      return data.sort((dp1, dp2) => dp2.date - dp1.date).map(dp => {
        let dateMoment = this.moment(dp.date);
        return {
          val: Math.round(dp.value * multiplier * decimalDivisor) / decimalDivisor,
          xValLines: [
            dateMoment.format('MMM') +
            (dateMoment.date() !== 1 ? dateMoment.format(' D') : ''),
            dateMoment.get('year') !== this.moment().get('year') ? ' ' + dateMoment.format('YY') : ''
          ]
        };
      });
    });
  }

  roundValue(value) {
    if (!value) {
      return value;
    }
    const numDecimals = Math.abs(value) < 50.0 ? 1 : 0;
    const decimalDivisor = Math.pow(10, numDecimals);
    return Math.round(value * decimalDivisor) / decimalDivisor;
  }

  showHideWellbeingFeedbackPopup() {
    this.wellbeingFeedbackPopupShown = !this.wellbeingFeedbackPopupShown;
  }

  saveWellbeingFeedback() {
    this.sustainabilityService.saveWellbeingFeedback(this.wellbeingEstimateValues, this.isDemoWellbeing).then(() => {
      this.wellbeingFeedbackButtonTranslateKey = 'SUSTAINABILITY.THANKS_FOR_YOUR_ANSWER';
      this.wellbeingVisualizationConfig.dateLastAnswered = this.moment().format('YYYY-MM-DD');
      this.canSubmitWellbeingFeedback = false;
      this.wellbeingFeedbackPopupShown = false;
    });
  }

  onExpandIsComingSoonClick() {
    this.isComingSoonExpanded = !this.isComingSoonExpanded;
    if (this.isComingSoonExpanded) {
      this.trackingService.track('sustainability.is_coming_soon.expanded');
    }
  }

  openReportPopup(reportPopup){
    return this.$mdDialog.show({
      template: require('./sustainabilitySnapshotPopup.tmpl.html'),
      locals: {
        data: reportPopup
      },
      clickOutsideToClose: true,
      controllerAs: 'vm',
      controller: this.reportPopupCtrl
    });
  }

  reportPopupCtrl(data, $mdDialog) {
    this.data = data;
    this.hideDialog = function () {
      $mdDialog.hide();
    };
  }

}

let moduleConfig = angular.module('ll')
  .component('sustainability', {
    template: require('./sustainability.html'),
    controller: SustainabilityController,
    controllerAs: 'vm',
    bindings: {
      scope: '<',
      initialPeriod: '<',
      initialDateFrom: '<',
      reportPopup: '<'
    },
  })
  .config(createRoute({
    name: 'app.sustainability',
    url: '/sustainability',
    redirectTo: 'app.sustainability.' + SUSTAINABILITY_SCOPES[0]
  }));

for (let scope of SUSTAINABILITY_SCOPES) {
  moduleConfig = moduleConfig.config(createRoute({
    name: 'app.sustainability.' + scope,
    component: 'sustainability',
    url: '/' + scope + '?{initialPeriod:string}&{initialDateFrom:string}',
    params: {
      reportPopup: null
    },
    resolve: {
      scope: () => scope,
      initialPeriod: ($transition$) => $transition$.params().initialPeriod,
      initialDateFrom: ($transition$) => $transition$.params().initialDateFrom,
      reportPopup: ($transition$) => $transition$.params().reportPopup
    },
  }));
}

import angular from 'angular';
import {createRoute} from '../../core/config';

angular.module('ll')
  .component('classifieds', {
    template: require('./classifieds.component.html'),
    controllerAs: 'vm',
    bindings: {
      adType: '<'
    }
  })
  .config(createRoute({
    abstract: true,
    name: 'app.advertisement',
    url: '/ad'
  }))
  .config(createRoute({
    name: 'app.advertisement.detail',
    component: 'advertisementDetail',
    url: '/:id',
    resolve: {
      item: ($transition$, ClassifiedsService, ToastMessageService, $state, $translate) => ClassifiedsService.getAdvertisement($transition$.params().id).then((item) => {
        return item;
      }, (err) => {
        ToastMessageService.showMessage($translate.instant('ERROR_MESSAGE.AD_NOT_ACCESSIBLE'));
        $state.go('app.marketplace');
      }),
      reviews: ($transition$, ClassifiedsService) => {
        let id = $transition$.params().id;
        return ClassifiedsService.getAdvertisementReviews(id);
      }
    }
  }))
  .config(createRoute({
    name: 'app.advertisement.edit',
    component: 'advertisementEdit',
    url: '/:id/edit',
    resolve: {
      item: ($transition$, ClassifiedsService) => {
        let id = $transition$.params().id;
        return ClassifiedsService.getAdvertisement(id);
      },
      currentUser: (SessionService) => SessionService.getUser()
    }
  }))
  .config(createRoute({
    abstract: true,
    name: 'app.classifieds',
    url: '/classifieds/:adType?{adCategory:int}&{search:string}&{sortField:string}&{sortDirection:string}&{onlyFreeItems:bool}',
    params: {
      classifiedsType: 'sell',
      adType: null,
      adCategory: 1,
      onlyFreeItems: false
    },
    resolve: {
      adType: ($transition$, ClassifiedsService) => ClassifiedsService.getAdvertisementTypeById($transition$.params().adType),
      adCategory: ($transition$, ClassifiedsService) => ClassifiedsService.getAdvertisementCategoryFilterById($transition$.params().adCategory),
      searchText: ($transition$) => $transition$.params().search,
      sortField: ($transition$) => $transition$.params().sortField,
      sortDirection: ($transition$) => $transition$.params().sortDirection,
      onlyFreeItems: ($transition$) => $transition$.params().onlyFreeItems,
      postCategory: (adType, FeedService) => FeedService.getPostCategories().then(c => c.find(c => c.name === adType.postCategory))
    },
    views: {
      '': 'classifieds',
      'desktopMenu@app': 'classifiedsMenu'
    }
  }))
  .config(createRoute({
    name: 'app.classifieds.list',
    component: 'advertisementList',
    url: '/list'
  }))
  .config(createRoute({
    name: 'app.classifieds.myItems',
    component: 'myAdvertisements',
    url: '/my',
    resolve: {
      items: (ClassifiedsService, SessionService, adType) => {
        let userId = SessionService.getUser().userId;
        return ClassifiedsService.fetchAdvertisements(adType.id, null, 0, null, null, 1000, false, userId);
      }
    }
  }))
  .config(createRoute({
    name: 'app.classifieds.rentOut',
    component: 'rentOut',
    url: '/rent-out',
    resolve: {
      items: (ClassifiedsService) =>  ClassifiedsService.getRentOutBookings()
    }
  }))
  .config(createRoute({
    name: 'app.classifieds.rentBookings',
    component: 'rentBookings',
    url: '/rent',
    resolve: {
      items: (ClassifiedsService) => ClassifiedsService.getRentBookings()
    }
  }));

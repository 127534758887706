import angular from 'angular';
import {createRoute} from '../../core/config';

class SetFacilityidTemplateController {

  constructor(LoaderInterceptorService, ToolbarService) {
    this.loaderInterceptorService = LoaderInterceptorService;
    this.toolbarService = ToolbarService;
  }

  $onInit() {
    this.isLoading = false;
    this.pageTitle = '';
    this.loaderInterceptorService.onLoading(isLoading => this.isLoading = isLoading);
    this.toolbarService.onConfigurationChange(settings => this.pageTitle = settings.title);
  }
}

angular.module('ll')
  .component('setFacilityidTemplate', {
    template: require('./setFacilityidTemplate.html'),
    controller: SetFacilityidTemplateController,
    controllerAs: 'vm'
  })
  .config(createRoute({
    abstract: true,
    name: 'setFacilityidTemplate',
    component: 'setFacilityidTemplate'
  }));

import angular from 'angular';
import {createRoute} from '../../core/config';

class UserSettingsController {
  constructor($scope, UserService, $translate, LoggingService, ToastMessageService, ToolbarService, $mdMedia, DeleteAccountService, Version, LanguageService, SessionService) {
    this.$scope = $scope;
    this.userService = UserService;
    this.$translate = $translate;
    this.loggingService = LoggingService;
    this.toastMessageService = ToastMessageService;
    this.toolbarService = ToolbarService;
    this.$mdMedia = $mdMedia;
    this.deleteAccountService = DeleteAccountService;
    this.version = Version;
    this.languageService = LanguageService;
    this.sessionService = SessionService;
  }

  $onInit() {
    this.title = this.$translate.instant('USERSETTINGS.TITLE');
    this.toolbarService.setPageTitle(this.title);

    this.userService.getNotificationPreferences()
      .then(data => this.populateNotificationPreferences(data));

    this.address = this.user.address;
    this.hasUserChosenPropertygroup = this.user.isPropertyMember();
    this.propertyName = this.user.isPropertyMember() ? this.user.property.name : '';
    this.newEmail = '';
    this.newEmailConfirm = '';
    this.password = '';
    this.currentPassword = '';
    this.newPassword = '';
    this.newPasswordConfirm = '';
    this.availableLanguages = [{code:'sv-SE', name:'Svenska'}, {code:'en-US', name:'English'}, {code:'pt-PT', name:'Português'}];
    this.showPropertyLevel = this.user.showPropertyLevel();
    this.showWasteInletUpdates = this.user.hasEnabledFeature('error_reporting');
    this.showMarketplaceNotifications = this.user.hasEnabledFeature('marketplace') || this.user.hasEnabledFeature('marketplace_coming_soon');
    this.showSustainabilityUpdates = this.user.hasEnabledFeature('sustainability');
    this.showPauseHourNotifications = this.user.hasEnergyFeature() || this.user.hasEnabledFeature('sustainability') && this.user.hasEnabledFeature('electricity');
    this.isDevelopment = this.version.isDevelopment;
  }

  changeLanguage() {
    this.languageService.setLanguage(this.user.language);
  }

  populateNotificationPreferences(data) {
    if (!data) {
      this.loggingService.error('Could not load notification settings');
      data = [];
    }
    this.notificationPreferences = data;
  }

  saveNotificationSettings() {
    this.userService.saveNotificationSettings(this.notificationPreferences)
      .then(() => this.showSuccessMessage())
      .catch(data => {
        this.loggingService.error('Error saving user notification settings', data);
        this.showErrorMessage();
      });
  }

  editUserInformation() {
    this.$scope.personalInfoForm.$setSubmitted(); // triggers any error messages on fields with errors
    if (this.$scope.personalInfoForm.$invalid) {
      return;
    }

    this.userService.editAccountDetails(this.user)
      .then(data => { this.userInfoFormSaved = true;})
      .then(() => this.userService.fetchLoggedInUser())
      .then(() => {
        this.$scope.personalInfoForm.$setPristine();
        this.showSuccessMessage();
      })
      .catch(data => {
        this.loggingService.error('Error saving user settings', data);
        this.showErrorMessage();
      });
  }

  editUserEmail() {
    this.$scope.changeEmailForm.$setSubmitted();
    if (this.$scope.changeEmailForm.$invalid) {
      return;
    }

    this.userService.editUserEmail(this.user.userId, this.newEmail, this.password)
      .then(resp => {
        // Reset form
        this.user.contactInformation.email = this.newEmail;
        this.password = '';
        this.newEmail = '';
        this.newEmailConfirm = '';
        this.$scope.changeEmailForm.$setUntouched();
        this.$scope.changeEmailForm.$setPristine();
        // Change to the new token
        this.sessionService.doAuthenticate(resp.token);
      })
      .then(() => this.userService.fetchLoggedInUser())
      .then(() => this.showSuccessMessage())
      .catch(data => {
        switch (data.message) {
          case 'EMAIL_ALREADY_REGISTERED':
            this.showErrorMessage(this.$translate.instant('REGISTER.ERROR.EMAIL_ALREADY_REGISTERED'));
            break;
          case 'INCORRECT_PASSWORD':
            this.showErrorMessage(this.$translate.instant('USERSETTINGS.INVALID_PASSWORD'));
            break;
          default:
            this.showErrorMessage();
        }
      });
  }

  changePassword() {
    this.$scope.changePasswordForm.$setSubmitted();
    if (this.$scope.changePasswordForm.$invalid) {
      return;
    }
    this.userService.editUserPassword(this.currentPassword, this.newPassword)
      .then(() => {
        this.showSuccessMessage();
        this.$scope.changePasswordForm.$setPristine();
      })
      .catch(resp => {
        let message = resp.status === 403 ? this.$translate.instant('USERSETTINGS.INVALID_CURRENT_PASSWORD') : this.$translate.instant('USERSETTINGS.SAVE_ERROR');
        this.showErrorMessage(message);
      });
  }

  showSuccessMessage() {
    this.toastMessageService.showMessage(this.$translate.instant('USERSETTINGS.SAVE_SUCCESS'));
  }

  showErrorMessage(message = null) {
    message = message || this.$translate.instant('USERSETTINGS.SAVE_ERROR');
    this.toastMessageService.showMessage(message);
  }

  reloadUserData() {
    this.userService.fetchLoggedInUser()
      .then(() => {
        this.toastMessageService.showMessage('User data reloaded');
      })
      .catch(resp => {
        this.toastMessageService.showMessage('Error reloading user data');
      });
  }

  saveNotificationsPreferences() {
    this.$scope.notificationPreferencesForm.$setSubmitted();
    if (this.$scope.notificationPreferencesForm.$invalid) {
      return;
    }
    this.userService.saveNotificationPreferences(this.notificationPreferences)
      .then(() => this.$scope.notificationPreferencesForm.$setPristine())
      .then(() => this.showSuccessMessage());
  }

  promptDeleteAccount(e) {
    this.deleteAccountService.prompt();
  }
}

angular.module('ll')
  .component('userSettings', {
    template: require('./user-settings.component.html'),
    controller: UserSettingsController,
    controllerAs: 'vm',
    bindings: {
      user: '<'
    }
  })
  .config(createRoute({
    name: 'app.userSettings',
    component: 'userSettings',
    url: '/settings',
    resolve: {
      user: (UserService) => UserService.fetchLoggedInUser()
    },
  }));
